import api from 'api'
import store from '../store'

export default {
  namespaced: true,

  state: {
    sumCategoris: [],
    sumUrlFields: [],
    sumCategoryVars: [],
    sumSetting: {},
    loading: false,
  },

  getters: {
    getSumCategoris(state) {
      return state.sumCategoris
    },
    getSumCategorisLen(state) {
      return state.sumCategoris.length
    },
    getSumCategoryVars(state) {
      return state.sumCategoryVars
    },
    getSumCategoryVarslen(state) {
      return state.sumCategoryVars.length
    },
    getSumUrlFields(state) {
      return state.sumUrlFields
    },
    getSumUrlFieldsLength(state) {
      return state.sumUrlFields.length
    },
    getSumSetting(state) {
      return state.sumSetting
    }
  },

  mutations: {
    setResponseData(state, {response: response, commit: commit }) {
      const included = response.data.included

      const sumCategoris =  included
        .filter(e =>  e.type == 'sum_categori')
        .sort((e1, e2) => {
          return e1.attributes.sort - e2.attributes.sort
        })
      if (sumCategoris.length > 0) {
        commit('setSumCategoris', sumCategoris)
      } else {
        commit('initSumCategoris', commit)
      }

      const sumUrlFields = included
        .filter(e =>  e.type == 'sum_url_field')
        .sort((e1, e2) => {
          return e1.attributes.sort - e2.attributes.sort
        })
      if (sumUrlFields.length > 0) {
        commit('setSumUrlFields', sumUrlFields)
      } else {
        commit('initSumUrlFields', commit)
      }

      const sumCategorisVars =  included.filter(e =>  e.type == 'sum_category_var')
      if (sumCategorisVars.length > 0) {
        commit('setSumCategoryVars', sumCategorisVars)
      } else {
        commit('initSumCategoryVars', commit)
      }

      const sumSetting =  included.find(e => e.type == 'sum_setting')
      if (sumSetting != undefined) {
        commit('setSumSetting', sumSetting)
      } else {
        commit('initSumSetting', commit)
      }
    },

    setSumCategoris(state, sumCategoris) {
      state.sumCategoris = sumCategoris
    },
    addSumCategoris(state) {
      // ID採番
      const sumCategoryLen = state.sumCategoris.length
      let id = null
      if (sumCategoryLen > 0) {
        id = Math.max(...state.sumCategoris.map(e => e.attributes.id)) + 1
      } else {
        id = 1
      }

      const sumCategori = {
        attributes: {
          id: id,
          name: '',
          sort: sumCategoryLen + 1
        }
      }
      state.sumCategoris.push(sumCategori)
    },
    spliceSumCategoris(state, { categoryId: categoryId }) {
      state.sumCategoris.some((sumCategory, i) => {
        if (sumCategory.attributes.id == categoryId)  state.sumCategoris.splice(i, 1)
      })
    },
    initSumCategoris(state, commit) {
      state.sumCategoris = []
      commit('addSumCategoris')
      commit('addSumCategoris')
    },

    setSumCategoryVars(state, sumCategoryVars) {
      state.sumCategoryVars = sumCategoryVars
    },
    addSumCategoryVars(state, { categoryId: categoryId, urlId: urlId}) {
      const sumCategoryVar = {
        attributes: {
          url_id: urlId,
          category_id: categoryId,
          title: '',
        }
      }
      state.sumCategoryVars.push(sumCategoryVar)
    },
    spliceSumCategoryVars(state, { key: key, value: value }) {
      // 逆順で削除処理を実施
      for(let i = state.sumCategoryVars.length - 1; i >= 0; i--) {
        const sumCategoryVar = state.sumCategoryVars[i]
        if (sumCategoryVar.attributes[key] == value)  state.sumCategoryVars.splice(i, 1)
      }
    },
    initSumCategoryVars(state, commit) {
      state.sumCategoryVars = []
      state.sumCategoris.forEach((sumCategory) => {
        state.sumUrlFields.forEach((sumUrlField) => {
          commit('addSumCategoryVars', {categoryId: sumCategory.attributes.id, urlId: sumUrlField.attributes.id})
        })
      })
    },

    setSumUrlFields(state, sumUrlFields) {
      state.sumUrlFields = sumUrlFields
    },
    addSumUrlFields(state) {
      // ID採番
      const sumUrlFieldsLen = state.sumUrlFields.length
      let id = null
      if (sumUrlFieldsLen > 0) {
        id = Math.max(...state.sumUrlFields.map(e => e.attributes.id)) + 1
      } else {
        id = 1
      }
      const sumUrlField = {
        attributes: {
          id: id,
          url: '',
          page_name: '',
          sort: sumUrlFieldsLen + 1
        }
      }
      state.sumUrlFields.push(sumUrlField)
    },
    spliceSumUrlFields(state, { urlId: urlId }) {
      state.sumUrlFields.some((sumUrlField, i) => {
        if (sumUrlField.attributes.id == urlId)  state.sumUrlFields.splice(i, 1)
      })
    },
    initSumUrlFields(state, commit) {
      state.sumUrlFields = []
      commit('addSumUrlFields')
      commit('addSumUrlFields')
    },

    setSumSetting(state, sumSetting) {
      state.sumSetting = sumSetting
    },
    clearSumSetting(state) {
      state.sumSetting = {}
    },
    initSumSetting(state, commit) {
      const initSumSetting = {
        attributes: {
          browse_time_over: '',
          browse_time_under: '',
        }
      }
      commit('setSumSetting', initSumSetting)
    },

    setLoading(state, loading) {
      state.loading = loading
    }
  },

  actions: {
    async edit({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
      }

      state.loading = true
      const response =  await api.get('analysis_setting/edit', { params: params }).then(function (response) {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async update({state}) {
      const params = {
        company_id: store.state.auth.user.company_id,
        sumCategoris: state.sumCategoris,
        sumUrlFields: state.sumUrlFields,
        sumCategoryVars: state.sumCategoryVars,
        sumSetting: state.sumSetting,
      }

      state.loading = true
      const response = await api.patch('analysis_setting', { params: params }).then((response) => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async delete({state, commit}) {
      state.loading = true
      const response = await api.delete('analysis_setting').then((response) => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvDownload({state}) {
      state.loading = true
      const response =  await api.post('analysis_setting/csv_download', { responseType: 'blob' }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvUpload({state, commit}, file) {
      state.loading = true
      var formData = new FormData()
      formData.append('csvfile', file)

      const response = await api.post('analysis_setting/csv_upload', { params: formData }).then(response => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    addCategory({state ,commit}) {
      commit('addSumCategoris')
      const addSumCategory = state.sumCategoris[state.sumCategoris.length - 1]
      state.sumUrlFields.forEach((sumUrlField) => {
        commit('addSumCategoryVars', {categoryId: addSumCategory.attributes.id, urlId: sumUrlField.attributes.id})
      })
    },

    addUrl({state ,commit}) {
      commit('addSumUrlFields')
      const addSumUrlField = state.sumUrlFields[state.sumUrlFields.length - 1]
      state.sumCategoris.forEach((sumCategory) => {
        commit('addSumCategoryVars', {categoryId: sumCategory.attributes.id, urlId: addSumUrlField.attributes.id})
      })
    },

    deleteCategory({commit}, {categoryId: categoryId}) {
      commit('spliceSumCategoris', { categoryId: categoryId })
      commit('spliceSumCategoryVars', { key: 'category_id', value: categoryId })
    },

    deleteUrl({commit}, {urlId: urlId}) {
      commit('spliceSumUrlFields', {urlId: urlId})
      commit('spliceSumCategoryVars', { key: 'url_id', value: urlId })
    },
  },
}
