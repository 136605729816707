  <template lang='pug'>
v-dialog(v-model='displayDialogAssign' scrollable persistent width='600px')
  v-card
    v-card-title
      span.mr-5
        | {{ roleName }} 割り当て
      v-text-field(v-model='searchWord' prepend-inner-icon='search' label='検索')
    v-divider
    v-card-text
      v-layout(row wrap)
        v-flex(v-for="individualUser in searchIndividualUsers" xs12 :key="individualUser.id")
          v-checkbox(:input-value="selectedIndividual(individualUser)" @change='changeIndividual(individualUser, $event)')
            template(v-slot:label)
              span(:class="[selectedIndividual(individualUser) ? 'red--text' : '']") {{ individualUser.attributes.name }}
    v-card-actions
      div
        v-btn(color="blue darken-1" flat @click="clickCancel") キャンセル
        v-btn(color="blue darken-1" flat @click="clickSave") 決定
</template>
<script>
import { } from 'vuex'
export default {
  name: 'TheConversionLogTd',
  props: {
    individualUsers: {
      type: Array,
      required: true,
    },
    assignIndividualUsers: {
      type: Array,
      required: true
    },
    roleName: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      displayDialogAssign: false,
      selectedIndividualsUsers: [],
      searchWord: '',
      beforeIndividuals: [],
    }
  },
  computed: {
    searchIndividualUsers: function() {
      if (!this.searchWord) { return this.individualUsers }
      return this.individualUsers.filter(e => {
        const name = e.attributes.name
        return (name || '').toLowerCase().indexOf((this.searchWord || '').toLowerCase()) > -1
      })
    },
  },
  mounted: function() {
  },
  methods: {
    displayDialog() {
      this.selectedIndividualsUsers = JSON.parse(JSON.stringify(this.assignIndividualUsers))
      this.searchWord = ''
      this.setBeforeResponsible()
      this.displayDialogAssign = true
    },
    hiddenDialog() {
      this.displayDialogAssign = false
    },
    selectedIndividual(checkUser) {
      return this.selectedIndividualsUsers.some(individualUser => checkUser.id == individualUser)
    },
    changeIndividual(individualUser, checkValue) {
      if (checkValue) {
        this.selectedIndividualsUsers.push(Number(individualUser.id))
      } else {
        this.selectedIndividualsUsers = 
          this.selectedIndividualsUsers.filter(individual => {
            return individual != individualUser.id
          })
      }
    },
    setBeforeResponsible() {
      this.beforeIndividuals = JSON.parse(JSON.stringify(this.selectedIndividualsUsers))
    },
    clickSave() {
      this.$emit('saveChangeIndividuals', this.selectedIndividualsUsers, this.beforeIndividuals)
    },
    clickCancel() {
      this.displayDialogAssign = false
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
