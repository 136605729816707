<template lang='pug'>
v-layout.column
  h3.headline
    | ホットサイン 
  v-card.mb-5.pr-3.pl-3
    v-card-text
      template(v-if="hotSignLoading")
        v-layout.justify-center(raw wrap)
          v-progress-circular.mb-3(:size="80" :width='10' color='grey' indeterminate style="width: 100%;")
          span ホットサイン読み込み中
      template(v-else)
        template(v-if="hotSignStatus=='ok'")
          template(v-if="hotSigns.length > 0")
            v-layout(row wrap).mb-3
              span.red--text.font-weight-bold {{ this.hotSignCreatedAt }}
            v-layout.pb-3(row wrap align-center v-for="hotSign in hotSigns" :key="hotSign.attributes.id")
              v-icon.pr-3(v-if="hotSign.attributes.checked" large color='orange') check_box
              v-icon.pr-3(v-else large color='grey lighten-1') indeterminate_check_box
              | {{ hotSign.attributes.name }}
          template(v-else)
            v-layout(raw wrap)
              span.red--text.font-weight-bold.pa-2 ホットサインが設定されていません。
        template(v-if="hotSignStatus=='error'")
          v-layout(raw wrap)
            span.red--text.font-weight-bold.pa-2 ホットサイン読み込みに失敗しました。
</template>

<script>

export default {
  name: 'TheHotSign',

  components: {
  },
  props: {
    storeModule: {
      type: String,
      default: ''
    },
  },
  computed: {
    hotSigns: function() {
      return this.$store.state[this.storeModule].hotSigns
    },
    hotSignStatus: function() {
      return this.$store.state[this.storeModule].hotSignStatus
    },
    hotSignLoading: function() {
      return this.$store.state[this.storeModule].hotSignLoading
    },
    hotSignCreatedAt: function() {
      return this.$store.getters[this.storeModule + '/hotSignCreatedAt']
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
