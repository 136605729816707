<script>
import FileMixin from './FileMixin'
export default {
  name: 'ResponseMixin',
  mixins: [FileMixin],
  methods: {
    chkResponse(response) {
      switch(response.status) {
      // ok: 各ページに処理を委譲する
      case 200:
        return true

      // bad_reques,Unprocessable Entity: スナックバーで表示し同じページにとどまる
      case 400:
      case 422:
        this.$store.dispatch('flash/showMessage', { message: response.data.errors.join('\r\n'), color: 'error lighten-2' })
        return false

      // forbidden: ダイアログで表示しトップページに強制戻る
      case 403:
        this.$store.dispatch('dialog/showDialog', { title: 'Error', message: response.data.errors.join('\r\n'), color: 'error lighten-2' })
        this.$router.push({ name: 'index' })
        return false

      // internal_server_error: ダイアログで表示しページにとどまる
      case 500:
        return false
      }
    },

    async chkResponseBlob(response) {
      let messages = []
      switch(response.status) {
      // ok: 各ページに処理を委譲する
      case 200:
        return true

      // bad_reques: スナックバーで表示し同じページにとどまる
      case 400:
        messages = await this.blobDataMessages(response)
        this.$store.dispatch('flash/showMessage', { message: messages.join('\r\n'), color: 'error lighten-2' })
        return false

      // forbidden: ダイアログで表示しトップページに強制戻る
      case 403:
        messages = await this.blobDataMessages(response)
        this.$store.dispatch('dialog/showDialog', { title: 'Error', message: messages.join('\r\n'), color: 'error lighten-2' })
        this.$router.push({ name: 'index' })
        return false

      // internal_server_error: ダイアログで表示しページにとどまる
      case 500:
        return false
      }
    },

    async blobDataMessages(response) {
      let resText = await new Promise((resolve) => {
        let reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsText(response.data)
      })
      return JSON.parse(resText).errors
    }
  }
}
</script>
