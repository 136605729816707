<template lang='pug'>
v-card
  v-form(ref='form', v-model='valid', lazy-validastion='', @submit.prevent='submit')
    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') アカウント
      v-flex(xs9)
        v-text-field(v-model="formAttributes.account", :rules='accountRules', required='')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') ユーザー名
      v-flex(xs9)
        v-text-field(v-model="formAttributes.name", :rules='nameRules', required='')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') メールアドレス
      v-flex(xs9)
        v-text-field(v-model="formAttributes.email", :rules='emailRules', required='')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') 権限
      v-flex(xs9)
        v-select(
          v-model='formAttributes.company_role_id',
          @change='changeCompanyRole'
          :items='getCompanyRoleList',
          item-text='role_name',
          item-value='id',
          label='選択してください',
          single-line='',
          :rules='companyRoleRules',
          required='',
        )

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label') デフォルト表示
      v-flex(xs9)
        v-select(
          v-model='formAttributes.report_page_default_view',
          :items='reportPageDefaultViewItems',
          item-text='text',
          item-value='value',
          label='選択してください',
          single-line='',
        )

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label') コンバージョンレポート 閲覧履歴表示順序
      v-flex(xs9)
        v-select(
          v-model='formAttributes.conversion_report_default_sort',
          :items='conversionReportDefaultSortItems',
          item-text='text',
          item-value='value',
          label='選択してください',
          single-line='',
          required='',
        )

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label') 再来訪メール 閲覧履歴表示順序
      v-flex(xs9)
        v-select(
          v-model='formAttributes.revisit_mail_sort',
          :items='revisitMailSortItems',
          item-text='text',
          item-value='value',
          label='選択してください',
          single-line='',
          required='',
        )

    v-layout(v-if="(isTargetRole('shop') || isTargetRole('ind'))", row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') 所属部門
      v-flex(xs9)
        v-select(
          v-model='formAttributes.affiliation_dept',
          :items='deptUsers',
          item-text='name',
          item-value='id',
          label='選択してください',
          :rules='affiliationDeptRules',
          single-line='',
          :disabled="isTargetRole('ind')",
          required='',
        )

    v-layout(v-if="isTargetRole('ind')", row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label') 所属店
      v-flex(xs9)
        v-select(
          v-model='formAttributes.affiliation_shop',
          @change='changeAffiliationShop',
          :items='shopUsers',
          item-text='name',
          item-value='id',
          label='選択してください',
          :rules='affiliationShopRules',
          single-line='',
          required='',
        )

    v-layout(row wrap class='mb-5')
      v-flex.offset-xs3(v-if='isNew()')
        v-btn(color='info' @click='submitCreate' :disabled='!valid || loading' :loading="loading") 登録
        p.red--text ※ 登録したメールアドレス宛に、パスワード発行用のメールが送信されます。
      v-flex.offset-xs3(v-else)
        v-btn(color='info' @click='submitUpdate' :disabled='!valid || loading' :loading="loading") 更新
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'TheUserForm',

  props: {
    action: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      valid: true,
      reportPageDefaultViewItems: [
        { text: '詳細', value: 0, },
        { text: '一覧', value: 1, },
        { text: '要約', value: 2, },
      ],
      conversionReportDefaultSortItems: [
        { text: '観覧日時が古い順', value: 0, },
        { text: '観覧日時が新しい順', value: 1, },
        { text: '観覧時間が長い順', value: 2, },
        { text: '観覧時間が短い順', value: 3, },
      ],
      revisitMailSortItems: [
        { text: '観覧日時が古い順', value: 0, },
        { text: '観覧日時が新しい順', value: 1, },
        { text: '観覧時間が長い順', value: 2, },
        { text: '観覧時間が短い順', value: 3, },
      ],
      accountRules: [
        v => !!v || '必須入力です。',
      ],
      nameRules: [
        v => !!v || '必須入力です。',
        v => (v.length <= 255) || '255文字以内で入力してください。',
      ],
      emailRules: [
        v => !!v || '必須入力です。',
        v => (v.length <= 255) || '255文字以内で入力してください。',
        v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
      ],
      companyRoleRules: [
        v => !!v || '必須選択です。',
      ],
      affiliationDeptRules: [
        v => !!v || '必須選択です。',
      ],
      affiliationShopRules: [
        v => !!v || '必須選択です。',
      ],
    }
  },

  computed: {
    ...mapGetters('user', [
      'getCompanyRoleList',
      'isTargetRole',
    ]),

    formAttributes: {
      get () {
        return this.$store.state.user.formAttributes
      },
      set (value) {
        this.$store.commit('user/setFormAttributes', value)
      }
    },

    loading: {
      get () {
        return this.$store.state.user.loading
      },
      set (value) {
        this.$store.commit('user/changeLoading', value)
      }
    },

    deptUsers: function () {
      return this.$store.state.user.deptUsers
    },

    shopUsers: function () {
      return this.$store.state.user.shopUsers
    },
  },

  async created() {
    let params = { company_id: this.$route.params.company_id, role_code: '' }
    this.fetchCompanyRoles(params)
    params.role_code = 'dep'
    await this.fetchUsersByRole(params)
    params.role_code = 'shop'
    await this.fetchUsersByRole(params)
  },

  beforeDestroy: function() {
    this.clearFormAttributes()
  },

  methods: {
    ...mapActions('user', [
      'fetchCompanyRoles',
      'fetchUsersByRole',
      'create',
      'update',
    ]),

    ...mapMutations('user', [
      'clearFormAttributes',
      'changeLoading',
    ]),

    changeCompanyRole() {
      this.formAttributes.affiliation_dept = null
      this.formAttributes.affiliation_shop = null
    },

    // 選択した所属店に紐づく所属部門を自動でセットする
    changeAffiliationShop() {
      const shopUser = this.shopUsers.find(data => data.id == this.formAttributes.affiliation_shop)
      const deptUser = this.deptUsers.find(data => data.id == shopUser.affiliation_dept)
      if (deptUser === void 0) {
        this.formAttributes.affiliation_dept = null
      } else {
        this.formAttributes.affiliation_dept = deptUser.id
      }
    },

    async submitCreate() {
      if (!this.validate()) {
        return
      }

      this.loading = true
      const params = { company_id: this.$route.params.company_id }
      const res = await this.create(params)

      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', { message: 'ユーザーを登録しました。', color: 'info' })
        this.$router.push({ name: 'users'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error'} )
      }
      this.loading = false
    },

    async submitUpdate() {
      if (!this.validate()) {
        return
      }

      this.loading = true
      const params = {
        company_id: this.$route.params.company_id,
        user_id: this.$route.params.user_id,
      }
      const res = await this.update(params)

      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', { message: 'ユーザー情報を更新しました。', color: 'info'} )
        this.$router.push({ name: 'users'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error'} )
      }
      this.loading = false
    },

    validate () {
      return this.$refs.form.validate()
    },

    isNew() {
      return this.action === 'new'
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
