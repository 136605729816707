import api from 'api'

export default {
  namespaced: true,

  state: {
    conversionlogs: [],
    conversionlog: {},
    loading: false,
    totalPages: 0,
    searchCondition: {
      filterColumn: '',
      filterValue: ''
    }
  },

  getters: {
    getConversionlog(state) {
      return state.conversionlog
    },
    getConversionlogs(state) {
      return state.conversionlogs
    },
    getConversionlogsSize(state) {
      return state.conversionlogs.length
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
      }
    }
  },

  mutations: {
    setConversionlog(state, conversionlog) {
      // DB関連付けの格納先
      conversionlog.relation = {}
      for (let key in conversionlog.included)  {
        let include = conversionlog.included[key]
        if (conversionlog.relation[include.type] == undefined) {
          conversionlog.relation[include.type] = [include]
        } else {
          conversionlog.relation[include.type].push(include)
        }
      }
      delete conversionlog.included
      state.conversionlog = conversionlog
    },
    setConversionlogs(state, conversionlogs) {
      state.conversionlogs = conversionlogs
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },
    setFilterColumn(state, filterColumn) {
      state.searchCondition.filterColumn = filterColumn
    },
    setFilterValue(state, filterValue) {
      state.searchCondition.filterValue = filterValue
    },
    changeLoading(state, loading) {
      state.loading = loading
    },

    chgStopRevisit(state, idx) {
      let conversion = state.conversionlogs[idx]
      conversion.attributes.stop_revisit = !conversion.attributes.stop_revisit
    },
    clearConversionlogs(state) {
      state.conversionlogs = []
    },
    clearConversionlog(state) {
      state.conversionlog = {}
    },
    clearSearchCondition(state) {
      state.searchCondition = {
        filterColumn: '',
        filterValue: ''
      }
    }
  },

  actions: {
    fetch({commit, state}, urlParams) {
      const params = {
        company_id: urlParams.company_id,
        filter_column: state.searchCondition.filterColumn,
        filter_value: state.searchCondition.filterValue
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      let options = {
        params: params
      }
      return new Promise((resolve) => {
        api.get('/admin/companies/' + urlParams.company_id + '/conversion_logs',  options)
          .then(response => {
            let data = response.data
            commit('setConversionlogs', data.data)
            commit('setTotalPages', data.links.total_pages)
            resolve()
          })
      })
    },

    show({commit}, urlParams) {
      return new Promise((resolve) => {
        api.get('/admin/companies/' + urlParams.company_id + '/conversion_logs/' + urlParams.conversion_log_id)
          .then(response => {
            let data = response.data
            commit('setConversionlog', data)
            resolve()
          })
      })
    },

    async update(state, {conversionLog: conversionLog, urlParams: urlParams}) {
      const params = {
        stop_revisit: conversionLog.attributes.stop_revisit,
        is_delete: conversionLog.attributes.is_delete,
      }
      return await api.patch('/admin/companies/' + urlParams.company_id + '/conversion_logs/' + conversionLog.id, {params: params}).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
    },
    chgStopRevisit({commit, getters}, {urlParams: urlParams, idx: idx}) {
      commit
      idx
      let conversion = getters.getConversionlogs[idx]
      return new Promise((resolve) => {
        api.patch('/admin/companies/' + urlParams.company_id + '/conversion_logs/' + conversion.id + '/chg_stop_revisit/'  )
          .then(response => {
            commit('chgStopRevisit', idx)
            resolve(response)
          }).catch(error => {
            resolve(error.response)
          })
      })
    }
  },
}
