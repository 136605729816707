import api from 'api'

export default {
  namespaced: true,

  state: {
    loading: false,
    searchText: '',
    ipAddressInfos: [],
  },
  mutations: {
    setSearchText(state, searchText) {
      state.searchText = searchText
    },
    setIpAddressInfos(state, ipAddressInfos) {
      state.ipAddressInfos = ipAddressInfos
    },
  },
  actions: {
    async fetch({ state, commit }) {
      const params = {
        ips :  state.searchText
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      state.loading = true
      await api.get('./admin/ip_addresses', { params: params }).then(function (response) {
        const data = response.data
        commit('setIpAddressInfos', data)
      }).catch(err => {
        return err.response
      })
      state.loading = false
    },
  },
}
