<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 会社情報登録

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-card
        v-form
          companyForm(action='new', :company="getCompany", v-if="!loading")
</template>


<script>
import companyForm from '../../components/TheCompanyForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Companies',
  components: {
    companyForm
  },
  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters('company', [
      'getCompany',
    ]),
  },

  mounted: function() {
    this.new()
    this.loading = false
  },

  beforeDestroy: function() {
    this.clearCompany()
  },

  methods: {
    ...mapActions('company', [
      'clearCompany',
      'new'
    ]),
    update (pathName, params) {
      this.$router.push({ name: pathName, params: params })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
