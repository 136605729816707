import api from 'api'

const DEFAULT_CONVERSION_LOG = {
  attributes: {}
}
const DEFAULT_USER_COOKIE = {
  attributes: {}
}
const DEFAULT_MAIL_DEFAULT = {
  attributes: {
    replace_mail_body: ''
  }
}

export default {
  namespaced: true,

  state: {
    conversionLog: {},
    userCookie: {},
    mailDefault: {},
    mailFormats: [],
    mailFormatsBySumCategoryId: {},
    sumCategories: [],
    sumCategoryById: {},
    loading: false,
  },

  getters: {
    conversionLogsRelationCode(state) {
      return state.conversionLog.attributes.relation_code ?
        state.conversionLog.attributes.relation_code.replace(/,/g, ', ') : ''
    },
    conversionLogsItemCode(state) {
      return state.conversionLog.attributes.item_code ?
        state.conversionLog.attributes.item_code.replace(/,/g, ', ') : ''
    },
    conversionLogsCreated(state) {
      return state.conversionLog.attributes.created
    },
    sumCategoryById: (state) => (id) => {
      return state.sumCategoriesById[id]
    },
  },

  mutations: {
    setConversionLog(state, conversionLog) {
      state.conversionLog = conversionLog
    },
    setUserCookie(state, userCookie) {
      state.userCookie = userCookie
    },
    setMailFormats(state, mailFormats) {
      state.mailFormats = mailFormats
      state.mailFormatsBySumCategoryId = {}
      mailFormats.forEach(mailFormat => {
        const category_id = mailFormat.attributes.category_id
        if (state.mailFormatsBySumCategoryId[category_id]) {
          state.mailFormatsBySumCategoryId[category_id].push(mailFormat)
        } else {
          state.mailFormatsBySumCategoryId[category_id] = [mailFormat]
        }
      })
    },
    setMailDefaultReplaceMailBody(state, replaceMailBody) {
      state.mailDefault.attributes.replace_mail_body = replaceMailBody
    },
    setMailDefault(state, mailDefault) {
      state.mailDefault = mailDefault
    },
    setSumCategories(state, sumCategories) {
      state.sumCategories = sumCategories
      state.sumCategoriesById = {}
      sumCategories.forEach(sumCategory => {
        state.sumCategoriesById[sumCategory.id] = sumCategory
      })
    },
  },

  actions: {
    async fetch({ state, commit }, urlParams) {
      state.loading = true
      const params = {
      }
      const options = {
        params: params,
      }
      const response =  await api.get('conversion_logs/' + urlParams.conversion_log_id + '/auto_mail', options).then(response => {
        const data = response.data
        commit('setConversionLog', data.conversion_log.data)
        commit('setUserCookie', data.user_cookie.data)
        data.mail_default.data ? commit('setMailDefault', data.mail_default.data) : commit('setMailDefault', JSON.parse(JSON.stringify(DEFAULT_MAIL_DEFAULT)))
        commit('setMailFormats', data.mail_formats.data)
        commit('setSumCategories', data.sum_categories.data)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async initState({ commit } ) {
      commit('setConversionLog', JSON.parse(JSON.stringify(DEFAULT_CONVERSION_LOG)))
      commit('setUserCookie', JSON.parse(JSON.stringify(DEFAULT_USER_COOKIE)))
      commit('setMailDefault', JSON.parse(JSON.stringify(DEFAULT_MAIL_DEFAULT)))
    },

    async chgReplaceMailBody({commit}, replaceMailBody) {
      commit('setMailDefaultReplaceMailBody', replaceMailBody)
    }
  },
}
