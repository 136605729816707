<template lang='pug'>
v-layout(align-center justify-center)
  v-flex(xs12 sm8 md4)
    v-card
      v-card-text
        v-layout(align-center justify-center)
          img(src='~apojaws.svg')
      v-card-text
        v-form(ref='form' v-model='valid' lazy-validation @submit.prevent='submit')
          v-text-field(v-model='email' prepend-icon='person' name='login' label='ID' type='email' :rules='emailRules' required)
          v-text-field#password(v-model='password' prepend-icon='lock' name='password' label='パスワード' type='password' :rules='passwordRules' required)
          v-card-actions
            v-layout(align-center justify-center)
              v-btn(color='accent' :disabled='!valid || loading' :loading='loading' type='submit')
                v-icon lock_open
                | ログイン
      v-layout.align-center.justify-center.column(v-if='!isAdmin')
        v-card-text
          | ※パスワードをお忘れの場合または、パスワード登録の有効期限が切れてしまった場合は、
          |
          router-link(:to="{ name: 'send_mail' }") こちら
          |
          | からパスワードの再発行を行ってください。
</template>

<script>
import 'logo.jpg'

export default {
  name: 'Login',
  data () {
    return {
      valid: true,
      loading: false,
      email: '',
      password: '',
      passwordRules: [
        v => !!v || '必須入力です。',
        v => (v && v.length <= 255) || '255文字以内で入力してください。',
      ],
      emailRules: [
        v => !!v || '必須入力です。',
        v => (v && v.length <= 255) || '255文字以内で入力してください。',
        v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
      ],
    }
  },

  computed: {
    isAdmin: function () {
      return this.$store.state.apojaws.isAdmin
    },
  },

  methods: {
    async submit () {
      if (!this.validate()) {
        return
      }

      this.loading = true
      const storeKey = this.isAdmin ? 'admin_auth' : 'auth'
      const apiParams = {
        email: this.email,
        password: this.password,
      }

      // ログイン
      const res = await this.$store.dispatch(storeKey + '/login', apiParams)

      if (res.status == 200 && this.$store.state[storeKey].loggedIn) {
        return this.go()
      } else if (res.status == 500) {
        this.loading = false
        return 
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error' })
      }

      this.loading = false
    },

    go () {
      // URL クエリが存在しなければトップ画面に遷移
      // 存在する場合はアクセスしようとした画面に遷移
      if (Object.keys(this.$route.query).length === 0) {
        this.$router.push({ name: this.isAdmin ? 'admin_index' : 'index'})
      } else {
        this.$router.push({ path: this.$route.query.redirect })
      }
    },

    validate () {
      return this.$refs.form.validate()
    },
  }
}
</script>

<style lang='scss' scoped>
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
