<template lang='pug'>
v-card.pa-4
  v-form(ref='form' v-model='valid' lazy-validastion='', @submit.prevent="submit")
    v-layout(row xs12 class="mb-3")
      v-flex(xs2)
        v-subheader(class='label')
          | 名前
    v-layout(row xs12 class="mb-3")
      v-flex(xs2 offset-xs2)
        v-subheader(class='required label')
          | タイトル
      v-flex(xs8)
        v-text-field(v-model='formAttributes.name' :rules='titleRule')
    v-divider
    v-layout(row xs12 class="mb-3")
      v-flex(xs2)
        v-subheader(class='label')
          | 条件
    v-layout(row xs12 class="mb-3")
      v-flex(xs2 offset-xs2)
        v-subheader(class='required label')
          | 対象
      v-flex(xs8)
        v-select(
          v-model='formAttributes.hot_sign_class_id',
          :items='hotSignClasses',
          item-text='[1].text',
          item-value='[1].id',
          label='選択してください',
          single-line='',
          required='',
          :rules='hotSignClassRules',
        )
    template(v-if='(this.hotSignClass != undefined)')
      component(v-bind:is="currentFormComponent" :formAttributes="formAttributes" :hotSignClass="hotSignClass")
    v-layout(row wrap class='mb-5')
      v-flex(xs2 offset-xs2)
      v-flex(xs8)
        v-btn(v-if="action == 'new'" color='info' @click='submitCreate' :disabled='!valid' :loading="loading") 登録
        v-btn(v-else-if="action == 'edit'" color='info' @click='submitUpdate' :disabled='!valid' :loading="loading") 更新
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import visit from './hot_sign/TheHotSignVist'
import device from './hot_sign/TheHotSignDevice'
import visit_stay from './hot_sign/TheHotSignVisitStay'
import referror from './hot_sign/TheHotSignReferror'
import visit_time from './hot_sign/TheHotSignVisitTime'
import visit_number from './hot_sign/TheHotSignVisitNumber'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'HotSignForm',
  components: {
    visit,
    device,
    visit_stay,
    referror,
    visit_time,
    visit_number,
  },
  mixins: [ResponseMixin],
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      titleRule: [
        v => !!v || '入力必須です',
      ],
      hotSignClassRules: [
        v => !!v || '入力必須です',
      ],
    }
  },
  computed: {
    ...mapGetters('company', [
    ]),
    formAttributes: {
      get() {
        return this.$store.state.hot_sign.formAttributes
      },
      set(value) {
        this.$store.commit('hot_sign/setFormAttributes', value)
      }
    },
    hotSignClasses: {
      get () {
        return this.$store.state.hot_sign.hotSignClasses
      },
      set (value) {
        this.$store.commit('hot_sign/setHotSignClasses', value)
      }
    },
    hotSignClass: function() {
      return this.hotSignClasses.find((e) => {
        return e[1].id == this.formAttributes.hot_sign_class_id
      })
    },
    currentFormComponent: function() {
      return this.hotSignClass[0]
    },
    loading: function () {
      return this.$store.state.hot_sign.loading
    }
  },

  methods: {
    ...mapActions('hot_sign', [
      'create',
      'update'
    ]),

    async submitUpdate() {
      const urlParams = {
        id: this.$route.params.hot_sign_id
      }
      const response = await this.update(urlParams)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: 'ホットサイン設定を変更しました。', color: 'info' })
      this.$router.push({ name: 'hot_signs'})
    },

    async submitCreate() {
      const response = await this.create()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: 'ホットサイン設定を追加しました。', color: 'info' })
      this.$router.push({ name: 'hot_signs'})
    },
  },

}
</script>

<style lang='scss' scoped>
</style>
