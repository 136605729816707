<template lang='pug'>
div(v-if="notice")
  v-menu(v-model="menu" :close-on-click="false" :close-on-content-click="false" offset-x left :z-index="zindex")
    template(v-slot:activator="{ on }")
      v-btn(fab fixed bottom right @click="submitChangeMenu" v-on="on")
        v-img(:src="notice.attributes.vue_file_full_path")
    v-card
      v-flex.text-xs-right
        v-icon.mr-1(v-if="!fullScreen" @click="submitChangeFullScreen") fullscreen
        v-icon.mr-1(v-if="fullScreen" @click="submitChangeFullScreen") fullscreen_exit
        v-icon.mr-1(@click="submitChangeMenu") close
      v-divider
      v-card-text(:class="cardClass")
       p(v-html="notice.attributes.message")
</template>

<script>

export default {
  name: 'TheNotice',
  props: {
    stateTarget: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    }
  },
  computed: {
    zindex: function() {
      if (this.stateTarget == 'admin_notice') {
        return 203
      } else {
        return 200
      }
    },
    notice: function() {
      return this.$store.state[this.stateTarget].notice
    },
    menu: {
      get() {
        return this.$store.state.notice_display.menu
      },
      set(value) {
        this.$store.commit('notice_display/setMenu', value)
      },
    },
    fullScreen: {
      get() {
        return this.$store.state.notice_display.fullScreen
      },
      set(value) {
        this.$store.commit('notice_display/setFullScreen', value)
      }
    },
    cardClass: {
      get() {
        return this.$store.state.notice_display.cardClass
      },
      set(value) {
        this.$store.commit('notice_display/setCardClass', value)
      }
    }
  },
  watch: {
    fullScreen() {
      this.cardClass = this.fullScreen ? 'full-message' : 'small-message'
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    if (this.menu) {
      this.menuReload(1000)
    }
  },
  created() {
  },
  methods:{
    handleResize: function() {
      this.menuReload(100)
    },
    submitChangeFullScreen: function() {
      this.fullScreen = !this.fullScreen
      this.menuReload(100)
    },
    submitChangeMenu: function() {
      this.menu = !this.menu
    },
    menuReload(time) {
      this.menu = !this.menu
      setTimeout(() => {
        this.menu = !this.menu
      },time)
    }
  },
}
</script>

<style lang='scss' scoped>
.small-message {
  max-width: 375px;
  max-height: 105px;
  overflow-y: scroll;
}
.full-message {
  white-space: nowrap;
}
</style>
