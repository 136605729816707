<template lang='pug'>
v-layout.column
  v-layout.row.wrap
    v-flex.text-xs-left
      h2.headline IPアドレス検索結果一覧
  v-layout.row.wrap.mt-1
    v-flex.xs12
      v-card
        v-card-text
          v-layout.row.wrap
            v-flex.text-xs-left
              h3 IPアドレス検索
          v-layout.row.wrap.align-center.justify-center
            v-flex.xs10
              // 検索フィールド
              v-textarea(v-model='searchText' prepend-icon='form' name='ips' type='text' placeholder='111.111.222.222, 111.111.222.223')
          // 検索ボタン
          v-btn(block color="primary" :loading='loading' :disabled='loading' @click='search()')
            v-icon search
            | 検索
  v-layout.row.wrap.mb-2.xs12
    v-flex.xs12
      v-data-table#data-table(:items="ipAddressInfos", :loading="loading", class="elevation-1")
        template(v-slot:headers="props")
          tr#table-th.primary
            th.body-2.font-weight-bold.white--text(width='15%') IPアドレス
              span.body-2
            th.body-2.font-weight-bold.white--text(width='20%') 場所
              span.body-2
            th.body-2.font-weight-bold.white--text(width='40%') 会社名
              span.body-2
        template(v-slot:items="props" transition="slide-x-transition" width)
          td {{ props.item.ip }}
          td {{ props.item.access_location }}
          td {{ props.item.access_organization }}
</template>

<script>
import { mapActions, mapState }  from 'vuex'

export default {
  name: 'IpAddresses',

  computed: {
    ...mapState(['admin_ip_address']),

    ipAddressInfos: function() {
      return this.admin_ip_address.ipAddressInfos
    },
    searchText: {
      get () {
        return this.admin_ip_address.searchText
      },
      set (value) {
        this.$store.commit('admin_ip_address/setSearchText', value)
      },
    },
    loading: function () {
      return this.admin_ip_address.loading
    },
  },
  methods: {
    ...mapActions('admin_ip_address', [
      'fetch'
    ]),
    async search() {
      await this.changeSearchQuery({})
      await this.fetch()
    },
  }
}
</script>
