<template lang='pug'>
div
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline ホットサイン一覧
    v-flex(text-xs-right)
      v-btn(color="primary" @click='submitCsvDownload' :disabled='loading' :loading='loading') 現在の結果をダウンロード（CSV）
  v-layout(row wrap class='mb-2')
    v-flex.xs12
      v-data-table(:items="getConversionLogs", :headers="headers" :loading="loading", class="elevation-3", :total-items="getConversionLogsSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(
              v-for="(header, idx) in headers",
              :key="idx",
              :class="['column', header.sortable ? 'sortable' : '', header.value === pagination.sortBy ? 'active' : '']",
              @click="changeSort(header)",
            )
              v-icon(v-if="header.sortable" small) arrow_upward
              | {{ header.text }}
              v-menu(v-if='header.searchMenu', v-model='menus[header.value]', :close-on-content-click='false', :nudge-width='200', offset-x='')
                template(v-slot:activator='{ on }')
                  v-icon(:color="searchConditions[idx - DEFAULT_HEADERS_LENGTH].value != null ? 'accent' : ''", v-on='on', small) search
                v-card
                  v-list
                    v-list-tile(avatar='')
                      v-list-tile-content
                        v-list-tile-title {{ header.text }}
                  v-divider
                  v-list
                    v-list-tile
                      v-select(
                        v-model='searchConditions[idx - DEFAULT_HEADERS_LENGTH].value',
                        :items='items',
                        :item-text='items.text',
                        :item-value='items.value',
                        label='選択してください',
                        single-line=''
                      )
                  v-card-actions
                    v-spacer
                    v-btn(flat='', @click='menus[header.value] = false') 閉じる
                    v-btn(color='primary', flat='', @click='search(header.value)', :disabled='loading', :loading='loading') 絞り込み
        template(v-slot:items="props")
          tr(:class='hotSignRatioColor(props.item)')
            td
              template(v-if="!noVisitedBeacon(props.item)")
                router-link(:to="{name: 'conversion_logs_analysis', params: { conversion_log_id: props.item.id } }") {{ props.item.attributes.created }}
              template(v-else)
                | {{ props.item.attributes.created }}
            td {{ props.item.attributes.relation_code }}
            td {{ props.item.attributes.session_count }}
            td {{ getDepartmentName(props.item.attributes.department) }}
            td {{ getShopName(props.item.attributes.shop) }}
            td 
              v-chip(v-for='individual in props.item.attributes.individuals' :key='individual' color='indigo lighten-5') {{ getIndividualName(individual) }}
            td(v-for="(searchHotCondition, idx) in searchConditions" class='text-md-center')
              v-icon(v-if="props.item.attributes.vue_rank_api_result[searchHotCondition.name] == true", color='orange') fiber_manual_record
              v-icon(v-else-if="props.item.attributes.vue_rank_api_result[searchHotCondition.name] == false", color='orange') remove
              template(v-else)
                span.red--text.font-weight-bold 未集計
      ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import FileMixin from '../../libs/FileMixin'
import ResponseMixin from '../../libs/ResponseMixin'
import ThePagination from '../../components/ThePagination'
import ColorMixin from '../../libs/ColorMixin'

export default {
  name: 'ConversionLogsHotSign',
  components: {
    ThePagination
  },
  mixins: [FileMixin, ResponseMixin, ColorMixin],
  data: function () {
    return {
      DEFAULT_HEADERS_LENGTH: 6,
      menus: {
      },
      items: [
        {
          text: 'すべて',
          value: null,
        },
        {
          text: 'ON',
          value: 'true',
        },
        {
          text: 'OFF',
          value: 'false',
        },
      ]
    }
  },

  computed: {
    ...mapState(['conversion_hot_sign', 'route']),
    ...mapGetters('conversion_hot_sign', [
      'getConversionLogs',
      'getConversionLogsSize',
      'getHotSigns',
      'getTotalPages',
      'getCurrentPage',
      'getDepartmentName',
      'getShopName',
      'getIndividualName',
      'getRoleNameByRoleCode',
      'getInitUrlQuery',
    ]),
    headers: function() {
      return [
        {
          text: '問い合わせ日時',
          value: 'created',
          descending: 'desc',
          sortable: false,
          searchMenu: false,
        },
        {
          text: '問い合わせ番号',
          value: 'relation_code',
          sortable: false,
          searchMenu: false,
        },
        {
          text: '訪問回数',
          value: 'session_count',
          sortable: false,
          searchMenu: false,
        },
        {
          text: this.getRoleNameByRoleCode('dep'),
          value: 'department',
          sortable: false,
          searchMenu: false,
        },
        {
          text: this.getRoleNameByRoleCode('shop'),
          value: 'shop',
          sortable: false,
          searchMenu: false,
        },
        {
          text: this.getRoleNameByRoleCode('ind'),
          value: 'individual',
          sortable: false,
          searchMenu: false,
        },
      ].concat(
        this.searchConditions.map((hotSign, idx) => {
          return {
            text: hotSign.name,
            value: 'conversion_' + idx,
            sortable: false,
            searchMenu: true,
          }
        })
      )
    },
    pagination: function(){
      return this.route.pagination
    },
    totalPages: function() {
      return this.conversion_hot_sign.totalPages
    },
    loading: {
      get () {
        return this.$store.state.conversion_hot_sign.loading
      },
      set (value) {
        this.$store.commit('conversion_hot_sign/changeLoading', value)
      },
    },
    searchConditions: function() {
      return this.$store.state.conversion_hot_sign.searchConditions
    },
    conversion_log_atr: function () {
      return function (cv_hot_sign) {
        return cv_hot_sign.attributes.conversion_log.data.attributes
      }
    },
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    await this.init()
    this.fetchEveryRoleUsers()
    this.actionHotSigns()
  },

  beforeDestroy: function() {
    this.clearAffiliationUsers()
  },

  methods: {
    ...mapActions('conversion_hot_sign', [
      'init',
      'fetch',
      'fetchEveryRoleUsers',
      'csv_download',
    ]),

    ...mapMutations('conversion_hot_sign', [
      'clearAffiliationUsers',
    ]),

    async actionHotSigns() {
      const response = await this.fetch()
      if (!this.chkResponse(response)) {
        return
      }
    },

    changePage() {
      this.actionHotSigns()
    },

    changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = header.value
        this.pagination.descending = false
      }
      this.pagination.currentPage = 1
      this.actionHotSigns()
    },

    async search(headerValue) {
      this.menus[headerValue] = false
      await this.changeSearchQuery(this.searchConditions)
      this.actionHotSigns()
    },
    noVisitedBeacon(conversionLog) {
      return (conversionLog.attributes.is_beacon && conversionLog.attributes.session_count <= 0)
    },

    async submitCsvDownload() {
      const response = await this.csv_download()
      if (!await this.chkResponseBlob(response)) {
        return
      }
      this.fileDownload({filePath: 'cv_hot_signs.csv', type: 'text/csv', content: response.data })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
