<template lang='pug'>
div
  v-layout(row xs12 class="mb-3")
    v-flex(xs2 offset-xs2)
      v-subheader(class='label')
        | 対象となるURL
    v-flex(xs8)
      v-textarea(v-model='formAttributes.url' solo)
  v-layout(row xs12 class="mb-3")
    v-flex(xs2 offset-xs2)
      v-subheader(class='label')
        | 基準
    v-flex(xs8)
      v-select(
        v-model='formAttributes.standard',
        :items='hotSignClass[1]["unit_options"]',
        item-text='[0]',
        item-value='[1]',
        label='選択してください',
        single-line='',
        required='',
        :suffix='hotSignClass[1]["unit"]'
      )
</template>

<script>

export default {
  name: 'HotSignReferror',
  props: {
    formAttributes: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hotSignClass: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
    }
  },
  created: function() {
    if (!this.formAttributes.standard) {
      this.formAttributes.standard = 1
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
