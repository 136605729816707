<template lang='pug'>
v-layout.column
  v-layout.mb-1(row wrap)
    v-flex(text-xs-left)
      h2.headline アナリティクスレポート
  v-layout.mb-2(row wrap)
    v-flex(xs12)
      v-card.elevation-4.mb-2
        v-card-text()
          v-layout.mb-2.pt-3(row wrap)
            v-flex(text-xs-left)
              v-select(
                v-model='selectAnalysis'
                :items='this.selectsAnalysis',
                item-text="text",
                item-value="value",
                solo,
                return-object,
                single-line,
              )
            v-flex(text-xs-right)
              v-dialog(v-model='searchDialog' width="500")
                template(v-slot:activator="{ on }")
                  v-btn(color="primary" v-on="on")
                    v-icon.pr-3 search
                    span(v-html="rangeSeachCondition")
                TheAnalysisSearchField(@close='closeDialog' @fetch='actionFetch')
          v-layout.mb-2(row wrap)
            v-expansion-panel(popout focusable)
              v-expansion-panel-content(v-for='panel in this.selectAnalysis.panels' :key="panel.value")
                template(v-slot:header)
                  div {{ panel.text }}
                v-card.ma-2.elevation-2
                  v-card-text
                    div.mb-5.pb-5(v-for="id in selectAnalysis.ids" :key="id")
                      v-subheader.title {{ titleById(id) }}
                      v-layout(row wrap)
                        v-flex(xs12 md5 lg4)
                          template(v-if='aggregateItems(panel.type, id).length > 0')
                            ThePieChart(:data='pieChartData(aggregateItems(panel.type, id))' :options='options')
                        v-flex(xs12 md7 lg8)
                          v-data-table(:items="aggregateItems(panel.type, id)", :headers="selectAnalysis.headers", hide-actions)
                            template(v-slot:headers="props")
                              tr
                                th(v-for="header in selectAnalysis.headers")
                                  | {{ header.text }}
                            template(v-slot:items="props")
                              tr
                                td(v-for="header in selectAnalysis.headers")
                                  | {{ props.item[header.value] }}
                                  template(v-if="header.value == 'ratio'") %
                            template(v-slot:footer)
                              template(v-if='aggregateItems(panel.type, id).length > 0')
                                tr
                                  td 合計
                                  td(v-for="idx in selectAnalysis.headers.length -3")
                                  td {{ aggregates(panel.type, id).sum }}
                                  td 100%
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import colors from 'vuetify/es5/util/colors'
import ThePieChart from '../components/ThePieChart'
import TheAnalysisSearchField from '../components/TheAnalysisSearchField'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'Analysis',
  components: {
    ThePieChart,
    TheAnalysisSearchField
  },
  mixins: [ResponseMixin],
  data() {
    return {
      searchDialog: false,
      backgroundColors: [
        colors.blue.lighten1,
        colors.grey.darken1,
        colors.green.lighten1,
        colors.amber.lighten1,
        colors.red.lighten2,
        colors.blue.lighten2,
        colors.grey.base,
        colors.green.lighten2,
        colors.amber.lighten2,
        colors.red.lighten2,
      ],
    }
  },
  computed: {
    ...mapGetters('analysis', [
      'aggregatesTypeId',
      'titleById',
      'rangeSeachCondition',
      'aggregateItems',
      'aggregates',
    ]),
    selectsAnalysis: function () {
      return [
        {
          text: 'カテゴリ×人数(ユニークユーザー数)の分析結果',
          value: 'categoryConversionCounts',
          ids: this.sumCategoris.map(e => e.id),
          panels:
            [
              {
                text: '1. 問い合わせしたお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'all',
              },
              {
                text: '2. アポ取得済みのお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'appoint',
              },
              {
                text: '3. 成約したお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'contract',
              },
            ],
          headers:
            [
              {
                text: 'カテゴリ名',
                value: 'category',
                sortable: false,
              },
              {
                text: 'ユニークユーザー数',
                value: 'value',
                sortable: false,
              },
              {
                text: '割合',
                value: 'ratio',
                sortable: false,
              },
            ]
        },
        {
          text: 'カテゴリ×閲覧時間合計の分析結果',
          value: 'categoryBrowses',
          ids: this.sumCategoris.map(e => e.id),
          panels:
            [
              {
                text: '1. 問い合わせしたお客様のカテゴリ別閲覧時間合計',
                type: 'all',
              },
              {
                text: '2. アポ取得済みのお客様のカテゴリ別閲覧時間合計',
                type: 'appoint',
              },
              {
                text: '3. 成約したお客様のカテゴリ別閲覧時間合計',
                type: 'contract',
              },
            ],
          headers:
            [
              {
                text: 'カテゴリ名',
                value: 'category',
                sortable: false,
              },
              {
                text: '閲覧時間',
                value: 'value',
                sortable: false,
              },
              {
                text: '割合',
                value: 'ratio',
                sortable: false,
              },
            ]
        },
        {
          text: 'ページ×人数(ユニークユーザー数)の分析結果',
          value: 'pageConversionCounts',
          ids: [0],
          panels:
            [
              {
                text: '1. 問い合わせしたお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'all',
              },
              {
                text: '2. アポ取得済みのお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'appoint',
              },
              {
                text: '3. 成約したお客様のカテゴリ別閲覧シェア(ユニークユーザー単位)',
                type: 'contract',
              },
            ],
          headers:
            [
              {
                text: 'ページ名',
                value: 'category',
                sortable: false,
              },
              {
                text: 'URL',
                value: 'url',
                sortable: false,
              },
              {
                text: 'ユニークユーザー数',
                value: 'value',
                sortable: false,
              },
              {
                text: '割合',
                value: 'ratio',
                sortable: false,
              },
            ]
        },
        {
          text: 'ページ×閲覧時間合計の分析結果',
          value: 'pageBrowses',
          ids: [0],
          panels:
            [
              {
                text: '1. 問い合わせしたお客様のカテゴリ別閲覧時間合計',
                type: 'all',
              },
              {
                text: '2. アポ取得済みのお客様のカテゴリ別閲覧時間合計',
                type: 'appoint',
              },
              {
                text: '3. 成約したお客様のカテゴリ別閲覧時間合計',
                type: 'contract',
              },
            ],
          headers:
            [
              {
                text: 'ページ名',
                value: 'category',
                sortable: false,
              },
              {
                text: 'URL',
                value: 'url',
                sortable: false,
              },
              {
                text: '閲覧時間',
                value: 'value',
                sortable: false,
              },
              {
                text: '割合',
                value: 'ratio',
                sortable: false,
              },
            ]
        },
      ]
    },
    user: function () {
      return this.$store.state.auth.user
    },
    loading: {
      get() {
        return this.$store.state.analysis.loading
      },
      set (value) {
        this.$store.commit('analysis/setLoading', value)
      }
    },
    sumCategoris: function() {
      return this.$store.state.analysis.sumCategoris
    },
    sumCategoriId: function() {
      return function(id) {
        return this.$store.state.analysis.sumCategoris.find(sumCategori => sumCategori.id == id)
      }
    },
    backgroundColor: function() {
      return function(items) {
        let colors = []
        for (let i = 0; i < items.length; i++ ) {
          colors.push(this.backgroundColors[i % this.backgroundColors.length])
        }
        return colors
      }
    },
    pieChartData: function (){
      return function(items) {
        return {
          labels: items.map(e => e.category.slice(0, 8)).slice(0, 20),
          datasets: [
            {
              label: items.map(e => e.category),
              data: items.map(e => e.ratio),
              backgroundColor: this.backgroundColor(items),
            }
          ],
        }
      }
    },
    options: function (){
      return {
        tooltips: {
          callbacks: {
            title: function(tooltipItems, data){
              return data.datasets[0].label[tooltipItems[0].index]
            },
            label: function(tooltipoItem, data) {
              const ratio = data.datasets[0].data[tooltipoItem.index]
              return '閲覧率:' + ratio + '%'
            }
          }
        },
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'top',
          fullWidth: false,
          labels: {
            boxWidth: 10,
          }
        },
      }
    },
    selectAnalysis: {
      get() {
        return this.$store.state.analysis.selectAnalysis
      },
      set (value) {
        this.$store.commit('analysis/setSelectAnalysis', value)
      }
    }
  },
  async created() {
    await this.actionFetch()
    this.selectAnalysis = this.selectsAnalysis[0]
  },
  methods: {
    ...mapActions('analysis', [
      'fetch',
    ]),
    async actionFetch() {
      const response = await this.fetch()
      if (!this.chkResponse(response)) {
        return
      }
    },
    closeDialog() {
      this.searchDialog = false
    },
  }
}
</script>

<style lang='scss' scoped>
.honorific {
  font-size: 90%;
  padding-right: 5px;
}
</style>
