<template lang='pug'>
v-snackbar(v-model="flash.needDisplay" top=true :color="flash.color" auto-height=true :timeout="flash.timeout")
  p.flash {{ flash.message }}
  v-btn(dark flat @click="submitFlashClose") 閉じる
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TheSnackbar',
  computed: {
    ...mapState({
      flash: 'flash'
    })
  },

  methods: {
    submitFlashClose() {
      this.$store.commit('flash/clearMessage')
    },
  },
}
</script>

<style lang='scss' scoped>
.flash {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
  max-height: 400px;
}
</style>
