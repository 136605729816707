<template lang='pug'>
tr.blue.lighten-5
  th(v-for="header in headerProps.headers",
     :key="header.text",
     )
    | {{ header.text }}
  th(v-for="(sumCategori, idx) in getSumCategoris")
    v-layout(row wrap)
      template(v-if="idx > 1")
        v-flex(xs10)
          v-text-field(v-model="sumCategori.attributes.name" :label="'カテゴリ' + (idx + 1)")
        v-flex(xs2)
          v-icon.mt-3(@click='submitDelCategory({categoryId: sumCategori.attributes.id})') remove_circle_outline
      template(v-else)
        v-text-field(v-model="sumCategori.attributes.name" :label="'カテゴリ' + (idx + 1)")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheAnalysisSettingTh',
  components: {
  },
  mixins: [],
  props: {
    headerProps: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('analysis_setting', [
      'getSumCategoris',
    ]),
  },

  async created() {
  },

  methods: {
    ...mapActions('analysis_setting', [
      'deleteCategory',
    ]),

    submitDelCategory({categoryId: categoryId}) {
      this.deleteCategory({categoryId: categoryId})
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
