<template lang='pug'>
tr
  td
    v-checkbox(v-model="urlProps.item.attributes.disabled" hide-details)
  td
    | {{ urlProps.item.attributes.search_url }}
  td
    v-text-field(v-model="urlProps.item.attributes.name")
  template(v-for="rankingName in getNames")
    td
      v-checkbox(
        :input-value='getAvable(urlProps.item.attributes.frontId, rankingName.attributes.id)'
        @change='changeAvable({urlId: urlProps.item.attributes.frontId,nameId: rankingName.attributes.id})'
        hide-details)
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'AnalysisSetting',
  props: {
    urlProps: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('auto_ranking_preference', [
      'getAvable',
      'getNames',
    ]),
  },

  async created() {
  },

  methods: {
    ...mapActions('auto_ranking_preference', [
    ]),
    ...mapMutations('auto_ranking_preference', [
      'changeAvable',
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
