import api from 'api'
import store from '../store'

export default {
  namespaced: true,

  state: {
    telConversionLogs: [],
    companyDivs: [],
    companyDivByDivCode: {},
    loading: false,
    totalPages: 0,
    searchConditions: {}
  },

  getters: {
    getTelConversionLogs(state) {
      return state.telConversionLogs
    },
    getTelConversionLogsSize(state) {
      return state.telConversionLogs.length
    },
    getTotalPages(state) {
      return state.totalPages
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
        searchConditions: {
          div_code: ''
        }
      }
    }
  },

  mutations: {
    setTelConversionLogs(state, telConversionLogs) {
      state.telConversionLogs = telConversionLogs
    },
    spliceCopmany(state, idx) {
      state.telConversionLogs.splice(idx, 1)
    },
    setCompanyDivs(state, companyDivs) {
      companyDivs.unshift({attributes: {name: 'すべて', div_code: null}})
      state.companyDivs = companyDivs
      state.companyDivByDivCode = {}
      companyDivs.forEach(companyDiv => {
        state.companyDivByDivCode[companyDiv.attributes.div_code] = companyDiv
      })
    },
    clearTelConversionLogs(state) {
      state.telConversionLogs = []
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },
    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },
    changeLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async init({commit}) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
    },

    async fetch({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
        div_code: state.searchConditions.div_code
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      state.loading = true
      const response = await api.get('tel_conversion_logs', { params: params }).then(function (response) {
        const data = response.data
        commit('setTelConversionLogs', data.tel_conversion_logs.data)
        commit('setCompanyDivs', data.company_divs.data)
        commit('setTotalPages', data.tel_conversion_logs.links.total_pages)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async update({ state, getters, commit }, {index: index}) { 
      const tel_conversion_log = getters.getTelConversionLogs[index]
      const params = {
        company_id: store.state.auth.user.company_id,
        attributes: {
          relation_code: tel_conversion_log.attributes.relation_code,
        }
      }
      state.loading = true
      return await api.patch('tel_conversion_logs/' + tel_conversion_log.id, { params: params }).then((response) => {
        commit('spliceCopmany', index)
        state.loading = false
        return response
      }).catch(err => {
        state.loading = false
        return err.response
      })
    },

    async delete({ state, getters, commit }, {index: index}) { 
      const tel_conversion_log = getters.getTelConversionLogs[index]
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      state.loading = true
      const response =  await api.delete('tel_conversion_logs/' + tel_conversion_log.id, { params: params }).then((response) => {
        commit('spliceCopmany', index)
        state.loading = false
        return response
      }).catch(err => {
        state.loading = false
        return err.response
      })
      return response
    },
  }
}
