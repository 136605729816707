<template lang='pug'>
v-layout(row wrap)
  v-flex(text-xs-right)
    template(v-if="isDisplayApojaws")
      router-link(:to="{name: apojaws_link_name}").btn-link
        v-btn(color="primary")
          v-icon.pr-2 library_books
          | アポジョーズ
    template(v-if="isDisplayAutoMail")
      router-link(:to="{name: 'conversion_logs_auto_mail'}",).btn-link
        v-btn(color="primary")
          v-icon.pr-2 mail
          | 自動メール作成
    template(v-if="isDisplayAnalysis")
      router-link(:to="{name: analysis_link_name}").btn-link
        v-btn(color="primary")
          v-icon.pr-2 equalizer
          | アナリティクス
    v-btn(color="primary" @click="submitPrint")
      v-icon.pr-2 print
      | 印刷
</template>

<script>
export default {
  name: 'TheNav',
  computed: {
    isDisplayApojaws: function() {
      return !(this.$route.name=='conversion_logs_report' ||  this.$route.name=='conversion_logs_revisit')
    },
    isDisplayAnalysis: function() {
      return this.roleAtr.analytics_view && !(this.$route.name=='conversion_logs_analysis' || this.$route.name=='conversion_logs_analysis_revisit')
    },
    isDisplayAutoMail: function() {
      return this.roleAtr.auto_mail_usage && !(this.$route.name=='conversion_logs_auto_mail')
    },
    apojaws_link_name: function() {
      return this.$route.params.revisit_id ? 'conversion_logs_revisit' : 'conversion_logs_report'
    },
    analysis_link_name: function() {
      return this.$route.params.revisit_id ? 'conversion_logs_analysis_revisit' : 'conversion_logs_analysis'
    },
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
  },
  methods: {
    submitPrint() {
      window.print()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
