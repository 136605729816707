<template lang='pug'>
v-layout.column
  v-layout(row wrap xs12 class="mb-1")
    v-flex(text-xs-left)
      h2 会社詳細情報
  v-layout(row wrap xs12 class="mb-4")
    v-flex(xs12 v-if='!loading')
      v-data-table(:items="[getCompany]", :headers="headers", :loading="loading", class="elevation-4", hide-actions)
        template(v-slot:items="props")
          tr
            td ID
            td {{ props.item.data.id }}
          tr
            td 会社コード
            td {{ props.item.data.attributes.company_code }}
          tr
            td 会社名
            td {{ props.item.data.attributes.company_name }}
          tr
            td グループ名
            td {{ props.item.data.attributes.group_name }}
          tr
            td 作成日時
            td {{ props.item.data.attributes.created }}
          tr
            td 更新日時
            td {{ props.item.data.attributes.updated }}
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShowCompany',
  data() {
    return {
      loading: true,
      headers: [
        {
          text: '項目',
          sortable: false
        },
        {
          text: '値',
          sortable: false,
        },
      ]
    }
  },

  computed: {
    ...mapGetters('company', [
      'getCompany',
    ]),
  },

  mounted: function() {
    this.loading = true
    this.show({company_id: this.$route.params.company_id}).then(() =>
      this.loading = false
    )
  },

  beforeDestroy: function() {
    this.clearCompany()
  },

  methods: {
    ...mapActions('company', [
      'clearCompany',
      'show'
    ]),

  },
}
</script>

<style lang='scss' scoped>
</style>
