<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline ランキング設定
    v-flex(text-xs-right)
      router-link(v-if="roleAtr.analytics_config"  :to="{name: 'analysis_setting'}", class='btn-link')
        v-btn(color="primary") アナリティクス設定
      router-link(v-if="roleAtr.auto_ranking"  :to="{name: 'autoranking_preferences'}", class='btn-link')
        v-btn(color="primary") 自動ランキング設定
  v-layout(row wrap class='mb-2' v-if="!initLoading")
    v-flex(xs12)
      v-card
        v-card-text
          v-form(ref='form' v-model='valid')
            v-flex(subheading)
              | １. ページ、ランキング設定
            v-flex
              v-container.fluid.mb-3
                v-data-table(:items="getUrls", :headers="headers", :loading="loading", class="elevation-4",  hide-actions)
                  template(v-slot:headers="props")
                    TheRankingPreferenceTh(:headerProps='props')
                  template(v-slot:items="props")
                    TheRankingPreferenceTd(:urlProps='props')
                v-icon(class='pl-3 pt-3' medium @click='addUrl') add_circle_outline
            v-flex(subheading)
              | ２．解析の基準を設定する
              br
              small(class='pl-4')
                | ※範囲内で閲覧しているページを抽出して分析する
              v-container.fluid
                v-layout(row xs12)
                  v-flex(xs3)
                    v-text-field(v-model="getPreference.attributes.filter_duration_visiting_time_min" label='秒以上' type='number' :rules='timeOverRules' ref='timeOver')
                  v-flex(xs3 ml-4)
                    v-text-field(v-model="getPreference.attributes.filter_duration_visiting_time_max" label='秒以下' type='number' :rules='timeUnderRules' ref='timeUnder')
                v-flex(text-xs-center)
                  v-btn(color="primary" @click='submitUpdate' :disabled='loading' :loading='loading') 登録・保存する
                  v-btn(color="error lighten-2" @click='submitDelete' :disabled='loading' :loading='loading') データを削除する

          v-flex(subheading)
            | ３．CSV一括登録
            br
            small(class='pl-4')
              | ※現在の保存設定値をCSVダウンロード、CSVアップロード設定する
            v-flex(text-xs-center)
              v-btn(@click='submitDownCsv' :disabled='loading' :loading='loading') CSVダウンロード
              v-btn(@click="$refs.inputUpload.click()" :disabled='loading' :loading='loading') CSV一括登録
              input(v-show="false" ref="inputUpload" type="file" @change="submitUpCsv" accept=".csv,.txt")
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FileMixin from '../libs/FileMixin'
import ResponseMixin from '../libs/ResponseMixin'
import TheRankingPreferenceTd from '../components/TheRankingPreferenceTd'
import TheRankingPreferenceTh from '../components/TheRankingPreferenceTh'

export default {
  name: 'AnalysisSetting',
  components: {
    TheRankingPreferenceTd,
    TheRankingPreferenceTh
  },
  mixins: [FileMixin, ResponseMixin],
  data() {
    return {
      initLoading: true,
      valid: true,
      timeOverRules: [
        v => (!v || !this.$refs.timeUnder || !this.$refs.timeUnder.value ||  v < this.$refs.timeUnder.value) || '入力範囲が異常です',
      ],
      timeUnderRules: [
        v => (!v || !this.$refs.timeOver || !this.$refs.timeOver.value || v > this.$refs.timeOver.value) || '入力範囲が異常です',
      ],
      urlRules: [
        v => !!v || '入力は必須です',
        v => (!v || v.length <= 250) || '250文字以内で入力してください。'
      ],
      headers: [
        {
          text: 'URL',
          value: 'url',
          sortable: false,
          rankingName: false,
        },
        {
          text: 'ページ名',
          value: 'page_name',
          sortable: false,
          rankingName: false,
        },
        {
          text: 'ランキング1',
          value: 'ranking1',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング2',
          value: 'ranking2',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング3',
          value: 'ranking3',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング4',
          value: 'ranking4',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング5',
          value: 'ranking5',
          sortable: false,
          rankingName: true,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('ranking_preference', [
      'getPreference',
      'getUrls',
    ]),
    user: function () {
      return this.$store.state.auth.user
    },
    loading: {
      get() {
        return this.$store.state.ranking_preference.loading
      },
      set (value) {
        this.$store.commit('ranking_preference/setLoading', value)
      }
    },
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
  },

  async created() {
    this.initLoading = true
    let urlParams = {
      company_id: this.$route.params.company_id
    }
    const response = await this.fetch(urlParams)
    if (!this.chkResponse(response)) {
      return
    }
    this.initLoading = false
  },

  methods: {
    ...mapActions('ranking_preference', [
      'fetch',
      'addUrl',
      'update',
      'delete',
      'csvUpload',
      'csvDownload',
    ]),
    ...mapMutations('ranking_preference', [
    ]),
    async submitUpdate() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('flash/showMessage', { message: '入力誤りがあります。', color: 'error' })
        return
      }
      const response = await this.update()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を更新しました。', color: 'info' })
    },
    async submitDelete() {
      if (!window.confirm('本当に全て削除しますか？')) {
        return
      }
      const response = await this.delete()
      this.$refs.form.resetValidation()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を削除しました。', color: 'info' })
    },
    async submitDownCsv() {
      const response = await this.csvDownload()
      if (!await this.chkResponseBlob(response)) {
        return
      }
      this.fileDownload({filePath: 'ランキング設定.csv', type: 'text/csv', content: response.data })
    },
    async submitUpCsv(e) {
      const files = e.target.files
      if (files[0] == undefined) return
      const response = await this.csvUpload(files[0])
      if (!this.chkResponse(response)) {
        return
      }

      this.$store.dispatch('flash/showMessage', { message: 'CSVを正常に取り込みました。', color: 'info' })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
