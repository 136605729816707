<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline ホットサイン設定 一覧
    v-flex(text-xs-right)
      router-link.btn-link(:to="{name: 'new_hot_sign'}")
        v-btn(color="primary")
          v-icon(class='pr-2') note_add
          | 新規作成
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-data-table(:items="getHotSigns", :headers="headers", :loading="loading", class="elevation-4", :total-items="getHotSignsSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(
              v-for="header in props.headers",
              :key="header.text",
              :class="['column', header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
              @click="changeSort(header)",
            )
              v-icon(v-if="header.sortable" small) arrow_upward
              | {{ header.text }}
        template(v-slot:items="props")
          td {{ props.item.attributes.name }}
          td
            | {{ props.item.attributes.condition_text }}
            br
            | {{ props.item.attributes.url }}
          td
            v-flex(text-xs-center)
              router-link.btn-link(:to="{name: 'edit_hot_sign', params: { hot_sign_id: props.item.id}}")
                v-btn(class="mr-3 ml-3 info")
                  v-icon(class='pr-2') edit
                  | 編集
              v-btn(class="mr-3 ml-3 error lighten-3" @click='submitDelete(props.item.id)' :disabled='loading' :loading='loading')
                v-icon(class='pr-2') delete_forever
                | 削除
  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ResponseMixin from '../libs/ResponseMixin'
import ThePagination from '../components/ThePagination'

export default {
  name: 'HotSigns',
  components: {
    ThePagination
  },
  mixins: [ResponseMixin],
  data: function () {
    return {
      headers: [
        {
          text: 'タイトル',
          value: 'name',
          descending: 'desc',
          sortable: true,
        },
        {
          text: '条件',
          value: 'condition',
          sortable: false,
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState(['route', 'hot_sign']),
    ...mapGetters('hot_sign', [
      'getHotSigns',
      'getHotSignsSize',
      'getTotalPages',
      'getCurrentPage',
      'getInitUrlQuery'
    ]),
    ...mapGetters('route', [
      'getOrder'
    ]),
    pagination: function() {
      return this.route.pagination
    },
    totalPages: function() {
      return this.hot_sign.totalPages
    },
    loading: function () {
      return this.$store.state.hot_sign.loading
    },
  },

  async mounted() {
    await this.initUrlQuery(this.getInitUrlQuery)
    await this.init()
    this.actionFetch()
  },

  methods: {
    ...mapActions('hot_sign', [
      'init',
      'fetch',
      'delete',
    ]),

    async actionFetch() {
      const response = await this.fetch()
      if (!this.chkResponse(response)) {
        return
      }
    },

    changePage() {
      this.actionFetch()
    },

    async changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.actionFetch()
    },
    async submitDelete(hotSignId) {
      if (!window.confirm('本当に削除しますか？')) {
        return
      }
      const urlParams = {
        hotSignId: hotSignId
      }
      const response = await this.delete(urlParams)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: 'ホットサイン設定を削除しました。', color: 'info' })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
