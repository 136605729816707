import { render, staticRenderFns } from "./EditCompany.vue?vue&type=template&id=5f40a706&scoped=true&lang=pug&"
import script from "./EditCompany.vue?vue&type=script&lang=js&"
export * from "./EditCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f40a706",
  null
  
)

export default component.exports