<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline 電話一覧
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-card(class="elevation-4 mb-2 elevation-2")
        v-card-text
          v-flex(text-xs-left mb-3 )
            | 以下は電話ページに到着したユーザーの情報です。
            br
            | 見込み客であり、アポジョーズで追跡開始する場合には、情報を入力し、アポジョーズを作動させてください。
          v-data-table(:items="getTelConversionLogs", :headers="headers", :loading="loading", class="elevation-2", :total-items="getTelConversionLogsSize", hide-actions)
            template(v-slot:headers="props")
              tr
                th(
                  v-for="header in props.headers",
                  :key="header.text",
                  :class="['column', header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
                  @click="changeSort(header)",
                )
                  v-icon(v-if="header.sortable" small) arrow_upward
                  | {{ header.text }}
                  v-menu(v-if='header.searchMenu', v-model='menus[header.value]', :close-on-content-click='false', :nudge-width='200', offset-x='')
                    template(v-slot:activator='{ on }')
                      v-icon(:color="searchConditions[header.value] ? 'accent' : ''", v-on='on', small) search
                    v-card
                      v-list
                        v-list-tile(avatar='')
                          v-list-tile-content
                            v-list-tile-title {{ header.text }}
                      v-divider
                      v-list
                        v-list-tile
                          v-select(
                            v-model='searchConditions[header.value]',
                            :items='companyDivs',
                            item-text='attributes.name',
                            item-value='attributes.div_code',
                            label='選択してください',
                            single-line=''
                          )
                      v-card-actions
                        v-spacer
                        v-btn(flat='', @click='menus[header.value] = false') 閉じる
                        v-btn(color='primary', flat='', @click='search(header.value)', :disabled='loading', :loading='loading') 絞り込み
            template(v-slot:items="props")
              td {{ props.item.attributes.created }}
              td {{ props.item.attributes.item_code }}
              td {{ props.item.attributes.affiliation_individuals }}
              td
                v-text-field(v-model="props.item.attributes.relation_code")
              td(class='text-xs-center')
                v-btn(class='info' @click='submitRelationCode({ index: props.index })' :disabled='loading' :loading='loading') 追加する
              td(class='text-xs-center')
                v-btn(color='error lighten-2' @click='submitDelete({ index: props.index })' :disabled='loading' :loading='loading') 削除
  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FilterMixin from '../libs/FilterMixin'
import response from '../libs/ResponseMixin'
import ThePagination from '../components/ThePagination'

export default {
  name: 'TelConversionLogs',
  components: {
    ThePagination
  },
  mixins: [FilterMixin, response],
  data: function () {
    return {
      menus: {
        name: false,
      },
      headers: [
        {
          text: '到着した日付',
          value: 'created',
          descending: 'desc',
          sortable: true,
          searchMenu: false,
        },
        {
          text: '電話発信時の閲覧ページ',
          value: 'item_code',
          searchMenu: false,
        },
        {
          text: '部署・店舗名',
          value: 'div_code',
          searchMenu: true,
        },
        {
          text: 'お客様情報',
          value: 'relation_code',
          searchMenu: false,
        },
        {
          text: 'アポジョーズへの追加',
          value: '',
          sortable: false,
          align: 'center',
          searchMenu: false,
        },
        {
          text: '履歴削除',
          sortable: false,
          searchMenu: false,
        },
      ],
    }
  },

  computed: {
    ...mapState(['tel_conversion_log', 'route']),
    ...mapGetters('tel_conversion_log', [
      'getTelConversionLogs',
      'getTelConversionLogsSize',
      'getTotalPages',
      'getCurrentPage',
      'getInitUrlQuery',
    ]),
    ...mapGetters('route', [
      'getOrder'
    ]),
    user: function() {
      return this.$store.state.auth.user
    },
    pagination: function(){
      return this.route.pagination
    },
    totalPages: function() {
      return this.tel_conversion_log.totalPages
    },
    loading: function () {
      return this.$store.state.tel_conversion_log.loading
    },
    searchConditions: function () {
      return this.$store.state.tel_conversion_log.searchConditions
    },
    companyDivs: function() {
      return this.$store.state.tel_conversion_log.companyDivs
    }
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    await this.init()
    this.callFetch()
  },

  methods: {
    ...mapActions('tel_conversion_log', [
      'init',
      'fetch',
      'update',
      'delete',
    ]),


    changePage() {
      this.callFetch()
    },

    async changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.callFetch()
    },

    async callFetch() {
      const response = await this.fetch()
      if (!this.chkResponse(response)) {
        return
      }
    },

    async submitRelationCode({index: index}) {
      const response = await this.update({index: index})
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', {message: 'アポージョーズへ追加しました。', color: 'info'})
    },

    async submitDelete({index: index}) {
      if (!window.confirm('本当に削除しますか？')) {
        return
      }
      const response = await this.delete({index: index})
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', {message: '電話情報を削除しました。', color: 'info'})
    },
    search(headerValue) {
      this.menus[headerValue] = false
      this.changeSearchQuery(this.searchConditions)
      this.callFetch()
    },
  },
}
</script>

<style lang='scss' scoped>
.honorific {
  font-size: 90%;
  padding-right: 5px;
}
</style>
