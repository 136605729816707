<script>
import { Pie } from 'vue-chartjs'
export default {
  name: 'PieChart',
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch : {
    data : function() {
      this.renderChart(this.data, this.options)
    },
    options : function() {
      this.renderChart(this.data, this.options)
    }
  },
  mounted: function(){
    this.renderChart(this.data, this.options)
  },
}
</script>
