<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-2')
    v-flex(class='subheading mb-4')
      | 一括データインポート
      br
    v-layout(row wrap class='mb-4')
      small(class='body-1')
        | ※登録したいデータが多数ある場合には一括インポートをご利用ください。インポートファイルのサンプルはコチラよりダウンロードしてください。
      v-flex(text-xs-center xs12)
        v-btn(@click='submitDownCsv') サンプルCSVダウンロード
        v-btn(@click="$refs.inputUpload.click()" :disabled='loading' :loading='loading') CSV一括登録
        input(v-show="false" ref="inputUpload" type="file" @change="submitUpCsv" accept=".csv,.txt")
    v-layout(v-if="companyRole.attributes.is_master" row wrap class='mb-2')
      small(class='body-1')
        | ※旧画面コネクト一覧からMailTrackBeaconList.csvファイルをダウンロードしてアップロード反映をしてください。
      v-flex(text-xs-center xs12)
        v-btn(@click='$refs.inputAssignUpload.click()' :disabled='loading' :loading='loading') コネクト担当割当
        input(v-show="false" ref="inputAssignUpload" type="file" @change="submitUpAssignCsv" accept=".csv,.txt")
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileMixin from '../libs/FileMixin'
import ResponseMixin from '../libs/ResponseMixin'
import EncodeMixin from '../libs/EncodeMixin'

export default {
  name: 'MailTrackingImport',
  mixins: [FileMixin, EncodeMixin, ResponseMixin],
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters('auth', [
      'getRoleNameByRoleCode',
    ]),
    loading: function () {
      return this.$store.state.mail_tracking.loading
    },
    companyRole: function() {
      return this.auth.companyRole
    }
  },
  methods: {
    ...mapActions('mail_tracking', [
      'csvUpload',
      'csvAssignUpload',
      'csvDownload',
    ]),
    async submitDownCsv() {
      const content = `# インポート用CSVファイルは、
#
#「コンバージョン日時」,「問合せ番号」, 「お客様メールアドレス」, 「${this.getRoleNameByRoleCode('dep')}」, 「${this.getRoleNameByRoleCode('shop')}」, 「${this.getRoleNameByRoleCode('ind')}」
#
# の順番で並び、カンマ区切りで入力してください。
# なお「コンバージョン日時」と「問い合わせ番号」は省略可能です。
# 問合せ番号は、お客様のお名前、お客様のメールアドレス、反響番号など、お客様を識別する情報を入力してください。
# ${this.getRoleNameByRoleCode('ind')}は複数入力可能で、カンマ区切りで入力してください。
#
例：2019/5/19 12:34,"鈴木 太郎",kazuto015@example.com,神奈川,横浜店,"佐藤一郎,斎藤二郎"
例：2019/9/23 1:23,"田中 花小",Mako_Takeshita@example.com,神奈川,川崎店,"鈴木三郎"
例：2019/3/13 12:03,"山田 太郎",fujio90505@example.com,神奈川,鎌倉店,"高橋四郎"`
      const sjisContent = this.convertArray(content, 'unicode', 'sjis')
      this.fileDownload({filePath: 'sample.csv', type: 'text/csv', content: sjisContent })
    },
    async submitUpCsv(e) {
      const files = e.target.files
      if (files[0] == undefined) return
      const response = await this.csvUpload(files[0])
      this.$refs.inputUpload.value = ''
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: 'CSVを正常に取り込みました。', color: 'info' })
    },

    async submitUpAssignCsv(e) {
      const files = e.target.files
      if (files[0] == undefined) return
      const response = await this.csvAssignUpload(files[0])
      this.$refs.inputAssignUpload.value = ''
      if (!this.chkResponse(response)) { return }
      this.$store.dispatch('flash/showMessage', { message: 'CSVを正常に取り込みました。', color: 'info' })
    },

    async submitConnectDownCsv() {
      const response = await this.csvDownload()
      if (!await this.chkResponseBlob(response)) { return }
      this.fileDownload({filePath: 'MailTrackBeaconList.csv', type: 'text/csv', content: response.data })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
