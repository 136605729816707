<template lang='pug'>
v-dialog(v-model="dialog.needDisplay" width="500")
  v-card
    v-card-title(:class="['headline', dialog.color,]" primary-title)
      | {{ dialog.title }}
    v-card-text(class='message')
      | {{ dialog.message }}
    v-divider

    v-card-actions
      v-spacer
      v-btn(color="primary" flat @click="submitClose")
       | 閉じる
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TheAlertDialog',
  computed: {
    ...mapState({
      dialog: 'dialog'
    })
  },
  methods: {
    submitClose() {
      this.$store.commit('dialog/clearMessage')
    },
  },
}
</script>

<style lang='scss' scoped>
.message {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
