<template lang='pug'>
div
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline ホットサイン設定 新規登録

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      hotSignForm(action='new')
</template>


<script>
import hotSignForm from '../components/TheHotSignForm'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'NewHotSign',
  components: {
    hotSignForm
  },
  mixins: [ResponseMixin],
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('hot_sign', [
      'getCompany',
    ]),

  },

  async mounted() {
    const response = await this.new()
    if (!this.chkResponse(response)) {
      return
    }
  },

  beforeDestroy: function() {
    this.clearFormAttributes()
  },

  methods: {
    ...mapActions('hot_sign', [
      'new',
    ]),
    ...mapMutations('hot_sign', [
      'clearFormAttributes'
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
