import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store'
import rison from 'rison-node'

// ユーザー機能
import Index from './pages/index'
import Activate from './pages/pre_user/Activate'
import PreUserComplete from './pages/pre_user/Complete'
import SendMail from './pages/password_reissue/SendMail'
import PasswordReissueComplete from './pages/password_reissue/Complete'
import ConversionLogs from './pages/ConversionLogs'
import TelConversionLogs from './pages/TelConversionLogs'
import News from './pages/News'
import AnalysisSetting from './pages/AnalysisSetting'
import AutoMail from './pages/AutoMail'
import AutoMailCategory from './pages/auto_mail/Category'
import AutoMailCustom from './pages/auto_mail/Custom'
import AutoMailDefault from './pages/auto_mail/Default'
import MailTracking from './pages/MailTracking'
import HotSigns from './pages/HotSigns'
import NewHotSign from './pages/NewHotSign'
import EditHotSign from './pages/EditHotSign'
import ConversionLogsHotSign from './pages/conversion_logs/HotSigns'
import RankingPreferences from './pages/RankingPreferences'
import AutoRankingPreferences from './pages/AutoRankingPreferences'
import Analysis from './pages/Analysis'
import ConversionLogsReport from './pages/conversion_logs/Report'
import ConversionLogsRevisit from './pages/conversion_logs/Revisit'
import ConversionLogsAnalysis from './pages/conversion_logs/Analysis'
import ConversionLogsAnalysisRevisit from './pages/conversion_logs/AnalysisRevisit'
import ConversionLogsAutoMail from './pages/conversion_logs/AutoMail'

// 管理者機能
import AdminIndex from './pages/admin/index'
import Companies from './pages/admin/Companies'
import EditCompany from './pages/admin/EditCompany'
import ShowCompany from './pages/admin/ShowCompany'
import NewCompany from './pages/admin/NewCompany'
import AdminAccessLogs from './pages/admin/AccessLogs'
import AdminConversionLogs from './pages/admin/ConversionLogs'
import ShowAdminConversionLog from './pages/admin/ShowConversionLog'
import CompanyRoles from './pages/admin/CompanyRoles'
import AdminTags from './pages/admin/Tags'
import AdminCrossDomainTags from './pages/admin/CrossDomainTags'
import Users from './pages/admin/Users'
import NewUser from './pages/admin/NewUser'
import EditUser from './pages/admin/EditUser'
import Notices from './pages/admin/Notices'
import EditNotice from './pages/admin/EditNotice'
import NewNotice from './pages/admin/NewNotice'
import IpAddresses from './pages/admin/IpAddresses'

// 共通
import Login from './pages/common/Login'
import NotFound from './pages/common/NotFound'

Vue.use(VueRouter)

// 「controller_name」部分は API のコントローラー名
// routes の path を指定する際に replace する
const userSingleBasePath = '/rp/:company_id/:user_id/controller_name'
const controllerName = 'controller_name'

const USER_BASE_TITLE = 'アポジョーズ'
const ADMIN_BASE_TITLE = 'アポジョーズ管理'

const routes = [
  // エラーページ
  { path: '*', name: 'not_found', component: NotFound, meta: { title: 'NotFound' } },

  // ユーザー機能
  { path: '/login', name: 'login', component: Login, meta: { isPublic: true, title: 'ログイン'  }, },
  { path: '/pre_user/activate/:token', name: 'activate', component: Activate, meta: { isPublic: true, title: 'パスワード登録'  }, },
  { path: '/pre_user/complete', name: 'pre_user_complete', title: '', component: PreUserComplete, meta: { isPublic: true, title: 'ユーザー登録完了' }, },
  { path: '/password_reissue/send_mail', name: 'send_mail', component: SendMail, meta: { isPublic: true, title: 'パスワード再発行'  }, },
  { path: '/password_reissue/complete', name: 'password_reissue_complete', component: PasswordReissueComplete, meta: { isPublic: true, title: '再発行メール送信'  }, },
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: () => {
      const urlParams = {
        company_id: store.state.auth.user.company_id,
        user_id: store.state.auth.user.id,
      }
      return { name: 'conversion_logs', params: urlParams }
    },
    children: [
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs'),
        name: 'conversion_logs',
        meta: { title: '過去のお問い合わせ' },
        component: ConversionLogs,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'tel_conversion_logs'),
        name: 'tel_conversion_logs',
        meta: { title: '電話一覧' },
        component: TelConversionLogs,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'news'),
        name: 'news',
        meta: { title: 'お知らせ' },
        component: News,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'analysis_setting'),
        name: 'analysis_setting',
        meta: { title: 'アナリティクス設定' },
        component: AnalysisSetting,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'auto_mail'),
        name: 'auto_mail',
        component: AutoMail,
        redirect: () => {
          return { name: 'auto_mail_custom' }
        },
        children: [
          {
            path: 'custom',
            name: 'auto_mail_custom',
            meta: { title: '自動メール設定' },
            component: AutoMailCustom,
          },
          {
            path: 'default',
            name: 'auto_mail_default',
            meta: { title: '自動メール設定' },
            component: AutoMailDefault,
          },
          {
            path: ':category_id',
            name: 'auto_mail_category',
            meta: { title: '自動メール設定' },
            component: AutoMailCategory,
          },
        ],
      },
      {
        path: userSingleBasePath.replace(controllerName, 'mail_tracking'),
        name: 'mail_tracking',
        meta: { title: 'コネクト' },
        component: MailTracking,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'hot_signs'),
        name: 'hot_signs',
        meta: { title: 'ホットサイン設定' },
        component: HotSigns,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'hot_signs/new'),
        name: 'new_hot_sign',
        meta: { title: 'ホットサイン設定' },
        component: NewHotSign,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'hot_signs/:hot_sign_id/edit'),
        name: 'edit_hot_sign',
        meta: { title: 'ホットサイン設定' },
        component: EditHotSign,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/hot_signs'),
        name: 'conversion_logs_hot_sings',
        meta: { title: 'ホットサイン一覧' },
        component: ConversionLogsHotSign,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'ranking_preferences'),
        name: 'ranking_preferences',
        meta: { title: 'ランキング設定' },
        component: RankingPreferences,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'auto_ranking_preferences'),
        name: 'autoranking_preferences',
        meta: { title: '自動ランキング設定' },
        component: AutoRankingPreferences,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'analysis'),
        name: 'analysis',
        meta: { title: 'アナリティクスレポート' },
        component: Analysis,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/:conversion_log_id/report'),
        name: 'conversion_logs_report',
        meta: { title: 'アポジョーズ' },
        component: ConversionLogsReport,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/:conversion_log_id/revisit/:revisit_id'),
        name: 'conversion_logs_revisit',
        meta: { title: 'リターンズ' },
        component: ConversionLogsRevisit,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/:conversion_log_id/analysis'),
        name: 'conversion_logs_analysis',
        meta: { title: 'アナリティクス', isAnalysisPage: true },
        component: ConversionLogsAnalysis,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/:conversion_log_id/analysis/revisits/:revisit_id'),
        name: 'conversion_logs_analysis_revisit',
        meta: { title: 'アナリティクス', isAnalysisPage: true  },
        component: ConversionLogsAnalysisRevisit,
      },
      {
        path: userSingleBasePath.replace(controllerName, 'conversion_logs/:conversion_log_id/auto_mail'),
        name: 'conversion_logs_auto_mail',
        meta: { title: '自動メール作成' },
        component: ConversionLogsAutoMail,
      },
    ]
  },

  // 管理者機能
  { path: '/admin/login', name: 'admin_login', component: Login, meta: { isPublic: true, title: 'ログイン' }, },
  {
    path: '/admin',
    name: 'admin_index',
    component: AdminIndex,
    redirect: () => {
      return { name: 'companies' }
    },
    children: [
      {
        path: 'companies',
        name: 'companies',
        meta: { title: '会社一覧' },
        component: Companies,
      },
      {
        path: 'companies/:company_id/edit',
        name: 'edit_company',
        meta: { title: '会社情報編集' },
        component: EditCompany,
      },
      {
        path: 'companies/new',
        name: 'new_company',
        meta: { title: '会社情報登録' },
        component: NewCompany,
      },
      {
        path: 'companies/:company_id',
        name: 'show_company',
        meta: { title: '会社詳細情報' },
        component: ShowCompany,
      },
      {
        path: 'companies/:company_id/access_logs',
        name: 'admin_access_logs',
        meta: { title: 'AccessLogs' },
        component: AdminAccessLogs,
      },
      {
        path: 'companies/:company_id/conversion_logs',
        name: 'admin_conversion_logs',
        meta: { title: 'Conversion Logs' },
        component: AdminConversionLogs,
      },
      {
        path: 'companies/:company_id/conversion_logs/:conversion_log_id',
        name: 'show_admin_conversion_log',
        meta: { title: 'Conversion Log' },
        component: ShowAdminConversionLog,
      },
      {
        path: 'companies/:company_id/company_roles',
        name: 'company_roles',
        meta: { title: '権限設定' },
        component: CompanyRoles,
      },
      {
        path: 'companies/:company_id/tags',
        name: 'admin_tags',
        meta: { title: '旧タグ発行' },
        component: AdminTags,
      },
      {
        path: 'companies/:company_id/cross_domain_tags',
        name: 'admin_cross_domain_tags',
        meta: { title: '新タグ発行' },
        component: AdminCrossDomainTags,
      },
      {
        path: 'companies/:company_id/users',
        name: 'users',
        meta: { title: 'ユーザー管理' },
        component: Users,
      },
      {
        path: 'companies/:company_id/users/new',
        name: 'new_user',
        meta: { title: '新規ユーザー管理' },
        component: NewUser,
      },
      {
        path: 'companies/:company_id/users/:user_id/edit',
        name: 'edit_user',
        meta: { title: 'ユーザー編集' },
        component: EditUser,
      },
      {
        path: 'notices',
        name: 'notices',
        meta: { title: 'コメント一覧' },
        component: Notices,
      },
      {
        path: 'notices/:notice_id/edit',
        name: 'edit_notice',
        meta: { title: 'コメント編集' },
        component: EditNotice,
      },
      {
        path: 'notices/new',
        name: 'new_notice',
        meta: { title: 'コメント新規登録' },
        component: NewNotice,
      },
      {
        path: 'ip_addresses',
        name: 'ip_addresses',
        meta: { title: 'IPアドレス検索' },
        component: IpAddresses,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  parseQuery: (query) => {
    return rison.decode(decodeURI(query))
  },
  stringifyQuery: (params) => {
    if (0 == Object.keys(params).length) {
      return ''
    }
    return '?' + rison.encode(params)
  }
})

const routingControl = function (to, next, storeKey) {
  // ログイン認証判定
  if (!store.state.apojaws.isPublicPage && !store.state[storeKey].loggedIn) {
    return next({
      name: store.getters['apojaws/getLoginRouteName'],
      query: { redirect: to.fullPath }
    })
  } else if (store.state[storeKey].loggedIn && /\/login/.test(to.fullPath)) {
    // ログイン状態でログイン画面へアクセスしてきた場合はそれぞれの Index に飛ばす
    next({ name: store.state.apojaws.isAdmin ? 'admin_index' : 'index' })
  }

  // 未ログイン状態でログイン画面へ来た場合はそのまま遷移させる
  if (!store.state[storeKey].loggedIn && /\/login/.test(to.fullPath)) {
    return next()
  }

  // ログイン画面以外で認証不要のページだった場合そのまま遷移させる
  if (store.state.apojaws.isPublicPage) {
    return next()
  }

  // 画面遷移が発生する度に、ログインしているユーザー情報を再取得する
  // ※ 設定情報が更新されている場合があるため
  const reacquire = async function (next) {
    await store.dispatch(storeKey + '/reacquire')
    // 再取得できなかった場合は強制的にログイン画面へ遷移
    if (!store.state[storeKey].loggedIn) {
      next({ name: store.getters['apojaws/getLoginRouteName'] })
      return
    }
    next()
  }
  reacquire(next)
}

router.beforeEach((to, _from, next) => {
  (async () => {
    await await store.dispatch('route/initRoute', to)
  })()
  // admin 判定をセット
  store.commit('apojaws/changeIsAdmin', to.fullPath)
  // 認証不要ページ判定をセット
  store.commit('apojaws/changeIsPublicPage', to.matched.some(record => record.meta.isPublic))
  // 特定ページ判定をセット
  store.commit('apojaws/changeIsAnalysisPage', to.matched.some(record => record.meta.isAnalysisPage))

  // ログイン状態やパスに合わせてルーティング制御を行う
  routingControl(to, next, store.getters['apojaws/getAuthStoreKey'])
})

router.beforeResolve((to, from, next) => {

  // アドミン画面の場合はパラメータを上書きしない
  if (store.state.apojaws.isAdmin) {
    return next()
  }

  const beforeParams = to.params

  // URL パラメータが無ければその時点でナビゲーションを確定
  if (Object.keys(beforeParams).length === 0) {
    return next()
  }

  // beforeParamsをdeep copyする
  let afterParams = JSON.parse(JSON.stringify(beforeParams))

  if (beforeParams.company_id) {
    afterParams.company_id = store.state.auth.user.company_id
  }

  if (beforeParams.user_id) {
    afterParams.user_id = store.state.auth.user.id
  }

  // URL パラメータとログインしている情報を比較して、一致していればナビゲーションを確定
  if (beforeParams.company_id == afterParams.company_id && beforeParams.user_id == afterParams.user_id) {
    return next()
  }

  // URL パラメータをログインしている実際の情報に置き換えて再ナビゲーション
  next({ name: to.name, params: afterParams })
})

router.afterEach((to) => {
  const baseTitle = store.state.apojaws.isAdmin ? ADMIN_BASE_TITLE : USER_BASE_TITLE

  if (to.meta.title) {
    document.title = baseTitle + ' | ' + to.meta.title
  } else {
    document.title = baseTitle
  }
})

export default router
