<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 会社情報編集

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-card
        v-form
          companyForm(action='edit', :company="getCompany", v-if="!loading")
</template>


<script>
import companyForm from '../../components/TheCompanyForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Companies',
  components: {
    companyForm
  },

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters('company', [
      'getCompany',
    ]),
  },

  mounted: function() {
    this.loading = true
    this.edit(this.$route.params.company_id).then(() => {
      this.loading = false
    })
  },

  beforeDestroy: function() {
    this.clearCompany()
  },

  methods: {
    ...mapActions('company', [
      'edit',
      'clearCompany'
    ]),
    update (pathName, params) {
      this.$router.push({ name: pathName, params: params })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
