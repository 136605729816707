import api from 'api'
import qs from 'qs'

const DEFAULT_NOTICE = {
  attributes: {
    with_monday: true,
    with_tuesday: true,
    with_wednesday: true,
    with_thursday: true,
    with_friday: true,
    with_saturday: true,
    with_sunday: true,
  }
}

export default {
  namespaced: true,

  state: {
    notices: [],
    notice: {},
    loading: false,
    totalPages: 0,
    searchConditions: {},
    initLoading: true,
  },

  getters: {
    noticesSize(state) {
      return state.notices.length
    },
    openSetting :(state) => (noticeIdx) => {
      const notice = state.notices[noticeIdx]
      let setting = ''
      setting += '期間:' + notice.attributes.from_datetime_ymdhm + ' ~ ' + notice.attributes.to_datetime_ymdhm + '\r\n'
      if (notice.attributes.week_of_month) {
        setting += '毎月第' + notice.attributes.week_of_month + '週' + '\r\n'
      }
      if (notice.attributes.day_of_month) {
        setting += '毎月' + notice.attributes.day_of_month + '日' + '\r\n'
      }
      const weekNames = Object.entries(notice.attributes.weeks).map(e => e[1].name).join('・')
      setting += '曜日:' + weekNames + '\r\n'
      return setting
    },
    changeFormData(state) {
      const attributes = state.notice.attributes
      const formData = new FormData()
      const noticeParameters = {
        from_datetime: attributes.vue_from_datetime,
        to_datetime: attributes.vue_to_datetime,
        week_of_month: attributes.week_of_month,
        day_of_month: attributes.day_of_month,
        with_monday: attributes.with_monday,
        with_tuesday: attributes.with_tuesday,
        with_wednesday: attributes.with_wednesday,
        with_thursday: attributes.with_thursday,
        with_friday: attributes.with_friday,
        with_saturday: attributes.with_saturday,
        with_sunday: attributes.with_sunday,
        message: attributes.message,
        order: attributes.order,
      }
      formData.append('notice', JSON.stringify(noticeParameters))
      formData.append('uploadfile',attributes.vue_uploadfile)
      return formData
    },

    getInitUrlQuery() {
      return {
        pagination: {
          sortBy: 'order',
          descending: false,
          currentPage: 1,
        },
        searchConditions: {
          date: '',
          time: '',
          dateTime: '',
        }
      }
    }
  },

  mutations: {
    setNotices(state, notices) {
      state.notices = notices
    },

    setNotice(state, notice) {
      if (notice) {
        notice.attributes.vue_upload_image = ''
        notice.attributes.vue_from_datetime = ''
        notice.attributes.vue_to_datetime = ''
        notice.attributes.vue_uploadfile = ''
      }
      state.notice = notice
    },

    setUploadFile(state, uploadFile) {
      state.notice.attributes.vue_uploadfile = uploadFile
    },

    setUploadImage(state, uploadImage) {
      state.notice.attributes.vue_upload_image = uploadImage
    },

    setFromDateTime(state, fromDateTime) {
      state.notice.attributes.vue_from_datetime = fromDateTime 
    },

    setToDateTime(state, toDateTime) {
      state.notice.attributes.vue_to_datetime = toDateTime
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },

    setSearchDateTime(state, dateTime) {
      state.searchConditions.dateTime = dateTime
    },

    setSearchDate(state, date) {
      state.searchConditions.date = date
    },
    setSearchTime(state, time) {
      state.searchConditions.time = time
    },
    setLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async init({ state, commit }) {
      state.initLoading = true
      commit('setNotice', JSON.parse(JSON.stringify(DEFAULT_NOTICE)))
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
      state.initLoading = false
    },

    async fetch({ state, commit }) {
      state.loading = true
      const params = {
        searchCondition: state.searchConditions
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params),
      }
      const response = await api.get('/admin/notices/', options).then(response => {
        const data = response.data
        commit('setNotices', data.notices.data)
        commit('setTotalPages', data.notices.links.total_pages)
        commit('setSearchDate', data.searchParams.date)
        commit('setSearchTime', data.searchParams.time)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async edit({ state, commit }, urlParams) {
      state.loading = true

      const response = await api.get('/admin/notices/' + urlParams.notice_id + '/edit', {}).then(response => {
        const data = response.data
        commit('setNotice', data.data)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },
    createImage({commit}, file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        commit('setUploadImage', e.target.result)
        commit('setUploadFile', file)
      }
      reader.readAsDataURL(file)
    },

    async update({ state, getters }, urlParams) {
      state.loading = true
      const options = {
        params: getters.changeFormData
      }
      const response = await api.patch('/admin/notices/' + urlParams.notice_id, options).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },
    async create({ state, getters }) {
      state.loading = true
      const options = {
        params: getters.changeFormData
      }
      const response = await api.post('/admin/notices/',  options).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },
    async destroy({ state, dispatch }, notice) {
      state.loading = true
      const response = await api.delete('/admin/notices/' + notice.id,  {}).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      dispatch('fetch')
      state.loading = false
      return response
    },
    async changeStop({ state, dispatch }, notice) {
      state.loading = true
      const formData = new FormData()
      const noticeParameters = {
        stop_flag: !notice.attributes.stop_flag
      }
      formData.append('notice', JSON.stringify(noticeParameters))
      const options = {
        params: formData
      }
      const response = await api.patch('/admin/notices/' + notice.id, options).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      dispatch('fetch')
      state.loading = false
      return response
    },

    async preview({state, commit} ) {
      state.loading = true
      const params = {
        searchCondition: state.searchConditions
      }
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params),
      }
      const response = await api.get('/admin/notices/preview', options).then(response => {
        const data = response.data
        const noticeData = (data.data != null) ? data.data : null
        commit('setNotice', noticeData)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

  },
}
