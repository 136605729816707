<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline 自動ランキング設定
    v-flex(text-xs-right)
      router-link(v-if="roleAtr.analytics_config"  :to="{name: 'analysis_setting'}", class='btn-link')
        v-btn(color="primary") アナリティクス設定
      router-link(v-if="roleAtr.ranking_config"  :to="{ name: 'ranking_preferences' }", class='btn-link')
        v-btn(color="primary") ランキング設定
  v-layout(row wrap class='mb-2' v-if="!initLoading")
    v-flex(xs12)
      v-card
        v-card-text
          v-layout(mb-5)
            v-flex(text-xs-left)
              v-btn(@click='submitDef' :class="[show==='default' ? 'success' : '']" :loading='loading') 全て
              v-btn(@click='submitEnable' :class="[show==='enabled' ? 'success' : '']" :loading='loading') 有効
              v-btn(@click='submitDisable' :class="[show==='disabled' ? 'success' : '']" :loading='loading') 無効
            v-flex(text-xs-right)
              v-btn(v-if="getPreference.attributes.has_auto_ranking" @click='submitChgStopRevisit' class='error lighten-2' :loading='loading') 自動ランキングを無効にする
              v-btn(v-else @click='submitChgStopRevisit' :loading='loading') 自動ランキングを有効にする
          v-form(ref='form' v-model='valid')
            v-flex(subheading)
              | １. 自動ランキング設定
            v-flex
              v-container.fluid.mb-3
                v-data-table(:items="getUrls", :headers="headers", :loading="loading", class="elevation-4",  hide-actions class='mb-4')
                  template(v-slot:headers="props")
                    TheAutoRankingPreferenceTh(:headerProps='props')
                  template(v-slot:items="props")
                    TheAutoRankingPreferenceTd(:urlProps='props')
                v-layout(row wrap)
                  v-flex(text-xs-center)
                    ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
            v-flex(subheading)
              | ２．パラメータのホワイトリスト
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-icon(v-on="on") help_outline
                div.body-2
                  div.mb-3
                    span.yellow--text.text--accent-2
                      | 自動ランキングはURLの不要なパラメータは除去します。パラメータを残したい場合はここで設定して下さい
                      br
                      | URLの「?」より右にあるパラメータを指定すると、それがキーとなり、ユニークなURLとして自動ランキング設定に反映されます
                      br
                      | 複数のパラメータをand条件で指定する場合、「,」で区切ってください
                      br
                      | 複数条件を指定した場合、上の方が条件が一致したものが自動ランキング設定として登録されます。
                  div
                    span.cyan--text.text--accent-3
                      | 例）「town,detail」と指定すると
                      br
                      | 「http://test.com?town=shinagawa&detail=1000」や「http://test.com?town=shinjuku&detail=2000」をユニークとしてランキング集計されます
              v-container.fluid
                v-layout(row xs12)
                  v-textarea(v-model="getPreference.attributes.parameter_white_list" solo outline auto-grow)

            v-flex(subheading)
              | ３．除外URL
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-icon(v-on="on") help_outline
                div.body-2
                  div.mb-3
                    span.yellow--text.text--accent-2
                      | 除外したいURLを設定できます
                      br
                      | 「*」を最初(最後)につけると、後方(前方)一致でURLを除外します
                      br
                      | 複数設定したい場合は改行で区切って下さい
                  div
                    span.cyan--text.text--accent-3
                      | 例）「http://test.com/bukken/1」、「*test.com/bukken*」、「*test.com/bukken」、「http://test.com/bukken*」
              v-container.fluid
                v-layout(row xs12)
                  v-textarea(v-model="getPreference.attributes.reject_urls" solo outline auto-grow)
                v-flex(text-xs-center)
                  v-btn(color="primary" @click='submitUpdate' :disabled='loading' :loading='loading') 登録・保存する

          v-flex(subheading)
            | ４．CSVダウンロード
            v-flex(text-xs-center)
              v-btn(@click='submitDownCsv' :disabled='loading' :loading='loading') CSVダウンロード
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import FileMixin from '../libs/FileMixin'
import ResponseMixin from '../libs/ResponseMixin'
import TheAutoRankingPreferenceTd from '../components/TheAutoRankingPreferenceTd'
import TheAutoRankingPreferenceTh from '../components/TheAutoRankingPreferenceTh'
import ThePagination from '../components/ThePagination'

export default {
  name: 'AnalysisSetting',
  components: {
    TheAutoRankingPreferenceTd,
    TheAutoRankingPreferenceTh,
    ThePagination
  },
  mixins: [FileMixin, ResponseMixin],
  data() {
    return {
      initLoading: true,
      valid: true,
      headers: [
        {
          text: '無効',
          value: 'disabled',
          sortable: false,
          rankingName: false,
        },
        {
          text: 'URL',
          value: 'url',
          sortable: false,
          rankingName: false,
        },
        {
          text: 'ページ名',
          value: 'page_name',
          sortable: false,
          rankingName: false,
        },
        {
          text: 'ランキング1',
          value: 'ranking1',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング2',
          value: 'ranking2',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング3',
          value: 'ranking3',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング4',
          value: 'ranking4',
          sortable: false,
          rankingName: true,
        },
        {
          text: 'ランキング5',
          value: 'ranking5',
          sortable: false,
          rankingName: true,
        },
      ],
    }
  },

  computed: {
    ...mapState(['auto_ranking_preference']),
    ...mapGetters('auto_ranking_preference', [
      'getPreference',
      'getUrls',
      'getTotalPages',
      'getInitUrlQuery',
      'getShow',
    ]),
    user: function () {
      return this.$store.state.auth.user
    },
    loading: {
      get() {
        return this.$store.state.auto_ranking_preference.loading
      },
      set (value) {
        this.$store.commit('auto_ranking_preference/setLoading', value)
      }
    },
    pagination: function(){
      return this.$store.state.auto_ranking_preference.pagination
    },
    totalPages: function() {
      return this.auto_ranking_preference.totalPages
    },
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
    searchConditions: function() {
      return this.auto_ranking_preference.searchConditions
    },
    show: {
      get() {
        return this.getShow
      },
      set(value) {
        this.$store.commit('auto_ranking_preference/setShow', value)
      }
    },
  },

  async created() {
    this.initLoading = true
    await this.initUrlQuery(this.getInitUrlQuery)
    await this.init()
    await this.actionFetch()
    this.initLoading = false
  },


  methods: {
    ...mapActions('auto_ranking_preference', [
      'init',
      'fetch',
      'update',
      'csvDownload',
      'changeHasAutoRanking'
    ]),
    async actionFetch() {
      let urlParams = {
        company_id: this.$route.params.company_id
      }
      const response = await this.fetch(urlParams)
      if (!this.chkResponse(response)) {
        return
      }
      return response
    },
    ...mapMutations('auto_ranking_preference', [
    ]),
    async submitUpdate() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('flash/showMessage', { message: '入力誤りがあります。', color: 'error' })
        return
      }
      const response = await this.update()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を更新しました。', color: 'info' })
    },
    async submitDownCsv() {
      const response = await this.csvDownload()
      if (!await this.chkResponseBlob(response)) {
        return
      }
      this.fileDownload({filePath: '自動ランキング設定.csv', type: 'text/csv', content: response.data })
    },
    async submitDef(){
      this.show = 'default'
      await this.changeSearchQuery(this.searchConditions)
      await this.actionFetch()
    },
    async submitEnable(){
      this.show = 'enabled'
      await this.changeSearchQuery(this.searchConditions)
      await this.actionFetch()
    },
    async submitDisable(){
      this.show = 'disabled'
      await this.changeSearchQuery(this.searchConditions)
      await this.actionFetch()
    },
    changePage() {
      this.actionFetch()
    },
    async submitChgStopRevisit() {
      const response = await this.changeHasAutoRanking()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '自動ランキング設定を変更しました。', color: 'info' })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
