<script>
export default {
  name: 'PrefMixin',
  computed: {
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
    linkItems: function() {
      return [
        { title: 'お知らせ', icon: 'dashboard', link: 'news', show: true },
        { title: 'アポジョーズ一覧', icon: 'question_answer', link: 'conversion_logs', show: true},
        { title: 'アナリティクスレポート', icon: 'equalizer', link: 'analysis', show: this.roleAtr.analytics_graph},
        { title: 'コネクト', icon: 'compare_arrows', link: 'mail_tracking', show: this.roleAtr.connect},
        { title: '電話一覧', icon: 'phone_callback', link: 'tel_conversion_logs', show: this.roleAtr.phone_conversion },
      ]
    },
    linkConfigItems: function() {
      return [
        { title: 'アナリティクス', icon: 'sort', link: 'analysis_setting', show: this.roleAtr.analytics_config},
        { title: '自動メール', icon: 'email', link: 'auto_mail', show: this.roleAtr.auto_mail_config},
        { title: 'ホットサイン', icon: 'timeline', link: 'hot_signs', show: this.roleAtr.hot_sign},
      ]
    },
    adminCompanyLinkItems: function() {
      return [
        { title: '会社一覧', link: 'companies', show: true},
        { title: '会社編集', link: 'edit_company', show: true},
        { title: '権限設定', link: 'company_roles', show: true},
        { title: 'ユーザー管理', link: 'users', show: true},
        { title: '旧タグ発行', link: 'admin_tags', show: true},
        { title: '新タグ発行', link: 'admin_cross_domain_tags', show: process.env.available_cross_domain !== 'false'},
        { title: 'アクセスログ', link: 'admin_access_logs', show: true},
        { title: 'コンバージョンログ', link: 'admin_conversion_logs', show: true}
      ]
    },
    adminLinkItems: function() {
      return [
        { title: '会社一覧', link: 'companies', show: true},
        { title: 'コメント設定', link: 'notices', show: true},
      ]
    },
    linkConfigCounts: function() {
      return this.linkConfigItems.filter(configItem => configItem.show == true)
    }
  },
}
</script>
