import api from 'api'

const DEFAILT_FORM_ATTRIBUTES = {
  password: '',
  password_confirmation: ''
}

export default {
  namespaced: true,

  state: {
    loading: true,
    preUser: {},
    formAttributes: JSON.parse(JSON.stringify(DEFAILT_FORM_ATTRIBUTES)),
  },

  mutations: {
    setPreUser(state, data) {
      state.preUser = data
    },

    setFormAttributes(state, formAttributes) {
      state.formAttributes = formAttributes
    },

    changeLoading(state, loading) {
      state.loading = loading
    },

    clearPreUser(state) {
      state.preUser = {}
    },

    clearFormAttributes(state) {
      state.formAttributes = JSON.parse(JSON.stringify(DEFAILT_FORM_ATTRIBUTES))
    },
  },

  actions: {
    async fetch({ commit }, params) {
      const options = {
        notAuth: true,
        params: params,
      }

      return await api.get('pre_users', options).then(response => {
        commit('setPreUser', response.data.data.attributes)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async activate({ state }) {
      const options = {
        notAuth: true,
        params: {
          form_attributes: state.formAttributes,
          pre_user: state.preUser,
        },
      }

      return await api.patch('/pre_users/' + state.preUser.id + '/activate/', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },
  }
}
