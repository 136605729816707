import api from 'api'
import qs from 'qs'

const DEFAULT_CONVERSION_LOG = {
  attributes: {}
}
const DEFAULT_USER_COOKIE = {
  attributes: {}
}

export default {
  namespaced: true,

  state: {
    conversionLog: {},
    accessLogs: [],
    accessLogTotalPage: 1,
    userSessions: [],
    docodocoIpParams: {},
    userCookie: {},
    hotSigns: [],
    hotSignStatus: '',
    rankings: [],
    rankingStatus: '',
    glaphs: [],
    sumCategories: [],
    sumCategoriesById: {},
    sumCategoryUrlFields: [],
    sumCategoryUrlFieldById: {},
    loading: true,
    hotSignLoading: true,
    rankingLoading: true,
    pagination: {},
    searchConditions: {},
    rankingSearchConditions: {},
  },

  getters: {
    conversionLogsRelationCode(state) {
      return state.conversionLog.attributes.relation_code ?
        state.conversionLog.attributes.relation_code.replace(/,/g, ', ') : ''
    },
    conversionLogsItemCode(state) {
      return state.conversionLog.attributes.item_code ?
        state.conversionLog.attributes.item_code.replace(/,/g, ', ') : ''
    },
    conversionLogsCreated(state) {
      return state.conversionLog.attributes.created
    },
    userSessionRefereUrl: (state) => (idx) => {
      return state.userSessions[idx].attributes.referer_url || '不明'
    },
    userSessionSearchWord: (state) => (idx) => {
      return state.userSessions[idx].attributes.search_word || '不明'
    },
    userSessionIpSource: (state) => (idx) => {
      return state.docodocoIpParams[state.userSessions[idx].attributes.ip]
    },
    sumCategoryById: (state) => (id) => {
      return state.sumCategoriesById[id]
    },
    hotSignCreatedAt(state) {
      const hotSign = state.hotSigns.find(hotSign => {
        return hotSign.attributes.created
      })
      return hotSign ? hotSign.attributes.created + ' 時点の集計' : '未集計'
    },
    sumCategoryUrlFieldNameByIid: (state) => (id) => {
      return state.sumCategoryUrlFieldById[id] ? state.sumCategoryUrlFieldById[id].attributes.page_name : '-'
    },
    // rankingsのrankを逆順に探し一番最初の有効なcreatedを求める。
    rankingCreatedAt(state) {
      let created = null
      state.rankings.slice().reverse().some(ranking => {
        if (ranking.attributes.rank) {
          return ranking.attributes.rank.slice().reverse().some(rank => {
            if (rank.created) {
              created = rank.created
              return true
            } else {
              return false
            }
          })
        } else {
          return false
        }
      })
      return created ? created + ' 時点の集計' : '未集計'
    },
    accessLogScreenShotStatus: (state) => (idx) => {
      return state.accessLogs[idx].attributes.screen_shot.attributes.status
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          descending: true,
          rowsPerPage: 20,
        },
        searchConditions: {
          sort: '',
          browseTimeFrom: -1,
        },
        rankingSearchConditions: {
          order: 'total_visiting_seconds_all_days',
          counter: 'total_visiting_count_all_days',
        },
      }
    },
  },

  mutations: {
    setConversionLog(state, conversionLog) {
      state.conversionLog = conversionLog
    },
    setUserSessions(state, userSessions) {
      state.userSessions = userSessions
    },
    setDocodocoIpParams(state, docodocoIps) {
      docodocoIps.forEach((docodocoIp) => {
        state.docodocoIpParams[docodocoIp.attributes.ip] = docodocoIp.attributes.access_source
      })
    },
    setUserCookie(state, userCookie) {
      state.userCookie = userCookie
    },
    setHotSigns(state, hotSigns) {
      state.hotSigns = hotSigns
    },
    setHotSignStatus(state, hotSignStatus) {
      state.hotSignStatus = hotSignStatus
    },
    setRankings(state, rankings) {
      rankings.forEach(ranking => {
        ranking.attributes.vue_is_filter = ranking.attributes.rank.length > 5
        ranking.attributes.rank.forEach(rank => {
          rank.vue_is_show_img_dialog = false
        })
      })
      state.rankings = rankings
    },
    setRankingStatus(state, rankingStatus) {
      state.rankingStatus = rankingStatus
    },
    setGlaphs(state, glaphs) {
      state.glaphs = glaphs
    },
    setSumCategories(state, sumCategories) {
      state.sumCategories = sumCategories
      state.sumCategoriesById = {}
      sumCategories.forEach(sumCategory => {
        state.sumCategoriesById[sumCategory.id] = sumCategory
      })
    },
    setSumCategoryUrlFields(state, sumCategoryUrlFields) {
      state.sumCategoryUrlFields = sumCategoryUrlFields
      state.sumCategoryUrlFieldById = {}
      sumCategoryUrlFields.forEach(sumCategoryUrlField => {
        state.sumCategoryUrlFieldById[sumCategoryUrlField.id] = sumCategoryUrlField
      })
    },
    setRankingSearchConditions(state, rankingSearchConditions) {
      state.rankingSearchConditions = rankingSearchConditions
    },
    setRankingOrder(state, rankingOrder) {
      state.rankingSearchConditions.order = rankingOrder
    },
    setRankingCounter(state, rankingCounter) {
      state.rankingSearchConditions.counter = rankingCounter
    },
    setAccessLogs(state, accessLogs) {
      accessLogs.forEach(accessLog => {
        accessLog.attributes.vue_is_image_loading = !accessLog.attributes.is_iframe_screen
        accessLog.attributes.vue_is_show_img_dialog = false
      })
      state.accessLogs = accessLogs
    },
    setAccessLogVueIsimageLoading(state, {accessLog: accessLog, vue_is_image_loading: vue_is_image_loading} ) {
      accessLog.attributes.vue_is_image_loading = vue_is_image_loading
    },
    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },
    setPagination(state, pagination) {
      state.pagination = pagination
    },
    setRowsPerPage(state, rowsPerPage) {
      state.pagination.rowsPerPage = rowsPerPage
    },
    setAccessLogTotalPage(state, accessLogTotalPage) {
      state.accessLogTotalPage = accessLogTotalPage
    },
    setAccessLogCurrentPage(state, currentPage) {
      state.searchConditions.currentPage = currentPage
    },
    setBrowseTimeFrom(state, browseTimeFrom) {
      state.searchConditions.browseTimeFrom = browseTimeFrom
    },
    setSort(state, sort) {
      state.searchConditions.sort = sort
    },
    setPagePer(state, pagePer) {
      state.searchConditions.pagePer = pagePer
    },
    setLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async fetch({ state, commit }, urlParams) {
      state.loading = true
      const params = {
        access_log_search: {
          browse_time_from: state.searchConditions.browseTimeFrom,
          sort: state.searchConditions.sort,
        }
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params)
      }
      const response =  await api.get('conversion_logs/' + urlParams.conversion_log_id + '/analysis', options).then(response => {
        const data = response.data
        commit('setConversionLog', data.conversion_log.data)
        commit('setAccessLogs', data.access_logs.data)
        commit('setAccessLogTotalPage', data.access_logs.links.total_pages)
        commit('setSort', data.access_log_search_params.sort)
        commit('setUserSessions', data.user_sessions.data)
        commit('setDocodocoIpParams', data.docodoco_ips.data)
        commit('setUserCookie', data.user_cookie.data)
        commit('setGlaphs', data.glaphs)
        commit('setSumCategories', data.sum_categories.data)
        commit('setSumCategoryUrlFields', data.sum_category_url_fields.data)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async init({ commit }) {
      commit('setConversionLog', JSON.parse(JSON.stringify(DEFAULT_CONVERSION_LOG)))
      commit('setUserCookie', JSON.parse(JSON.stringify(DEFAULT_USER_COOKIE)))
    },

    async initState({ commit } ) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
      commit('setPagination', JSON.parse(JSON.stringify(this.state.route.pagination)))
      commit('setRankingSearchConditions', JSON.parse(JSON.stringify(this.state.route.rankingSearchConditions)))
    },

    async hot_signs({state, commit}, urlParams) {
      state.hotSignLoading = true
      const params = {
      }
      const options = {
        params: params,
      }
      const response =  await api.get('conversion_logs/' + urlParams.conversion_log_id + '/hot_signs/call_apis', options).then(response => {
        commit('setHotSigns', response.data.hot_signs.data)
        commit('setHotSignStatus', response.data.hot_signs.hot_sign_status)
        return response
      }).catch(err => {
        return err.response
      })
      state.hotSignLoading = false
      return response
    },

    async rankings({ state, commit }, urlParams) {
      state.rankingLoading = true
      const params = {
        search: {
          order: state.rankingSearchConditions.order
        }
      }
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params),
      }
      const response =  await api.get('conversion_logs/' + urlParams.conversion_log_id + '/rankings/call_apis', options).then(response => {
        commit('setRankings', response.data.rankings.data)
        commit('setRankingStatus',response.data.rankings.ranking_status) 
        return response
      }).catch(err => {
        return err.response
      })
      state.rankingLoading = false
      return response
    },
    reloadImg({state, dispatch}) {
      state.accessLogs.forEach(accessLog => {
        dispatch('reloadImgAccessLog', accessLog)
      })
    },

    reloadImgAccessLog({commit, dispatch}, accessLog) {
      if (!accessLog.attributes.vue_is_image_loading) {
        return
      }
      const img = new Image()
      img.src = accessLog.attributes.screen_shot.attributes.img_full_path
      img.onload = function() {
        commit('setAccessLogVueIsimageLoading', {accessLog: accessLog, vue_is_image_loading: false})
      }
      img.onerror = function() {
        setTimeout(() => {
          dispatch('reloadImgAccessLog', accessLog)
        }, 10000)
      }
    },
    async screenReload({state, dispatch}, {accessLogIdx: accessLogIdx, urlParams: urlParams}) {
      state.loading = true
      const accessLog = state.accessLogs[accessLogIdx]
      const response =  await api.get('access_logs/' + accessLog.id + '/screen_shot_reload', {}).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      await dispatch('fetch', urlParams)
      state.loading = false
      return response
    }
  }
}
