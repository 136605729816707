<script>
import moment from 'moment'
export default {
  name: 'FilterMixin',
  filters: {
    moment: function (date, outFormat) {
      if (date == undefined || date == '') {
        return ''
      }
      return moment(date).format(outFormat)
    }
  },
}
</script>
