<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 会社一覧
    v-flex(text-xs-right)
      router-link.btn-link(:to="{name: 'new_company'}")
        v-btn(color="primary") 会社新規追加

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-data-table(:items="getCompanies", :headers="headers", :loading="loading", class="elevation-4", :total-items="getCompaniesSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(v-for="header in props.headers",
               :key="header.text",
                 :class="['column', header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
               @click="changeSort(header)",
               )
              v-icon(v-if="header.sortable" small) arrow_upward
              | {{ header.text }}
        template(v-slot:items="props")
          td {{ props.item.id }}
          td {{ props.item.attributes.company_name }}
          td {{ props.item.attributes.group_name }}
          td()
            v-select(:items="selectCompanyLinks()"
                     @change='changeCompanyLink(props.item.id, props.item.companyLink)'
                     v-model='props.item.companyLink'
                     label="会社リンク"
                     )
          td
            router-link.btn-link(:to="{name: 'show_company', params: {company_id: props.item.id}}")
              v-btn(class="mr-3")
                v-icon list_alt
                | 詳細
            router-link.btn-link(:to="{name: 'edit_company', params: {company_id: props.item.id}}")
              v-btn(class="mr-3")
                v-icon edit
                | 編集
            v-btn(class="mr-3" @click='submitDelete(props.index, props.item.id)')
              v-icon delete_forever
              | 削除

  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>


<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ThePagination from '../../components/ThePagination'

export default {
  name: 'Companies',
  components: {
    ThePagination
  },
  data() {
    return {
      companyLink: '',
      delDialog: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
          descending: 'asc',
          sortable: true,
        },
        {
          text: '会社名',
          value: 'company_name',
          sortable: true,
        },
        {
          text: 'グループ名',
          value: 'group_name',
          sortable: true,
        },
        {
          text: '会社リンク',
          sortable: false,
        },
        {
          text: '操作',
          sortable: false,
        },
      ],
      companyLinks: [
        {
          text: '権限設定',
          pathName: 'company_roles',
          show: true,
        },
        {
          text: 'ユーザー管理',
          pathName: 'users',
          show: true,
        },
        {
          text: '旧タグ発行',
          pathName: 'admin_tags',
          show: true,
        },
        {
          text: '新タグ発行',
          pathName: 'admin_cross_domain_tags',
          show: process.env.available_cross_domain !== 'false',
        },
        {
          text: 'コンバージョンログ',
          pathName: 'admin_conversion_logs',
          show: true,
        },
        {
          text: 'アクセスログ',
          pathName: 'admin_access_logs',
          show: true,
        },
      ],
    }
  },

  computed: {
    ...mapState(['route']),
    ...mapGetters('company', [
      'getCompanies',
      'getTotalPages',
      'getCompaniesSize',
      'getCurrentPage',
      'getInitUrlQuery'
    ]),
    ...mapGetters('route', [
      'getOrder'
    ]),
    loading: {
      get () {
        return this.$store.state.company.loading
      },
      set (value) {
        this.$store.commit('company/setLoading', value)
      }
    },
    pagination: function(){
      return this.route.pagination
    },
    totalPages: function() {
      return this.$store.state.company.totalPages
    }
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    this.loading = true
    await this.fetch()
    this.loading = false
  },

  methods: {
    ...mapActions('company', [
      'fetch',
      'delete',
      'show'
    ]),

    changePage() {
      this.loading = true
      this.fetch().then(() => {
        this.loading = false
      })
    },

    async changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.loading = true
      this.fetch().then(() => {
        this.loading = false
      })
    },

    changeCompanyLink(company_id, text) {
      let targetLink = this.companyLinks.find(function(srcLink) {
        return srcLink.text == text
      },this)
      this.$router.push({name: targetLink.pathName, params: {company_id: company_id}})
    },
    selectCompanyLinks() {
      return this.companyLinks.filter(link => link.show)
    },
    submitDelete(idx, company_id) {
      if (window.confirm('本当に削除しますか？')) {
        this.loading = true
        this.delete({idx: idx, company_id: company_id}).then((res) => {
          this.loading = false
          if (res.status == 200) {
            this.$store.dispatch('flash/showMessage', {message: '会社を情報を削除しました。', color: 'info'})
          } else if (res.status == 500) {
            return
          } else {
            this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
          }
        })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
