<template lang='pug'>
v-layout.column
  h1.display-1.font-weight-black.green--text.text--darken-3 リターンズ
  TheNav.mb-3
  v-layout(row wrap)
    v-flex.xs12
      v-card.mb-4
        v-card-text.grey.lighten-3
          | 下記は問い合わせのあったお客様のHP訪問履歴・閲覧履歴情報です。（反響の詳細や個人情報は従来どおりの方法で確認して下さい）
      TheRevisit(storeModule='conversion_logs_revisit' @callFetch="actionFetch" baseColor='green')
      TheCustomer(storeModule='conversion_logs_revisit' baseColor='green')
      TheAccessLog(@callFetch="actionFetch" storeModule='conversion_logs_revisit')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'
import TheNav from '../../components/conversion_logs/TheNav'
import TheCustomer from '../../components/conversion_logs/TheCustomer'
import TheAccessLog from '../../components/conversion_logs/TheAccessLog'
import TheRevisit from '../../components/conversion_logs/TheRevisit'

export default {
  name: 'ConversionLogsReport',
  components: {
    TheCustomer,
    TheAccessLog,
    TheNav,
    TheRevisit,
  },
  mixins: [ResponseMixin],
  computed: {
    ...mapGetters('conversion_logs_revisit', [
      'getInitUrlQuery'
    ]),
  },

  async created() {
    await this.init()
    await this.initUrlQuery(this.getInitUrlQuery)
    await this.initState()
    await this.actionFetch()
  },

  async beforeDestroy() {
    await this.initState()
  },

  methods: {
    ...mapActions('conversion_logs_revisit', [
      'init',
      'initState',
      'fetch',
      'reloadImg',
    ]),

    async actionFetch() {
      const response = await this.fetch(this.$route.params)
      if (!this.chkResponse(response)) {
        return
      }
      this.reloadImg()
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
