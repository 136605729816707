import api from 'api'

const DEFAULT_SEARCH_CONDITION = {
  from_created: '',
  to_created: '',
  filter_start: Number,
  filter_end: Number,
}
export default {
  namespaced: true,

  state: {
    categoryBrowses: {},
    categoryConversionCounts: {},
    pageBrowses: {},
    pageConversionCounts: {},
    sumCategoris: [],
    loading: false,
    searchCondition: JSON.parse(JSON.stringify(DEFAULT_SEARCH_CONDITION)),
    selectAnalysis: {},
  },

  getters: {
    aggregates: (state) => (type, id) => {
      const stateAggregate = state[state.selectAnalysis.value][type]
      if (stateAggregate) {
        return stateAggregate[String(id)]
      } else {
        return null
      }
    },
    aggregateItems: (state, getters) => (type, id) => {
      const aggregates = getters.aggregates(type, id)
      if (aggregates != null) {
        return aggregates.sort_data
      } else {
        return []
      }
    },
    titleById: (state ) => (id) => {
      const sumCategori = state.sumCategoris.find(sumCategori => sumCategori.id == id)
      if (sumCategori) {
        return sumCategori.attributes.name
      } else {
        return ''
      }
    },
    sumCategoriId: (state) => (id) => {
      return state.sumCategoris.find( sumCategoris => sumCategoris.id == id )
    },
    rangeSeachCondition(state) {
      return state.searchCondition.from_created + '&nbsp;&nbsp;~&nbsp;&nbsp;' + state.searchCondition.to_created
    },
  },

  mutations: {
    setCategoryBrowse(state, data) {
      state.categoryBrowses = data
    },
    setCategoryConversionCounts(state, data) {
      state.categoryConversionCounts = data
    },
    setPageBrowse(state, data) {
      state.pageBrowses = data
    },
    setPageConversionCounts(state, data) {
      state.pageConversionCounts = data
    },
    setSumCategoris(state, data) {
      state.sumCategoris = data
    },
    setSelectAnalysis(state, selectAnalysis) {
      state.selectAnalysis = selectAnalysis
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setSearchCondition(state, searchCondition) {
      state.searchCondition = searchCondition
    },
    clearSearchCondition(state) {
      state.searchCondition = JSON.parse(JSON.stringify(DEFAULT_SEARCH_CONDITION))
    },
  },

  actions: {
    async fetch({ state, commit }) {
      state.loading = true
      const params = {
        filter_start: state.searchCondition.filter_start,
        filter_end: state.searchCondition.filter_end,
        from_created: state.searchCondition.from_created,
        to_created: state.searchCondition.to_created,
      }

      const response = await api.get('analysis', { params: params }).then(response => {
        commit('setCategoryBrowse', response.data.aggregate_categories.browse_time)
        commit('setCategoryConversionCounts', response.data.aggregate_categories.conversion_count)
        commit('setPageBrowse', response.data.aggregate_pages.browse_time)
        commit('setPageConversionCounts', response.data.aggregate_pages.conversion_count)
        commit('setSumCategoris', response.data.sum_categoris.data)
        commit('setSearchCondition', response.data.search_condition)
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },
  },
}
