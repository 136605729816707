<template lang='pug'>
v-layout.column
  v-layout(row wrap)
    v-flex
      h3.headline
        | 閲覧ページランキング
    v-flex(text-xs-right)
      v-btn(@click='submitOrderBtn("total_visiting_seconds_3_days", "total_visiting_count_3_days")' :class="[rankingOrder=='total_visiting_seconds_3_days' ? 'success' : '']" :loading='loading') 直近3日
      v-btn(@click='submitOrderBtn("total_visiting_seconds_7_days", "total_visiting_count_7_days")' :class="[rankingOrder=='total_visiting_seconds_7_days' ? 'success' : '']" :loading='loading') 直近7日
      v-btn(@click='submitOrderBtn("total_visiting_seconds_14_days", "total_visiting_count_14_days")' :class="[rankingOrder=='total_visiting_seconds_14_days' ? 'success' : '']" :loading='loading') 直近14日
      v-btn(@click='submitOrderBtn("total_visiting_seconds_30_days", "total_visiting_count_30_days")' :class="[rankingOrder=='total_visiting_seconds_30_days' ? 'success' : '']" :loading='loading') 直近30日
      v-btn(@click='submitOrderBtn("total_visiting_seconds_all_days", "total_visiting_count_all_days")' :class="[rankingOrder=='total_visiting_seconds_all_days' ? 'success' : '']" :loading='loading') 全期間
  v-card.mb-5.pr-3.pl-3
    v-card-text
      template(v-if="rankingLoading")
        v-layout.justify-center(raw wrap)
          v-progress-circular.mb-3(:size="80" :width='10' color='grey' indeterminate style="width: 100%;")
          span 閲覧ページランキング読み込み中
      template(v-else)
        template(v-if="rankingStatus=='ok'")
          span.red--text.font-weight-bold {{ this.rankingCreatedAt }}
          div(v-for="ranking in rankings").mb-5
            v-subheader.blue--text.font-weight-bold  {{ ranking.attributes.name }}
            template(v-if="ranking.attributes.rank.length > 0")
              v-data-table(:items="filteredRank(ranking)" class="elevation-1", hide-actions)
                template(v-slot:headers="props")
                  tr.lighten-5(:class='baseColor')
                    th.body-2.font-weight-bold 順位
                    th.body-2.font-weight-bold ページ
                    th.body-2.font-weight-bold ページ名
                    th.body-2.font-weight-bold 合計閲覧時間
                    th.body-2.font-weight-bold 合計回数
                template(v-slot:items="props")
                  tr
                    td {{ props.index + 1 }} 位
                    td
                      div.site-view.pa-1(@click="props.item.vue_is_show_img_dialog = true")
                        v-img(:src="props.item.full_thumbnail_url")
                        v-dialog(v-model='props.item.vue_is_show_img_dialog' fullscreen hide-overlay transition="dialog-bottom-transition")
                          v-card
                            v-toolbar(dark fixed).primary
                              v-btn(icon dark @click="props.item.vue_is_show_img_dialog = false")
                                v-icon close
                              v-toolbar-title(dark) {{ props.item.title }}
                            v-img.dialog-site-img(:src='props.item.full_thumbnail_url' @click="props.item.vue_is_show_img_dialog = false")
                    td {{ props.item.title }} 
                    td {{ toHHmmss(props.item[rankingOrder]) }} 
                    td {{ props.item[rankingCounter] }}  回
                template(v-slot:footer v-if="needFilter(ranking)")
                  tr
                    td.lighten-4.toggle-btn.grey(colspan=6 @click="submitChangeFilter(ranking)")
                      v-layout.justify-center
                        template(v-if="ranking.attributes.vue_is_filter")
                          v-icon.pr-3 arrow_downward
                          | 展開する
                        template(v-else)
                          v-icon.pr-3 arrow_upward
                          | 折り畳む
            template(v-else)
              v-layout(raw wrap)
                span.font-weight-bold.pa-2 該当ページはありません。
        template(v-if="rankingStatus=='error'")
          v-layout(raw wrap)
            span.red--text.font-weight-bold.pa-2 ランキングの読み込みに失敗しました。
</template>

<script>

export default {
  name: 'TheRanking',

  components: {
  },
  props: {
    storeModule: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: ''
    },
  },
  computed: {
    rankings: function() {
      return this.$store.state[this.storeModule].rankings
    },
    filteredRank: function() {
      return function(ranking){
        if (ranking.attributes.vue_is_filter) {
          return ranking.attributes.rank.slice(0, 5)
        } else {
          return ranking.attributes.rank
        }
      }
    },
    rankingSearchConditions: function() {
      return this.$store.state[this.storeModule].rankingSearchConditions
    },
    rankingStatus: function() {
      return this.$store.state[this.storeModule].rankingStatus
    },
    rankingLoading: function() {
      return this.$store.state[this.storeModule].rankingLoading
    },
    rankingOrder: {
      get() {
        return this.rankingSearchConditions.order
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setRankingOrder', value)
      }
    },
    rankingCounter:  {
      get() {
        return this.rankingSearchConditions.counter
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setRankingCounter', value)
      }
    },
    loading: function() {
      return this.$store.state[this.storeModule].loading
    },
    rankingCreatedAt: function() {
      return this.$store.getters[this.storeModule + '/rankingCreatedAt']
    },
  },
  methods: {
    needFilter(ranking) {
      return ranking.attributes.rank.length > 5
    },
    submitChangeFilter(ranking) {
      ranking.attributes.vue_is_filter = !ranking.attributes.vue_is_filter
    },
    toHHmmss(t) {
      let hms = []
      const h = t / 3600 | 0
      const m = t % 3600 / 60 | 0
      const s = t % 60
  
      if (h != 0) {
        hms.push(h)
        hms.push(m)
        hms.push(this.paddingZero(s))
      } else {
        hms.push(m)
        hms.push(this.paddingZero(s))
      }
      return hms.join(':')
    },
    paddingZero(v) {
      if (v < 10) {
        return '0' + v
      } else {
        return v
      }
    },
    async submitOrderBtn(chgOrderColumn, chgCountCoumn) {
      this.rankingOrder = chgOrderColumn
      this.rankingCounter = chgCountCoumn
      await this.writeUrlQuery({rankingSearchConditions: this.rankingSearchConditions})
      this.$emit('callRanking')
    },
  }
}
</script>

<style lang='scss' scoped>
.v-datatable th,
.v-datatable td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.site-view {
  max-height: 100px;
  text-align: center;
  overflow: auto;
  cursor: pointer;
}
.dialog-site-img {
  cursor: pointer;
  margin-top: 64px;
}
</style>
