<template lang='pug'>
v-layout.row.wrap
  v-flex.text-xs-center
    v-pagination(:value='currentPage' :length='totalPages' :total-visible='totalVisible' v-on:input='changePage($event)' :disabled='loading')


</template>

<script>
import {  mapState } from 'vuex'

export default {
  name: 'ThePagination',

  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: function() {
    return {
    }
  },
  computed: {
    ...mapState(['route']),
    currentPage: function() {
      return this.route.pagination.currentPage
    },

    totalVisible: function() {
      const breakpoint = this.$vuetify.breakpoint
      if (breakpoint.xs) {
        return 5
      } else if (breakpoint.sm || breakpoint.md) {
        return 10
      } else {
        return 20
      }
    },
  },
  methods: {
    async changePage(value) {
      await this.changePageQuery(value)
      this.$emit('changePage')
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
