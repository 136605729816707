import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// ストアはモジュールに分割して管理する
// ここでインポートして、 new する際の modules に設定する
import apojaws from './modules/apojaws'
import news from './modules/news'
import company from './modules/company'
import auth from './modules/auth'
import pre_user from './modules/pre_user'
import conversion_log from './modules/conversion_log'
import tel_conversion_log from './modules/tel_conversion_log'
import analysis_setting from './modules/analysis_setting'
import auto_mail from './modules/auto_mail'
import flash from './modules/flash'
import dialog from './modules/dialog'
import admin_auth from './modules/admin/auth'
import admin_conversion_log from './modules/admin/conversion_log'
import admin_access_log from './modules/admin/access_log'
import admin_cross_domain_tags from './modules/admin/cross_domain_tag'
import company_role from './modules/admin/company_role'
import user from './modules/admin/user'
import mail_tracking from './modules/mail_tracking'
import hot_sign from './modules/hot_sign'
import conversion_hot_sign from './modules/conversion_logs/hot_sign'
import ranking_preference from './modules/ranking_preference'
import auto_ranking_preference from './modules/auto_ranking_preference'
import analysis from './modules/analysis'
import conversion_logs_report from './modules/conversion_logs/report'
import conversion_logs_revisit from './modules/conversion_logs/revisit'
import conversion_logs_analysis from './modules/conversion_logs/analysis'
import conversion_logs_analysis_revisit from './modules/conversion_logs/analysis_revisit'
import conversion_logs_auto_mail from './modules/conversion_logs/auto_mail'
import admin_notice from './modules/admin/notice'
import admin_ip_address from './modules/admin/ip_address.js'
import notice_display from './modules/notice_display'
import route from './modules/route'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    apojaws,
    news,
    company,
    auth,
    pre_user,
    conversion_log,
    tel_conversion_log,
    auto_mail,
    flash,
    dialog,
    admin_auth,
    admin_access_log,
    admin_conversion_log,
    admin_cross_domain_tags,
    company_role,
    user,
    analysis_setting,
    mail_tracking,
    hot_sign,
    conversion_hot_sign,
    ranking_preference,
    auto_ranking_preference,
    analysis,
    conversion_logs_report,
    conversion_logs_revisit,
    conversion_logs_analysis,
    conversion_logs_analysis_revisit,
    conversion_logs_auto_mail,
    admin_notice,
    admin_ip_address,
    notice_display,
    route,
  },
  plugins: [
    createPersistedState({
      key: 'apojaws',
      paths: ['auth.user', 'auth.loggedIn'],
      storage: window.localStorage,
      filter: mutation => {
        return /^auth\//.test(mutation.type)
      },
    }),
    createPersistedState({
      key: 'apojaws_admin',
      paths: ['admin_auth.admin_user', 'admin_auth.loggedIn'],
      storage: window.localStorage,
      filter: mutation => {
        return /^admin_auth\//.test(mutation.type)
      },
    }),
    createPersistedState({
      key: 'apojaws_session',
      paths: ['notice_display'],
      storage: window.sessionStorage,
    }),
  ],
})
