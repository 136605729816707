<template lang='pug'>
v-layout(row wrap)
  v-flex(xs12)
    v-data-table.ma-4(:items="accessLogs" class="elevation-1", hide-actions)
      template(v-slot:headers="props")
        tr.primary
          th.body-2.font-weight-bold.white--text 閲覧順序
          th.body-2.font-weight-bold.white--text 閲覧日時
          th.body-2.font-weight-bold.white--text(v-if="isAnalysisPage()" v-for="sumCategory in sumCategories")
            | {{ sumCategory.attributes.name }}
          th.body-2.font-weight-bold.white--text ページタイトル
          th.body-2.font-weight-bold.white--text(v-if="isAnalysisPage()") ページ名
          th.body-2.font-weight-bold.white--text 閲覧時間
      template(v-slot:items="props")
        tr(:class="[rowColor(props.item)]")
          td 訪問 {{ props.item.attributes.session_number }} 回目、 {{ props.item.attributes.access_number }} ページ目
          td {{ props.item.attributes.created }}
          td(v-if="isAnalysisPage()" v-for="sumCategory in sumCategories")
            | {{ categoryNmae(props.item, sumCategory) }}
          td
            a(:href="props.item.attributes.url" target="_blank") {{ props.item.attributes.title }}
          td(v-if="isAnalysisPage()") {{ categoryPageName(props.item) }}
          td {{ props.item.attributes.browse_time_minutes }}
  v-flex(xs12)
    ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import ResponseMixin from '../../../libs/ResponseMixin'
import ThePagination from '../../../components/ThePagination'
import ColorMixin from '../../../libs/ColorMixin'

export default {
  name: 'TheSimple',
  components: {
    ThePagination,
  },
  mixins: [ResponseMixin, ColorMixin],
  props: {
    storeModule: {
      type: String,
      default: ''
    },
  },
  computed: {
    conversionLog: function() {
      return this.$store.state[this.storeModule].conversionLog
    },
    accessLogs: function() {
      return this.$store.state[this.storeModule].accessLogs
    },
    loading: function() {
      return this.$store.state[this.storeModule].loading
    },
    sumCategories: function(){
      return this.$store.state[this.storeModule].sumCategories
    },
    totalPages: function() {
      return this.$store.state[this.storeModule].accessLogTotalPage
    },
  },
  methods: {
    categoryNmae(access_log, sumCategory) {
      const sumCategoryVar = access_log.attributes.category_var_by_category_id[sumCategory.id]
      return sumCategoryVar ? sumCategoryVar.title : '-'
    },
    categoryPageName(access_log) {
      return access_log.attributes.category_url_field ? access_log.attributes.category_url_field.page_name : '-'
    },
    isAnalysisPage() {
      return this.$store.state.apojaws.isAnalysisPage
    },
    rowColor(accessLog) {
      return accessLog.attributes.is_conversion_access ? this.conversionLogSessionClass : this.viewTimeColor(accessLog.attributes.browse_time)
    },
    async changePage() {
      const response = await this.$store.dispatch(this.storeModule + '/fetch', this.$route.params)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch(this.storeModule + '/reloadImg')
    }
  }
}
</script>

<style lang='scss' scoped>
.v-datatable th,
.v-datatable td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
