<template lang='pug'>
v-layout.column
  h1.display-1.font-weight-black.green--text.text--darken-3 アナリティクス
  TheNav.mb-3
  v-layout(row wrap)
    v-flex.xs12
      v-card.mb-4
        v-card-text.grey.lighten-3
          | 下記は問い合わせのあったお客様のHP訪問履歴・閲覧履歴情報です。（反響の詳細や個人情報は従来どおりの方法で確認して下さい）
      TheRevisit(storeModule='conversion_logs_analysis_revisit' @callFetch="actionFetch" baseColor='blue')
      TheHotSign(v-if="useableRanking" storeModule='conversion_logs_analysis_revisit' baseColor='blue').mb-3
      TheCustomerFavorite(storeModule='conversion_logs_analysis_revisit' baseColor='blue').mb-3
      TheRanking(v-if="useableRanking" storeModule='conversion_logs_analysis_revisit' baseColor='blue' @callRanking='actionRankings').mb-3
      TheCustomer(storeModule='conversion_logs_analysis_revisit' baseColor='blue').mb-3
      TheAccessLog(@callFetch="actionFetch" storeModule='conversion_logs_analysis_revisit' baseColor='blue')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'
import TheNav from '../../components/conversion_logs/TheNav'
import TheRevisit from '../../components/conversion_logs/TheRevisit'
import TheHotSign from '../../components/conversion_logs/TheHotSign'
import TheCustomerFavorite from '../../components/conversion_logs/TheCustomerFavorite'
import TheRanking from '../../components/conversion_logs/TheRanking'
import TheCustomer from '../../components/conversion_logs/TheCustomer'
import TheAccessLog from '../../components/conversion_logs/TheAccessLog'

export default {
  name: 'ConversionLogsAnalysisRevisit',
  components: {
    TheNav,
    TheCustomer,
    TheCustomerFavorite,
    TheAccessLog,
    TheHotSign,
    TheRanking,
    TheRevisit,
  },
  mixins: [ResponseMixin],
  data: function () {
    return {
    }
  },

  computed: {
    ...mapGetters('conversion_logs_analysis_revisit', [
      'getInitUrlQuery'
    ]),
    ...mapGetters('auth', [
      'useableRanking',
    ]),
  },

  async created() {
    await this.init()
    await this.initUrlQuery(this.getInitUrlQuery)
    await this.initState()
    await this.actionFetch()
  },

  async beforeDestroy() {
    await this.initState()
  },

  methods: {
    ...mapActions('conversion_logs_analysis_revisit', [
      'init',
      'fetch',
      'hot_signs',
      'rankings',
      'initState',
      'reloadImg'
    ]),

    async actionFetch() {
      let promises = []
      const fetchResponse = await this.fetch(this.$route.params)
      if (!this.chkResponse(fetchResponse)) {
        return true
      }
      if (this.useableRanking) {
        promises.push(this.hot_signs(this.$route.params))
        promises.push(this.rankings(this.$route.params))
      }
      await Promise.all(promises).then(responses => {
        responses.some(response => {
          if (!this.chkResponse(response)) {
            return true
          }
        })
      })
      this.reloadImg()
    },
    async actionRankings() {
      if (!this.useableRanking) {
        return
      }
      const response = this.rankings(this.$route.params)
      if (!this.chkResponse(response)) {
        return true
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
