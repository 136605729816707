<template lang='pug'>
v-layout(align-center justify-center)
  v-flex(xs12 sm8 md4)
    v-card
      v-card-text
        v-layout(align-center justify-center)
          img(src='~apojaws.svg')
      v-card-text
        v-layout(lign-center justify-center)
          h2.display-2 404 Not Found
        v-layout(lign-center justify-center)
          span お探しのページは見つかりませんでした。
        v-card-actions.mt-3
          v-layout(align-center justify-center)
            v-btn(color='accent' @click='goTop()')
              v-icon keyboard_backspace
              | TOP ページへ戻る
</template>

<script>
export default {
  name: 'NotFound',

  computed: {
    isAdmin: function () {
      return this.$store.state.apojaws.isAdmin
    },
  },

  methods: {
    goTop () {
      this.$router.push({ name: this.isAdmin ? 'admin_index' : 'index' })
    }
  },
}
</script>

<style lang='scss' scoped>
</style>
