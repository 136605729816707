<template lang='pug'>
v-card
  v-card-title(class="title cyan lighten-2" primary-title)
    | ログイン回数期間検索
  v-divider
  v-card-text
    v-form(ref='form' v-model="searchValid" lazy-validation=true)
      v-layout(row xs12 class="mb-3")
        v-flex.mr-3(xs6)
          v-menu(v-model='pickerMenuFrom' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model='loginFrom' label='Fromを選択' prepend-icon='event' readonly='' v-on='on' :rules='loginToRules')
            v-date-picker(v-model='loginFrom' @input='pickerMenuFrom = false' locale='ja')
        v-flex(xs6)
          v-menu(v-model='pickerMenuTo' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model='loginTo' label='Toを選択' readonly='' v-on='on' :rules='loginFromRules')
            v-date-picker(v-model='loginTo' @input='pickerMenuFrom = false' locale='ja')
  v-card-actions
    v-spacer
    v-btn(color="" @click="submitClose")
     | 閉じる
    v-btn(color="primary" @click="submitSearch" :loading='loading' :disabled="!searchValid")
     | 検索
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'TheAdminUserSearchField',
  data: function() {
    return {
      pickerMenuFrom: false,
      pickerMenuTo: false,
      searchValid: true,
      loginToRules: [
        v => !!v || '入力必須です',
      ],
      loginFromRules: [
        v => !!v || '入力必須です',
      ],
    }
  },

  computed: {
    ...mapState(['user']),
    ...mapGetters('user', [
    ]),

    searchConditions: function(){
      return this.user.searchConditions
    },

    loading: function () {
      return this.user.loading
    },

    loginFrom: {
      get (){
        return this.user.searchConditions.login_from
          ? this.user.searchConditions.login_from
          : this.user.searchConditions.default_login_from
      },
      set (value) {
        if (!this.user.searchConditions.login_to) { this.user.searchConditions.login_to = this.user.searchConditions.default_login_to }
        this.user.searchConditions.login_from = value
      },
    },

    loginTo: {
      get (){
        return this.user.searchConditions.login_to
          ? this.user.searchConditions.login_to
          : this.user.searchConditions.default_login_to
      },
      set (value) {
        if (!this.user.searchConditions.login_from) { this.user.searchConditions.login_from = this.user.searchConditions.default_login_from }
        this.user.searchConditions.login_to = value
      },
    }
  },

  methods: {
    ...mapMutations('user', [
      'clearSearchConditions',
    ]),

    ...mapActions('user', [
      'fetch',
    ]),

    async submitSearch() {
      if (!this.$refs.form.validate())  { return }
      await this.changeSearchQuery(this.searchConditions)
      this.$emit('close')
      this.$emit('fetchUsers')
    },
    clearConditionsAndReacquire() {
      this.clearSearchConditions()
      this.changeSearchQuery(this.searchConditions)
      this.fetchUsers()
    },
    submitClose() {
      this.$emit('close')
    }
  },
}
</script>

<style lang='scss' scoped>
.title {
  color: black;
}
</style>
