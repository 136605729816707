import api from 'api'
import store from '../store'

export default {
  namespaced: true,

  state: {
    rankingPreference: {},
    autoRankingUrls: [],
    pagination: {},
    autoRankingNames: [],
    // key: auto_ranking_url_id + ':' + rank_name_id  value: rankingAvairable でメモ化
    autoRankingAvarablesH: {},
    loading: true,
    avableKey: function(urlId, nameId) {
      return urlId + ':' + nameId
    },
    totalPages: 0,
    searchConditions: {},
  },

  getters: {
    getPreference(state) {
      return state.rankingPreference
    },
    getUrls(state) {
      return state.autoRankingUrls
    },
    getTotalPages(state) {
      return state.totalPages
    },
    getCurrentPage(state) {
      return  state.currentPage
    },
    getAvableH(state) {
      return state.autoRankingAvarablesH
    },
    getAvableKey: (state) => (urlId, nameId) => {
      return state.avableKey(urlId, nameId)
    },
    getAvable: (state) => (urlId, nameId) => {
      const key = state.avableKey(urlId, nameId)
      return state.autoRankingAvarablesH[key]
    },
    getNames(state) {
      return state.autoRankingNames
    },
    getNameidx: (state) => (idx) => {
      return state.autoRankingNames[idx]
    },
    getShow(state) {
      return state.searchConditions.show
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created_at',
          descending: true,
        },
        searchConditions: {
          show: 'default'
        }
      }
    }
  },

  mutations: {
    setRankingPreferenceData(state, {response: response, commit: commit }) {
      const data = response.data
      commit('setRankingPreferences', data)
      commit('setRankingNames', data.attributes.ranking_names.data)
    },
    setAutoRankingUrlData(state, {response: response, commit: commit }) {
      const data = response.data
      const included = response.included
      const links = response.links
      commit('setAutoRankingUrls', data)
      commit('setTotalPages', links.total_pages)
      commit('setRankingAvablesH', included.filter(e => e.type == 'auto_ranking_avable'))
    },
    setRankingPreferences(state, rankingPreference) {
      state.rankingPreference = rankingPreference
    },
    setRankingNames(state, autoRankingNames) {
      state.autoRankingNames = autoRankingNames
    },
    setAutoRankingUrls(state, autoRankingUrls) {
      state.autoRankingUrls = []
      autoRankingUrls.forEach(rankingUrl => {
        rankingUrl.attributes.frontId = rankingUrl.attributes.id
        state.autoRankingUrls.push(rankingUrl)
      })
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },
    setRankingAvablesH(state, rankingAvables) {
      state.autoRankingAvarablesH = {}
      rankingAvables.forEach(rankingAvable => {
        const urlId = rankingAvable.attributes.auto_ranking_url_id
        const nameId = rankingAvable.attributes.ranking_name_id
        const key = state.avableKey(urlId, nameId)
        state.autoRankingAvarablesH[key] = {
          type: 'auto_ranking_avable',
          attributes: {
            id: rankingAvable.attributes.id,
            ranking_name_id: nameId,
            auto_ranking_url_id: urlId
          }
        }
      })
    },
    changeAvable(state, {urlId: urlId, nameId: nameId}) {
      const key = state.avableKey(urlId, nameId)
      const targetAvable = state.autoRankingAvarablesH[key]
      if (targetAvable) {
        delete state.autoRankingAvarablesH[key]
      } else {
        state.autoRankingAvarablesH[key] = {
          type: 'auto_ranking_avable',
          attributes: {
            ranking_name_id: nameId,
            auto_ranking_url_id: urlId
          }
        }
      }
    },
    setLoading(state, loading) {
      state.loading = loading
    },

    setShow(state, show) {
      state.searchConditions.show = show
    }
  },

  actions: {
    async init({commit}) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
    },

    async fetch({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
        show: state.searchConditions.show,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      state.loading = true
      const response =  await api.get('auto_ranking_preference/edit', { params: params }).then(response => {
        commit('setRankingPreferenceData', {response: response.data.ranking_preference, commit: commit})
        commit('setAutoRankingUrlData', {response: response.data.auto_ranking_urls, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async update({state, commit}) {
      state.loading = true
      const autoRankingNamesAttributes= {}
      state.autoRankingNames.forEach((value, key) => {
        autoRankingNamesAttributes[key] = {
          id: value.attributes.id,
          name: value.attributes.name
        }
      })
      const autoRankingUrlsAttributes = {}
      state.autoRankingUrls.forEach(value => {
        autoRankingUrlsAttributes[Object.keys(autoRankingUrlsAttributes).length] = {
          id: value.attributes.id,
          name: value.attributes.name,
          disabled: value.attributes.disabled,
        }
      })
      const rankingAvableAttributes = {}
      Object.keys(state.autoRankingAvarablesH).forEach((key, idx) => {
        const rankingAvable = state.autoRankingAvarablesH[key]
        rankingAvableAttributes[idx] = {
          id: rankingAvable.attributes.id,
          auto_ranking_url_id: rankingAvable.attributes.auto_ranking_url_id,
          ranking_name_id: rankingAvable.attributes.ranking_name_id
        }
      })
      const params = {
        company_id: store.state.auth.user.company_id,
        show: state.searchConditions.show,
        ranking_preference: {
          attributes: {
            parameter_white_list: state.rankingPreference.attributes.parameter_white_list,
            reject_urls: state.rankingPreference.attributes.reject_urls,
            ranking_names_attributes: autoRankingNamesAttributes,
            auto_ranking_urls_attributes: autoRankingUrlsAttributes,
          }
        },
        auto_ranking_avables: rankingAvableAttributes,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const response =  await api.patch('auto_ranking_preference' , { params: params }).then((response) => {
        commit('setRankingPreferenceData', {response: response.data.ranking_preference, commit: commit})
        commit('setAutoRankingUrlData', {response: response.data.auto_ranking_urls, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async changeHasAutoRanking({state, commit}) {
      state.loading = true
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const response =  await api.patch('auto_ranking_preference/has_auto_ranking' , { params: params }).then((response) => {
        commit('setRankingPreferenceData', {response: response.data.ranking_preference, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvDownload({state}) {
      state.loading = true
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      const response =  await api.get('auto_ranking_preference/csv', { params: params, responseType: 'blob' }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvUpload({state, dispatch}, file) {
      state.loading = true
      var formData = new FormData()
      formData.append('csvfile', file)

      const response =  await api.post('auto_ranking_preference/csv', { params: formData }).then(response => {
        return response
      }).catch(err => err.response)
      await dispatch('fetch')
      state.loading = false
      return response
    },

    addUrl({commit}) {
      commit('addRankiingUrl')
    },

    delUrl({commit}, {urlId: urlId}) {
      commit('delRankingUrl', {urlId: urlId})
    },
  },
}
