import api from 'api'
import store from '../store'

const CATEGORY_TEXT_REGEXP = /##_category_text_##\r?\n\r?\n##_category_text_##\r?\n\r?\n##_category_text_##/g
const DEFAULT_MAIL_BODY = `○○様

はじめまして。○○住宅の○○と申します。
この度は、お問い合わせをいただきありがとうございまいした。

○○様の理想のお住まいが建てられるよう、全力でお手伝いさせていただきます。
ただ、住宅会社には実は得意なものがあります。
弊社の特徴を簡単にご紹介いたします。
##_category_text_##

##_category_text_##

##_category_text_##
弊社の特徴はそんなところですが、もし○○の様のご要望にかないそうであれば、一度ご面会させていただく機会を頂戴できれば幸いです。
つきましては以下の日時はご都合いかがでしょうか？

○月○日（○）10:00〜:
○月○日（○）12:00〜:
○月○日（○）15:00〜:

○○様の理想の住まいを伺えること、楽しみにしております。
よろしくお願いいたします。`

export default {
  namespaced: true,

  state: {
    sumCategories: [],
    mailFormats: [],
    mailFormatOrgs: [],
    mailDefault: {},
    loading: false,
  },

  getters: {
    mailFormatOrgSize(state) {
      return state.mailFormatOrgs.length
    },
  },

  mutations: {
    setSumCategories(state, sumCategories) {
      let list = []
      for (const i in sumCategories) {
        list.push(sumCategories[i].attributes)
      }
      state.sumCategories = list
    },

    setMailFormats(state, mailFormats) {
      let list = []
      for (const i in mailFormats) {
        list.push(mailFormats[i].attributes)
        // メール本文が入力されている場合は表示状態にする
        list[i].checked = list[i].mail_body ? true : false
      }
      state.mailFormats = list
    },

    setMailFormatOrgs(state, mailFormatOrgs) {
      let list = []
      for (const i in mailFormatOrgs) {
        list.push(mailFormatOrgs[i].attributes)
      }
      state.mailFormatOrgs = list
    },

    setMailDefault(state, mailDefault) {
      if (mailDefault.length > 0) {
        state.mailDefault = mailDefault[0].attributes
      } else {
        state.mailDefault = {
          percent: '0',
          mail_body: this.getters['auth/isAnalyticsConfig'] ? DEFAULT_MAIL_BODY : DEFAULT_MAIL_BODY.replace(CATEGORY_TEXT_REGEXP, ''),
        }
      }
    },

    addMailFormatOrg(state) {
      state.mailFormatOrgs.push(
        {
          category_name: '',
          mail_body: '',
        }
      )
    },

    removeMailFormatOrg(state, deleteIndex) {
      state.mailFormatOrgs = state.mailFormatOrgs.filter((_, index) => index !== deleteIndex)
    },

    changMailFormats(state, mailFormats) {
      state.mailFormats = mailFormats
    },

    changMailFormatOrgs(state, mailFormatOrgs) {
      state.mailFormatOrgs = mailFormatOrgs
    },

    changMailDefault(state, mailDefault) {
      state.mailDefault = mailDefault
    },

    changeLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async fetch({ commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      return await api.get('auto_mail', { params: params }).then(function (response) {
        commit('setSumCategories', response.data.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async fetchMailFormats({ commit }, urlParams) {
      return await api.get('auto_mail/' + urlParams.category_id + '/mail_formats',).then(function (response) {
        commit('setMailFormats', response.data.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async saveMailFormats({ state }, urlParams) {
      const options = {
        params: {
          data: { mail_formats: state.mailFormats }
        }
      }
      return await api.patch('auto_mail/' + urlParams.category_id + '/update_mail_formats', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async deleteMailFormat(state, urlParams) {
      const options = {
        params: {
          mail_format_id: urlParams.mail_format_id
        }
      }
      return await api.delete('auto_mail/' + urlParams.category_id + '/destroy_mail_format', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async fetchMailFormatOrgs({ commit }) {
      return await api.get('auto_mail/mail_format_orgs').then(function (response) {
        commit('setMailFormatOrgs', response.data.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async saveMailFormatOrgs({ state }) {
      const options = {
        params: {
          data: { mail_format_orgs: state.mailFormatOrgs }
        }
      }
      return await api.patch('auto_mail/create_or_update_mail_format_orgs', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async deleteMailFormatOrg(state, params) {
      const options = {
        params: {
          mail_format_org_id: params.mail_format_org_id
        }
      }
      return await api.delete('auto_mail/destroy_mail_format_org', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async fetchMailDefault({ commit }) {
      return await api.get('auto_mail/mail_default').then(function (response) {
        commit('setMailDefault', response.data.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async createMailDefault({ state }) {
      const options = {
        params: {
          mail_default: state.mailDefault
        }
      }
      return await api.post('auto_mail/create_mail_default', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async saveMailDefault({ state }) {
      const options = {
        params: {
          mail_default: state.mailDefault
        }
      }
      return await api.patch('auto_mail/update_mail_default', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
    },
  },
}
