<template lang='pug'>
v-container
  v-form(ref='form' v-model='valid' lazy-validastion='', @submit.prevent="submit")
    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label')
          | 会社名
      v-flex(xs9)
        v-text-field(v-model="company.data.attributes.company_name", :rules='copmanyNameRules', required='')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label')
          | グループ名
      v-flex(xs9)
        v-text-field(v-model="company.data.attributes.group_name")

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label')
          | URL
      v-flex(xs9)
         v-text-field(v-model="company.data.attributes.url", required, :rules='urlRules')

    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs9 offset-xs3)
        v-checkbox(v-model="company.data.attributes.cross_domain_flag", label='クロスドメイン')
      v-layout(row xs12 class="mb-3" wrap v-if='company.data.attributes.cross_domain_flag')
        v-flex(xs3)
          v-subheader(class='label') クロスドメイン
        v-flex(xs9)
          v-text-field(v-if="availableCrossDomain && !isHomeUrlRedirect" v-model="company.data.attributes.storage_redirect_url" label='リダイレクトURL' :rules='redirectUrlRules')
          p
            v-btn(v-if="action == 'edit'" @click='submitCsvDownload') CSVダウンロード
            v-btn(@click="$refs.inputUpload.click()") アップロードファイル選択(CSV)
            input(v-show="false" ref="inputUpload" type="file" @change="submitUploadFile")
        v-layout(row wrap xs12 class='mb-1')
          v-flex(xs9 offset-xs3)
            v-data-table(hide-actions, :headers="domain_groups_headers", :items="company.relation.domain_groups", class='elevation-1' item-key='domain_groups')
              template(v-slot:items="props")
                td
                  v-text-field(v-model="props.item.attributes.domain" size=60 :rules='checkRule(props.item, domainRules)' :ref="'domain_groups_domain' + props.index")
                td
                  | {{ props.item.attributes.created | moment('YYYY/MM/DD hh:mm:ss') }}
                td
                  v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addDomainGroups({targetList: company.relation.domain_groups, idx: props.index})', color='success') 追加
                  v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.relation.domain_groups, idx: props.index})', color=' lighten-2') 削除
                  v-btn(v-else, @click='popList({targetList: company.relation.domain_groups, idx: props.index})', :disabled='true') 削除予定

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='required label')
          | メール（再訪時）
      v-flex(xs9)
         v-text-field(v-model="company.data.attributes.email",  required, :rules='emailRequireRules')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label')
          | メール（成約時）
      v-flex(xs9)
         v-text-field(v-model="company.data.attributes.conv_email" :rules='emailRules')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label')
          | Bcc Email
      v-flex(xs9)
         v-text-field(v-model="company.data.attributes.bcc_email", :rules='emailRules')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label')
          | 旧権限
      v-flex
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.revisit_flag", label='再訪メール送付', @change="changeRevisitFlag")
          v-flex(xs8 md9)
            v-checkbox(v-model="company.data.attributes.is_only_individuals", label='個別設定のメールアドレスのみ送付', :disabled="!company.data.attributes.revisit_flag")
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.beacon_flag", label='コネクト')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.is_ana_user", label='解析機能')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.is_auto_mail", label='自動メール生成')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.is_chart", label='アナリティクスグラフ機能')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.has_tel_conversion", label='電話コンバージョン機能')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.has_ranking", label='ランキング機能', @change="changeHasRanking")
          v-flex(xs8 md9)
            v-checkbox(v-model="company.data.attributes.has_auto_ranking", label='自動ランキング', :disabled="!company.data.attributes.has_ranking")
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.edit_relation_code", label='問合わせ番号編集')
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.stop_zerotime_conversion", label='0秒再訪通知停止')

    v-layout(row xs12 class="mb-3")
      v-flex(xs3)
        v-subheader(class='label')
          label デフォルト画面
      v-flex(xs9)
        v-radio-group(v-model="company.data.attributes.report_page_default", row)
          v-radio(v-for="pageDefault in pageDefaults" :key="pageDefault.label", :label="pageDefault.label", :value="pageDefault.value")

    v-layout(row wrap xs12 class='mb-5')
      v-flex(offset-xs3)
        p アポジョーズ・ビジュアル化ページでの「訪問履歴」や「閲覧履歴」の表示順
        small  ※ チェックなし(デフォルト)で、古い順に表示します。
        v-layout(row wrap xs12)
          v-flex(xs4 md3)
            v-checkbox(v-model="company.data.attributes.report_rsort", label='新しい順')

    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs3)
        v-subheader(class='label') Div Emails
      v-flex(xs9 pt-4)
        p()
          | 例)Div Code=shiten_shonan メールアドレス=nagatomo@marsary.co.jp soneda@marsary.co.jp
          br
          small
            |⇒ Div Code=shiten_shonan のメールの Cc に指定された2者が加わります。
      v-layout(row wrap xs12 class='mb-1')
        v-flex(xs9 offset-xs3)
          v-data-table(hide-actions, :headers="parse_div_emails_headers", :items="company.parse.parse_div_emails", class='elevation-1' item-key='parse_div_emails')
            template(v-slot:items="props")
              td
                v-text-field(v-model="props.item.attributes.div_code" size=60 :ref="'parse_div_emails_div_code' + props.index" :rules="checkRule(props.item, urlParamRules)")
              td
                v-combobox(v-model="props.item.attributes.emails" multiple chips hide-selected :ref="'parse_div_emails_emails' + props.index" :rules='checkRule(props.item, emailsRules)')
              td
                v-text-field(v-model="props.item.attributes.div_name" size=60 :ref="'parse_div_emails_div_name' + props.index")
              td
                v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addDivEmails({targetList: company.parse.parse_div_emails, idx: props.index})', color='success') 追加
                v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.parse.parse_div_emails, idx: props.index})', color=' lighten-2') 削除
                v-btn(v-else, @click='popList({targetList: company.parse.parse_div_emails, idx: props.index})', :disabled='true') 削除予定


    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs3)
        v-subheader(class='label') Cv Titles
      v-flex(xs9 pt-4)
        p
          | 例)Cv Code=toiawase タイトル=物件問合せ
          br
          small
            |⇒ Cv Code=toiawase のメールのタイトルが「物件問合せがありました」になります。
      v-layout(row wrap xs12 class='mb-1')
        v-flex(xs9 offset-xs3)
          v-data-table(hide-actions, :headers="parse_cv_titles_headers", :items="company.parse.parse_cv_titles", class='elevation-1' item-key='parse_cv_titles')
            template(v-slot:items="props")
              td
                v-text-field(v-model="props.item.attributes.cv_code" size=60 :ref="'parse_cv_titles_cv_code' + props.index" :rules="checkRule(props.item, urlParamRules)")
              td
                v-text-field(v-model="props.item.attributes.title" size=60 :ref="'parse_cv_titles_title' + props.index")
              td
                v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addCvTitles({targetList: company.parse.parse_cv_titles, idx: props.index})', color='success') 追加
                v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.parse.parse_cv_titles, idx: props.index})', color=' lighten-2') 削除
                v-btn(v-else, @click='popList({targetList: company.parse.parse_cv_titles, idx: props.index})', :disabled='true') 削除予定

    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs3)
        v-subheader(class='label') Ip Black List
      v-flex(xs9 pt-4)
        p
          | 例) 118.243.253.216
          br
          small
            |⇒ 118.243.253.216 からのアクセスには何もせず終了します。
      v-layout(row wrap xs12 class='mb-1')
        v-flex(xs9 offset-xs3)
          v-data-table(hide-actions, :headers="ip_black_lists_headers", :items="company.relation.ip_black_lists", class='elevation-1' item-key='ip_black_lists')
            template(v-slot:items="props")
              td
                v-text-field(v-model="props.item.attributes.ip" size=60 :ref="'relation_ip_black_lists_ip' + props.index" :rules="checkRule(props.item, ipRules)")
              td
                v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addIpBlackLists({targetList: company.relation.ip_black_lists, idx: props.index})', color='success') 追加
                v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.relation.ip_black_lists, idx: props.index})', color=' lighten-2') 削除
                v-btn(v-else, @click='popList({targetList: company.relation.ip_black_lists, idx: props.index})', :disabled='true') 削除予定

    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs3)
        v-subheader(class='label') ログインIPアドレス制限
      v-flex(xs9 pt-4)
        p
          | 例）118.243.253.218⇒118.243.253.218からのアクセスでのみログインを許可します
      v-layout(row wrap xs12 class='mb-1')
        v-flex(xs9 offset-xs3)
          v-data-table(hide-actions, :headers="ip_black_lists_headers", :items="company.relation.ip_white_lists", class='elevation-1' item-key='ip_white_lists')
            template(v-slot:items="props")
              td
                v-text-field(v-model="props.item.attributes.ip" size=60 :ref="'relation_ip_white_lists_ip' + props.index" :rules="checkRule(props.item, ipRules)")
              td
                v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addIpWhiteLists({targetList: company.relation.ip_white_lists, idx: props.index})', color='success') 追加
                v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.relation.ip_white_lists, idx: props.index})', color=' lighten-2') 削除
                v-btn(v-else, @click='popList({targetList: company.relation.ip_white_lists, idx: props.index})', :disabled='true') 削除予定

    v-layout(row wrap xs12 class='mb-5')
      v-flex(xs3)
        v-subheader(class='label') 認証
      v-flex(xs9 pt-4)
        p アポジョーズ・ビジュアル化ページへの認証アカウント
        small(v-if="action == 'edit'") ※ 現行バージョンでは、本ページ上でパスワードの確認・変更はできません。
        v-text-field(v-model="company.data.attributes.auth_user", label='ユーザー名')
        v-text-field(v-model="company.data.attributes.auth_raw_pass", label='パスワード', v-if="action == 'new'" type="password")

    v-layout(row xs12 class="mb-3" wrap)
      v-flex(xs3)
        v-subheader(class='label') Iframe Url List
      v-flex(xs9 pt-4)
        p
          |例) http(s?):\/\/(www\.)?example\.co\.jp
          br
          |→   https://www.example.co.jp、https://example.co.jp、http://example.co.jp、http://www.example.co.jp
          br
          small
            |※ 設定したurl正規表現に一致した場合、アボジョーズサイトで、サイトのスクリーンショット画像ではなく、iframeを利用します
      v-layout(row wrap xs12 class='mb-1')
        v-flex(xs9 offset-xs3)
          v-data-table(hide-actions, :headers="use_iframe_urls_headers", :items="company.relation.use_iframe_urls", class='elevation-1' item-key='use_iframe_urls')
            template(v-slot:items="props")
              td
                v-text-field(v-model="props.item.attributes.filter_url" size=60  :ref="'relation_use_iframe_urls_filter_url' + props.index")
              td
                v-btn(v-if="!props.item.vue.existDB && !props.item.vue.needSave", @click='addIframeUrlList({targetList: company.relation.use_iframe_urls, idx: props.index})', color='success') 追加
                v-btn(v-else-if="props.item.vue.needSave", @click='popList({targetList: company.relation.use_iframe_urls, idx: props.index})', color=' lighten-2') 削除
                v-btn(v-else, @click='popList({targetList: company.relation.use_iframe_urls, idx: props.index})', :disabled='true') 削除予定

    v-layout.row.xs12.mb-3
      v-flex.xs3
        v-subheader.label
          | 新 URL へのリダイレクト設定
      v-flex.pt-4
        p
          | onにした場合コンバージョンメール、電話コンバージョンメール、再来訪メールが新URL管理内容で送信されます。
        v-layout.row.wrap.xs12
          v-flex.xs4.md3
            v-switch(v-model='company.data.attributes.is_redirect_to_newly_site', label='off/on')

    v-layout(row wrap class='mb-5')
      v-flex(offset-xs3)
        v-btn(v-if="action == 'new'" color='info' @click='submitCreate' :disabled='loading' :loading="loading") 登録
        v-btn(v-else-if="action == 'edit'" color='info' @click='submitUpdate' :disabled='loading' :loading="loading") 更新
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import FileMixin from '../libs/FileMixin'
import FilterMixin from '../libs/FilterMixin'
import EncodeMixin from '../libs/EncodeMixin'

const REG_DOMAIN_CODE = /^[-\w.]+$/
const REG_URL_PARAM = /^[a-zA-Z0-9_-]+$/

export default {
  name: 'CompanyForm',
  mixins: [FileMixin, FilterMixin, EncodeMixin],
  props: {
    action: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      errSnackbar: false,
      errSnackMessage: '',
      errSnackTime: 5000,
      valid: true,
      loading: false,
      copmanyNameRules: [
        v => !!v || '入力必須です',
      ],
      urlRules: [
        v => (v && v.length <= 255) || '255文字以内で入力してください。',
        v => /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/.test(v) || 'URLの形式で入力してください。',
      ],
      redirectUrlRules: [
        v => (!v || v.length <= 255) || '255文字以内で入力してください。',
        v => (!v || /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/.test(v)) || 'URLの形式で入力してください。',
      ],
      emailRequireRules: [
        v => !!v || '入力必須です',
        v => (v.length <= 511) || '511文字以内で入力してください。',
        v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
      ],
      emailRules: [
        v => (!v || v.length <= 255) || '255文字以内で入力してください。',
        v => (!v || /.+@.+/.test(v)) || 'メールアドレスの形式で入力してください。',
      ],
      emailsRules: [
        v => (!v ||  v.length <= 0 || v.every(e => /.+@.+/.test(e))) || '全てメールアドレスの形式で入力してください。',
      ],
      domainRules: [
        v => (!v || REG_DOMAIN_CODE.test(v)) || 'ドメインの形式で入力してください。',
      ],
      urlParamRules: [
        v => (!v || REG_URL_PARAM.test(v)) || '英数字と「_ -」の組み合わせで入力してください。',
      ],
      ipRules: [
        v => (v == undefined || v == '' || /^\d+\.\d+\.\d+\.(\d+|\*)$/.test(v)) || 'IPの形式で入力してください。',
      ],

      domain_groups_headers: [
        {
          text: 'ドメイン',
          value: 'domain',
          sortable: false,
        },
        {
          text: '作成日時',
          value: 'created',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],

      parse_div_emails_headers: [
        {
          text: 'Div Code',
          value: 'div_code',
          sortable: false,
        },
        { text: 'メールアドレス',
          value: 'emails',
          sortable: false,
        },
        { text: '部署名',
          value: 'div_name',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],
      parse_cv_titles_headers: [
        {
          text: 'Cv Code',
          value: 'cv_code',
          sortable: false,
        },
        {
          text: 'タイトル',
          value: 'title',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],
      ip_black_lists_headers: [
        {
          text: 'IP',
          value: 'ip',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],
      ip_white_lists_headers: [
        {
          text: 'IP',
          value: 'ip',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],
      use_iframe_urls_headers: [
        {
          text: 'Filter URL',
          value: 'filter_url',
          sortable: false,
        },
        { text: '操作',
          sortable: false,
        },
      ],
      pageDefaults: [
        {
          label: '詳細',
          value: 'detail',
        },
        {
          label: '一覧',
          value: 'list'
        },
        {
          label: '要約',
          value: 'simple'
        },
      ],
    }
  },

  computed: {
    ...mapGetters('company', [
    ]),
    homeRedirectUrl: function() {
      return this.$store.state.company.homeRedirectUrl

    },
    availableCrossDomain: function() {
      return process.env.available_cross_domain !== 'false'
    },
    isHomeUrlRedirect: function() {
      return this.$store.state.company.isHomeUrlRedirect
    },
  },

  mounted: function () {
    if (this.action == 'edit') {
      this.$refs.form.validate()
    }
  },

  created: function() {
  },

  methods: {
    ...mapActions('company', [
      'update',
      'create',
      'popList',
      'pushList'
    ]),
    checkRule(obj, rule) {
      return (!obj.vue.existDB || obj.vue.needSave) ? rule : true
    },

    changeHasRanking() {
      this.company.data.attributes.has_auto_ranking = false
    },

    changeRevisitFlag() {
      this.company.data.attributes.is_only_individuals = false
    },

    addDomainGroups({targetList: targetList, idx: idx}) {
      let atrKey = 'domain_groups_'
      let domainForm = this.$refs[atrKey.concat('domain').concat(idx)]
      if (!domainForm.valid || domainForm.value == undefined || domainForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'ドメインを正常に入力してください。', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },

    addDivEmails({targetList: targetList, idx: idx}) {
      let atrKey = 'parse_div_emails_'
      let divcodeForm = this.$refs[atrKey.concat('div_code').concat(idx)]
      let emailsForm = this.$refs[atrKey.concat('emails').concat(idx)]
      if (!divcodeForm.valid || divcodeForm.value == undefined || divcodeForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'Div Codeを正常に入力してください。', color: 'error'})
        return
      }
      if (!emailsForm.valid || emailsForm.value == undefined || emailsForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'メールアドレスを正常に入力してください。', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },

    addCvTitles({targetList: targetList, idx: idx}) {
      let atrKey = 'parse_cv_titles_'
      let cvcodeForm = this.$refs[atrKey.concat('cv_code').concat(idx)]
      let titleForm = this.$refs[atrKey.concat('title').concat(idx)]
      if (!cvcodeForm.valid || cvcodeForm.value == undefined || cvcodeForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'Cv Codeを正常に入力してください。', color: 'error'})
        return
      }
      if (!titleForm.valid || titleForm.value == undefined || titleForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'タイトルを入力してください。', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },

    addIpBlackLists({targetList: targetList, idx: idx}) {
      let atrKey = 'relation_ip_black_lists_'
      let ipForm = this.$refs[atrKey.concat('ip').concat(idx)]
      if (!ipForm.valid || ipForm.value == undefined || ipForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'IPを正常に入力してください', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },
    addIpWhiteLists({targetList: targetList, idx: idx}) {
      let atrKey = 'relation_ip_white_lists_'
      let ipForm = this.$refs[atrKey.concat('ip').concat(idx)]
      if (!ipForm.valid || ipForm.value == undefined || ipForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'IPを正常に入力してください', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },
    addIframeUrlList({targetList: targetList, idx: idx}) {
      let atrKey = 'relation_use_iframe_urls_'
      let ipForm = this.$refs[atrKey.concat('filter_url').concat(idx)]
      if (!ipForm.valid || ipForm.value == undefined || ipForm.value == '') {
        this.$store.dispatch('flash/showMessage', {message: 'Filter URLを正常に入力してください。', color: 'error'})
        return
      }
      this.pushList({targetList: targetList, idx: idx})
    },

    validDupDomain() {
      let domain_code_set = new Set()
      let dupDomain = this.company.relation.domain_groups.find(function(domainGroup) {
        if (domainGroup.vue.needSave == false) {
          return false
        }
        if (domain_code_set.has(domainGroup.attributes.domain)) {
          return true
        } else {
          domain_code_set.add(domainGroup.attributes.domain)
        }
      })
      if (dupDomain) {
        this.$store.dispatch('flash/showMessage', {message: 'ドメインが重複しています。', color: 'error'})
        return false
      }
      return true
    },

    validDupDivCode() {
      let div_code_set = new Set()
      let dupDivCode = this.company.parse.parse_div_emails.find(function(divEmail) {
        if (divEmail.vue.needSave == false) {
          return false
        }
        if (div_code_set.has(divEmail.attributes.div_code)) {
          return true
        } else {
          div_code_set.add(divEmail.attributes.div_code)
        }
      })
      if (dupDivCode) {
        this.$store.dispatch('flash/showMessage', {message: 'Div Codeが重複しています。', color: 'error'})
        return false
      }
      return true
    },

    validDupCvCode() {
      let cv_code_set = new Set()
      let dupCvCode = this.company.parse.parse_cv_titles.find(function(cvTitle) {
        if (cvTitle.vue.needSave == false) {
          return false
        }
        if (cv_code_set.has(cvTitle.attributes.cv_code)) {
          return true
        } else {
          cv_code_set.add(cvTitle.attributes.cv_code)
        }
      })
      if (dupCvCode) {
        this.$store.dispatch('flash/showMessage', {message: 'Cv Codeが重複しています。', color: 'error'})
        return false
      }
      return true
    },


    validDupIp() {
      let ip_set = new Set()
      let dupIp = this.company.relation.ip_black_lists.find(function(ipBlackList) {
        if (ipBlackList.vue.needSave == false) {
          return false
        }
        if (ip_set.has(ipBlackList.attributes.ip)) {
          return true
        } else {
          ip_set.add(ipBlackList.attributes.ip)
        }
      })
      if (dupIp) {
        this.$store.dispatch('flash/showMessage', {message: 'IPが重複しています。', color: 'error'})
        return false
      }
      return true
    },

    validDupFilterUrl() {
      let filter_url_set = new Set()
      let dupFilterUrl = this.company.relation.use_iframe_urls.find(function(use_iframe_url) {
        if (use_iframe_url.vue.needSave == false) {
          return false
        }
        if (filter_url_set.has(use_iframe_url.attributes.filter_url)) {
          return true
        } else {
          filter_url_set.add(use_iframe_url.attributes.filter_url)
        }
      })
      if (dupFilterUrl) {
        this.$store.dispatch('flash/showMessage', {message: 'FilterURLが重複しています。', color: 'error'})
        return false
      }
      return true
    },

    validSubmitChk() {
      if (!this.validDupDomain()) {
        return false
      }
      if (!this.validDupDivCode()) {
        return false
      }
      if (!this.validDupCvCode()) {
        return false
      }
      if (!this.validDupIp()) {
        return false
      }
      if (!this.validDupFilterUrl()) {
        return false
      }
      return true
    },

    submitUpdate() {
      this.loading = true
      if (!this.validSubmitChk()) {
        this.loading = false
        return
      }
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('flash/showMessage', {message: '入力誤りがあります。', color: 'error'})
        this.loading = false
        return
      }
      this.update(this.getUpdateCreateParam()).then(res => {
        if (res.status == 200) {
          this.$router.push({ name: 'companies'})
          this.$store.dispatch('flash/showMessage', {message: '会社情報を更新しました。', color: 'info'})
        } else if (res.status == 500) {
          this.loading = false
          return
        } else {
          this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
        }
      })
      this.loading = false
    },

    submitCreate() {
      this.loading = true
      if (!this.validSubmitChk()) {
        this.loading = false
        return
      }
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('flash/showMessage', {message: '入力誤りがあります。', color: 'error'})
        this.loading = false
        return
      }
      this.create(this.getUpdateCreateParam()).then(res => {
        if (res.status == 200) {
          this.$store.dispatch('flash/showMessage', {message: '会社情報を登録しました。', color: 'info'})
          this.$router.push({ name: 'companies'})
        } else if (res.status == 500) {
          this.loading = false
          return
        } else {
          this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
        }
      })
      this.loading = false
    },

    submitCsvDownload() {
      const filePath = this.company.data.id + '.csv'
      let content = 'ドメイン,削除フラグ,作成日時\r\n'
      this.company.relation.domain_groups.forEach((domain_group) => {
        if (!domain_group.vue.needSave) {
          return
        }
        content += domain_group.attributes.domain + ','
        content += domain_group.vue.needSave ? '0,' : '1,'
        content += '"' + moment(domain_group.attributes.created).format('YYYY/MM/DD HH:mm:ss') + '"'
        content += '\r\n'
      })
      content = content.trim()
      const sjisContent  = this.convertArray(content, 'unicode', 'sjis')
      this.fileDownload({filePath: filePath, type: 'text/csv', content: sjisContent})
    },

    submitUploadFile(e) {
      const file = e.target.files[0]
      let domainGroups = this.company.relation.domain_groups
      this.readFile({file: file, separator: /\r\n|\r|\n/ }, result => {
        if (result.status == 'error') {
          this.$store.dispatch('flash/showMessage', {message: 'CSVアップロードに失敗しました。', color: 'error'})
          return
        }

        let flashMessage = 'CSVファイルの読み込みを反映しました。\r\n'
        result.texts.forEach((lineText, idx) => {
          // ヘッダースキップ
          if (idx == 0) {
            return
          }

          const lineTextSplit = lineText.split(',')
          const domainCode = lineTextSplit[0]
          const delFlg = lineTextSplit[1]
          const existDomainIdx = domainGroups.findIndex((srcDomain) => {
            return srcDomain.attributes.domain == domainCode
          })

          // 削除フラグが立ち、ドメインDBに登録済み → 削除
          // 削除フラグが立ち、ドメインDBに登録無し → 何もしない
          if (delFlg == '1') {
            if (existDomainIdx >= 0) {
              this.popList({targetList: domainGroups, idx: existDomainIdx})
            }
            return

          // 削除フラグが無し、ドメインDBに登録済み → 何もしない
          // 削除フラグが無し、ドメインDBに登録無し → 登録処理
          } else {
            if (existDomainIdx >= 0) {
              return
            }

            // 登録必要ドメインコードチェック
            if (domainCode != undefined && domainCode != '' && REG_DOMAIN_CODE.test(domainCode)) {
              const lastDomainIdx = domainGroups.length - 1
              let lastDomainGroup = domainGroups[lastDomainIdx]
              lastDomainGroup.attributes.domain = domainCode
              this.pushList({targetList: domainGroups, idx: lastDomainIdx})
            } else {
              flashMessage += (idx + 1) + '行目の「' + domainCode + '」 はドメイン不正のため取込失敗しました。\r\n'
            }
          }
        })
        flashMessage = flashMessage.trim()
        this.$store.dispatch('flash/showMessage', {message: flashMessage, color: 'warning', timeout: 0})
      })
    },

    getUpdateCreateParam() {
      let data = this.company

      // 新規レコード分の追加
      for (let key in data.relation) {
        let keyAtr = key + '_attributes'
        data.data.attributes[keyAtr] = []
        data.relation[key].forEach(function(e) {
          if (e.vue.needSave == true) {
            // 不要パラメータ削除
            delete e.attributes.data
            data.data.attributes[keyAtr].push(e.attributes)
          }
        })
      }

      // 不要パラメータ削除
      delete data.data.attributes.data
      return data
    },

  },

}
</script>

<style lang='scss' scoped>
</style>
