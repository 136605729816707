export default {
  namespaced: true,

  state: {
    title: '',
    message: '',
    color: '',
    needDisplay: false,
  },

  mutations: {
    setMessage(state, {title: title, message: message, color: color}) {
      state.title = title
      state.message = message
      state.color = color
      state.needDisplay =  true
    },

    clearMessage(state) {
      state.title = ''
      state.message = ''
      state.color = ''
      state.needDisplay = false
    }
  },

  actions: {
    showDialog({commit}, {title: title, message: message, color: color='info'}) {
      return new Promise((resolve) => {
        commit('clearMessage')
        commit('setMessage', {title: title, message: message, color: color})
        resolve()
      })
    }
  }
}
