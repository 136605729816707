<template lang='pug'>
v-layout.align-center.justify-center
  v-flex.xs12.sm8.md4
    v-card
      v-card-text
        v-layout.align-center.justify-center
          img(src='~apojaws.svg')
      v-card-text
        v-layout.align-center.justify-center.column
          span パスワードの再発行を受け付け、入力したメールアドレス宛にメールを送信しました。
          span メールに添付の URL より、パスワードの再発行をしてください。
      v-card-actions.mt-3
        v-layout.align-center.justify-center
          router-link.btn-link(:to="{ name: 'login' }")
            v-btn(color='accent')
              v-icon keyboard_backspace
              | ログイン画面へ
</template>

<script>
import 'logo.jpg'

export default {
  name: 'PasswordReissueComplete',
}
</script>

<style lang='scss' scoped>
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
