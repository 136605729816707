<script>
export default {
  name: 'RouterMixin',
  methods: {
    walkObject: function(data, keys, callback) {
      Object.keys(data).forEach((key) => {
        let copyKeys = JSON.parse(JSON.stringify(keys))
        if (typeof data[key] == 'object' && data[key] != null) {
          copyKeys.push(key)
          this.walkObject(data[key], copyKeys, callback)
        } else {
          callback(key, copyKeys, data[key])
        }
      })
    },

    // URLクエリを上書きする
    writeQueryVal(targetQuery, writeQuery, isOverWrite){
      let query = JSON.parse(JSON.stringify(targetQuery))
      this.walkObject(writeQuery, [], (key, copyKeys, value) => {
        let target = copyKeys.reduce((query, key) => {
          if (query[key] == undefined) {query[key] = {}}
          return query[key]
        }, query)
        if (isOverWrite || target[key] == undefined) {target[key] = value}
      })
      return query
    },

    writeCurrentPageFirst(query) {
      return this.writeQueryVal(query, {pagination: {currentPage: 1}}, true)
    },

    // URLクエリーを上書きして遷移する
    async writeUrlQuery(writeQuery) {
      this.$router.replace({query: this.writeQueryVal(this.$route.query, writeQuery, true)})
    },
    
    // ソート順番の変更
    async changeDescendingQuery() {
      const query = this.writeCurrentPageFirst({pagination: {descending: !this.$route.query.pagination.descending}})
      this.writeUrlQuery(query)
    },

    // ソートカラムの変更
    async changeSortByQuery(sortBy) {
      const query = this.writeCurrentPageFirst({pagination: {descending: true, sortBy: sortBy}})
      this.writeUrlQuery(query)
    },

    // ページ数の変更
    async changeRowsPerPage(rowsPerPage) {
      const query = this.writeCurrentPageFirst({pagination: {descending: true, rowsPerPage: rowsPerPage}})
      this.writeUrlQuery(query)
    },

    // ページ目の変更
    async changePageQuery(page) {
      this.writeUrlQuery({pagination: {currentPage: page}})
    },

    // 検索条件の変更
    async changeSearchQuery(searchConditions) {
      const query = this.writeCurrentPageFirst({searchConditions: searchConditions})
      this.writeUrlQuery(query)
    },

    // URLクエリーが設定されていなければ設定する。mounte,creted時
    async initUrlQuery(addQuery) {
      this.$router.replace({query: this.writeQueryVal(this.$route.query, addQuery, false)})
    },
  }
}
</script>
