<template lang='pug'>
v-layout.column(v-if="Object.keys(glaphs).length > 0")
  h3.headline
    | お客様の好み
  v-card.mb-5.pr-3.pl-3
    v-card-text
      v-layout(xs12 row wrap)
        v-flex(xs6 v-for="(glaph, sumCategoryId) in glaphs" :key="glaph.conversion_type").pb-5
          v-layout.justify-center(xs12 row wrap)
            v-flex(xs8)
              ThePieChart(:data='pieChartData(glaph.sort_data)' :options='options(sumCategoryId)')
</template>

<script>

import ThePieChart from '../ThePieChart'
import colors from 'vuetify/es5/util/colors'

export default {
  name: 'TheCustomerFavorite',

  components: {
    ThePieChart,
  },
  props: {
    storeModule: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      backgroundColors: [
        colors.blue.lighten1,
        colors.grey.darken1,
        colors.green.lighten1,
        colors.amber.lighten1,
        colors.red.lighten2,
        colors.blue.lighten2,
        colors.grey.base,
        colors.green.lighten2,
        colors.amber.lighten2,
        colors.red.lighten2,
      ],
    }
  },
  computed: {
    sumCategoryById: function() {
      return this.$store.getters[this.storeModule + '/sumCategoryById']
    },
    aggregateItems: function() {
      return this.$store.getters[this.storeModule + '/aggregateItems']
    },
    glaphs: function() {
      return this.$store.state[this.storeModule].glaphs
    },
    backgroundColor: function() {
      return function(items) {
        let colors = []
        for (let i = 0; i < items.length; i++ ) {
          colors.push(this.backgroundColors[i % this.backgroundColors.length])
        }
        return colors
      }
    },
    pieChartData: function (){
      return function(items) {
        return {
          labels: items.map(e => e.category).slice(0, 20),
          datasets: [
            {
              label: items.map(e => e.category),
              data: items.map(e => e.ratio),
              backgroundColor: this.backgroundColor(items),
            }
          ],
        }
      }
    },
    options: function() {
      return function(sumCategoryId) {
        return {
          tooltips: {
            callbacks: {
              title: function(tooltipItems, data){
                return data.datasets[0].label[tooltipItems[0].index]
              },
              label: function(tooltipoItem, data) {
                const ratio = data.datasets[0].data[tooltipoItem.index]
                return '閲覧率:' + ratio + '%'
              }
            }
          },
          title: {
            display: true,
            fontSize: 15,
            text: this.sumCategoryById(sumCategoryId).attributes.name,
          },
          legend: {
            display: true,
            position: 'bottom',
            fullWidth: false,
            labels: {
              fontSize: 15,
              boxWidth: 30,
            }
          },
        }
      }
    },
  }
}
</script>

<style lang='scss' scoped>
</style>
