// ログイン認証に関わるストア
import api from 'api'

export default {
  namespaced: true,

  state: {
    admin_user: {},
    loggedIn: false,
  },

  mutations: {
    login(state, data) {
      if (data.data.attributes.token) {
        state.admin_user = data.data.attributes
        state.loggedIn = true
      }
    },

    logout(state) {
      state.admin_user = {}
      state.loggedIn = false
    },
  },

  actions: {
    async login({ commit }, params) {
      return await api.post('admin/admin_users/login', { params: params, notAuth: true }).then(response => {
        commit('login', response.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    // ログイン情報再取得
    // 画面遷移するたびに呼ぶ
    async reacquire({ commit }) {
      await api.get('admin/admin_users/reacquire').then(response => {
        // 再取得できなかった場合は強制ログアウト
        response.data.data ? commit('login', response.data) : commit('logout')
      }).catch(err => {
        return err.response
      })
    },
  },
}
