// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@babel/polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
import store from '../store/store'
import router from '../routes'
import App from '../app'
import api from '../api'
import '../assets/scss/style'
import 'vuetify/dist/vuetify.min.css'
import VueAnalytics from 'vue-analytics'
import RouterMixin from 'libs/RouterMixin'
import DomAction from 'libs/DomAction'

if (process.env.google_analytics_id) {
  Vue.use(VueAnalytics, {
    id: process.env.google_analytics_id,
    router
  })
}

Vue.mixin(RouterMixin)
Vue.mixin(DomAction)

Vue.use(Vuetify, {
  theme: {
    // 「アポジョーズ」ロゴの文字色と同じ
    primary: '#3f5aa7',
    secondary: '#b0bec5',
    // 「アポジョーズ」ロゴにある「ポ」の半濁点と同じ
    accent: '#f35321',
    error: '#b71c1c'
  }
})

Vue.prototype.$api = api

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('app'))
  new Vue({
    el,
    store,
    router,
    render: (h) => h(App),
  })
})
