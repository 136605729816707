<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 ユーザー管理 {{ getCompanyName }} 様
  v-layout.row.wrap.mt-1
    v-flex(text-xs-right)
      v-btn(color='accent', @click='clearConditionsAndReacquire', :disabled='loading', :loading='loading')
        v-icon.pr-3 clear
        | 全ての絞り込み条件をクリアする
      v-dialog(v-model='searchDialog' width="500")
        template(v-slot:activator="{ on }")
          v-btn(color="primary" v-on="on" :disabled='loading' :loading='loading')
            v-icon.pr-3 search
            | ログイン回数期間検索
        TheAdminUserLoginCountSearchField(@close='closeDialog' @fetchUsers='fetchUsers')
      v-btn(color='primary' :disabled='loading' :loading='loading' @click='submitDownload')
        v-icon.pr-3 file_download
        | CSVダウンロード
      router-link.btn-link(:to="{ name: 'new_user', params: {co_id: $route.params.company_id}}")
        v-btn(color='primary' :disabled='loading' :loading='loading')
          v-icon.pr-3 person_add
          | 新規ユーザー登録
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-data-table(:items="getUsers", :headers="headers",  :loading="loading", class="elevation-4", :total-items="getUsersSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(
              v-for="header in props.headers",
              :key="header.text",
              :class="['column', header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
              @click="changeSort(header)",
            )
              v-icon(v-if="header.sortable" small) arrow_upward
              | {{ header.text }}
              v-menu(v-if='header.searchMenu', v-model='menus[header.value]', :close-on-content-click='false', :nudge-width='200', offset-x='')
                template(v-slot:activator='{ on }')
                  v-icon(:color="searchConditions[header.value] ? 'accent' : ''", v-on='on', small) search
                v-card
                  v-list
                    v-list-tile(avatar='')
                      v-list-tile-content
                        v-list-tile-title {{ header.text }}
                  v-divider
                  v-list
                    v-list-tile
                      v-select(
                        v-if="header.value === 'company_role_id'"
                        v-model='searchConditions[header.value]',
                        :items='searchCompanyRoleList',
                        item-text='role_name',
                        item-value='id',
                        label='選択してください',
                        single-line=''
                      )
                      v-select(
                        v-else-if="header.value === 'affiliation_dept'"
                        v-model='searchConditions[header.value]',
                        :items='searchDeptUsers',
                        item-text='name',
                        item-value='id',
                        label='選択してください',
                        single-line=''
                      )
                      v-select(
                        v-else-if="header.value === 'affiliation_shop'"
                        v-model='searchConditions[header.value]',
                        :items='searchShopUsers',
                        item-text='name',
                        item-value='id',
                        label='選択してください',
                        single-line=''
                      )
                      v-text-field(v-else v-model='searchConditions[header.value]', prepend-icon='edit', :name='[header.value]', type='text')
                  v-card-actions
                    v-spacer
                    v-btn(flat='', @click='menus[header.value] = false') 閉じる
                    v-btn(color='primary', flat='', @click='search(header.value)', :disabled='loading', :loading='loading') 絞り込み

        template(v-slot:items="props")
          td {{ props.item.id }}
          td {{ props.item.attributes.account }}
          td {{ props.item.attributes.name }}
          td {{ props.item.attributes.email }}
          td {{ getCompanyRoleName(props.item.attributes.company_role_id) }}
          td {{ getAffiliationDeptName(props.item.attributes.affiliation_dept) }}
          td {{ getAffiliationShopName(props.item.attributes.affiliation_shop) }}
          td {{ props.item.attributes.last_login_at }}
          td {{ props.item.attributes.login_count }}
          td(:class="props.item.attributes.is_active ? '' : 'stop_color'") {{ getStateName(props.index) }}
          td
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                router-link.btn-link(:to="{name: 'edit_user', params: {company_id: $route.params.company_id, user_id: props.item.id }}")
                  v-btn(v-on='on' :disabled='loading' :loading='loading' small fab)
                    v-icon edit
              span 編集
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-btn(v-on='on' @click='submitChangeIsActive(props.index)' :disabled='loading' :loading='loading' small fab)
                  v-icon {{ getStateChangeButtonIcon(props.index) }}
              span {{ getStateChangeButtonLabel(props.index) }}
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-btn(v-on='on'  @click='submitDelete(props.index)' :disabled='loading' :loading='loading' small fab)
                  v-icon delete_forever
              span 削除
  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import TheAdminUserLoginCountSearchField from '../../components/TheAdminUserLoginCountSearchField'
import ThePagination from '../../components/ThePagination'
import FileMixin from '../../libs/FileMixin'

export default {
  name: 'Users',
  components: {
    TheAdminUserLoginCountSearchField,
    ThePagination
  },
  mixins: [FileMixin],
  data: function () {
    return {
      searchDialog: false,
      menus: {
        email: false,
        company_role_id: false,
        affiliation_dept: false,
        affiliation_shop: false,
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
          descending: 'asc',
          sortable: true,
          searchMenu: false,
        },
        {
          text: 'アカウント',
          value: 'account',
          descending: 'asc',
          sortable: false,
          searchMenu: false,
        },
        {
          text: 'ユーザー名',
          value: 'name',
          descending: 'asc',
          sortable: false,
          searchMenu: true,
        },
        {
          text: 'メールアドレス',
          value: 'email',
          descending: 'asc',
          sortable: false,
          searchMenu: true,
        },
        {
          text: '権限',
          value: 'company_role_id',
          descending: 'asc',
          sortable: false,
          searchMenu: true,
        },
        {
          text: '所属部門',
          value: 'affiliation_dept',
          descending: 'asc',
          sortable: false,
          searchMenu: true,
        },
        {
          text: '所属店',
          value: 'affiliation_shop',
          descending: 'asc',
          sortable: false,
          searchMenu: true,
        },
        {
          text: '最終ログイン',
          value: 'last_login_at',
          descending: 'asc',
          sortable: true,
          searchMenu: false,
        },
        {
          text: 'ログイン回数',
          value: 'login_count',
          descending: 'asc',
          sortable: true,
          searchMenu: false,
        },
        {
          text: 'ロック状況',
          value: 'is_active',
          descending: 'asc',
          sortable: true,
          searchMenu: false,
        },
        {
          text: '操作',
          value: '',
          descending: 'asc',
          sortable: false,
          searchMenu: false,
        },
      ],
    }
  },

  computed: {
    ...mapState(['route', 'user']),
    ...mapGetters('user', [
      'getCompanyName',
      'getUsers',
      'getUsersSize',
      'getTotalPages',
      'getCurrentPage',
      'getOrder',
      'getStateName',
      'getStateChangeButtonLabel',
      'getStateChangeButtonIcon',
      'getCompanyRoleList',
      'getCompanyRoleName',
      'getAffiliationDeptName',
      'getAffiliationShopName',
      'getInitUrlQuery',
    ]),
    ...mapGetters('route', [
      'getOrder'
    ]),

    pagination: function() {
      return this.route.pagination
    },

    totalPages: function() {
      return this.user.totalPages
    },

    searchConditions: function () {
      return this.$store.state.user.searchConditions
    },

    companyRoles: function () {
      return this.$store.state.user.companyRoles
    },

    deptUsers: function () {
      return this.$store.state.user.deptUsers
    },

    shopUsers: function () {
      return this.$store.state.user.shopUsers
    },

    searchCompanyRoleList: function() {
      let searchCompanyRoleList = JSON.parse(JSON.stringify(this.getCompanyRoleList))
      searchCompanyRoleList.unshift({role_name: 'すべて', id: null })
      return searchCompanyRoleList
    },

    searchDeptUsers: function() {
      let searchDeptUsers = JSON.parse(JSON.stringify(this.deptUsers))
      searchDeptUsers.unshift({name: 'すべて', id: null })
      return searchDeptUsers
    },

    searchShopUsers: function() {
      let searchShopUsers = JSON.parse(JSON.stringify(this.shopUsers))
      searchShopUsers.unshift({name: 'すべて', id: null })
      return searchShopUsers
    },

    loading: {
      get () {
        return this.$store.state.user.loading
      },
      set (value) {
        this.$store.commit('user/changeLoading', value)
      }
    },
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    this.loading = true
    const params = { company_id: this.$route.params.company_id }
    await this.initUrlQuery(this.getInitUrlQuery)
    await this.init()
    await this.fetch(params)
    params.role_code = 'dep'
    await this.fetchUsersByRole(params)
    params.role_code = 'shop'
    await this.fetchUsersByRole(params)
    this.loading = false
  },

  beforeDestroy: function() {
    this.clearAllData()
  },

  methods: {
    ...mapActions('user', [
      'init',
      'fetch',
      'delete',
      'changeIsActive',
      'fetchUsersByRole',
      'download',
    ]),

    ...mapMutations('user', [
      'clearAllData',
      'clearSearchConditions',
      'changeLoading',
    ]),

    async fetchUsers() {
      this.loading = true
      const response = await this.fetch({ company_id: this.$route.params.company_id })
      if (response.status == 200) {
        this.$store.dispatch('flash/showMessage', {message: 'ユーザー一覧を更新しました。', color: 'primary'})
      } else if (response.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', {message: 'ユーザー一覧の更新に失敗しました。', color: 'error'})
      }
      this.loading = false
    },

    async submitDelete(index) {
      const user = this.getUsers[index].attributes

      if (!window.confirm(this.getConfirmMessage(user, '本当に削除しますか？'))) {
        return
      }

      this.loading = true
      const params = {
        company_id: this.$route.params.company_id,
        user_id: user.id,
      }
      const res = await this.delete(params)
      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', {message: 'ユーザーを削除しました。', color: 'info'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
      }
      this.loading = false
    },

    async submitChangeIsActive(index) {
      const user = this.getUsers[index].attributes

      if (!window.confirm(this.getConfirmMessage(user, 'ロック状況を変更しますか？'))) {
        return
      }

      this.loading = true
      const params = {
        company_id: this.$route.params.company_id,
        user_id: user.id,
      }
      const res = await this.changeIsActive(params)
      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', {message: 'ロック状況の更新をしました。', color: 'info'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
      }
      this.loading = false
    },

    async search(headerValue) {
      this.menus[headerValue] = false
      await this.changeSearchQuery(this.searchConditions)
      this.fetchUsers()
    },

    async clearConditionsAndReacquire() {
      await this.writeUrlQuery(this.getInitUrlQuery)
      await this.init()
      this.fetchUsers()
    },

    changePage() {
      this.fetchUsers()
    },

    async changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.fetch({ company_id: this.$route.params.company_id })
    },

    getConfirmMessage(user, message) {
      return `アカウント：${user.account}
ユーザー名：${user.name}

${message}`
    },
    closeDialog() {
      this.searchDialog = false
    },

    async submitDownload() {
      const response = await this.download(this.$route.params)

      this.fileDownload({filePath: 'ユーザー一覧.csv', type: 'text/csv', content: response.data })
    }
  },
}
</script>

<style lang='scss' scoped>
.stop_color {
  color: red;
}
</style>
