<template lang='pug'>
div
  v-layout(row xs12 class="mb-3")
    v-flex(xs2 offset-xs2)
      v-subheader(class='label')
        | 対象となるURL
    v-flex(xs8)
      v-textarea(v-model='formAttributes.url' solo)
  v-layout(row xs12 class="mb-3")
    v-flex(xs2 offset-xs2)
      v-subheader(class='label')
        | 基準
    v-flex(xs8)
      v-text-field(v-model='formAttributes.standard' type='time' :suffix='hotSignClass[1]["unit"]')
</template>

<script>

export default {
  name: 'HotSignVisitStay',
  props: {
    formAttributes: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hotSignClass: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
