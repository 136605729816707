import api from 'api'
import store from '../store'

const DEFAULT_FORM_ATTRIBUTES = {
  relation_code: '',
  beacon_user_mail: '',
  cv_date: '',
  cv_time: '',
  role: '',
  department: '',
  shop: '',
  individuals: [],
}

export default {
  namespaced: true,

  state: {
    conversionLogs: [],
    domainGroups: [],
    loading: false,
    totalPages: 0,
    formAttributes: {},
    activeDepartmentUsers: [],
    departmentUsersByUserId: {},
    activeShopUsers: [],
    shopUsersByUserId: {},
    activeIndividualUsers: [],
    individualUsersByUserId: {},
  },

  getters: {
    getConversionLogs(state) {
      return state.conversionLogs
    },
    getConversionLog(state, index) {
      return state.conversionLogs[index]
    },
    getConversionLogsSize(state) {
      return state.conversionLogs.length
    },
    getDomainGroups(state) {
      return state.domainGroups
    },
    getJsonActiveDepartmentUsers(state) {
      return JSON.parse(JSON.stringify(state.activeDepartmentUsers))
    },
    getJsonActiveShopUsers: (state) => (userId) => {
      const activeShopUsers = state.activeShopUsers[userId]
      return activeShopUsers
        ? JSON.parse(JSON.stringify(activeShopUsers))
        : undefined
    },
    getJsonActiveIndividualUsers: (state) => (userId) => {
      const activeIndividualUsers = state.activeIndividualUsers[userId]
      return activeIndividualUsers
        ? JSON.parse(JSON.stringify(activeIndividualUsers))
        : undefined
    },
    getDepartmentUsers: (state, getters) => (companyRole, user) => {
      let users = [state.formAttributes.department, user.affiliation_dept, user.id].map(id => state.departmentUsersByUserId[id]).filter(user => user)
      if (companyRole.attributes.level >= 4) { users = users.concat(getters.getJsonActiveDepartmentUsers || []) }
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      if (users.length >= 1) { users.unshift({attributes: { id: null, name: '' }}) }
      return users
    },

    // 「部署」に紐づく「店」のユーザー一覧を取得
    getShopUsersAssociateInUpperLevel: (state, getters) => (companyRole, user) => {
      let users = [state.formAttributes.shop, user.affiliation_shop, user.id].map(id => state.shopUsersByUserId[id]).filter(user => user)
      if (companyRole.attributes.level >= 3) { users = users.concat(getters.getJsonActiveShopUsers(state.formAttributes.department) || []) }
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      if (users.length >= 1) { users.unshift({attributes: { id: null, name: '' }}) }
      return users
    },
    // 「店」に紐づく「担当」のユーザー一覧を取得
    getIndividualUsersAssociateInUpperLevel: (state, getters) => (companyRole, user) => {
      const shop_user_id = state.formAttributes.shop
      const responsible_individuals = state.formAttributes.individuals
      let users = responsible_individuals.concat(user.id).map(id => state.individualUsersByUserId[id]).filter(user => user)
      if (companyRole.attributes.level >= 2) { users = users.concat(getters.getJsonActiveIndividualUsers(shop_user_id) || []) }
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      return users
    },
    getInitUrlQuery() {
      return {
        pagination: {
          ortBy: 'created_at',
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
      }
    }
  },

  mutations: {
    setResponseData(state, {data: data, commit: commit} ) {
      const conversionLogsData = data.conversion_logs
      const domainGroupsData = data.domain_groups
      commit('setConversionLogs', conversionLogsData.data)
      commit('setTotalPages', conversionLogsData.links.total_pages)
      commit('setDomainGroups', domainGroupsData)
    },
    setConversionLogs(state, data) {
      state.conversionLogs = data
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },
    setDomainGroups(state, domainGroups) {
      state.domainGroups = domainGroups
    },
    changeLoading(state, loading) {
      state.loading = loading
    },
    setFormAttributes(state, value) {
      state.formAttributes = value
    },
    clearFormAttributes(state, {user: user, companyRole: companyRole}) {
      state.formAttributes = JSON.parse(JSON.stringify(DEFAULT_FORM_ATTRIBUTES))
      const level = companyRole.attributes.level
      if (level <= 3) {
        state.formAttributes.department = level == 3
          ? user.id
          : user.affiliation_dept
      }
      if (level <= 2) {
        state.formAttributes.shop = level == 2
          ? user.id
          : user.affiliation_shop
      }
      if (level == 1) {
        state.formAttributes.individuals = [user.id]
      }
    },
    setIndividualsCvMails(state, value) {
      state.formAttributes.individuals_cv_mail = value
    },
    setEveryRoleUsers(state, data) {
      state.activeDepartmentUsers = data.department_users.data.filter(departmentUser => departmentUser.attributes.is_active)
      state.departmentUsersByUserId = {}
      data.department_users.data.forEach(departmentUser => { state.departmentUsersByUserId[departmentUser.id] = departmentUser })
      let activeShopUsers = {}
      data.shop_users.data.filter(shopUser => shopUser.attributes.is_active).forEach(shopUser => { 
        activeShopUsers[shopUser.attributes.affiliation_dept]
          ? activeShopUsers[shopUser.attributes.affiliation_dept].push(shopUser)
          : activeShopUsers[shopUser.attributes.affiliation_dept] = [shopUser]
      })
      state.activeShopUsers = activeShopUsers
      state.shopUsersByUserId= {}
      data.shop_users.data.forEach(shopUser => { state.shopUsersByUserId[shopUser.id] = shopUser })

      let activeIndividualUsers = {}
      data.individual_users.data.filter(individualUser => individualUser.attributes.is_active).forEach(individualUser => {
        activeIndividualUsers[individualUser.attributes.affiliation_shop]
          ? activeIndividualUsers[individualUser.attributes.affiliation_shop].push(individualUser)
          : activeIndividualUsers[individualUser.attributes.affiliation_shop] = [individualUser]
      })
      state.activeIndividualUsers = activeIndividualUsers
      state.individualUsersByUserId = {}
      data.individual_users.data.forEach(individualUser => { state.individualUsersByUserId[individualUser.id] = individualUser })
    },
  },

  actions: {
    async init({commit}, {user: user, companyRole: companyRole}) {
      commit('clearFormAttributes', {user: user, companyRole: companyRole})
    },

    async edit({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
      }

      Object.assign(params, this.getters['route/getPaginationApiParam'])
      state.loading = true
      const response = api.get('users/every_role').then(response => {
        commit('setEveryRoleUsers', response.data)
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async create({state}) {
      state.loading = true
      const params = {
        relation_code: state.formAttributes.relation_code,
        beacon_user_mail: state.formAttributes.beacon_user_mail,
        cv_date: state.formAttributes.cv_date,
        cv_time: state.formAttributes.cv_time,
        department: state.formAttributes.department,
        shop: state.formAttributes.shop,
        individuals: state.formAttributes.individuals,
      }
      const response = await api.post('mail_trackings', { params: params }).then((response) => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvDownload({state}) {
      state.loading = true
      const response =  await api.post('mail_trackings/csv_download', { responseType: 'blob' }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvUpload({state}, file) {
      state.loading = true
      var formData = new FormData()
      formData.append('csvfile', file)

      const response = await api.post('mail_trackings/csv_upload', { params: formData }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvAssignUpload({state}, file) {
      state.loading = true
      var formData = new FormData()
      formData.append('csvfile', file)
      const response = await api.post('mail_trackings/csv_assign', { params: formData }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },
  }
}

