var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.conversionLogs,"hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',{staticClass:"lighten-5",class:_vm.baseColor},[_c('th',{staticClass:"body-2 font-weight-bold"},[_vm._v("お問い合わせ日時"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("問い合わせがあった日時です。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold"},[_vm._v("お問い合わせ番号"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("お客様を識別する情報です。事前設定が必要です。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold"},[_vm._v("お問い合わせ対象"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("問い合わせの対象です。事前設定が必要です。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold"},[_vm._v("利用デバイス"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("どのデバイスで問い合わせがあったのか表記しています。")])])],1)])]}},{key:"items",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.conversionLogsCreated))]),_c('td',[_vm._v(_vm._s(_vm.conversionLogsRelationCode))]),_c('td',[_vm._v(_vm._s(_vm.conversionLogsItemCode))]),_c('td',[_vm._v(_vm._s(_vm.userCookie.attributes.device))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }