<template lang='pug'>
v-card
  v-card-text
    v-layout.row.wrap
      v-flex.text-xs-left
        h3 検索
      v-flex.text-xs-right
        v-btn(color='accent' @click='clearSearchCondition()')
          v-icon clear
          | 全ての条件をクリアする
    v-layout.row.wrap.align-center.justify-center
      v-flex.xs10
        v-text-field(v-model='searchConditions.relation_codes' prepend-icon='forum' name='relation_code' label='問い合わせ番号' type='text' placeholder='追客力太郎 1123455')
    v-layout.row.wrap.align-center.justify-center
      v-flex.xs10
        v-expansion-panel(popout)
          v-expansion-panel-content
            template(v-slot:header='')
              div.primary--text その他の検索項目を選択してください
            v-card
              v-card-text
                v-layout.row.xs12(v-if='roleAtr.level >= 4')
                  v-flex.xs2
                    v-subheader.label {{ getRoleNameByRoleCode('dep') }}
                  v-flex.xs10
                    v-select.select(:items='searchDepartmentUsers' v-model='searchConditions.department' item-text='attributes.name' item-value='attributes.id' solo)
                v-layout.row.xs12(v-if='roleAtr.level >= 3')
                  v-flex.xs2
                    v-subheader.label  {{ getRoleNameByRoleCode('shop') }}
                  v-flex.xs10
                    v-select.select(:items='searchShopUsers' v-model='searchConditions.shop' item-text='attributes.name' item-value='attributes.id' solo)
                      template(v-slot:item='data')
                        v-list-tile-content
                          v-list-tile-title(v-html='data.item.attributes.name')
                          v-list-tile-sub-title.inset-list.grey--text.text-lighten-2 {{ getDepartmentName(data.item.attributes.affiliation_dept) }}
                v-layout.row.xs12(v-if='roleAtr.level >= 2')
                  v-flex.xs2
                    v-subheader.label  {{ getRoleNameByRoleCode('ind') }}
                  v-flex.xs10
                    v-select.select(:items='searchIndividualUsers' v-model='searchConditions.individual' item-text='attributes.name' item-value='attributes.id' solo)
                      template(v-slot:item='data')
                        v-list-tile-content
                          v-list-tile-title(v-html='data.item.attributes.name')
                          v-list-tile-sub-title.inset-list.grey--text.text-lighten-2 {{ getDepartmentName(data.item.attributes.affiliation_dept) }} {{ getShopName(data.item.attributes.affiliation_shop) }}
                v-layout.row.xs12
                  v-flex.xs2
                    v-subheader.label 反響期間
                  v-flex.xs5
                    v-menu(v-model='pickerMenuFrom' :close-on-content-click='false' :nudge-right='40' lazy transition='scale-transition' offset-y full-width min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(v-model='relationDateFrom' label='年月日を選択' prepend-icon='event' readonly='' v-on='on')
                      v-date-picker(v-model='relationDateFrom' @input='pickerMenuFrom = false' locale='ja')
                  v-flex.xs1
                    v-subheader.label 〜
                  v-flex.xs5
                    v-menu(v-model='pickerMenuTo' :close-on-content-click='false' :nudge-right='40' lazy transition='scale-transition' offset-y full-width min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(v-model='relationDateTo' label='年月日を選択', prepend-icon='event' readonly='' v-on='on')
                      v-date-picker(v-model='relationDateTo' @input='pickerMenuTo = false' locale='ja')
              template(v-if="roleAtr.connect")
                v-layout.row.xs12
                  v-flex.xs2
                    v-subheader.label コネクト発行ID
                  v-flex.xsd10
                    v-text-field(v-model='searchConditions.beacon_id' type='text')
                v-layout.row.xs12
                  v-flex.xs2
                    v-subheader.label 顧客メールアドレス
                  v-flex.xsd10
                    v-text-field(v-model='searchConditions.beacon_user_mail' type='text')
              template(v-if="roleAtr.is_master")
                v-layout.row.xs12
                  v-flex.xs2
                    v-subheader.label リターンズ非表示も閲覧する
                  v-flex.xsd10
                    v-switch(v-model="searchConditions.includeDeleteRevisit" value='1')
    v-btn(block color='primary' :loading='loading' :disabled='loading' @click='search()')
        v-icon search
        | 検索する
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'TheConversionLogsSearchField',
  data: function () {
    return {
      pickerMenuFrom: false,
      pickerMenuTo: false,
    }
  },

  computed: {
    ...mapGetters('conversion_log', [
      'getDepartmentName',
      'getShopName',
      'getRoleNameByRoleCode',
      'getShopUsers',
      'getIndividualUsers',
      'getInitUrlQuery'
    ]),
    ...mapState(['conversion_log']),

    searchDepartmentUsers: function () {
      let searchDepartmentUsers = JSON.parse(JSON.stringify(this.conversion_log.activeDepartmentUsers))
      searchDepartmentUsers.unshift({attributes: { name: '', id: null }})
      return searchDepartmentUsers
    },

    searchShopUsers: function() {
      let searchShopUsers = this.getShopUsers
      searchShopUsers.unshift({attributes: { name: '', id: null }})
      return searchShopUsers
    },

    searchIndividualUsers: function() {
      let searchIndividualUsers = this.getIndividualUsers
      searchIndividualUsers.unshift({attributes: { name: '', id: null }})
      return searchIndividualUsers
    },

    searchConditions: {
      get () {
        return this.conversion_log.searchConditions
      },
      set (value) {
        this.$store.commit('conversion_log/setSearchCondition', value)
      },
    },

    relationDateFrom: {
      get () {
        return !this.searchConditions.relationDateFrom
          ? this.searchConditions.defaultRelationDateFrom
          : this.searchConditions.relationDateFrom
      },
      set (value) {
        this.searchConditions.relationDateFrom = value
      },
    },
    relationDateTo: {
      get () {
        return !this.searchConditions.relationDateTo
          ? this.searchConditions.defaultRelationDateTo
          : this.searchConditions.relationDateTo
      },
      set (value) {
        this.searchConditions.relationDateTo = value
      },
    },

    loading: function () {
      return this.$store.state.conversion_log.loading
    },

    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
  },

  methods: {
    ...mapMutations('conversion_log', [
      'clearSearchCondition'
    ]),

    ...mapActions('conversion_log', [
      'init',
      'fetch'
    ]),

    async clearSearchCondition() {
      await this.writeUrlQuery(this.getInitUrlQuery)
      await this.init()
      await this.fetch()
      this.$store.dispatch('flash/showMessage', { message: '検索結果が一覧に反映されました', color: 'primary'} )
    },

    async search() {
      await this.changeSearchQuery(this.searchConditions)
      await this.fetch()
      this.$store.dispatch('flash/showMessage', { message: '検索結果が一覧に反映されました', color: 'primary'} )
    }
  },
}
</script>

<style lang='scss' scoped>
.inset-list {
  padding-left: 10px;
}
</style>
