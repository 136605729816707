import api from 'api'
import store from '../store'

const DEFAULT_FORM_ATTRIBUTES = {
  name: '',
  hot_sign_class_id: '',
  url: '',
  standard: '',
}

export default {
  namespaced: true,

  state: {
    hotSigns: [],
    loading: false,
    hotSignClasses: [],
    formAttributes: {},
    totalPages: 0,
  },

  getters: {
    getHotSigns(state) {
      return state.hotSigns
    },
    getHotSignsSize(state) {
      return state.hotSigns.length
    },
    getTotalPages(state) {
      return state.totalPages
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created_at',
          descending: true,
        },
      }
    }
  },

  mutations: {
    setHotSigns(state, hotSigns) {
      state.hotSigns = hotSigns
    },

    clearHotSigns(state) {
      state.hotSigns = []
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    changeLoading(state, loading) {
      state.loading = loading
    },

    setHotSignClasse(state, hotSignClasses) {
      state.hotSignClasses = hotSignClasses
    },

    setFormAttributes(state, formAttributes) {
      Object.keys(DEFAULT_FORM_ATTRIBUTES).map(key => {
        if (formAttributes[key]) {
          // standardカラム型が、VARCHARだがホットサインクラスによっては数値が入るため変換処理をする。
          if (key == 'standard' && !isNaN(formAttributes[key])) {
            state.formAttributes[key] = Number(formAttributes[key])
          } else {
            state.formAttributes[key] = formAttributes[key]
          }
        }
      })
    },
    clearFormAttributes(state) {
      state.formAttributes = JSON.parse(JSON.stringify(DEFAULT_FORM_ATTRIBUTES))
    }
  },

  actions: {
    async init({ commit }) {
      commit('clearFormAttributes')
    },

    async fetch({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      state.loading = true
      const response = await api.get('hot_signs', { params: params }).then((response) => {
        const data = response.data
        commit('setHotSigns', data.data)
        commit('setTotalPages', data.links.total_pages)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async delete({ dispatch }, urlParams) {
      const response = await api.delete('hot_signs/' + urlParams.hotSignId).then((response) => {
        return response
      }).catch(err => {
        return err.response
      })
      await dispatch('fetch')
      return response
    },

    async new({ commit, state }) {
      state.loading = true
      const response = await api.get('hot_signs/new').then((response) => {
        const data = response.data
        commit('setHotSignClasse', data.hot_sign_class)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async edit({ commit ,state }, urlParams) {
      state.loading = true
      const response = await api.get('hot_signs/' + urlParams.id + '/edit').then((response) => {
        const data = response.data
        commit('setFormAttributes', data.hot_sign.data.attributes)
        commit('setHotSignClasse', data.hot_sign_class)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async create({state}) {
      const params =  {
        hot_sign: {
          name: state.formAttributes.name,
          hot_sign_class_id: state.formAttributes.hot_sign_class_id,
          url: state.formAttributes.url,
          standard: state.formAttributes.standard,
        }
      }
      const response = await api.post('hot_signs/', {params: params}).then((response) => {
        return response
      }).catch(err => {
        return err.response
      })
      return response
    },

    async update({state}, urlParams) {
      const params =  {
        hot_sign: {
          name: state.formAttributes.name,
          hot_sign_class_id: state.formAttributes.hot_sign_class_id,
          url: state.formAttributes.url,
          standard: state.formAttributes.standard,
        }
      }
      const response = await api.patch('hot_signs/' + urlParams.id, {params: params}).then((response) => {
        return response
      }).catch(err => {
        return err.response
      })
      return response
    },
  }
}
