<template lang='pug'>
v-layout.column
  v-layout(row wrap xs12 class="mb-1")
    v-flex(text-xs-left)
      h2.headline 旧タグ発行
  v-layout(row wrap class='mb-3' v-if="!loading")
    v-flex(xs12)
      v-card(class="elevation-4")
        v-card-text
          v-container.fluid.pr-5.pl-5
            v-layout(row wrap xs12 class='mb-3')
              v-flex(xs12)
                v-flex(xs12)
                  span(class='title mr-2')
                    | Access Tag
                v-flex(xs12)
                  v-textarea(v-model="accessTag" solo outline onclick="this.select()" auto-grow)
            v-layout(row wrap xs12 class='mb-3')
              v-flex(xs12)
                v-flex(xs12)
                  span(class='title mr-2')
                    | Conversion Tag
                    br
                    small(class="body-2 red--text")
                      | コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい
                v-flex(xs12)
                  v-textarea(v-model="conversionTag" solo outline onclick="this.select()" auto-grow)
            v-layout(v-if='getCompany.data.attributes.has_tel_conversion' row wrap xs12 class='mb-3')
              v-flex(xs12)
                v-flex(xs12)
                  span(class='title mr-2')
                    | Tel Conversion Tag
                    span(class="body-2") (class追加用クリック電話コンバージョン用)
                    br
                    small(class="body-2 red--text")
                      | buttonタグ等の場合、クリックで電話コンバージョンしたいタグに「class='apojows_tel_conversion'」を設置して下さい
                      br
                      | 電話コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい
                v-flex(xs12)
                  v-textarea(v-model="btnTelConversionTag" solo outline onclick="this.select()" auto-grow)
            v-layout(v-if='getCompany.data.attributes.has_tel_conversion' row wrap xs12 class='')
              v-flex(xs12)
                v-flex(xs12)
                  span(class='title mr-2')
                    | Tel Conversion Tag
                    span(class='body-2') (ページアクセス電話コンバージョン用)
                    br
                    small(class="body-2 red--text")
                      | 電話コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい
                v-flex(xs12)
                  v-textarea(v-model="acsTelConversionTag" solo outline onclick="this.select()" auto-grow)
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AdminTags',
  data() {
    return {
      host_name: process.env.hanplus_host,
      accessTag: '',
      conversionTag: '',
      btnTelConversionTag: '',
      acsTelConversionTag: '',
      loading: true,
      initAccessCode: function() {
        this.accessTag =
        [
          '<!-- Access Code -->',
          '<script type="text/javascript" src="//' + this.host_name + '/js/piwik/piwik.js"></' + 'script>',
          '<script type="text/javascript" src="//' + this.host_name + '/js/piwik/piwik_check.js?cid=' + this.company_code + '" ></' + 'script>',
          '<!-- End Access Code -->'
        ].join('\r\n')
      },
      initConversionTag: function() {
        this.conversionTag =
        [
          '<!-- Conversion Code -->',
          '<script type="text/javascript">',
          '  try {',
          '    var piwikTracker = Piwik.getTracker(\'//' + this.host_name + '/access_logs/conversion\', \'' + this.company_code + '\');',
          '    piwikTracker.trackPageView(document.title, \'div=部署&user=会員ID&item=物件ID&cv=反響種別&rel=反響ID\');',
          '    piwikTracker.enableLinkTracking();',
          '  } catch( err ) {}',
          '</' + 'script>',
          '<!-- End Conversion Code -->'
        ].join('\r\n')
      },
      initBtnTelConversionTag: function() {
        this.btnTelConversionTag =
        [
          '<!-- Tel Conversion Code(click tel conversion) -->',
          '<script type="text/javascript" src="//' + this.host_name + '/js/piwik/piwikx_ap.js"></' + 'script>',
          '<script type="text/javascript">',
          '  document.addEventListener("DOMContentLoaded", function(){',
          '    if(typeof PiwikxAp!="undefined"){',
          '      PiwikxAp.classTag(document, apojows_tel_conversion);',
          '    }',
          '  });',
          '  function apojows_tel_conversion(){',
          '    if(typeof Piwik!="undefined"){',
          '      var piwikTracker=Piwik.getTracker(\'//' + this.host_name + '/access_logs/tel_conversion\',\'' + this.company_code + '\');',
          '      piwikTracker.trackPageView(document.title,\'div=部署&user=会員ID&item=物件ID&cv=反響種別\');',
          '      piwikTracker.enableLinkTracking();',
          '    }',
          '  }',
          '</' + 'script>',
          '<!-- End Tel Conversion Tag(click tel conversion) -->',
        ].join('\r\n')
      },
      initAcsTelConversionTag: function() {
        this.acsTelConversionTag =
        [
          '<!-- Tel Conversion Code(page access tel conversion) -->',
          '<script type="text/javascript">',
          '  try {',
          '    var piwikTracker = Piwik.getTracker(\'//' + this.host_name +  '/access_logs/tel_conversion\', \'' + this.company_code + '\');',
          '    piwikTracker.trackPageView(document.title, \'div=部署&user=会員ID&item=物件ID&cv=反響種別\');',
          '    piwikTracker.enableLinkTracking();',
          '  } catch( err ) {}',
          '</' + 'script>',
          '<!-- End Tel Conversion Code(page access tel conversion) -->',
        ].join('\r\n')
      }
    }
  },

  computed: {
    ...mapGetters('company', [
      'getCompany',
    ]),

    company_code: function () {
      return this.$store.state.company.company.data.attributes.company_code
    },
  },

  mounted: function() {
    this.loading = true
    this.show({company_id: this.$route.params.company_id}).then(() => {
      this.initAccessCode()
      this.initConversionTag()
      this.initBtnTelConversionTag()
      this.initAcsTelConversionTag()
      this.loading = false
    }, this)
  },

  beforeDestroy: function() {
    this.clearCompany()
  },

  methods: {
    ...mapActions('company', [
      'clearCompany',
      'show'
    ]),

  },
}
</script>

<style lang='scss' scoped>
.annotation {
  color: red;
}
.btn-link {
  text-decoration: none;
}
.title {
  color: grey;
}
</style>
