import api from 'api'
import qs from 'qs'

const DEFAILT_FORM_ATTRIBUTES = {
  company_id: null,
  account: '', // アカウント
  name: '', // ユーザー名
  email: '', // メールアドレス
  company_role_id: null, // 権限
  report_page_default_view: 1, // デフォルト表示
  conversion_report_default_sort: 0, // コンバージョンレポート 閲覧履歴表示順序
  revisit_mail_sort: 0, // 再来訪メール 閲覧履歴表示順序
  affiliation_dept: null, // 所属部門
  affiliation_shop: null, // 所属店
  is_active: true, // ロック状況 稼働状態
}

// 部門
const ROLE_CODE_DEP = 'dep'
// 店
const ROLE_CODE_SHOP = 'shop'

export default {
  namespaced: true,

  state: {
    users: [],
    deptUsers: [],
    shopUsers: [],
    company: {},
    companyRoles: [],
    totalPages: 0,
    loading: false,
    searchConditions: {},
    formAttributes: JSON.parse(JSON.stringify(DEFAILT_FORM_ATTRIBUTES))
  },

  getters: {
    getUsers(state) {
      return state.users
    },

    getUsersSize(state) {
      return state.users.length
    },

    getTotalPages(state) {
      return state.totalPages
    },

    getCurrentPage(state) {
      return  state.currentPage
    },

    getCompanyName(state) {
      return state.company.company_name
    },

    getStateName: (state) => (index) => {
      return state.users[index].attributes.is_active ? '正常' : '停止'
    },

    getStateChangeButtonLabel: (state) => (index) => {
      return state.users[index].attributes.is_active ? '停止' : '稼働'
    },

    getStateChangeButtonIcon: (state) => (index) => {
      return state.users[index].attributes.is_active ? 'pause' : 'fiber_manual_record'
    },

    getCompanyRoleList(state) {
      let list = []
      for (const i in state.companyRoles) {
        list.push(state.companyRoles[i].attributes)
      }
      return list
    },

    getCompanyRoleName: (state) => (id) => {
      const companyRole = state.companyRoles.find(data => data.id == id)
      return companyRole === void 0 ? '' : companyRole.attributes.role_name
    },

    isTargetRole: (state) => (role_code) => {
      const companyRole = state.companyRoles.find(data => data.id == state.formAttributes.company_role_id)
      if (companyRole === void 0) {
        return false
      }
      return companyRole.attributes.role_code == role_code
    },

    getAffiliationDeptName: (state) => (id) => {
      const deptUser = state.deptUsers.find(data => data.id == id)
      return deptUser === void 0 ? '' : deptUser.name
    },

    getAffiliationShopName: (state) => (id) => {
      const shopUser = state.shopUsers.find(data => data.id == id)
      return shopUser === void 0 ? '' : shopUser.name
    },

    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          descending: 'desc',
          sortBy: 'created_at',
          totalPages: 0,
        },
        searchConditions: {
          name: '',
          email: '',
          company_role_id: '',
          affiliation_dept: '',
          affiliation_shop: '',
          login_from: null,
          login_to: null,
          default_login_from: null,
          default_login_to: null,
        }
      }
    }
  },

  mutations: {
    setUsers(state, data) {
      state.users = data
    },

    clearUsers(state) {
      state.users = []
    },

    setDeptUsers(state, data) {
      let users = []
      for (const i in data) {
        users.push(data[i].attributes)
      }
      state.deptUsers = users.filter((user) => user.id != state.formAttributes.id)
    },

    setShopUsers(state, data) {
      let users = []
      for (const i in data) {
        users.push(data[i].attributes)
      }
      state.shopUsers = users.filter((user) => user.id != state.formAttributes.id)
    },
    setCompany(state, data) {
      state.company = data
    },

    setCompanyRoles(state, data) {
      state.companyRoles = data
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },

    clearAllData(state) {
      state.users = []
      state.deptUsers = []
      state.shopUsers = []
      state.company = {}
      state.companyRoles = []
    },

    clearSearchConditions(state) {
      state.searchConditions = {
        email: '',
        company_role_id: null,
        affiliation_dept: null,
        affiliation_shop: null,
        login_from: null,
        login_to: null,
        default_login_from: null,
        default_login_to: null,
      }
    },

    setDefaultLoginFrom(state, default_login_from) {
      state.searchConditions.default_login_from = default_login_from
    },

    setDefaultLoginTo(state, default_login_to) {
      state.searchConditions.default_login_to = default_login_to
    },

    clearFormAttributes(state) {
      state.formAttributes = JSON.parse(JSON.stringify(DEFAILT_FORM_ATTRIBUTES))
    },

    changeLoading(state, loading) {
      state.loading = loading
    },

    setFormAttributes(state, formAttributes) {
      state.formAttributes = formAttributes
    },

    mergeUserDataToFormAttributes(state, user) {
      state.formAttributes = Object.assign(state.formAttributes, user)
    },
  },

  actions: {
    async init({commit, rootState}) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(rootState.route.query.searchConditions)))
    },

    // ユーザー一覧取得
    async fetch({ commit, dispatch, state  }, urlParams) {
      await dispatch('fetchCompany', urlParams)
      await dispatch('fetchCompanyRoles', urlParams)

      const params = {
        company_id: urlParams.company_id,
        searchConditions: state.searchConditions,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params),
      }

      return await api.get('/admin/companies/' + urlParams.company_id + '/users/', options).then(function (response) {
        const data = response.data
        commit('setUsers', data.users.data)
        commit('setTotalPages', data.users.links.total_pages)
        commit('setDefaultLoginFrom', data.users_search_params.login_from)
        commit('setDefaultLoginTo', data.users_search_params.login_to)
        return response
      }).catch(err => {
        return err.response
      })
    },

    // 会社の権限設定一覧取得
    async fetchCompanyRoles({ commit }, urlParams) {
      await api.get('/admin/companies/' + urlParams.company_id + '/company_roles/').then(function (response) {
        commit('setCompanyRoles', response.data.data)
      }).catch(err => {
        return err.response
      })
    },

    // 会社情報取得
    async fetchCompany({ commit }, urlParams) {
      await api.get('/admin/companies/' + urlParams.company_id).then(function (response) {
        commit('setCompany', response.data.data.attributes)
      }).catch(err => {
        return err.response
      })
    },

    // 対象の権限を持つユーザー一覧を取得
    async fetchUsersByRole({ commit }, urlParams) {
      const params = {
        company_id: urlParams.company_id,
        role_code: urlParams.role_code,
      }

      await api.get('/admin/companies/' + urlParams.company_id + '/users/by_role/', { params: params }).then(function (response) {
        const data = response.data
        switch (urlParams.role_code) {
        case ROLE_CODE_DEP:
          commit('setDeptUsers', data.data)
          break
        case ROLE_CODE_SHOP:
          commit('setShopUsers', data.data)
          break
        }
        return response
      }).catch(err => {
        return err.response
      })
    },

    async create({ state }, urlParams) {
      const options = {
        params: state.formAttributes
      }

      const response = await api.post('/admin/companies/' + urlParams.company_id + '/users/', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })

      return response
    },

    async edit({ commit }, urlParams) {
      await api.get('/admin/companies/' + urlParams.company_id + '/users/' + urlParams.user_id + '/edit/').then(function (response) {
        const data = response.data
        commit('mergeUserDataToFormAttributes', data.data.attributes)
        return response
      }).catch(err => {
        return err.response
      })
    },

    async update({ state }, urlParams) {
      const options = {
        params: state.formAttributes
      }

      const response = await api.patch('/admin/companies/' + urlParams.company_id + '/users/' + urlParams.user_id, options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })

      return response
    },

    async delete({ dispatch }, urlParams) {
      const response = await api.delete('/admin/companies/' + urlParams.company_id + '/users/' + urlParams.user_id).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
      await dispatch('fetch', urlParams)
      urlParams.role_code = ROLE_CODE_DEP
      await dispatch('fetchUsersByRole', urlParams)
      urlParams.role_code = ROLE_CODE_SHOP
      await dispatch('fetchUsersByRole', urlParams)

      return response
    },

    async changeIsActive({ dispatch }, urlParams) {
      const response = await api.patch('/admin/companies/' + urlParams.company_id + '/users/' + urlParams.user_id + '/change_is_active/').then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
      await dispatch('fetch', urlParams)

      return response
    },

    async download({state}, urlParams) { 
      const params = {
        company_id: urlParams.company_id,
        searchConditions: state.searchConditions,
      }

      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const options = {
        params: params,
        responseType: 'blob',
        paramsSerializer: (params) => qs.stringify(params),
      }

      const response = await api.get('/admin/companies/' + urlParams.company_id + '/users/csvs/', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })

      return response
    }
  },
}
