<template lang='pug'>
v-layout.column
  v-layout(row xs12)
    v-flex(text-xs-right)
      v-btn(color='primary', @click='submit', :disabled='mailFormatOrgSize == 0 || !valid || loading', :loading='loading', type='submit')
        v-icon save
        | 登録・保存する

  v-form(ref='form', v-model='valid', lazy-validastion='', @submit.prevent='submit')
    template(v-for='(mailFormatOrg, index) in mailFormatOrgs')
      v-card.mb-1
        v-card-text
          v-subheader ■カテゴリ名とメールテキストを入力してください
          v-layout(row xs12)
            v-flex(text-xs-left)
              v-text-field(v-model='mailFormatOrg.category_name', name='category_name', label='カテゴリ名', :rules='categoryNameRules', type='text', outline)
            v-flex(text-xs-right)
              v-btn(color='accent', @click='submitDelete(index)')
                v-icon clear
                | 削除
          v-textarea(v-model='mailFormatOrg.mail_body', label='メールテキスト', outline)

  v-layout.mb-1(row)
    v-flex(text-xs-center)
      v-btn(color='primary', @click='addMailFormatOrg', :disabled='loading || mailFormatOrgSize >= 10', :loading='loading')
        v-icon add
        | 追加する

  v-layout.mb-1(v-if='mailFormatOrgSize >= 10', row)
    v-flex(text-xs-center)
      v-alert(:value='true', color='warning', icon='priority_high', outline='')
        | 登録できる最大件数は10件までです。
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'

export default {
  name: 'AutoMailCustom',

  mixins: [ResponseMixin],
  data: function () {
    return {
      valid: true,
      categoryNameRules: [
        v => (v.length <= 255) || '255文字以内で入力してください。',
      ],
    }
  },

  computed: {
    ...mapGetters('auto_mail', [
      'mailFormatOrgSize',
    ]),
    ...mapGetters('auth', [
      'isAnalyticsConfig'
    ]),

    mailFormatOrgs: {
      get () {
        return this.$store.state.auto_mail.mailFormatOrgs
      },
      set (value) {
        this.$store.commit('auto_mail/changMailFormatOrgs', value)
      }
    },

    loading: {
      get () {
        return this.$store.state.auto_mail.loading
      },
      set (value) {
        this.$store.commit('auto_mail/changeLoading', value)
      }
    },
  },

  async created() {
    // アナリティクス設定権限以外はデフォルトメールのみ操作可能
    if (!this.isAnalyticsConfig) {
      this.$router.push({ name: 'auto_mail_default' })
      return
    }
    this.init()
  },

  mounted: function () {
  },

  methods: {
    ...mapActions('auto_mail', [
      'fetchMailFormatOrgs',
      'saveMailFormatOrgs',
      'deleteMailFormatOrg',
    ]),

    ...mapMutations('auto_mail', [
      'changMailFormatOrgs',
      'addMailFormatOrg',
      'removeMailFormatOrg',
    ]),

    async init() {
      const response = await this.fetchMailFormatOrgs()
      if (!this.chkResponse(response)) {
        return
      }
    },

    async submit() {
      if (!this.validate()) {
        return
      }

      this.loading = true
      const res = await this.saveMailFormatOrgs()
      if (!this.chkResponse(res)) {
        return
      }

      this.$store.dispatch('flash/showMessage', { message: 'メールフォーマットを保存しました。', color: 'info' })
      if (res.status == 200) {
        this.init()
      }
      this.loading = false
    },

    async submitDelete(index) {
      const mailFormatOrg = this.mailFormatOrgs[index]

      if (!window.confirm(this.getConfirmMessage(mailFormatOrg, '本当に削除しますか？'))) {
        return
      }

      // DB から取得していないデータは配列から削除するだけ
      if (!mailFormatOrg.id) {
        return this.removeMailFormatOrg(index)
      }

      this.loading = true
      const params = {
        mail_format_org_id: mailFormatOrg.id,
      }
      const res = await this.deleteMailFormatOrg(params)

      if (!this.chkResponse(res)) {
        this.loading = false
        return
      }

      this.$store.dispatch('flash/showMessage', { message: 'メールフォーマットを削除しました。', color: 'info' })
      this.init()
      this.loading = false
    },

    getConfirmMessage(mailFormatOrg, message) {
      return `カテゴリ名：${mailFormatOrg.category_name}

${message}`
    },

    validate () {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
