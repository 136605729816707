<template lang='pug'>
v-layout(row wrap)
  v-flex(xs12)
    h4 リンク
  template(v-if="isShowCompanyPage()")
    template(v-for="item in adminCompanyLinkItems" v-if="item.show")
      router-link.btn-link(v-if="!isSameLinkCurrentPage(item.link)" :to=" { name: item.link }")
        v-btn {{ item.title }}
          v-icon(right) arrow_right_alt
  template(v-else)
    v-btn(:href="news_admin_url",target="_blank")
      | お知らせ(外部サイトへ)
      v-icon(right) arrow_right_alt
    template(v-for="item in adminLinkItems" v-if="item.show")
      router-link.btn-link(v-if="!isSameLinkCurrentPage(item.link)" :to=" { name: item.link }")
        v-btn {{ item.title }}
          v-icon(right) arrow_right_alt
</template>

<script>
import PrefMixin from '../libs/PrefMixin'

export default {
  name: 'TheAdminLink',
  mixins: [PrefMixin],
  data: function () {
    return {
      news_admin_url: process.env.news_admin_url,
    }
  },

  computed: {
  },

  methods: {
    isShowCompanyPage() {
      return this.$route.params.company_id != undefined
    },
    isSameLinkCurrentPage(link) {
      return this.$route.name == link
    }
  },
}
</script>

<style lang='scss' scoped>
</style>
