<template lang='pug'>
v-layout(v-if="userSessions" row wrap)
  v-flex(xs12)
    div.mb-2.mt-2
      span.pa-2.mr-2(:class="conversionLogSessionClass") 問合訪問
  v-flex(xs12)
    v-data-table(:items="userSessions" class="elevation-1" hide-actions)
      template(v-slot:headers="props")
        tr.lighten-5(:class='baseColor')
          th.body-2.font-weight-bold(width='10%') 訪問回数
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-icon.pl-2.tooltip-icon(v-on="on") help_outline
              span.body-2
                | HPを訪問した回数です。
          th.body-2.font-weight-bold(width='10%') 訪問日時
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-icon.pl-2.tooltip-icon(v-on="on") help_outline
              span.body-2
                | HPを訪問した日時です。
          th.body-2.font-weight-bold(width='50%') HP訪問直前のページ
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-icon.pl-2.tooltip-icon(v-on="on") help_outline
              span.body-2
                | どこからHPに訪問したのかURLを表記しています。
                br
                | メール内のリンク、お気に入りなどから訪問する場合は「不明」となります。
          th.body-2.font-weight-bold(width='10%') 検索キーワード
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-icon.pl-2.tooltip-icon(v-on="on") help_outline
              span.body-2
                | お客様がGoogleやYahoo!で検索してHPを訪れる場合、その検索したキーワードを取得しています。
          th.body-2.font-weight-bold(width='10%') 滞在時間
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                v-icon.pl-2.tooltip-icon(v-on="on") help_outline
              span.body-2
                | ホームページに滞在した合計時間です。直帰した場合は「-」が表示されます。
      template(v-slot:items="props" transition="slide-x-transition")
        tr(:class="rowColor(props.item)")
          td {{ (props.index + 1) + '回目' }}
          td {{ props.item.attributes.created }}
          td {{ userSessionRefereUrl(props.index) }}
          td {{ userSessionSearchWord(props.index) }}
          td {{ props.item.attributes.total_view_time }}
      template(v-slot:footer v-if="needFilter()")
        tr
          td.lighten-4.toggle-btn.grey(colspan=6 @click='submitChangeFilter')
            v-layout.justify-center
              template(v-if="filter")
                v-icon.pr-3 arrow_downward
                | 展開する
              template(v-else)
                v-icon.pr-3 arrow_upward
                | 折り畳む
</template>

<script>
import ColorMixin from '../../libs/ColorMixin'

export default {
  name: 'TheInquiry',
  mixins: [ColorMixin],
  props: {
    storeModule: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: ''
    },
  },
  data: function () {
    return {
      filter: true
    }
  },
  computed: {
    conversionLog: function() {
      return this.$store.state[this.storeModule].conversionLog
    },
    userSessions: function() {
      const userSessions = this.$store.state[this.storeModule].userSessions
      if (!userSessions) {
        return null
      }
      if (this.filter) {
        return userSessions.slice(0,3)
      } else {
        return userSessions
      }
    },
    userSessionRefereUrl: function() {
      return this.$store.getters[this.storeModule + '/userSessionRefereUrl']
    },
    userSessionSearchWord: function() {
      return this.$store.getters[this.storeModule + '/userSessionSearchWord']
    },
    userSessionIpSource: function() {
      return this.$store.getters[this.storeModule + '/userSessionIpSource']
    }
  },
  methods: {
    needFilter() {
      return this.$store.state[this.storeModule].userSessions.length >= 4
    },
    submitChangeFilter() {
      this.filter = !this.filter
    },
    rowColor(userSession) {
      if (userSession.attributes.is_conversion_session) {
        return this.conversionLogSessionClass
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.toggle-btn {
  cursor: pointer;
}
.v-datatable th,
.v-datatable td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tooltip-icon {
  vertical-align: bottom;
}
</style>
