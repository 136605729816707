import api from 'api'

export default {
  namespaced: true,

  state: {
    company: {},
    urlHomeRedirect: '',
    isHomeUrlRedirect: '',
    urlTrackJs: '',
    initLoading: true,
    loading: false,
  },

  getters: {
    redirectUrl(state) {
      return state.company.attributes.storage_redirect_url && !state.isHomeUrlRedirect
        ? state.company.attributes.storage_redirect_url
        : state.urlHomeRedirect
    }
  },

  mutations: {
    setCompany(state, company) {
      state.company = company
    },
    setUrlHomeRedirect(state, urlHomeRedirect) {
      state.urlHomeRedirect = urlHomeRedirect
    },
    setIsHomeUrlRedirect(state, isHomeUrlRedirect) {
      state.isHomeUrlRedirect = isHomeUrlRedirect
    },
    setUrlTrackJs(state, urlTrackJs) {
      state.urlTrackJs = urlTrackJs
    }
  },

  actions: {
    async show({commit, state}, urlParams) {
      state.initLoading = true
      const response = await api.get('admin/companies/' + urlParams.company_id + '/cross_domain_tags').then(response => {
        commit('setCompany', response.data.company.data)
        commit('setIsHomeUrlRedirect', response.data.is_home_url_redirect)
        commit('setUrlHomeRedirect', response.data.url_home_redirect)
        commit('setUrlTrackJs', response.data.url_track_js)
        return response
      }).catch(err => {
        return err.response
      })
      state.initLoading = false
      return response
    },

    async downloadJavascript({state}, urlParams) {
      state.loading = true 
      const response = await api.get('admin/companies/' + urlParams.company_id + '/cross_domain_tags/javascripts').then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async downloadHtml({state}, urlParams) {
      state.loading = true 
      const response = await api.get('admin/companies/' + urlParams.company_id + '/cross_domain_tags/htmls').then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },
  },
}
