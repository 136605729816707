<template lang='pug'>
v-toolbar(app :dark="isAdmin")
  v-toolbar-side-icon.hidden-lg-and-up(v-if="!isAdmin" @click.stop="showDrawr = !showDrawr")
  img.ml-3.toolbar-log(src='~apojaws.svg')
  v-layout()
    v-toolbar-items.ml-5()
      template(v-if="!isAdmin")
        | ユーザー名： {{ user.name }}
        br
        | アカウント： {{ user.account }}
  v-spacer
  v-toolbar-items
    template(v-if="!isAdmin")
      v-btn.hidden-md-and-down(flat active-class='' v-for="(item, index) in linkItems" v-if='item.show'  :to="{ name: item.link }" :key="index") {{ item.title }}
      v-menu(offset-y v-if="linkConfigCounts.length > 0")
        template(v-slot:activator="{ on }")
          v-btn.hidden-md-and-down(flat v-on="on")
            | 設定
            v-icon arrow_drop_down
        v-list
          v-list-tile(v-for="(config, index) in linkConfigItems" v-if='config.show' :key="index" :to="{ name: config.link }" active-class='' )
            v-list-tile-title {{ config.title }}
    v-btn(flat @click.stop='logout') ログアウト
</template>

<script>
import PrefMixin from '../libs/PrefMixin'
export default {
  name: 'TheToolber',
  mixins: [PrefMixin],
  computed: {
    showDrawr: {
      get () {
        return this.$store.state.apojaws.showDrawr
      },
      set (value) {
        this.$store.commit('apojaws/changeShowDrawr', value)
      }
    },

    isAdmin: function () {
      return this.$store.state.apojaws.isAdmin
    },

    user: function() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    logout () {
      this.$store.commit((this.isAdmin ? 'admin_auth' : 'auth') + '/logout')
      this.$router.push({ name: this.isAdmin ? 'admin_login' : 'login' })
    }
  },
}
</script>

<style lang='scss' scoped>
.toolbar-log {
  width: inherit;
  height: 100%;
  max-height: 38px;
}
</style>
