<script>
import Encoding from 'encoding-japanese'
export default {
  name: 'EncodeMixin',

  methods: {
    convertArray(trgtStr, from, to) {
      var strArray = []
      for(let i = 0; i < trgtStr.length; i++) {
        strArray.push(trgtStr.charCodeAt(i))
      }
      const chgArray = Encoding.convert(strArray, to, from)
      return new Uint8Array(chgArray)
    },
  },
}
</script>

