<template lang='pug'>
v-container
  v-layout(row wrap class='mb-2')
    v-flex(subheading)
      | ID個別発行
      br
      small(class='body-1')
        | ※IDを付与したいお客様のメールアドレス、反響日時をご入力した上で、「発行する」ボタンをクリックしてください。
    v-flex(xs12)
      v-form(v-model="valid" ref='form' lazy-validation)
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(class='label')
              | 問合わせ番号
          v-flex(xs9)
            v-text-field(v-model='formAttributes.relation_code')
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(class='required label')
              | メールアドレス
          v-flex(xs9)
            v-text-field(v-model='formAttributes.beacon_user_mail' required='' :rules='emailRules' ref='beacon_user_mail')
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(class='label')
              | 反響日時
          v-flex(xs9)
            v-layout(row xs12 class="mb-3")
              v-flex(xs6 pr-5)
                v-text-field(v-model='formAttributes.cv_date' label='年月日を入力', type='date')
              v-flex(xs6)
                v-text-field(v-model='formAttributes.cv_time' label='日時を入力', type='time')
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(:class='classDepartment()')
              | {{ getRoleNameByRoleCode('dep') }}
          v-flex(xs9)
            v-select.body-2(
              :value='formAttributes.department'
              @change='saveChangeDep($event)'
              :items='getDepartmentUsers(companyRole, user)'
              item-text='attributes.name'
              item-value='attributes.id'
              :no-data-text="'該当の' + getRoleNameByRoleCode('dep') + 'が存在しません'"
              single-line
              hide-selected
              :disabled='disabledDepartment()'
              :rules='departmentUserRules'
            )
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(:class='classShop()')
              | {{ getRoleNameByRoleCode('shop') }}
          v-flex(xs9)
            v-select.body-2(
              :value='formAttributes.shop'
              @change='saveChangeShop($event)'
              :items='getShopUsersAssociateInUpperLevel(companyRole, user)'
              item-text='attributes.name'
              item-value='attributes.id'
              :no-data-text="'該当の' + getRoleNameByRoleCode('shop') + 'が存在しません'"
              single-line
              hide-selected
              ref='shopUser'
              :disabled="disabledShop()"
              :rules='shopUserRules'
            )
        v-layout(row xs12 class="mb-3")
          v-flex(xs3)
            v-subheader(:class='classIndividual()')
              | {{ getRoleNameByRoleCode('ind') }}
          v-flex(xs9)
            v-select(
              v-model='formAttributes.individuals'
              :items='individualUsers'
              item-text='attributes.name'
              item-value='attributes.id'
              :disabled='loading || disabledIndividual()'
              multiple chips
              readonly
              @focus='focusIndividuals()'
              :rules='individualUserRules'
            )
              template(v-slot:selection='data')
                v-chip.chip--select-multi(:selected='data.selected' color='indigo lighten-5')
                  | {{ data.item.attributes.name }}
            TheIndividualUserDialog(
              ref='individualUserDialog'
              :individualUsers='individualUsers'
              :assignIndividualUsers='formAttributes.individuals'
              :roleName='getRoleNameByRoleCode("ind")'
              @saveChangeIndividuals='saveChangeIndividuals'
              )
        v-layout(row xs12 class="mb-3")
          v-flex(offset-xs3)
            v-btn(class='info' @click='submitCreate' :disabled='!valid || loading' :loading='loading')
              | 発行する
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ResponseMixin from '../libs/ResponseMixin'
import TheIndividualUserDialog from './TheIndividualUsersDialog'

export default {
  name: 'MailTrackingForm',
  components: {
    TheIndividualUserDialog,
  },
  mixins: [ResponseMixin],
  data() {
    return {
      displayDialogAssign: false,
      valid: true,
      emailRules: [
        v => !!v || '入力必須です',
        v => (!v || v.length <= 255) || '255文字以内で入力してください。',
        v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
      ],
      departmentUserRules: [
        v => (!this.requireDepartment() || !!v) || '入力必須です'
      ],
      shopUserRules: [
        v => (!this.requireShop() || !!v) || '入力必須です'
      ],
      individualUserRules: [
        v => (!this.requireIndividual() || !!v) || '入力必須です'
      ]
    }
  },
  computed: {
    ...mapState(['auth']),
    ...mapGetters('mail_tracking', [
      'getDepartmentUsers',
      'getShopUsersAssociateInUpperLevel',
      'getIndividualUsersAssociateInUpperLevel',
    ]),
    ...mapGetters('auth', [
      'getRoleNameByRoleCode',
    ]),
    formAttributes: {
      get () {
        return this.$store.state.mail_tracking.formAttributes
      },
      set (value) {
        this.$store.commit('mail_tracking/setFormAttributes', value)
      }
    },
    individualUsers: function() {
      return this.getIndividualUsersAssociateInUpperLevel(this.companyRole, this.user)
    },
    loading: function() {
      return this.$store.state.mail_tracking.loading
    },
    individuals_cv_mails: {
      get () {
        const individuals_cv_mail = this.formAttributes.individuals_cv_mail
        if (!individuals_cv_mail) { return [] }
        return this.formAttributes.individuals_cv_mail.split(',')
      },
      set (value) {
        this.$store.commit('mail_tracking/setIndividualsCvMails', value.join(','))
      }
    },
    user: function() {
      return this.auth.user
    },
    companyRole: function() {
      return this.auth.companyRole
    }
  },
  async created() {
    await this.init({user: this.user, companyRole: this.companyRole})
    this.edit()
  },
  methods: {
    ...mapActions('mail_tracking', [
      'init',
      'edit',
      'create',
    ]),
    async submitCreate() {
      if (!this.$refs.form.validate()) {
        return
      }
      const response = await this.create()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '登録が完了しました。' , color: 'info' })
      await this.$refs.form.resetValidation()
      await this.$store.commit('mail_tracking/clearFormAttributes', { user: this.user, companyRole: this.companyRole })
    },
    requireDepartment() {
      return this.companyRole.attributes.level <= 3
    },
    requireShop() {
      return this.companyRole.attributes.level <= 2
    },
    requireIndividual() {
      return this.companyRole.attributes.level <= 1
    },
    classDepartment() {
      return this.requireDepartment() ? 'label required' : 'label'
    },
    classShop() {
      return this.requireShop() ? 'label required' : 'label'
    },
    classIndividual() {
      return this.requireIndividual() ? 'label required' : 'label'
    },
    disabledDepartment() {
      return this.requireDepartment()
    },
    disabledShop() {
      return this.requireShop() || !this.formAttributes.department
    },
    disabledIndividual() {
      return this.requireIndividual() || !this.formAttributes.shop
    },
    focusIndividuals() {
      this.$refs.individualUserDialog.displayDialog()
    },
    saveChangeDep(changeDepartment) {
      this.formAttributes.shop = ''
      if (this.formAttributes.individuals.length > 0) { this.formAttributes.individuals = [] }
      this.formAttributes.department = changeDepartment
    },
    saveChangeShop(changeShop) {
      if (this.formAttributes.individuals.length > 0) { this.formAttributes.individuals = [] }
      this.formAttributes.shop = changeShop
    },
    saveChangeIndividuals(individuals) {
      this.formAttributes.individuals = individuals
      this.$refs.individualUserDialog.hiddenDialog()
    }
  },
}
</script>

<style lang='scss' scoped>
</style>
