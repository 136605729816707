<template lang='pug'>
v-layout.column
  v-layout.mb-1(row wrap)
    v-flex(text-xs-left)
      h2.headline コメント編集
  v-layout.mb-2(row wrap)
    v-flex(xs12)
      v-card
        TheNoticeForm(v-if="!initLoading" action='edit' :notice="notice" :loading="loading")
</template>

<script>
import TheNoticeForm from '../../components/TheNoticeForm'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'EditNotice',
  components: {
    TheNoticeForm
  },
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('admin_notice', [
    ]),
    ...mapState({
      admin_notice: 'admin_notice'
    }),

    notice: function() {
      return this.admin_notice.notice
    },

    loading: {
      get () {
        return this.$store.state.admin_notice.loading
      },
      set (value) {
        this.$store.commit('admin_notice/setLoading', value)
      }
    },
    initLoading: function() {
      return this.admin_notice.initLoading
    }
  },

  async created() {
    await this.init()
    await this.edit(this.$route.params)
  },

  async mounted() {
  },

  beforeDestroy: function() {
  },

  methods: {
    ...mapActions('admin_notice', [
      'init',
      'edit',
    ]),

    ...mapMutations('admin_notice', [
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
