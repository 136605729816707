<template lang='pug'>
v-layout.column
  v-layout.mb-1(row wrap)
    v-flex(text-xs-right)
      v-btn(color='primary', @click='submit', :disabled='!valid || loading', :loading='loading', type='submit')
        v-icon save
        | 登録・保存する
  v-card
    v-form(ref='form', v-model='valid', lazy-validastion='', @submit.prevent='submit')
      v-layout(row align-center justify-end)
        v-flex(text-xs-left)
          v-btn(v-if="isAnalyticsConfig" color='blue darken-2', dark, @click="insertTextIntoMailBody(CATEGORY_TEXT)", :disabled='loading', :loading='loading') カテゴリ差し込みを追加
          v-btn(color='amber darken-2', dark, @click="insertTextIntoMailBody(RECOMMEND_TEXT)", :disabled='loading', :loading='loading') レコメンドを追加
        v-flex(v-if="isAnalyticsConfig" text-xs-right)
          v-layout(row align-center justify-end)
            v-subheader 観覧割合：
            v-text-field(v-model="mailDefault.percent", name='percent', type='number', :rules='percentNameRules' required)
            v-subheader ％以上のものをメールに反映
    v-layout.mr-3.ml-3(column)
      v-subheader ※追加したカテゴリタグは閲覧割合が一番大きい順にメールテキストを追加します。
      v-textarea(v-model='mailDefault.mail_body', height='500' outline)

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'

export default {
  name: 'AutoMailDefault',
  mixins: [ResponseMixin],

  data: function () {
    return {
      CATEGORY_TEXT: '##_category_text_##',
      RECOMMEND_TEXT: '##_recommend_##',
      valid: true,
      percentNameRules: [
        v => (!!v || v === 0) || '必須入力です。',
      ],
    }
  },

  computed: {
    ...mapGetters('auto_mail', [
    ]),
    ...mapGetters('auth', [
      'isAnalyticsConfig'
    ]),

    mailDefault: {
      get () {
        return this.$store.state.auto_mail.mailDefault
      },
      set (value) {
        this.$store.commit('auto_mail/changMailDefault', value)
      }
    },

    loading: {
      get () {
        return this.$store.state.auto_mail.loading
      },
      set (value) {
        this.$store.commit('auto_mail/changeLoading', value)
      }
    },
  },

  async created() {
    this.init()
  },

  mounted: function () {
  },

  methods: {
    ...mapActions('auto_mail', [
      'fetchMailDefault',
      'saveMailDefault',
      'createMailDefault',
    ]),

    ...mapMutations('auto_mail', [
      'changMailDefault',
    ]),

    async init() {
      const response = await this.fetchMailDefault()
      if (!this.chkResponse(response)) {
        return
      }
    },

    // カーソル位置にテキストを挿入する
    insertTextIntoMailBody(insertText) {
      if (this.checkMatchCountOrMore(insertText, 10)) {
        this.AlertInsertTextOver(insertText)
        return
      }
      const textarea = document.querySelector('textarea')
      let value = textarea.value
      const len = value.length
      const pos = textarea.selectionStart
      const before = value.substr(0, pos)
      const after = value.substr(pos, len)

      this.mailDefault.mail_body = before + insertText + after
      textarea.value = this.mailDefault.mail_body

      textarea.focus()
      textarea.selectionEnd = pos + insertText.length
    },

    // 挿入テキストが10件を超えているかチェックする
    checkMatchCountOrMore(insertText, count) {
      // 既存アポジョーズでは「#」を含めていなかったので「#」をすべて削除
      // 例：##_category_text_## → _category_text_
      const regexp = new RegExp(insertText.replace(/#/g, ''), 'g')
      return (this.mailDefault.mail_body.match(regexp) || []).length >= count
    },

    // 挿入テキストが10件を超えていた場合のアラート
    AlertInsertTextOver(insertText) {
      const word = insertText == this.CATEGORY_TEXT ? 'カテゴリ' : 'レコメンド'
      const message = '挿入できる' + word + 'テキストは10件までです。'
      this.showFlash({ status: 200 }, message, 'warning')
    },

    async submit() {
      if (!this.validate()) {
        return
      }

      this.loading = true
      // id があれば更新、なければ新規作成する
      const res = this.mailDefault.id ? await this.saveMailDefault() : await this.createMailDefault()
      if (!this.chkResponse(res)) {
        this.loading = false
        return
      }

      this.showFlash(res, 'メールフォーマットを保存しました。', 'info')
      this.init()
      this.loading = false
    },

    validate () {
      if (this.checkMatchCountOrMore(this.CATEGORY_TEXT, 11)) {
        this.AlertInsertTextOver(this.CATEGORY_TEXT)
        return false
      }

      if (this.checkMatchCountOrMore(this.RECOMMEND_TEXT, 11)) {
        this.AlertInsertTextOver(this.RECOMMEND_TEXT)
        return false
      }

      return this.$refs.form.validate()
    },

    showFlash(res, message, color) {
      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', { message: message, color: color })
      } else if (res.status == 500) {
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error' })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
