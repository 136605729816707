<template lang='pug'>
v-layout.column
  v-container
    template(v-for="(accessLog, idx) in accessLogs")
      v-card.ma-4.elevation-5
        v-divider
        v-list(:class="[rowColor(accessLog)]")
          v-list-tile
            | {{ accessLog.attributes.session_number }} 回目
            v-divider.mx-4(vertical)
            | {{accessLog.attributes.access_number}} P
            v-divider.mx-4(vertical)
            | {{ accessLog.attributes.created }}
            template(v-if="accessLogScreenShotStatus(idx) == 'STATUS_FINISH'")
              v-divider.mx-4(vertical)
              v-btn(@click='submitImgReload(idx)' icon :loading="loading")
                v-icon replay
            v-divider.mx-4(vertical)
            | {{ accessLog.attributes.browse_time_minutes }}
          v-divider
        v-list(:class="[rowColor(accessLog)]")
          v-list-tile.list-title
            | {{ accessLog.attributes.title }}
          v-divider
        div(:class="[accessLog.attributes.vue_is_image_loading ? 'site-view-loading grey darken-2' : 'site-view ' + rowColor(accessLog)]")
          template(v-if="accessLog.attributes.is_iframe_screen")
            iframe.site-iframe(:src="accessLog.attributes.url")
          template(v-else-if="accessLog.attributes.vue_is_image_loading")
            v-container(fill-height)
              v-progress-circular.justify-center.align-center(:size="200" :width='10' color="white" indeterminate style="width: 100%;")
          template(v-else)
            template(v-if="isActiveTab")
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-img.ma-2.site-img(:src='accessLog.attributes.screen_shot.attributes.img_full_path' @click="accessLog.attributes.vue_is_show_img_dialog = true" v-on="on")
                span
                  | 画像取得時刻：{{ accessLog.attributes.screen_shot.attributes.last_capture_at_ja_ymdhm }}
                  template(v-if="accessLogScreenShotStatus(idx) == 'STATUS_FINISH'")
                    br
                    | 画像を更新したい場合は「
                    v-icon(dark small) replay
                    | 」を押下してください。
              v-dialog(v-model='accessLog.attributes.vue_is_show_img_dialog' fullscreen hide-overlay transition="dialog-bottom-transition")
                v-card
                  v-toolbar(dark fixed).primary
                    v-btn(icon dark @click="accessLog.attributes.vue_is_show_img_dialog = false")
                      v-icon close
                    v-toolbar-title(dark) {{ accessLog.attributes.title }}
                  v-img.dialog-site-img(:src='accessLog.attributes.screen_shot.attributes.img_full_path' @click="accessLog.attributes.vue_is_show_img_dialog = false")
      v-layout.justify-center(v-if="idx < accessLogs.length - 1")
        v-icon(color='primary' x-large) arrow_downward
  v-flex(xs12)
    ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import ResponseMixin from '../../../libs/ResponseMixin'
import ThePagination from '../../../components/ThePagination'
import ColorMixin from '../../../libs/ColorMixin'

export default {
  name: 'TheDetail',
  components: {
    ThePagination,
  },
  mixins: [ResponseMixin, ColorMixin],
  props: {
    isActiveTab: {
      type: Boolean,
      default: false
    },
    storeModule: {
      type: String,
      default: ''
    },
  },
  computed: {
    conversionLog: function() {
      return this.$store.state[this.storeModule].conversionLog
    },
    accessLogs: function() {
      return this.$store.state[this.storeModule].accessLogs
    },
    loading: function() {
      return this.$store.state[this.storeModule].loading
    },
    accessLogScreenShotStatus: function() {
      return this.$store.getters[this.storeModule + '/accessLogScreenShotStatus']
    },
    totalPages: function() {
      return this.$store.state[this.storeModule].accessLogTotalPage
    },
  },
  methods: {
    async submitImgReload(accessLogIdx) {
      const response = await this.$store.dispatch(this.storeModule + '/screenReload', {urlParams: this.$route.params , accessLogIdx: accessLogIdx})
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch(this.storeModule + '/reloadImg')
    },
    rowColor(accessLog) {
      return accessLog.attributes.is_conversion_access ? this.conversionLogSessionClass : this.viewTimeColor(accessLog.attributes.browse_time)
    },
    async changePage() {
      const response = await this.$store.dispatch(this.storeModule + '/fetch', this.$route.params)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch(this.storeModule + '/reloadImg')
    }
  }
}
</script>

<style lang='scss' scoped>
.site-iframe {
  height: 500px;
  width: 100%;
  overflow: auto;
}
.site-view {
  max-height: 500px;
  text-align: center;
  overflow: auto;
}
.site-view-loading {
  height: 500px;
  text-align: center;
}
.v-list {
  padding: 0px;
}
.site-img {
  cursor: pointer;
}
.dialog-site-img {
  cursor: pointer;
  margin-top: 64px;
}
.list-title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  min-height: 48px;
  align-items: center;
  word-break: break-all;
}
</style>
