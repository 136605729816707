<script>
export default {
  name: 'DomAction',
  data() {
    return {
    }
  },

  methods: {
    submitEllipsisText(e) {
      e.toElement.classList.remove('ellipsis-text')
    }
  },
}
</script>

