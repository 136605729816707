<template lang='pug'>
div
  section(v-for='news in newses()' class='news')
    h2.news_title {{ news.title.rendered }}
    p(v-html='news.content.rendered' class='rendered')
</template>

<script>
export default {
  created: function() {
    this.$store.dispatch('news/getWordpress')
  },

  beforeDestroy: function() {
    this.$store.dispatch('news/clearNewses')
  },

  methods: {
    newses: function() {
      return this.$store.state.news.newses
    }
  },

}


</script>

<style lang='scss' scoped>
section.news {
  .news_title {
    margin-bottom: 20px;
    border-left: solid 10px #c82d4d;
    padding-left: 10px;
    font-weight: normal;
    letter-spacing: 2px;
  }
  + section .news_title {
    margin-top: 50px;
  }
  .rendered {
    padding-left: 20px;
    padding-bottom: 50px;
    border-bottom: 2px solid #ccc;
  }
}

</style>

