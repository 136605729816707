import Axios from 'axios'
import store from './store/store'
import router from 'routes'

const axios = Axios.create({
  baseURL: process.env.api_base_url,
})

const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-TOKEN'] = token

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status == 500) {
    handle500(error)
  }
  if (error.response.status == 404) {
    router.push({ name: 'not_found' })
  }
  // ユーザー認証エラーが発生した場合は、強制的にログアウトしてログイン画面へ遷移させる
  if (error.response.status === 401 && !store.state.apojaws.isPublicPage) {
    store.commit(store.getters['apojaws/getAuthStoreKey'] + '/logout')
    router.push({ name: store.getters['apojaws/getLoginRouteName'] })
  }
  return Promise.reject(error)
})

async function handle500(error) {
  let messages = []
  if (error.request.responseType == 'blob') {
    messages = await blobDataMessages(error.response)
  } else {
    messages = error.response.data.errors
  }
  store.dispatch('dialog/showDialog', { title: '500Error', message: messages.join('\r\n'), color: 'error lighten-2' })
}

async function blobDataMessages(response) {
  let resText = await new Promise((resolve) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsText(response.data)
  })
  return JSON.parse(resText).errors
}


export default {
  /**
   * API へのリクエスト処理
   *
   * @param method string アクション指定
   * @param url string API の URL
   * @param options Object params = パラメータ, notAuth = トークンで認証しないかどうか
   */
  request (method, url, options) {
    let promise = null
    let params = {}

    if (options.params) {
      // リクエストパラメーターのセット
      params = options.params
    }

    // notAuth が設定されていないか ture じゃない時は必ず認証する
    if (!options.notAuth) {
      // ヘッダーに Authorization のトークンをセット
      const token = store.state.apojaws.isAdmin ? store.state.admin_auth.admin_user.token : store.state.auth.user.token
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
    }

    switch(method) {
    case 'get':
    case 'delete':
      promise = axios({
        method: method,
        url: url,
        params: params,
        responseType: options.responseType,
        paramsSerializer: options.paramsSerializer,
      })
      break
    case 'post':
    case 'patch':
      promise = axios({
        method: method,
        url: url,
        data: params,
        responseType: options.responseType,
      })
      break
    }

    return promise
  },

  get (url, options = {}) {
    return this.request('get', url, options)
  },

  post (url, options = {}) {
    return this.request('post', url, options)
  },

  patch (url, options = {}) {
    return this.request('patch', url, options)
  },

  delete (url, options = {}) {
    return this.request('delete', url, options)
  }
}
