import api from 'api'

export default {
  namespaced: true,

  state: {
    companyRoles: [],
    roles: [],
    company: {},
    loading: true,
  },

  getters: {
    getCompanyRoles(state) {
      return state.companyRoles
    },

    getCompanyRolesSize(state) {
      return state.companyRoles.length
    },

    getTargetRoleCode: (state) => (id) => {
      const role = state.roles.find(role => role.id == id)
      return role.attributes.role_code
    },

    getCompanyName(state) {
      return Object.keys(state.company).length === 0 ? '' : state.company.attributes.company_name
    },
  },

  mutations: {
    setCompanyRoles(state, companyRoles) {
      state.companyRoles = companyRoles
    },

    clearCompanyRoles(state) {
      state.companyRoles = []
    },

    setRoles(state, included) {
      state.roles = included.filter(data => data.type === 'role')
    },

    setCompany(state, included) {
      state.company = included.find(data => data.type === 'company')
    },

    rowAllChange(state, params) {
      for (const i in state.companyRoles) {
        state.companyRoles[i].attributes[params.targetKey] = params.value
      }
    },

    changeLoading(state, loading) {
      state.loading = loading
    },
  },

  actions: {
    async fetch({ state, commit }, urlParams) {
      state.loading = true
      await api.get('/admin/companies/' + urlParams.company_id + '/company_roles/').then(function (response) {
        commit('setCompanyRoles', response.data.data)
        commit('setRoles', response.data.included)
        commit('setCompany', response.data.included)
      }).catch(err => {
        return err.response
      })
      state.loading = false
    },

    async save({ state }, urlParams) {
      const url = '/admin/companies/' + urlParams.company_id + '/company_roles/'
      let company_roles = []
      for (const i in state.companyRoles) {
        company_roles.push(state.companyRoles[i].attributes)
      }
      const options = {
        params: {
          data: { company_roles: company_roles }
        }
      }

      state.loading = true
      const response = await api.patch(url, options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false

      return response
    },
  },
}
