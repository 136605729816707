<template lang='pug'>
div
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline ホットサイン設定 編集

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      hotSignForm(action='edit')
</template>


<script>
import hotSignForm from '../components/TheHotSignForm'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'NewHotSign',
  components: {
    hotSignForm
  },
  mixins: [ResponseMixin],
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('hot_sign', [
      'getCompany',
    ]),
  },

  async mounted() {
    const urlParams = {
      id: this.$route.params.hot_sign_id
    }
    const response = await this.edit(urlParams)
    if (!this.chkResponse(response)) {
      return
    }
  },

  beforeDestroy: function() {
    this.clearFormAttributes()
  },

  methods: {
    ...mapActions('hot_sign', [
      'edit',
    ]),
    ...mapMutations('hot_sign', [
      'clearFormAttributes'
    ])
  },
}
</script>

<style lang='scss' scoped>
</style>
