<template lang='pug'>
v-layout.column
  v-layout.row.wrap
    v-flex.text-xs-left
      h2.headline アポジョーズ一覧
  v-layout.row.wrap.mt-1
    v-flex.xs12
      //- 検索フィールド
      search-field
  v-layout.row.wrap.mt-4
    v-flex.text-xs-right
      router-link.btn-link(v-if='useableRanking' :to="{name: 'conversion_logs_hot_sings'}")
        v-btn(color="primary") ホットサインの一覧
      v-btn(color='primary' @click="$refs.inputUpload.click()" :disabled='loading' :loading='loading') 一括変更
      input(v-show="false" ref="inputUpload" type="file" @change="submitUploadCsv" accept=".csv,.txt")
      v-btn(color='primary' @click='submitDownloadCsv' :disabled='loading' :loading='loading') 検索結果をダウンロード（CSV）
  v-layout.row.wrap.mb-2.xs12
    v-flex.xs12
      v-data-table#data-table(:items="getConversionLogs", :headers="headers", :loading="loading", class="elevation-4", :total-items="getConversionLogsSize", hide-actions)
        template(v-slot:headers="props")
          tr#table-th.primary
            template(v-for="header in props.headers")
              th.body-2.font-weight-bold.white--text(
                v-if="isShowColumn(header)",
                :key="header.text"
                :class="['column', header.class, header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
                :width="header.width",
                @click="changeSort(header)",
                :rowspan="header.rowspan",
                :colspan="header.colspan"
              )
                v-icon(v-if="header.sortable" small color='white') arrow_upward
                span.header-text {{header.text}}
          tr#table-th.primary
            template(v-for="header in props.headers")
              template(v-if="header.children")
                template(v-for="childrenHeader in header.children")
                  th.body-2.font-weight-bold.white--text(
                    v-if="isShowColumn(header)",
                    :key="childrenHeader.text",
                    :class="['column', childrenHeader.class, childrenHeader.sortable ? 'sortable' : '', getOrder, childrenHeader.value === pagination.sortBy ? 'active' : '']",
                    :width="childrenHeader.width"
                    @click="changeSort(childrenHeader)",
                  )
                    v-icon(v-if="childrenHeader.sortable" small) arrow_upward
                    span.header-text {{ childrenHeader.text }}
        template(v-slot:items="props")
          TheConversionLogTd#table-td-child(:conversionLogProp='props')

  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import TheConversionLogsSearchField from '../components/TheConversionLogsSearchField'
import FileMixin from '../libs/FileMixin'
import ResponseMixin from '../libs/ResponseMixin'
import TheConversionLogTd from '../components/TheConversionLogTd'
import ThePagination from '../components/ThePagination'

export default {
  name: 'ConversionLogs',

  components: {
    'search-field': TheConversionLogsSearchField,
    TheConversionLogTd,
    ThePagination
  },

  mixins: [FileMixin, ResponseMixin],

  data: function () {
    return {
    }
  },

  computed: {
    ...mapState(['route', 'conversion_log']),
    ...mapGetters('conversion_log', [
      'getConversionLogs',
      'getConversionLogsSize',
      'getTotalPages',
      'getRoleNameByRoleCode',
      'getInitUrlQuery',
    ]),
    ...mapGetters('auth', [
      'useableRanking',
    ]),
    ...mapGetters('route', [
      'getOrder',
    ]),
    pagination: function(){
      return this.route.pagination
    },
    totalPages: function() {
      return this.conversion_log.totalPages
    },
    loading: {
      get () {
        return this.$store.state.conversion_log.loading
      },
      set (value) {
        this.$store.commit('conversion_log/changeLoading', value)
      }
    },
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
    headers: function() {
      return [
        {
          text: '問い合わせ日時',
          value: 'created',
          descending: 'desc',
          sortable: true,
          rowspan: 2,
          colspan: 1,
          width: '10%',
        },
        {
          text: '問い合わせ番号',
          value: 'relation_code',
          sortable: true,
          rowspan: 2,
          colspan: 1,
          width: '5%',
        },
        {
          text: '訪問回数',
          value: 'session_count',
          sortable: false,
          rowspan: 2,
          colspan: 1,
          width: '5%',
        },
        {
          text: this.getRoleNameByRoleCode('dep'),
          value: 'department',
          sortable: false,
          rowspan: 2,
          colspan: 1,
          width: '15%',
        },
        {
          text: this.getRoleNameByRoleCode('shop'),
          value: 'shop',
          sortable: false,
          rowspan: 2,
          colspan: 1,
          width: '15%',
        },
        {
          text: this.getRoleNameByRoleCode('ind'),
          value: 'individual',
          sortable: false,
          rowspan: 2,
          colspan: 1,
          width: '10%',
        },
        {
          text: 'リターンズ',
          sortable: false,
          rowspan: 1,
          colspan: 2,
          class: 'border-left',
          children: [
            {
              text: '履歴',
              value: 'revisit_histories',
              sortable: false,
              class: 'border-left',
              width: '20%',
            },
            {
              text: this.stop_revisit_header_text,
              value: 'stop_revisit',
              sortable: false,
              width: '5%',
            },
          ],
        },
        {
          text: 'アナリティクス',
          sortable: false,
          rowspan: 1,
          colspan: 3,
          class: 'border-left',
          analytics: true,
          children: [
            {
              text: 'グラフ',
              sortable: false,
              class: 'border-left border-bottom',
              analytics: true,
              width: '5%',
            },
            {
              text: 'アポ',
              value: 'appoint',
              sortable: false,
              class: 'border-bottom',
              analytics: true,
              width: '5%',
            },
            {
              text: '成約',
              value: 'contract',
              sortable: false,
              class: 'border-bottom',
              analytics: true,
              width: '5%',
            },
          ],
        },
        {
          text: 'コネクト',
          sortable: false,
          rowspan: 1,
          colspan: 3,
          class: 'border-left',
          connects: true,
          children: [
            {
              text: '発行ID',
              sortable: false,
              class: 'border-left border-bottom',
              connects: true,
              width: '5%',
            },
            {
              text: '顧客\r\nメールアドレス',
              sortable: false,
              class: 'border-bottom',
              connects: true,
              width: '5%',
            },
            {
              text: 'URL発行',
              sortable: false,
              class: 'border-bottom',
              connects: true,
              width: '5%',
            },
          ],
        },
        {
          text: 'CV\r\n削除',
          sortable: false,
          rowspan: 2,
          colspan: 1,
          class: 'border-left',
          otherOperations: true,
        },
      ]
    },
    stop_revisit_header_text: function() {
      if (this.roleAtr.is_master) {
        return 'ON\r\nOFF\r\n非表示'
      } else {
        return 'ON\r\nOFF'
      }
    },
  },
  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    await this.init()
    this.fetchEveryRoleUsers()
    this.fetch()
  },

  beforeDestroy: function() {
    this.clearAffiliationUsers()
  },

  methods: {
    ...mapActions('conversion_log', [
      'init',
      'fetch',
      'update',
      'fetchEveryRoleUsers',
      'csvDownload',
      'csvUpload',
    ]),

    ...mapMutations('conversion_log', [
      'clearSearchCondition',
      'clearAffiliationUsers',
    ]),

    changePage() {
      this.fetch()
    },

    async changeSort(header) {
      if (!header.sortable)  {
        return
      }

      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.fetch()
    },

    async submitDownloadCsv() {
      this.loading = true
      const response = await this.csvDownload()
      if (!await this.chkResponseBlob(response)) {
        this.loading = false
        return
      }
      this.fileDownload({filePath: 'conversion_logs.csv', type: 'text/csv', content: response.data })
      this.loading = false
    },

    async submitUploadCsv(e) {
      const files = e.target.files
      if (files[0] == undefined) {
        return
      }
      this.loading = true
      const response = await this.csvUpload(files[0])
      this.$refs.inputUpload.value = ''

      if (!this.chkResponse(response)) {
        this.loading = false
        return
      }
      await this.fetch()
      this.$store.dispatch('flash/showMessage', { message: response.data.messages.join('\r\n'), color: 'info'} )
      this.loading = false
    },

    isShowColumn(header) {
      if (header.analytics && !this.roleAtr.analytics_view) { return false }
      if (header.connects && !this.roleAtr.connect) { return false }
      if (header.otherOperations && !this.roleAtr.conversion_deletable && !this.roleAtr.connect_deletable) { return false }
      return true
    }
  }
}
</script>

<style lang='scss' scoped>
.select {
  font-size: 13px
}

.v-datatable td {
  text-align: center;
  vertical-align: center;
}

.border-left {
  border-left: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

#data-table {
  /deep/ {
    table {
      table-layout: auto;
    }
  }
}
#table-th > th {
  padding: 0 5px;
}
#table-td-child {
  /deep/ td {
    padding: 0 5px;
  }
}
.header-text {
  white-space: pre;
}
</style>
