<template lang='pug'>
div(id="app")
  v-app
    snackbar
    alertDialog
    router-view
</template>

<script>
import snackbar from './components/TheSnackbar'
import alertDialog from './components/TheAlertDialog'

export default {
  name: 'App',

  components: {
    alertDialog,
    snackbar,
  },
}
</script>

<style lang='scss' scoped>
</style>
