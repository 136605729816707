<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 Access Logs

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-data-table(:items="getAccesslogs", :headers="headers", :loading="loading", class="elevation-4", :total-items="getAccesslogsSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(v-for="header in props.headers",
               :key="header.text",
               :class="['column', header.sortable ? 'sortable' : '', getOrder, header.value === pagination.sortBy ? 'active' : '']",
               @click="changeSort(header)",
               :width="header.width"
               )
              v-icon(v-if="header.sortable" small) arrow_upward
              | {{ header.text }}
        template(v-slot:items="props")
          td {{ props.item.attributes.created }}
          td
            a(@click='submitFilter({filterColumn: "user_session_id", filterValue: props.item.attributes.user_session_id })')
              p
                |  {{ props.item.attributes.user_session_id }}
          td
            a(@click='submitFilter({filterColumn: "user_cookie_id", filterValue: props.item.attributes.user_cookie_id })')
              p
                | {{ props.item.attributes.user_cookie_id }}
          td
            p
             | {{ props.item.attributes.title }}
          td
            p.url {{ props.item.attributes.url }}
          td
            a(@click='submitFilter({filterColumn: "div_code", filterValue: props.item.attributes.div_code })')
              p
                | {{ props.item.attributes.div_code }}
          td
            a(@click='submitFilter({filterColumn: "user_code", filterValue: props.item.attributes.user_code })')
              p
                | {{ props.item.attributes.user_code }}
          td
            a(@click='submitFilter({filterColumn: "item_code", filterValue: props.item.attributes.item_code })')
              p
                | {{ props.item.attributes.item_code }}
  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import FilterMixin from '../../libs/FilterMixin'
import ThePagination from '../../components/ThePagination'

export default {
  name: 'AdminAccesslogs',
  components: {
    ThePagination
  },
  mixins: [FilterMixin],
  data() {
    return {
      headers: [
        {
          text: '作成日時',
          value: 'created',
          descending: 'asc',
          sortable: true,
        },
        {
          text: 'セッションID',
          value: 'user_session_id',
          sortable: true,
        },
        {
          text: 'クッキーID',
          value: 'user_cookie_id',
          sortable: true,
        },
        {
          text: 'タイトル',
          value: 'title',
          sortable: true,
        },
        {
          text: 'URL',
          value: 'url',
          sortable: true,
        },
        {
          text: 'Div Code',
          value: 'div_code',
          sortable: true,
        },
        {
          text: 'User Code',
          value: 'user_code',
          sortable: true,
        },
        {
          text: 'Item COde',
          value: 'item_code',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState(['route', 'admin_access_log']),
    ...mapGetters('admin_access_log', [
      'getAccesslogs',
      'getAccesslogsSize',
      'getTotalPages',
      'getCurrentPage',
      'getInitUrlQuery',
    ]),
    ...mapGetters('route', [
      'getOrder'
    ]),
    pagination: function(){
      return this.route.pagination
    },
    totalPages: function() {
      return this.admin_access_log.totalPages
    },
    filterValue: {
      get() {
        return this.$stor.state.admin_access_log.searchConditions.filterValue
      },
      set (value) {
        this.$store.commit('admin_access_log/setFilterValue', value)
      }
    },
    filterColumn: {
      get() {
        return this.$stor.state.admin_access_log.searchConditions.filterColumn
      },
      set (value) {
        this.$store.commit('admin_access_log/setFilterColumn', value)
      }
    },
    loading: {
      get() {
        return this.$store.state.admin_access_log.loading
      },
      set (value) {
        this.$store.commit('admin_access_log/changeLoading', value)
      }
    }
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
    let urlParams = {
      company_id: this.$route.params.company_id
    }
    this.loading = true
    await this.fetch(urlParams)
    this.loading = false
  },
  beforeDestroy: function() {
    this.$store.commit('admin_access_log/clearSearchCondition')
    this.$store.commit('admin_access_log/clearAccesslogs')
  },
  methods: {
    ...mapActions('admin_access_log', [
      'fetch',
      'chgStopRevisit'
    ]),
    changePage() {
      this.loading = true
      let urlParams = {
        company_id: this.$route.params.company_id
      }
      this.fetch(urlParams).then(() => {
        this.loading = false
      })
    },
    async changeSort(header) {
      if (!header.sortable)  {
        return
      }
      if (this.pagination.sortBy === header.value) {
        await this.changeDescendingQuery()
      } else {
        await this.changeSortByQuery(header.value)
      }
      this.loading = true
      let urlParams = {
        company_id: this.$route.params.company_id
      }
      this.fetch(urlParams).then(() => {
        this.loading = false
      })
    },
    async submitFilter({filterColumn: filterColumn, filterValue: filterValue}) {
      this.filterColumn = filterColumn
      this.filterValue = filterValue
      await this.changePageQuery(1)
      this.loading = true
      let urlParams = {
        company_id: this.$route.params.company_id
      }
      this.fetch(urlParams).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang='scss' scoped>
td {
  p.url,
  p.subject{
    width: 150px;
    word-wrap: break-word;
  }
}
</style>
