import api from 'api'

export default {
  namespaced: true,

  state: {
    conversionLogs: [],
    activeDepartmentUsers: [],
    departmentUsersByUserId: {},
    activeShopUsers: {},
    shopUsersByUserId: {},
    activeIndividualUsers: {},
    individualUsersByUserId: {},
    companyRoles: [],
    companyRoleNamesByRoleCode: {},
    totalPages: 0,
    loading: false,
    searchConditions: {},
  },

  getters: {
    getJsonActiveDepartmentUsers(state) {
      return JSON.parse(JSON.stringify(state.activeDepartmentUsers))
    },

    getJsonActiveShopUsers: (state) => (userId) => {
      const activeShopUsers = state.activeShopUsers[userId]
      return activeShopUsers
        ? JSON.parse(JSON.stringify(activeShopUsers))
        : undefined
    },

    getJsonActiveIndividualUsers: (state) => (userId) => {
      const activeIndividualUsers = state.activeIndividualUsers[userId]
      return activeIndividualUsers
        ? JSON.parse(JSON.stringify(activeIndividualUsers))
        : undefined
    },

    getShopUsers(state) {
      let shopUsers = []
      Object.keys(state.activeShopUsers).forEach(key => {
        shopUsers.push(state.activeShopUsers[key])
      })
      return shopUsers.reduce((user, val) => user.concat(val), [])
    },

    getIndividualUsers(state) {
      let individualUsers = []
      Object.keys(state.activeIndividualUsers).forEach(key => {
        individualUsers.push(state.activeIndividualUsers[key])
      })
      return individualUsers.reduce((user, val) => user.concat(val), [])
    },

    getConversionLogs(state) {
      return state.conversionLogs
    },

    getDepartmentUsers: (state, getters) => (responsible_department_id) => {
      let users = (getters.getJsonActiveDepartmentUsers || []).concat(state.departmentUsersByUserId[responsible_department_id] || [])
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      return users
    },

    // 「部署」に紐づく「店」のユーザー一覧を取得
    getShopUsersAssociateInUpperLevel: (state, getters) => (department_user_id, responsible_department_id) => {
      let users = (getters.getJsonActiveShopUsers(department_user_id) || []).concat(state.shopUsersByUserId[responsible_department_id] || [])
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      if (users.length >= 1) { users.unshift({attributes: { id: null, name: '' }}) }
      return users
    },
    // 「店」に紐づく「担当」のユーザー一覧を取得
    getIndividualUsersAssociateInUpperLevel: (state, getters, ) => (shop_user_id, responsible_individuals, searchWord) => {
      let users = (getters.getJsonActiveIndividualUsers(shop_user_id) || [])
        .concat(responsible_individuals.map(individual => state.individualUsersByUserId[individual]) || [])
      users = users.filter((v1, i1, a1) => {
        return (a1.findIndex((v2) => {
          return (v1.id===v2.id)
        }) === i1)
      })
      if (searchWord) {
        users = users.filter(e => {
          const name = e.attributes.name
          return (name || '').toLowerCase().indexOf((searchWord || '').toLowerCase()) > -1
        })
      }
      return users
    },

    getDepartmentName: (state) => (id) => {
      const departmentUser = state.departmentUsersByUserId[id]
      return departmentUser === void 0 ? '' : departmentUser.attributes.name
    },

    getShopName: (state) => (id) => {
      const shopUsers = state.shopUsersByUserId[id]
      return shopUsers === void 0 ? '' : shopUsers.attributes.name
    },

    getIndividualName: (state) => (id) => {
      const individualUsers = state.individualUsersByUserId[id]
      return individualUsers === void 0 ? '' : individualUsers.attributes.name
    },

    getRoleNameByRoleCode: (state) => (role_code) => {
      return state.companyRoleNamesByRoleCode[role_code]
    },

    getConversionLogsSize(state) {
      return state.conversionLogs.length
    },

    getTotalPages(state) {
      return state.totalPages
    },

    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
        searchConditions: {
          relation_codes: '',
          relationDateFrom: '',
          relationDateTo: '',
          defaultRelationDateFrom: '',
          defaultRelationDateTo: '',
          department: null,
          shop: null,
          individual: null,
          includeDeleteRevisit: null,
          beacon_user_mail: '',
          beacon_id: '',
        }
      }
    },

    getUsers: (state) => (ids) => {
      const usersByUserIds = [state.departmentUsersByUserId,state.shopUsersByUserId,state.individualUsersByUserId]
      return ids.map(id => usersByUserIds.map(userByUserId => userByUserId[id]))
        .reduce((user, val) => user.concat(val), [])
        .filter(user => user)
    }
  },

  mutations: {
    setConversionLogs(state, conversionLogs) {
      // 編集用の「問い合わせ番号」をセットする
      for (const i in conversionLogs) {
        conversionLogs[i].attributes.edit_relation_code = conversionLogs[i].attributes.relation_code
      }
      conversionLogs.forEach(conversionLog => {
        const revisits = conversionLog.attributes.revisit_histories.data
        conversionLog.attributes.vue_revisit_selects = revisits.map(revisit => {
          return {
            text: revisit.attributes.created,
            value: revisit
          }
        })
      })
      state.conversionLogs = conversionLogs
    },

    setEveryRoleUsers(state, data) {
      state.activeDepartmentUsers = data.department_users.data.filter(departmentUser => departmentUser.attributes.is_active)
      data.department_users.data.forEach(departmentUser => { state.departmentUsersByUserId[departmentUser.id] = departmentUser })
      let activeShopUsers = {}
      data.shop_users.data.filter(shopUser => shopUser.attributes.is_active).forEach(shopUser => {
        activeShopUsers[shopUser.attributes.affiliation_dept]
          ? activeShopUsers[shopUser.attributes.affiliation_dept].push(shopUser)
          : activeShopUsers[shopUser.attributes.affiliation_dept] = [shopUser]
      })
      state.activeShopUsers = activeShopUsers
      data.shop_users.data.forEach(shopUser => { state.shopUsersByUserId[shopUser.id] = shopUser })

      let activeIndividualUsers = {}
      data.individual_users.data.filter(individualUser => individualUser.attributes.is_active).forEach(individualUser => {
        activeIndividualUsers[individualUser.attributes.affiliation_shop]
          ? activeIndividualUsers[individualUser.attributes.affiliation_shop].push(individualUser)
          : activeIndividualUsers[individualUser.attributes.affiliation_shop] = [individualUser]
      })
      state.activeIndividualUsers = activeIndividualUsers
      data.individual_users.data.forEach(individualUser => { state.individualUsersByUserId[individualUser.id] = individualUser })
    },

    clearConversionLogs(state) {
      state.conversionLogs = []
    },

    clearLowLevelAffiliationData(state, params) {
      if (params.type == 'department') {
        state.conversionLogs[params.index].attributes.shop = null
      }
      state.conversionLogs[params.index].attributes.individuals = []
    },

    setCompanyRoles(state, companyRoles) {
      state.companyRoles = companyRoles
    },

    setCompanyRoleNamesByRoleCode(state, companyRoleNamesByRoleCode) {
      state.companyRoleNamesByRoleCode = companyRoleNamesByRoleCode
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },

    setSearchConditionRelationDateFrom(state, relationDateFrom) {
      state.searchConditions.relationDateFrom = relationDateFrom
    },

    setSearchConditionRelationDateTo(state, relationDateTo) {
      state.searchConditions.relationDateTo = relationDateTo
    },

    setDefaultSearchConditionRelationDateFrom(state, relationDateFrom) {
      state.searchConditions.defaultRelationDateFrom = relationDateFrom
    },

    setDefaultSearchConditionRelationDateTo(state, relationDateTo) {
      state.searchConditions.defaultRelationDateTo = relationDateTo
    },

    clearAffiliationUsers(state) {
      state.conversionLogs =  []
      state.activeDepartmentUsers =  []
      state.departmentUsersByUserId = {}
      state.activeShopUsers = {}
      state.shopUsersByUserId = {}
      state.activeIndividualUsers = {}
    },

    changeLoading(state, loading) {
      state.loading = loading
    },

    setDomainGroups(state, domainGroups) {
      state.domainGroups = domainGroups
    }
  },

  actions: {
    async init({commit}) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
    },

    async fetch({ state, commit }) {
      const params = {
        relation_codes: state.searchConditions.relation_codes,
        relation_date_from: state.searchConditions.relationDateFrom,
        relation_date_to: state.searchConditions.relationDateTo,
        department: state.searchConditions.department,
        shop: state.searchConditions.shop,
        individual: state.searchConditions.individual,
        include_delete_revisit: state.searchConditions.includeDeleteRevisit,
        beacon_user_mail: state.searchConditions.beacon_user_mail,
        beacon_id: state.searchConditions.beacon_id,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      state.loading = true
      await api.get('conversion_logs', { params: params }).then(function (response) {
        const data = response.data
        commit('setConversionLogs', data.conversion_logs.data)
        commit('setTotalPages', data.conversion_logs.links.total_pages)
        commit('setCompanyRoles', data.company_roles.data)
        commit('setDomainGroups', data.domain_groups)
        commit('setCompanyRoleNamesByRoleCode', data.company_roles.links.role_names_by_role_code)
        commit('setDefaultSearchConditionRelationDateFrom', data.search_condition.relation_date_from)
        commit('setDefaultSearchConditionRelationDateTo', data.search_condition.relation_date_to)
      }).catch(err => {
        return err.response
      })
      state.loading = false
    },

    async update(state, urlParams) {
      return await api.patch('conversion_logs/' + urlParams.conversion_log_id, {params: urlParams}).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async delete(state, urlParams) {
      return await api.delete('conversion_logs/' + urlParams.conversion_log_id).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async fetchEveryRoleUsers({ commit }) {
      await api.get('users/every_role').then(response => {
        commit('setEveryRoleUsers', response.data)
      }).catch(err => {
        return err.response
      })
    },

    async updateConversionLogAffiliations({ state }, index) {
      const options = {
        params: {
          department: state.conversionLogs[index].attributes.department,
          shop: state.conversionLogs[index].attributes.shop,
          individuals: state.conversionLogs[index].attributes.individuals,
        },
      }

      return await api.patch('conversion_logs/' + state.conversionLogs[index].attributes.id + '/update_conversion_log_affiliations/', options).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async csvDownload({ state }) {
      const params = {
        relation_codes: state.searchConditions.relation_codes,
        relation_date_from: state.searchConditions.relationDateFrom,
        relation_date_to: state.searchConditions.relationDateTo,
        department: state.searchConditions.department,
        shop: state.searchConditions.shop,
        individual: state.searchConditions.individual,
        include_delete_revisit: state.searchConditions.includeDeleteRevisit,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])

      return await api.post('conversion_logs/csv_download', { responseType: 'blob', params: params }).then(response => {
        return response
      }).catch(error => error.response)
    },

    async updateRelationCode(state, urlParams) {
      return await api.patch('conversion_logs/' + urlParams.conversion_log_id + '/update_relation_code/', { params: urlParams.params }).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
    },

    async csvUpload(state, file) {
      const formData = new FormData()
      formData.append('csvfile', file)

      return await api.post('conversion_logs/csv_upload', { params: formData }).then(response => {
        // TODO: 一覧を取得し直す
        return response
      }).catch(err => err.response)
    },
  },
}
