<template lang='pug'>
v-data-table(:items="conversionLogs" class="elevation-1", hide-actions)
  template(v-slot:headers="props")
    tr.lighten-5(:class='baseColor')
      th.body-2.font-weight-bold お問い合わせ日時
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon.pl-2.tooltip-icon(v-on="on") help_outline
          span.body-2
            | 問い合わせがあった日時です。
      th.body-2.font-weight-bold お問い合わせ番号
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon.pl-2.tooltip-icon(v-on="on") help_outline
          span.body-2
            | お客様を識別する情報です。事前設定が必要です。
      th.body-2.font-weight-bold お問い合わせ対象
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon.pl-2.tooltip-icon(v-on="on") help_outline
          span.body-2
            | 問い合わせの対象です。事前設定が必要です。
      th.body-2.font-weight-bold 利用デバイス
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon.pl-2.tooltip-icon(v-on="on") help_outline
          span.body-2
            | どのデバイスで問い合わせがあったのか表記しています。
  template(v-slot:items="props")
    tr
      td {{ conversionLogsCreated }}
      td {{ conversionLogsRelationCode }}
      td {{ conversionLogsItemCode }}
      td {{ userCookie.attributes.device }}
</template>

<script>

export default {
  name: 'TheInquiry',

  props: {
    storeModule: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: ''
    },
  },
  computed: {
    conversionLogs: function() {
      return [this.$store.state[this.storeModule].conversionLog]
    },
    userCookie: function() {
      return this.$store.state[this.storeModule].userCookie
    },
    conversionLogsRelationCode: function() {
      return this.$store.getters[this.storeModule + '/conversionLogsRelationCode']
    },
    conversionLogsItemCode: function() {
      return this.$store.getters[this.storeModule + '/conversionLogsItemCode']
    },
    conversionLogsCreated: function() {
      return this.$store.getters[this.storeModule + '/conversionLogsCreated']
    },
  },
}
</script>

<style lang='scss' scoped>
.v-datatable th,
.v-datatable td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tooltip-icon {
  vertical-align: bottom;
}
</style>
