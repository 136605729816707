<template lang='pug'>
v-layout.column
  v-layout(row wrap xs12 class="mb-1")
    v-flex(text-xs-left)
      h2 Conversion Log
  v-layout(row wrap xs12 class="mb-4")
    v-flex(xs12 v-if='!loading')
      v-data-table(:items="[getConversionlog]", :headers='headers' :loading="loading", class="elevation-4", hide-actions)
        template(v-slot:items="props")
          tr
            td ID
            td {{ props.item.data.id }}
          tr
            td 件名
            td {{ props.item.data.attributes.email_subject }}
          tr
            td メール本文
            td.line {{ props.item.data.attributes.email_body }}
          tr
            td Div Code
            td {{ props.item.data.attributes.div_code }}
          tr
            td User Code
            td {{ props.item.data.attributes.user_code }}
          tr
            td Item Code
            td {{ props.item.data.attributes.item_code }}
          tr
            td 作成日時
            td {{ props.item.data.attributes.created }}
          tr
            td 会社
            td
              router-link(:to="{ name: 'show_company' }")
                | {{ props.item.relation.company[0].attributes.company_name }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShowConversionlog',
  data() {
    return {
      headers: [
        {
          text: '項目',
          sortable: false
        },
        {
          text: '値',
          sortable: false,
        },
      ]
    }
  },

  computed: {
    ...mapGetters('admin_conversion_log', [
      'getConversionlog',
    ]),

    loading: {
      get() {
        return this.$store.state.admin_conversion_log.loading
      },
      set (value) {
        this.$store.commit('admin_conversion_log/changeLoading', value)
      }
    }
  },

  created: function () {
    let urlParams = {
      company_id: this.$route.params.company_id,
      conversion_log_id: this.$route.params.conversion_log_id
    }
    this.loading = true
    this.show(urlParams).then(() => {
      this.loading = false
    })
  },

  beforeDestroy: function() {
    this.$store.commit('admin_conversion_log/clearConversionlog')
  },

  methods: {
    ...mapActions('admin_conversion_log', [
      'show',
    ]),
  },

}
</script>

<style lang='scss' scoped>
.line {
  white-space: pre;
}
</style>
