<template lang='pug'>
tr(class='blue lighten-5')
  th(
    v-for="(header, idx) in headerProps.headers",
    :key="header.text")
    v-text-field(v-if="header.rankingName" v-model="getNameidx(idx - 3).attributes.name" :label="header.text")
    template(v-else)
      | {{ header.text }}
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'AnalysisSetting',
  components: {
  },
  mixins: [],
  props: {
    headerProps: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('auto_ranking_preference', [
      'getNameidx',
    ]),
    user: function () {
      return this.$store.state.auth.user
    },
  },

  async created() {
  },


  methods: {
    ...mapActions('auto_ranking_preference', [
    ]),
    ...mapMutations('auto_ranking_preference', [
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
