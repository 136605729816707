<template lang='pug'>
v-navigation-drawer(v-model='showDrawr', absolute='', temporary='', app='')
  v-list.pt-0(dense='')
    v-divider
    v-list-tile(v-for='item in linkItems' :key='item.title' v-if='item.show' :to="{ name: item.link }" item.show active-class='')
      v-list-tile-action
        v-icon {{ item.icon }}
      v-list-tile-content
        v-list-tile-title {{ item.title }}
    v-list-group(no-action value=false v-if="linkConfigCounts.length > 0")
      template(v-slot:activator)
        v-list-tile
          v-list-tile-action
            v-icon settings
          v-list-tile-content
            | 設定
      v-list-tile(v-for="(config, i) in linkConfigItems"  :key="i"  :to="{ name: config.link }" v-if='config.show' active-class='' )
        v-list-tile-title
          |  {{ config.title }}
        v-list-tile-action
          v-icon {{ config.icon }}
</template>

<script>
import PrefMixin from '../libs/PrefMixin'
export default {
  name: 'App',
  mixins: [PrefMixin],
  data () {
    return {
      drawer: null,
    }
  },
  computed: {
    showDrawr: {
      get () {
        return this.$store.state.apojaws.showDrawr
      },
      set (value) {
        this.$store.commit('apojaws/changeShowDrawr', value)
      }
    },
  },
}
</script>


<style lang='scss' scoped>
</style>
