<template lang='pug'>
v-layout.column
  h3.headline
    | お客様情報
  v-card.mb-5.pr-3.pl-3
    v-card-text
      TheInquiry.mb-5.mt-3(:storeModule="this.storeModule" :baseColor='baseColor')
      TheUserSession.mb-3(:storeModule="this.storeModule" :baseColor='baseColor')
</template>

<script>
import TheInquiry from './TheInquiry'
import TheUserSession from './TheUserSession'

export default {
  name: 'TheCustomer',

  components: {
    TheInquiry,
    TheUserSession,
  },
  props: {
    storeModule: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
