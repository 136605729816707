<template lang='pug'>
v-layout.column
  v-layout.mb-3(row wrap)
    v-flex(text-xs-left)
      h2.headline コメント一覧
  v-layout.mb-3(row wrap)
    v-flex(text-xs-left)
      v-layout(row xs12)
        v-flex(xs3 pr-3)
          v-menu(v-model='menuSearchDate' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model="searchConditions.date" label='年月日を入力' prepend-icon='event' readonly='' v-on="on")
            v-date-picker(v-model='searchConditions.date', @input='menuSearchDate = false', locale='ja')
        v-flex(xs3)
          v-menu(v-model='menuSearchTime' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model="searchConditions.time" label='時間を入力' readonly='' v-on="on")
            v-time-picker(v-model='searchConditions.time', @input='menuSearchTime = false', locale='ja')
        v-flex(xs3)
          v-btn(color="primary" @click="submitPreview") 表示確認
            v-dialog(v-model='displayDialog' fullscreen hide-overlay transition="dialog-bottom-transition" :loading="loading")
              v-card(v-if='displayDialog')
                v-toolbar(dark fixed).primary
                  v-btn(icon dark @click="displayDialog = false")
                    v-icon close
                  v-toolbar-title(dark) 表示確認
                img.dialog-site-img(src='~notice-preview.png' @click="displayDialog = false")
                TheNotice(v-if='displayDialog' stateTarget='admin_notice')
    v-flex(text-xs-right)
      router-link.btn-link(:to="{ name: 'new_notice' }")
        v-btn(color="primary") コメント新規追加
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-data-table(:items="notices", :headers="headers", :loading="loading", class="elevation-4", :total-items="noticesSize", hide-actions)
        template(v-slot:headers="props")
          tr
            th(v-for="header in props.headers",
               :key="header.text",
               :class="['column', header.sortable ? 'sortable' : '', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']",
               )
             | {{ header.text }}
        template(v-slot:items="props")
          td {{ props.item.attributes.order }}
          td
            p(v-html="openSetting(props.index)" style="white-space: pre-line;")
          td 
            v-img(:src="props.item.attributes.vue_file_full_path")
          td
            p(v-html="props.item.attributes.message")
          td
            router-link.btn-link(:to="{name: 'edit_notice', params: {notice_id: props.item.id}}")
              v-btn(class="mr-3")
                v-icon edit
                | 編集
            v-btn(v-if="!props.item.attributes.stop_flag" @click="submitChangeStop(props.item)" :loading="loading")
              v-icon lock
              | 非公開
            v-btn(v-else @click="submitChangeStop(props.item)" :loading="loading")
              v-icon lock_open
              | 公開
            v-btn(@click="submitDestroy(props.item)" :loading="loading")
              v-icon delete_forever
              | 削除
  ThePagination(:totalPages='totalPages' :loading='loading' @changePage="changePage")
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'
import TheNotice from '../../components/TheNotice'
import ThePagination from '../../components/ThePagination'

export default {
  name: 'Notices',
  components: {
    TheNotice,
    ThePagination
  },
  mixins: [ResponseMixin],
  data() {
    return {
      displayDialog: false,
      menuSearchDate: false,
      menuSearchTime: false,
      headers: [
        {
          text: '表示順',
          value: 'order',
          descending: 'asc',
          sortable: false,
        },
        {
          text: '公開設定',
          sortable: false,
        },
        {
          text: '公開画像',
          value: 'uploadfile_full_path',
          sortable: false,
        },
        {
          text: 'メッセージ',
          value: 'message',
          sortable: false,
        },
        {
          text: 'アクション',
          sortable: false,
        },

      ]
    }
  },

  computed: {
    ...mapGetters('admin_notice', [
      'noticesSize',
      'openSetting',
      'getInitUrlQuery',
    ]),
    ...mapState(['admin_notice', 'route']),

    notices: function() {
      return this.admin_notice.notices
    },

    searchConditions: {
      get() {
        return this.admin_notice.searchConditions
      },
      set(value) {
        this.$store.commit('admin_notice/setSearchCondition', value)
      }
    },
    searchConditionsForms: function() {
      return [this.admin_notice.searchConditions.date, this.admin_notice.searchConditions.time]
    },

    totalPages: function(){
      return this.admin_notice.totalPages
    },

    pagination: function(){
      return this.route.pagination
    },

    loading: {
      get () {
        return this.$store.state.admin_notice.loading
      },
      set (value) {
        this.$store.commit('admin_notice/changeLoading', value)
      }
    },
  },

  watch: {
    searchConditionsForms() {
      if (!this.searchConditionsForms.every(form => form)) {
        return
      }
      this.setSearchDateTime(this.searchConditions.date + ' ' + this.searchConditions.time)
    }
  },

  async created() {
    await this.initUrlQuery(this.getInitUrlQuery)
  },

  async mounted() {
    await this.init()
    await this.fetch()
  },

  beforeDestroy: function() {
  },

  methods: {
    ...mapActions('admin_notice', [
      'init',
      'fetch',
      'destroy',
      'changeStop',
      'preview',
    ]),

    ...mapMutations('admin_notice', [
      'setSearchDateTime'
    ]),

    changePage() {
      this.fetch()
    },

    async submitDestroy(notice) {
      if(!confirm('本当に削除しますか？')) {
        return
      }
      const response = await this.destroy(notice)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を削除しました。', color: 'info' })
    },

    async submitChangeStop(notice) {
      const response = await this.changeStop(notice)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を更新しました。', color: 'info' })
    },

    async submitPreview() {
      await this.preview()
      this.displayDialog = true
    }
  },
}
</script>

<style lang='scss' scoped>
.dialog-site-img {
  cursor: pointer;
  margin-top: 64px;
}
</style>
