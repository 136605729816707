<template lang='pug'>
div
  //- ログインページの場合はヘッダー要素を表示しない
  div(v-if="$route.path !== '/login'")
    toolbar
  v-content
    v-container.fluid
      router-view
      TheAdminLink
</template>

<script>
import toolbar from '../../components/TheToolbar'
import TheAdminLink from '../../components/TheAdminLink'

export default {
  name: 'AdminIndex',

  components: {
    toolbar,
    TheAdminLink
  },
}
</script>

<style lang='scss' scoped>
</style>
