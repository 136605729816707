export default {
  namespaced: true,

  state: {
    menu: true,
    fullScreen: false,
    cardClass: 'small-message'
  },

  mutations: {
    setMenu(state, menu) {
      state.menu = menu
    },
    setFullScreen(state, fullScreen) {
      state.fullScreen = fullScreen
    },
    setCardClass(state, cardClass) {
      state.cardClass = cardClass
    }
  }
}
