<template lang='pug'>
v-layout.column
  v-form(ref='form', v-model='valid' lazy-validation='', @submit.prevent="submit")
    v-layout(row wrap class='mb-1')
      v-flex(name-xs-left)
        h2 権限設定 {{ getCompanyName }} 様
      v-flex(text-xs-right)
        v-btn(color='primary', @click='submit', :disabled='!valid || loading', :loading='loading', type='submit')
          v-icon save
          | 現在の設定を保存する
    v-layout(row wrap class='mb-2')
      v-flex(xs12)
        v-data-table(:items="roleTypes", :headers="companyRoles", :loading="loading", class="elevation-4", hide-actions)
          template(v-slot:headers="props")
            tr
              th 権限名
              th(width='160') 一括
              th(v-for="header in props.headers" :key="header.attributes.id" :class="header.attributes.is_having_user ? 'red lighten-5' : ''")
                v-text-field(v-model='header.attributes.role_name', name='role_name', :label='getTargetRoleCode(header.attributes.role_id)', type='text', :rules='roleNameRules')
          template(v-slot:items="props")
            tr
              td {{ props.item.name }}
              td
                v-overflow-btn(
                  :items="[{ text: 'off' }, { text: 'on' }]",
                  @change='companyRoleRowAllChange(props.item.key, props.index)',
                  v-model='props.item.rowAllChangeSelected'
                )
              td(v-for="i in getCompanyRolesSize" :class="companyRoles[i - 1].attributes.is_having_user ? 'red lighten-5' : ''")
                v-switch(v-model='companyRoles[i - 1].attributes[props.item.key]', label='off/on')
    v-layout(row wrap)
      v-flex(text-xs-right)
        v-btn(color='primary', @click='submit', :disabled='!valid || loading', :loading='loading', type='submit')
          v-icon save
          | 現在の設定を保存する
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CompanyRoles',
  data: function () {
    return {
      valid: true,
      roleNameRules: [
        v => (v.length <= 255) || '255文字以内で入力してください。',
      ],
      roleTypes: [
        {
          name: 'CVメール受信',
          key: 'conversion_notification_mail',
        },
        {
          name: 'CV削除',
          key: 'conversion_deletable',
        },
        {
          name: '割り振り通知受信メール',
          key: 'assignment_notification_mail',
        },
        {
          name: '再来訪メール受信',
          key: 'revisit_notification_mail',
        },
        {
          name: '担当者変更権限',
          key: 'responsible_change',
        },
        {
          name: 'コネクト',
          key: 'connect',
        },
        {
          name: 'コネクト　削除',
          key: 'connect_deletable',
        },
        {
          name: '解析機能　閲覧',
          key: 'analytics_view',
        },
        {
          name: '解析機能　設定ページ',
          key: 'analytics_config',
        },
        {
          name: '自動メール生成　設定',
          key: 'auto_mail_config',
        },
        {
          name: '自動メール生成　利用',
          key: 'auto_mail_usage',
        },
        {
          name: 'アナリティクスグラフ機能',
          key: 'analytics_graph',
        },
        {
          name: '電話コンバージョン機能',
          key: 'phone_conversion',
        },
        {
          name: 'ランキング設定',
          key: 'ranking_config',
        },
        {
          name: '自動ランキング設定',
          key: 'auto_ranking',
        },
        {
          name: '自動ランキング閲覧',
          key: 'auto_ranking_view',
        },
        {
          name: 'ホットサイン設定',
          key: 'hot_sign',
        },
        {
          name: '問い合わせ番号編集',
          key: 'contact_number_edit',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('company_role', [
      'getCompanyRoles',
      'getCompanyRolesSize',
      'getTargetRoleCode',
      'getCompanyName',
    ]),

    companyRoles: {
      get () {
        return this.$store.state.company_role.companyRoles
      },
      set (value) {
        this.$store.commit('company_role/setCompanyRoles', value)
      }
    },

    roles: function () {
      return this.$store.state.company_role.roles
    },

    company: function () {
      return this.$store.state.company_role.company
    },

    loading: function () {
      return this.$store.state.company_role.loading
    },
  },

  mounted: function () {
    this.fetch({ company_id: this.$route.params.company_id })
  },

  beforeDestroy: function() {
    this.clearCompanyRoles()
  },

  methods: {
    ...mapActions('company_role', [
      'fetch',
      'save',
    ]),

    ...mapMutations('company_role', [
      'rowAllChange',
      'clearCompanyRoles',
    ]),

    companyRoleRowAllChange(targetKey, roleTypeIndex) {
      const value = this.roleTypes[roleTypeIndex].rowAllChangeSelected === 'on' ? true : false
      this.rowAllChange({ targetKey: targetKey, value: value })
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return
      }

      const response = await this.save({ company_id: this.$route.params.company_id })
      if (response.status == 200) {
        this.$store.dispatch('flash/showMessage', {message: '権限設定を保存しました。', color: 'info'})
      } else if (response.status == 500) {
        return
      } else {
        this.$store.dispatch('flash/showMessage', {message: '権限設定の保存に失敗しました。', color: 'error'})
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
