<template lang='pug'>
v-dialog(v-if="dialog" v-model="dialog" width="700")
  v-card
    v-card-title(class="title cyan lighten-2" primary-title)
      |「
      | {{ conversion.attributes.relation_code }}
      | 」様へのコネクトID付URLを発行します。
    v-divider
    v-card-text()
      | URLを入力し「URL発行」ボタンをクリックして下さい
      | 下記で始まるURL以外は設定できません。
      br
      span(v-for="(domainGroup, idx) in domainGroups" class="indigo--text" :key="idx")
        |「
        | {{ domainGroup }}
        | 」
        br
      v-flex(xs12)
        v-form(v-model="urlValid" lazy-validation=true)
          v-layout(v-for="(url, idx) in this.urls" row xs12 class="mb-3" :key="idx")
            v-flex(xs2)
              v-subheader(class='label')
                | URL {{ idx + 1 }}
            v-flex(xs9)
              v-text-field(v-model='urls[idx]' placeholder='（例）http://www.follow-up.jp?test=param1' :rules='urlRules')
    v-divider
    v-expand-x-transition
      v-card-text(v-if="showUrlResult" class='title' transition="slide-x-transition")
        | 結果:
        span(class='body-2') コネクトID付URLは以下の通りです。コピー後メール本文に貼り付けて下さい。
        v-flex(xs12)
          v-form(v-model="urlValid" lazy-validation=true)
            v-layout(v-for="(url, idx) in makeUrls" row xs12 class="mb-3" :key="idx")
              v-flex(xs2)
                v-subheader(class='label')
                  | URL {{ idx + 1 }}
              v-flex(xs9)
                v-text-field(v-model='makeUrls[idx]')
    v-card-actions
      v-spacer
      v-btn(color="" @click="submitClose")
       | 閉じる
      v-btn(color="primary" @click="submitMakeUrl" :disabled="!urlValid")
       | URL発行
</template>

<script>
export default {
  name: 'MailTracking',
  data() {
    return {
      conversion: {},
      domainGroups:[],
      urls: [],
      makeUrls: [],
      urlValid: true,
      dialog: false,
      showUrlResult: false,
      urlRules: [
        v => (!v || /http(s)?:\/\/.+/.test(v)) || 'URLの形式で入力してください。',
        v => {
          if (!v) return true
          const parser = document.createElement('a')
          parser.href = v
          const domain = parser.hostname
          const find = this.domainGroups.find((domainGroup) => {
            return domain.replace('www.', '') == domainGroup.replace('www.', '')
          })
          return find != undefined || '設定されているURLで始まるもの以外は入力できません。'
        }
      ],
    }
  },
  methods: {
    async showDialog({conversion: conversion, domainGroups: domainGroups}) {
      this.urls = ['', '', '']
      this.conversion = conversion
      this.domainGroups = domainGroups
      this.showUrlResult = false
      this.dialog = true
    },
    submitMakeUrl() {
      this.makeUrls = []
      this.showUrlResult = true
      this.urls.forEach((url) => {
        if (!url) {
          this.makeUrls.push('')
          return
        }
        const parser = document.createElement('a')
        parser.href = url
        if (parser.hostname == '')  {
          this.makeUrls.push('')
          return
        }
        let urlParam = ''
        if (parser.search) {
          urlParam = parser.search + '&mtb=' + this.conversion.attributes.beacon_id
        } else {
          urlParam = '?mtb=' + this.conversion.attributes.beacon_id
        }
        let x = parser.pathname
        x = x.indexOf('/') == 0 ? x : '/' + x
        const makeUrl = parser.protocol + '//' + parser.hostname + x + urlParam + parser.hash
        this.makeUrls.push(makeUrl)
      })
    },
    async submitClose() {
      this.dialog = false
    },
  },
}
</script>

<style lang='scss' scoped>
.title { 
  color: black;
}
</style>
