<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex.text-xs-left
      h2.headline 自動メール設定
    v-flex.pt-2(xs12)
      v-card
        v-tabs(fixed-tabs='')
          v-tab(v-for='category in sumCategories', :key='category.id', :to="{ name: 'auto_mail_category', params: { category_id: category.id }}" v-if="isAnalyticsConfig")
            | {{ category.name }}
          v-tab(v-for='tab in fixed_tabs', :key='tab.link', :to='{ name: tab.link }' v-if="tab.show")
            | {{ tab.name }}
        router-view
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'AutoMail',
  mixins: [ResponseMixin],

  data: function () {
    return {
    }
  },

  computed: {
    ...mapGetters('auto_mail', [
    ]),
    ...mapGetters('auth', [
      'isAnalyticsConfig'
    ]),

    sumCategories: function () {
      return this.$store.state.auto_mail.sumCategories
    },
    fixed_tabs: function() {
      return  [
        {
          name: 'カスタマイズ',
          link: 'auto_mail_custom',
          show: this.isAnalyticsConfig,
        },
        {
          name: 'デフォルトメール',
          link: 'auto_mail_default',
          show: true,
        },
      ]
    }
  },

  async created() {
    // アナリティクス設定権限以外はデフォルトメールのみ操作可能
    if (!this.isAnalyticsConfig) {
      this.$router.push({ name: 'auto_mail_default' })
    }
    await this.callFetch()
  },

  mounted: function () {
  },

  methods: {
    ...mapActions('auto_mail', [
      'fetch',
    ]),

    ...mapMutations('auto_mail', [
    ]),

    async callFetch() {
      if (!this.isAnalyticsConfig ) {
        return
      }
      const response = await this.fetch()
      if (!this.chkResponse(response)) {
        return
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
