import api from 'api'
import store from '../store'

const DEF_RANKING_URL_FORM = {
  id: null,
  search_url: '',
  name: '',
}

export default {
  namespaced: true,

  state: {
    rankingPreference: {},
    rankingUrls: [],
    delRankingUrls: [],
    rankingNames: [],
    // key: rank_url_id + ':' + rank_name_id  value: rankingAvairable でメモ化
    rankingAvarablesH: {},
    loading: true,
    avableKey: function(urlId, nameId) {
      return urlId + ':' + nameId
    },
  },

  getters: {
    getPreference(state) {
      return state.rankingPreference
    },
    getUrls(state) {
      return state.rankingUrls
    },
    getAvableH(state) {
      return state.rankingAvarablesH
    },
    getAvableKey: (state) => (urlId, nameId) => {
      return state.avableKey(urlId, nameId)
    },
    getAvable: (state) => (urlId, nameId) => {
      const key = state.avableKey(urlId, nameId)
      return state.rankingAvarablesH[key]
    },
    getNames(state) {
      return state.rankingNames
    },
    getNameidx: (state) => (idx) => {
      return state.rankingNames[idx]
    },
  },

  mutations: {
    setResponseData(state, {response: response, commit: commit }) {
      const data = response.data.data
      const included = response.data.included
      commit('setRankingPreferences', data)
      commit('setRankingNames', data.attributes.ranking_names.data)
      commit('setRankingUrls', included.filter(e => e.type == 'ranking_url'))
      commit('setRankingAvablesH', included.filter(e => e.type == 'ranking_avable'))
    },
    setRankingPreferences(state, rankingPreference) {
      state.rankingPreference = rankingPreference
    },
    setRankingNames(state, rankingNames) {
      state.rankingNames = rankingNames
    },
    setRankingUrls(state, rankingUrls) {
      state.rankingUrls = []
      state.delRankingUrls = []
      rankingUrls.forEach(rankingUrl => {
        rankingUrl.attributes.frontId = rankingUrl.attributes.id
        state.rankingUrls.push(rankingUrl)
      })
    },
    setRankingAvablesH(state, rankingAvables) {
      state.rankingAvarablesH = {}
      rankingAvables.forEach(rankingAvable => {
        const urlId = rankingAvable.attributes.ranking_url_id
        const nameId = rankingAvable.attributes.ranking_name_id
        const key = state.avableKey(urlId, nameId)
        state.rankingAvarablesH[key] = {
          type: 'ranking_avable',
          attributes: {
            id: rankingAvable.attributes.id,
            ranking_name_id: nameId,
            front_ranking_url_id: urlId
          }
        }
      })
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    changeAvable(state, {urlId: urlId, nameId: nameId}) {
      const key = state.avableKey(urlId, nameId)
      const targetAvable = state.rankingAvarablesH[key]
      if (targetAvable) {
        delete state.rankingAvarablesH[key]
      } else {
        state.rankingAvarablesH[key] = {
          type: 'ranking_avable',
          attributes: {
            ranking_name_id: nameId,
            front_ranking_url_id: urlId
          }
        }
      }
    },
    addRankiingUrl(state) {
      // ID採番
      const rankingUrlLen = state.rankingUrls.length
      let id = null
      if (rankingUrlLen > 0) {
        id = Math.max(...state.rankingUrls.map(e => e.attributes.frontId)) + 1
      } else {
        id = 1
      }
      const rankingUrl = {
        attributes:  JSON.parse(JSON.stringify(DEF_RANKING_URL_FORM))
      }
      rankingUrl.attributes.frontId = id
      state.rankingUrls.push(rankingUrl)
    },

    delRankingUrl(state, {urlId: urlId}) {
      state.rankingNames.forEach(rankingName => {
        const key = state.avableKey(rankingName.attributes.frontId, urlId)
        delete state.rankingAvarablesH[key]
      })
      const delRankingUrl = state.rankingUrls.find(e => e.attributes.frontId == urlId)
      state.delRankingUrls.push(delRankingUrl)
      state.rankingUrls = state.rankingUrls.filter(e => e.attributes.frontId != urlId)
      state.rankingNames.forEach(rankingName => {
        const key = state.avableKey(delRankingUrl.attributes.frontId, rankingName.attributes.id)
        delete state.rankingAvarablesH[key]
      })
    },
  },

  actions: {
    async fetch({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
      }

      state.loading = true
      const response =  await api.get('ranking_preference/edit', { params: params }).then(response => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async update({state, commit}) {
      state.loading = true
      const rankingNamesAttributes= {}
      state.rankingNames.forEach((value, key) => {
        rankingNamesAttributes[key] = {
          id: value.attributes.id,
          name: value.attributes.name
        }
      })
      const rankingUrlsAttributes = {}
      state.rankingUrls.forEach(value => {
        rankingUrlsAttributes[Object.keys(rankingUrlsAttributes).length] = {
          id: value.attributes.id,
          front_id: value.attributes.frontId,
          name: value.attributes.name,
          search_url: value.attributes.search_url,
          _destroy: 0,
        }
      })
      state.delRankingUrls.forEach((value) => {
        if (!value.attributes.id) {
          return
        }
        rankingUrlsAttributes[Object.keys(rankingUrlsAttributes).length] = {
          id: value.attributes.id,
          front_id: value.attributes.frontId,
          name: value.attributes.name,
          search_url: value.attributes.search_url,
          _destroy: 1,
        }
      })
      const rankingAvableAttributes = {}
      Object.keys(state.rankingAvarablesH).forEach((key, idx) => {
        const rankingAvable = state.rankingAvarablesH[key]
        rankingAvableAttributes[idx] = {
          id: rankingAvable.attributes.id,
          front_ranking_url_id: rankingAvable.attributes.front_ranking_url_id,
          ranking_name_id: rankingAvable.attributes.ranking_name_id
        }
      })
      const params = {
        company_id: store.state.auth.user.company_id,
        ranking_preference: {
          attributes: {
            filter_duration_visiting_time_max: state.rankingPreference.attributes.filter_duration_visiting_time_max,
            filter_duration_visiting_time_min: state.rankingPreference.attributes.filter_duration_visiting_time_min,
            ranking_names_attributes: rankingNamesAttributes,
            ranking_urls_attributes: rankingUrlsAttributes,
          }
        },
        ranking_avables: rankingAvableAttributes
      }
      const response =  await api.patch('ranking_preference' , { params: params }).then((response) => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async delete({state, commit}) {
      state.loading = true
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      const response =  await api.delete('ranking_preference', { params: params }).then((response) => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvDownload({state}) {
      state.loading = true
      const params = {
        company_id: store.state.auth.user.company_id,
      }
      const response =  await api.get('ranking_preference/csv', { params: params, responseType: 'blob' }).then(response => {
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    async csvUpload({state, commit}, file) {
      state.loading = true
      var formData = new FormData()
      formData.append('csvfile', file)

      const response =  await api.post('ranking_preference/csv', { params: formData }).then(response => {
        commit('setResponseData', {response: response, commit: commit})
        return response
      }).catch(err => err.response)
      state.loading = false
      return response
    },

    addUrl({commit}) {
      commit('addRankiingUrl')
    },

    delUrl({commit}, {urlId: urlId}) {
      commit('delRankingUrl', {urlId: urlId})
    },
  },
}
