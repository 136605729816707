<template lang='pug'>
v-layout.column(v-if="revisits.length > 1")
  h3.headline
    | 複数ブラウザでの閲覧
  v-card.mb-5.pr-3.pl-3
    v-card-text
      v-flex(text-xs-left)
        | コネクトID付のURLが複数のブラウザ・人にクリックされました。
        br
        | ブラウザのクッキーID毎にアポジョーズをご確認ください。
      v-data-table(:items="revisits" class="elevation-1" hide-actions)
        template(v-slot:headers="props")
          tr.lighten-5(:class='baseColor')
            th.body-2.font-weight-bold
              | クッキーID
            th.body-2.font-weight-bold
              | デバイス
            th.body-2.font-weight-bold
              | 最終訪問日時
            th.body-2.font-weight-bold
              | 操作
        template(v-slot:items="props")
          tr
            td {{ props.item.attributes.user_cookie_id }}
            td {{ revisitUserCookie(props.index).attributes.device }}
            td {{ displayLastAccesseAt(props.item.attributes.last_accessed_at) }}
            td 
              template(v-if="revisit.id == props.item.id")
                | {{ oldConnectMessage(props.item) }} 表示中
              template(v-else)
                router-link.btn-link(:to="{name: revisitLinkName, params: { revisit_id: props.item.id}}")
                  v-btn(:loading="loading") {{ oldConnectMessage(props.item) }}表示
</template>

<script>
export default {
  name: 'TheCustomer',

  props: {
    storeModule: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: ''
    },
  },
  computed: {
    revisit: function() {
      return this.$store.state[this.storeModule].revisit
    },
    revisits: function() {
      return this.$store.state[this.storeModule].revisits
    },
    revisitUserCookie: function() {
      return this.$store.getters[this.storeModule + '/revisitUserCookie']
    },
    loading: {
      get() {
        return this.$store.state[this.storeModule].loading
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setLoading', value)
      },
    },
    revisitLinkName: function() {
      switch(this.storeModule) {
      case 'conversion_logs_revisit' :
        return 'conversion_logs_revisit'
      default :
        return ''
      }
    },
  },
  watch: {
    '$route' (to) {
      this.$emit('callFetch', to.params.revisit_id)
    }
  },
  methods: {
    oldConnectMessage(revisit) {
      if (revisit.attributes.conversion_created) {
        return ''
      } else {
        return '（旧）'
      }
    },
    displayLastAccesseAt(lastAccesseAt) {
      return lastAccesseAt ? lastAccesseAt : '--'
    }
  }
}
</script>

<style lang='scss' scoped>
.v-datatable th,
.v-datatable td {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>
