<template lang='pug'>
v-layout.column
  template(v-if='mailFormats.length == 0')
    v-card
      v-card-text
        | 表示できるカテゴリが存在しません。
        br
        router-link.text-link(:to="{ name: 'analysis_setting' }")  「解析設定」
        | からカテゴリの設定をお願いします。

  template(v-else)
    v-layout(row wrap class='mb-1')
      v-flex(text-xs-right)
        v-btn(color='primary', @click='submit', :disabled='loading', :loading='loading', type='submit')
          v-icon save
          | 登録・保存する

    v-expansion-panel(v-model='panel', popout, expand)
      v-expansion-panel-content
        template(v-slot:header='')
          div カテゴリを選んでください
        v-card
          v-card-text
            v-layout(row xs12)
              template(v-for='mailFormat in mailFormats')
                v-flex(xs2)
                  v-checkbox(v-model='mailFormat.checked', :label='mailFormat.category_small_name')

    template(v-for='(mailFormat, index) in mailFormats')
      v-card.mb-1(v-if='mailFormat.checked')
        v-card-text
          v-layout(row xs12)
            v-flex(text-xs-left)
              v-subheader.card-title {{ mailFormat.category_small_name }}
            v-flex(text-xs-right)
              v-btn(color='accent', @click='submitDelete(index)')
                v-icon clear
                | 削除
          v-textarea(v-model='mailFormat.mail_body', label='メールテキスト', outline='')
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResponseMixin from '../../libs/ResponseMixin'

export default {
  name: 'AutoMailCategory',
  mixins: [ResponseMixin],

  data: function () {
    return {
      panel: [true],
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.init(to.params.category_id)
    next()
  },

  computed: {
    ...mapGetters('auto_mail', [
    ]),
    ...mapGetters('auth', [
      'isAnalyticsConfig'
    ]),

    mailFormats: {
      get () {
        return this.$store.state.auto_mail.mailFormats
      },
      set (value) {
        this.$store.commit('auto_mail/changMailFormats', value)
      }
    },

    loading: {
      get () {
        return this.$store.state.auto_mail.loading
      },
      set (value) {
        this.$store.commit('auto_mail/changeLoading', value)
      }
    },
  },

  created() {
    // アナリティクス設定権限以外はデフォルトメールのみ操作可能
    if (!this.isAnalyticsConfig) {
      this.$router.push({ name: 'auto_mail_default' })
      return
    }
    this.init(this.$route.params.category_id)
  },

  mounted: function () {
  },

  beforeDestroy: function() {
  },

  methods: {
    ...mapActions('auto_mail', [
      'fetchMailFormats',
      'saveMailFormats',
      'deleteMailFormat',
    ]),

    ...mapMutations('auto_mail', [
      'changMailFormats',
    ]),

    async init(categoryId) {
      const params = { category_id: categoryId }
      const response = await this.fetchMailFormats(params)
      if (!this.chkResponse(response)) {
        return
      }

      // メール本文が登録されているデータが一つでもある場合、
      // アコーディオンをオープン状態にしておく。
      const mailFormat = this.mailFormats.find(mailFormat => mailFormat.checked)
      this.panel = mailFormat !== void 0 ? [true] : [false]
    },

    async submit() {
      const params = { category_id: this.$route.params.category_id }
      const res = await this.saveMailFormats(params)
      if (!this.chkResponse(res)) {
        return
      }
      this.$store.dispatch('flash/showMessage', {message: '自動メール設定を保存しました。', color: 'info'})
    },

    async submitDelete(index) {
      const mailFormat = this.mailFormats[index]

      if (!window.confirm(this.getConfirmMessage(mailFormat, '本当に削除しますか？'))) {
        return
      }

      this.loading = true
      const params = {
        category_id: this.$route.params.category_id,
        mail_format_id: mailFormat.id,
      }
      const res = await this.deleteMailFormat(params)
      if (!this.chkResponse(res)) {
        return
      }
      this.$store.dispatch('flash/showMessage', {message: 'メールフォーマットを削除しました。', color: 'info'})
      this.init(this.$route.params.category_id)
      this.loading = false
    },

    getConfirmMessage(mailFormat, message) {
      return `カテゴリ名：${mailFormat.category_small_name}

${message}`
    },
  },
}
</script>

<style lang='scss' scoped>
.card-title {
  font-size: 18px;
}

.text-link {
  text-decoration: none;
}
</style>
