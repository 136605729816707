// ログイン認証に関わるストア
import api from 'api'

export default {
  namespaced: true,

  state: {
    user: {},
    company: {},
    companyRole: {},
    companyRoles: [],
    companyRoleNamesByRoleCode: {},
    notice: {},
    loggedIn: false,
  },

  getters: {
    isAnalyticsConfig(state) {
      return state.companyRole.attributes.analytics_config
    },
    useableRanking(state) {
      return state.companyRole.attributes.analytics_view && state.company.attributes.has_ranking
    },
    getRoleNameByRoleCode: (state) => (role_code) => {
      return state.companyRoleNamesByRoleCode[role_code]
    },
  },

  mutations: {
    login(state, data) {
      state.user = data.data.attributes
      state.company = data.included.find(data => data.type === 'company')
      state.companyRole = data.included.find(data => data.type === 'company_role')
      state.loggedIn = true
    },
    setNotice(state, notice) {
      state.notice = notice
    },

    logout(state) {
      state.user = {}
      state.company = {}
      state.companyRole = {}
      state.notice = {}
      state.loggedIn = false
    },

    setCompanyRoles(state, companyRoles) {
      state.companyRoles = companyRoles
      state.companyRoleNamesByRoleCode = {}
      companyRoles.forEach(companyRole => {
        state.companyRoleNamesByRoleCode[companyRole.attributes.role_code] = companyRole.attributes.role_name
      })
    },
  },

  actions: {
    async login({ commit }, params) {
      return await api.post('users/login', { params: params, notAuth: true }).then(response => {
        commit('login', response.data)
        return response
      }).catch(err => {
        return err.response
      })
    },

    // ログイン情報再取得
    // 画面遷移するたびに呼ぶ
    async reacquire({ commit }) {
      await api.get('users/reacquire').then(response => {
        // 再取得できなかった場合は強制ログアウト
        if (response.data.user.data) {
          commit('login', response.data.user) 
          response.data.notice ? commit('setNotice', response.data.notice.data) : void(0)
          response.data.company_roles ? commit('setCompanyRoles', response.data.company_roles.data) : void(0)
        } else {
          commit('logout')
        }
      }).catch(err => {
        return err.response
      })
    },
  },
}
