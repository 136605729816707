<template lang='pug'>
tr(:class='hotSignRatioColor(conversionLogProp.item)')
  td
    template(v-if="!conversionLogProp.item.attributes.is_beacon")
      router-link(:to="{ name: 'conversion_logs_report', params: { conversion_log_id: conversionLogProp.item.id } }")
        | {{ conversionLogProp.item.attributes.created }}
    template(v-else)
      | {{ conversionLogProp.item.attributes.created }}
  td
    | {{ conversionLogProp.item.attributes.relation_code }}
    v-menu(v-if='roleAtr.contact_number_edit' v-model='conversionLogProp.item.attributes.is_edit_relation_code' :close-on-content-click='false' :nudge-width='200' offset-x)
      template(v-slot:activator='{ on }')
        v-icon(v-on='on', color='primary' small) edit
      v-card
        v-list
          v-list-tile
            v-list-tile-action
              v-text-field(:placeholder='conversionLogProp.item.attributes.edit_relation_code' @change='changeRelationCode($event)' name='relation_code' type='text')
        v-card-actions
          v-btn(flat @click='conversionLogProp.item.attributes.is_edit_relation_code = false') キャンセル
          v-btn(flat color='primary' @click='submitUpdateRelationCode()' :disabled='loading' :loading='loading') 保存
  td {{ conversionLogProp.item.attributes.session_count }}
  td
    v-select.body-2(
      v-if='roleAtr.responsible_change && roleAtr.level >= 4'
      :value='department'
      @change="saveChangeDep($event)"
      :items='getDepartmentUsers(conversionLogProp.item.attributes.department)'
      item-text='attributes.name'
      item-value='attributes.id'
      :no-data-text="'該当の' + getRoleNameByRoleCode('dep') + 'が存在しません'"
      single-line
      hide-selected
    )
    template(v-else) {{ getDepartmentName(conversionLogProp.item.attributes.department) }}
  td
    v-tooltip(v-if='roleAtr.responsible_change && roleAtr.level >= 3' top :disabled='conversionLogProp.item.attributes.department != null')
      template(v-slot:activator='{ on }')
        div(v-on='on')
          v-select.body-2(
            :value='shop'
            :items='getShopUsersAssociateInUpperLevel(conversionLogProp.item.attributes.department, conversionLogProp.item.attributes.shop)'
            @change="saveChangeShop($event)"
            item-text='attributes.name'
            item-value='attributes.id'
            :no-data-text="'該当の' + getRoleNameByRoleCode('shop') + 'が存在しません'"
            single-line
            hide-selected
          )
      span {{ getRoleNameByRoleCode('dep') }}を選択しない限り、プルダウンにデータが表示されません
    template(v-else) {{ getShopName(conversionLogProp.item.attributes.shop) }}
  td
    v-tooltip(v-if='roleAtr.responsible_change' top :disabled='canSelectIndividualDialog()')
      template(v-slot:activator='{ on }')
        div(v-on='on')
          v-select(
            v-model='conversionLogProp.item.attributes.individuals'
            :items='searchIndividualUsers'
            item-text='attributes.name'
            item-value='attributes.id'
            :disabled='loading'
            multiple chips
            readonly
            @focus='focusIndividuals()'
          )
            template(v-slot:selection='data')
              v-chip.chip--select-multi(:selected='data.selected' color='indigo lighten-5')
                | {{ data.item.attributes.name }}
      TheIndividualUserDialog(
        ref='individualUserDialog'
        :individualUsers='searchIndividualUsers'
        :assignIndividualUsers='conversionLogProp.item.attributes.individuals'
        :roleName='getRoleNameByRoleCode("ind")'
        @saveChangeIndividuals='saveChangeIndividuals'
        )
      span {{ getRoleNameByRoleCode('shop') }}を選択しない限り、{{ getRoleNameByRoleCode('ind') }}の割り当てはできません。
    template(v-else)
      v-chip(v-for='individual in conversionLogProp.item.attributes.individuals' :key='individual' color='indigo lighten-5') {{ getIndividualName(individual) }}
  td.border-left
    v-select.caption(
      v-if="conversionLogProp.item.attributes.vue_revisit_selects.length > 0",
      :items="conversionLogProp.item.attributes.vue_revisit_selects",
      item-text='text'
      item-value='value'
      :value='conversionLogProp.item.attributes.vue_revisit_selects[0]'
      class='select',
      @change='changeSelectRevisitHistory(conversionLogProp.item.id, $event)',
      solo=''
      hide-details
    )
    div(v-else) 再来訪はありません
  td
    v-combobox(
      :value="selectStopRevisit(conversionLogProp.item)"
      :items="stop_revisit_items"
      @change="submitChangeStopRevisit($event)"
      hide-details
      hide-selected
      :loading='loading'
    )
      template(v-slot:selection)
        v-layout
          v-flex(text-xs-right)
            v-icon(v-if='conversionLogProp.item.attributes.stop_revisit == 0' small color='green' :disabled='loading') fiber_manual_record
            v-icon(v-else-if='!conversionLogProp.item.attributes.is_delete' small color='red' :disabled='loading') pause
            v-icon(v-else small color='red' :disabled='loading') clear

  template(v-if="roleAtr.analytics_view")
    td.border-left
      template(v-if="!noVisitedBeacon(conversionLogProp.item)")
        router-link(:to="{name: 'conversion_logs_analysis', params: { conversion_log_id: conversionLogProp.item.id } }")
          v-icon(small color='green') search
      template(v-else)
        v-icon(small color='green') remove
    td
      v-icon(v-if='conversionLogProp.item.attributes.appoint' small color='black' @click="submitReverseTargetData('appoint')" :disabled='loading') radio_button_checked
      v-icon(v-else small color='black' @click="submitReverseTargetData('appoint')" :disabled='loading') remove
    td
      v-icon(v-if='conversionLogProp.item.attributes.contract' small color='black' @click="submitReverseTargetData('contract')" :disabled='loading') radio_button_checked
      v-icon(v-else small color='black' @click="submitReverseTargetData('contract')" :disabled='loading') remove

  template(v-if="roleAtr.connect")
    template(v-if="conversionLogProp.item.attributes.is_beacon")
      td.border-left.ellipsis-text(@click='submitEllipsisText($event)')
        | {{ conversionLogProp.item.attributes.beacon_id }}
      td.ellipsis-text(@click='submitEllipsisText($event)')
        | {{ conversionLogProp.item.attributes.beacon_user_mail }}
      td
        v-btn(@click="submitMakeConnectUrl()") URL発行
        TheMakeConnectUrl(ref='make_connect_url')
    template(v-else)
      td.border-left
      td
      td
        v-icon(small color='black') remove
  template(v-if="isOtherOperatable")
    td
      v-combobox(
        :value="operationSelectedValue"
        :items="operationItems"
        @change="operateConversionLog($event)"
        hide-details
        hide-selected
        :loading='loading'
      )
        template(v-slot:selection)
          v-layout
            v-flex(text-xs-right)
              v-icon(small color='grey' :disabled='loading') fiber_manual_record
</template>
<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import ColorMixin from '../libs/ColorMixin'
import TheIndividualUserDialog from './TheIndividualUsersDialog'
import TheMakeConnectUrl from './TheMakeConnectUrl'

const OPERATION_ITEMS = [
  {
    text: '',
    value: 'none',
  },
  {
    text: '削除',
    value: 'delete',
  }
]

export default {
  name: 'TheConversionLogTd',
  components: {
    TheIndividualUserDialog,
    TheMakeConnectUrl
  },
  mixins: [ColorMixin],
  props: {
    conversionLogProp: {
      type: Object,
      default: function() {
        return {}
      }
    },
  },
  data: function () {
    return {
      displayDialogAssign: false,
      beforeDepartment: '',
      beforeShop: '',
      beforeIndividuals: [],
      searchWord: '',
      operationSelectedValue: OPERATION_ITEMS[0]
    }
  },

  watch: {
    operationSelectedValue: function(v) {
      // NOTE: 少し無理やりだが、コンボボックスが操作後も常に未選択状態となるようにする
      if(v !== this.operationItems[0]) {
        setTimeout(() => { this.operationSelectedValue = this.operationItems[0] }, 0)
      }
    }
  },

  computed: {
    ...mapState(['conversion_log']),
    ...mapGetters('conversion_log', [
      'getDepartmentUsers',
      'getDepartmentName',
      'getShopName',
      'getIndividualName',
      'getShopUsersAssociateInUpperLevel',
      'getIndividualUsersAssociateInUpperLevel',
      'getRoleNameByRoleCode',
      'getUsers',
    ]),
    searchIndividualUsers: function() {
      const shop = this.conversionLogProp.item.attributes.shop
      const individuals = this.conversionLogProp.item.attributes.individuals
      return this.getIndividualUsersAssociateInUpperLevel(shop, individuals)
    },
    shop: {
      get () {
        return this.conversionLogProp.item.attributes.shop
      },
      set(value) {
        this.conversionLogProp.item.attributes.shop = value
      }
    },
    department: {
      get () {
        return this.conversionLogProp.item.attributes.department
      },
      set(value) {
        this.conversionLogProp.item.attributes.department = value
      }
    },
    pagination: {
      get () {
        return this.$store.state.conversion_log.pagination
      },
      set (value) {
        this.$store.commit('conversion_log/setPagination', value)
      }
    },
    loading: {
      get () {
        return this.$store.state.conversion_log.loading
      },
      set (value) {
        this.$store.commit('conversion_log/changeLoading', value)
      }
    },
    roleAtr: function() {
      return this.$store.state.auth.companyRole.attributes
    },
    stop_revisit_items: function() {
      if (this.roleAtr.is_master) {
        return [
          {
            text: 'ON',
            value: 0,
          },
          {
            text: 'OFF',
            value: 1,
          },
          {
            text: '非表示',
            value: 2,
          },
        ]
      } else {
        return [
          {
            text: 'ON',
            value: 0,
          },
          {
            text: 'OFF',
            value: 1,
          },
        ]
      }
    },
    isOtherOperatable: function() {
      return this.roleAtr.conversion_deletable || (this.conversionLogProp.item.attributes.is_beacon && this.roleAtr.connect_deletable)
    },
    operationItems: function() {
      return OPERATION_ITEMS
    },
    domainGroups: function() {
      return this.conversion_log.domainGroups
    }
  },

  methods: {
    ...mapActions('conversion_log', [
      'fetch',
      'updateConversionLogAffiliations',
      'updateRelationCode',
      'update',
      'delete',
    ]),

    ...mapMutations('conversion_log', [
      'clearLowLevelAffiliationData',
    ]),

    async submitUpdateRelationCode() {
      this.loading = true
      const conversionLog = this.conversionLogProp.item.attributes
      const urlParams = {
        conversion_log_id: conversionLog.id,
        params: { relation_code: conversionLog.edit_relation_code }
      }
      const res = await this.updateRelationCode(urlParams)

      if (res.status == 200) {
        conversionLog.relation_code = conversionLog.edit_relation_code
        conversionLog.is_edit_relation_code = false
        this.$store.dispatch('flash/showMessage', {message: '更新に成功しました。', color: 'info'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error'} )
      }
      this.loading = false
    },

    async submitReverseTargetData(change_target) {
      this.loading = true
      const conversionLog = this.conversionLogProp.item.attributes
      const changeValue = conversionLog[change_target] == 1 ? 0 : 1
      await this.changeTargetData(conversionLog, {[change_target]: changeValue})
      this.loading = false
    },

    async submitChangeStopRevisit(stop_revisit_item) {
      this.loading = true
      const conversionLog = this.conversionLogProp.item.attributes
      const change_target_params = {}
      if (stop_revisit_item.text ==  '非表示') {
        change_target_params['stop_revisit'] = true
        change_target_params['is_delete'] = true
      } else {
        change_target_params['stop_revisit'] = Number(stop_revisit_item.value)
        change_target_params['is_delete'] = false
      }
      await this.changeTargetData(conversionLog, change_target_params)
      if (stop_revisit_item.text ==  '非表示') {
        await this.fetch()
      }
      this.loading = false
    },

    async changeTargetData(conversionLog, change_target_params) {
      const urlParams = {
        conversion_log_id: conversionLog.id,
        conversion_log: change_target_params,
      }
      const res = await this.update(urlParams)

      if (res.status == 200) {
        Object.keys(change_target_params).forEach((key) => {
          conversionLog[key] = change_target_params[key]
        })
        this.$store.dispatch('flash/showMessage', {message: '更新に成功しました。', color: 'info'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
      }
    },

    async changeAffiliations(params) {
      const userNames = this.getUsers(params.user_ids).map(user => '・' + user.attributes.name + '\r\n').join('')
      const confirmMessag = userNames
        ? userNames + ' に割り振ります。よろしいですか？'
        : '割り振りを無しで更新します。よろしいですか？'
      if (!window.confirm(confirmMessag)) {
        return false
      }

      if (params.type != 'individuals') {
        params.index = this.conversionLogProp.index
        this.clearLowLevelAffiliationData(params)
      }
      return await this.submitUpdateConversionLogAffiliations()
    },

    async submitUpdateConversionLogAffiliations() {
      this.loading = true
      const res = await this.updateConversionLogAffiliations(this.conversionLogProp.index)
      this.loading = false

      if (res.status == 200) {
        this.$store.dispatch('flash/showMessage', {message: '更新に成功しました。', color: 'info'})
        // 変更者が担当の場合は、自分自身を担当から外せるので一覧を更新する
        if (this.roleAtr.role_code == 'ind') {
          this.fetch()
        }
        return true
      } else if (res.status == 500) {
        return false
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error'} )
        return false
      }
    },

    async operateConversionLog(selected_item) {
      if (selected_item.value == 'delete') {
        if (!window.confirm('本当に削除しますか？')) {
          this.operationSelectedValue = 'none'
          return
        }

        this.loading = true
        const conversionLog = this.conversionLogProp.item.attributes

        const res = await this.delete({conversion_log_id: conversionLog.id})
        if (res.status == 200) {
          this.$store.dispatch('flash/showMessage', {message: '削除に成功しました。', color: 'info'})
          await this.fetch()
        } else if (res.status != 500) {
          this.$store.dispatch('flash/showMessage', {message: res.data.errors.join('\r\n'), color: 'error'})
        }
      }
      this.loading = false
    },

    changeSelectRevisitHistory(conversion_log_id, revisit) {
      this.$router.push({ name: 'conversion_logs_revisit', params: {conversion_log_id: conversion_log_id, revisit_id: revisit.id} })
    },

    noVisitedBeacon(conversionLog) {
      return (conversionLog.attributes.is_beacon && conversionLog.attributes.session_count <= 0)
    },
    selectStopRevisit(conversionLog) {
      return this.stop_revisit_items.find((e) => e.text == conversionLog.attributes.stop_revisit_text)
    },
    changeRelationCode(value) {
      this.conversionLogProp.item.attributes.edit_relation_code = value
    },
    focusIndividuals() {
      if (!this.canSelectIndividualDialog()) { return }
      this.$refs.individualUserDialog.displayDialog()
    },
    async saveChangeIndividuals(individuals, beforeIndividuals) {
      this.conversionLogProp.item.attributes.individuals = individuals
      if (await this.changeAffiliations({type: 'individuals', user_ids: individuals})) {
        this.$refs.individualUserDialog.hiddenDialog()
      } else {
        this.conversionLogProp.item.attributes.individuals = beforeIndividuals
      }
    },
    async saveChangeShop(changeShop) {
      this.setBeforeResponsible()
      this.shop = changeShop
      if (!await this.changeAffiliations({type: 'shop', user_ids: [this.shop] })) { this.backResuponsibleBefore() }
    },
    async saveChangeDep(changeDep) {
      this.setBeforeResponsible()
      this.department = changeDep
      if (!await this.changeAffiliations({type: 'department', user_ids: [this.department] })) { this.backResuponsibleBefore() }
    },
    canSelectIndividualDialog() {
      return this.conversionLogProp.item.attributes.shop != null
    },
    setBeforeResponsible() {
      this.beforeShop = JSON.parse(JSON.stringify(this.conversionLogProp.item.attributes.shop))
      this.beforeDepartment = JSON.parse(JSON.stringify(this.conversionLogProp.item.attributes.department))
    },
    async backResuponsibleBefore() {
      this.shop = await JSON.parse(JSON.stringify(this.beforeShop))
      this.department = await JSON.parse(JSON.stringify(this.beforeDepartment))
    },
    submitMakeConnectUrl() {
      this.$refs.make_connect_url.showDialog({conversion: this.conversionLogProp.item, domainGroups: this.domainGroups})
    }
  }
}
</script>

<style lang='scss' scoped>
.select {
  font-size: 13px
}

.v-datatable td {
  text-align: center;
  vertical-align: center;
}

.border-left {
  border-left: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>
