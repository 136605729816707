<template lang='pug'>
v-layout
  div(v-scroll='scroll')
    v-fab-transition
      v-btn.red.floating(bottom fixed left dark v-show="this.position > 300" icon @click='$vuetify.goTo(0, options)')
        v-icon keyboard_arrow_up
</template>

<script>

export default {
  name: 'TheFloatingButton',
  data() {
    return {
      position: 0,
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
    }
  },
  computed: {
    options () {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    }
  },
  methods:{
    scroll() {
      this.position = window.scrollY
    },
    submitScrol() {
    }
  },
}
</script>

<style lang='scss' scoped>
button.floating {
  width: 56px;
  height: 56px;
}
</style>
