// アポジョーズアプリ全体に関わるストア
export default {
  namespaced: true,

  state: {
    showDrawr: false,
    isAdmin: false,
    isPublicPage: false,
    isAnalysisPage: false,
  },

  mutations: {
    changeShowDrawr (state, value) {
      state.showDrawr = value
    },

    changeIsAdmin (state, path) {
      state.isAdmin = /\/admin\//.test(path)
    },

    changeIsPublicPage (state, value) {
      state.isPublicPage = value
    },

    changeIsAnalysisPage (state, value) {
      state.isAnalysisPage = value
    },
  },

  getters: {
    getAuthStoreKey(state) {
      return state.isAdmin ? 'admin_auth' : 'auth'
    },

    getLoginRouteName(state) {
      return state.isAdmin ? 'admin_login' : 'login'
    },
  },
}
