<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2 新規ユーザー登録 {{ getCompanyName }} 様

  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      TheUserForm(action='new')
</template>

<script>
import TheUserForm from '../../components/TheUserForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NewUser',

  components: {
    TheUserForm
  },

  computed: {
    ...mapGetters('user', [
      'getCompanyName',
    ]),
  },

  async created() {
    const params = { company_id: this.$route.params.company_id }
    await this.fetchCompany(params)
  },

  methods: {
    ...mapActions('user', [
      'fetchCompany',
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
