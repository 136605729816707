<template lang='pug'>
div
  //- ログインページの場合はヘッダー要素を表示しない
  div(v-if="$route.path !== '/login'")
    drawer
    toolbar
  v-content
    v-container.fluid
      router-view
  TheNotice(stateTarget='auth')
  TheFloatingButton
</template>

<script>
import drawer from '../components/TheDrawer'
import toolbar from '../components/TheToolbar'
import TheNotice from '../components/TheNotice'
import TheFloatingButton from '../components/TheFloatingButton'

export default {
  name: 'Index',

  components: {
    drawer,
    toolbar,
    TheNotice,
    TheFloatingButton,
  },
}
</script>

<style lang='scss' scoped>
</style>
