import api from 'api'
export default {
  namespaced: true,

  state: {
    newses: [],
  },

  mutations: {
    addNews(state, news) {
      state.newses.push(news)
    },

    clearNewses(state) {
      state.newses = []
    }
  },

  actions: {
    async getWordpress({commit}) {
      const response = await api.get('news/call_apis', {}).then((response) => {
        response.data.forEach(data => commit('addNews', data))
        return response
      }).catch(err => {
        return err.response
      })
      return response
    },

    clearNewses({commit}) {
      commit('clearNewses')
    }
  },
}
