<template lang='pug'>
v-layout.column
  v-layout(row wrap class='mb-1')
    v-flex(text-xs-left)
      h2.headline コネクト
  v-layout(row wrap class='mb-2')
    v-flex(xs12)
      v-tabs(v-model="active" color='cyan darken-2' dark slider-color="yellow accent-4")
        v-tab
          | 個別に発行する
        v-tab
          | 一括インポート
        v-tab-item
          v-card
            v-card-text
              mail_tracking_form
        v-tab-item
          v-card
            v-card-text
              mail_tracking_import
</template>
<script>
import mail_tracking_form from '../components/TheMailTrackingForm'
import mail_tracking_import from '../components/TheMailTrackingImport'

export default {
  name: 'MailTracking',
  components: {
    mail_tracking_form,
    mail_tracking_import
  },
  data() {
    return {
      active: null,
    }
  },
  mounted: function() {
    this.active = 0
  },
}
</script>

<style lang='scss' scoped>
</style>
