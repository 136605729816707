import api from 'api'

export default {
  namespaced: true,

  state: {
    accesslogs: [],
    loading: false,
    totalPages: 0,
    searchCondition: {
      filterColumn: '',
      filterValue: ''
    }
  },

  getters: {
    getAccesslogs(state) {
      return state.accesslogs
    },
    getAccesslogsSize(state) {
      return state.accesslogs.length
    },
    getTotalPages(state) {
      return state.totalPages
    },

    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
      }
    }
  },

  mutations: {
    setAccesslogs(state, accesslogs) {
      state.accesslogs = accesslogs
    },
    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },
    setFilterColumn(state, filterColumn) {
      state.searchCondition.filterColumn = filterColumn
    },
    setFilterValue(state, filterValue) {
      state.searchCondition.filterValue = filterValue
    },
    changeLoading(state, loading) {
      state.loading = loading
    },

    chgStopRevisit(state, idx) {
      let access = state.accesslogs[idx]
      access.attributes.stop_revisit = !access.attributes.stop_revisit
    },
    clearAccesslogs(state) {
      state.accesslogs = []
    },
    clearAccesslog(state) {
      state.accesslog = {}
    },
    clearSearchCondition(state) {
      state.searchCondition = {
        filterColumn: '',
        filterValue: ''
      }
    }
  },

  actions: {
    fetch({commit, state}, urlParams) {
      const params = {
        company_id: urlParams.company_id,
        filter_column: state.searchCondition.filterColumn,
        filter_value: state.searchCondition.filterValue
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      let options = {
        params: params
      }
      return new Promise((resolve) => {
        api.get('/admin/companies/' + urlParams.company_id + '/access_logs',  options)
          .then(response => {
            let data = response.data
            commit('setAccesslogs', data.data)
            commit('setTotalPages', data.links.total_pages)
            resolve()
          })
      })
    },
  },
}
