<template lang='pug'>
v-layout.column
  h3.headline.mb-1
    | ページ閲覧の流れ
  v-toolbar(color='grey lighten-4')
    v-tabs.tabs(v-model='tab' slider-color='primary' color='grey lighten-4' show-arrows)
      v-tab
        v-icon.pr-2 insert_photo
        | 詳細
      v-tab
        v-icon.pr-2 ballot
        | 一覧
      v-tab.mr-5
        v-icon.pr-2 list_alt
        | 要約

      v-flex(xs1).mr-3
        v-select(
          v-model='rowsPerPage'
          :items='rowsPerPages'
          item-text='text'
          item-value='value'
          label='表示件数'
          hide-details
        )
      v-flex(xs2).mr-3
        v-select(
          v-model='browseTimeFrom'
          :items='browseTimeFroms'
          item-text='text'
          item-value='value'
          label='絞り込み'
          hide-details
        )
      v-flex(xs2).mr-3
        v-select(
          v-model='sort'
          :items='sorts'
          item-text='text'
          item-value='value'
          label='表示順'
          hide-details
        )
      v-btn.primary(round :loading='loading' @click='submitSearch')
        v-icon.pr-2 search
        | 絞り込み
  v-tabs-items.v-toolbar(v-model="tab")
    v-tab-item
      v-card
        v-card-text
          div.mb-2.mt-2
            span.pa-2.mr-2(:class="second30OverColorClass") 30秒以上
            span.pa-2.mr-2(:class="second60OverColorClass") 60秒以上
            span.pa-2.mr-2(:class="second120OverColorClass") 120秒以上
            span.pa-2.mr-2(:class="conversionLogSessionClass") 問合訪問
          TheDetail(:isActiveTab="this.tab == 0" :storeModule="this.storeModule")
          v-layout(row wrap)
            v-flex(text-xs-center)
              v-btn(v-for="per in rowsPerPages" @click="submitPerPage(per)" :class="[rowsPerPage==per.value ? 'primary' : '']" :loading='loading' :key="per.text") {{ per.text }}
    v-tab-item
      v-card
        v-card-text
          div.mb-2.mt-2
            span.pa-2.mr-2(:class="second30OverColorClass") 30秒以上
            span.pa-2.mr-2(:class="second60OverColorClass") 60秒以上
            span.pa-2.mr-2(:class="second120OverColorClass") 120秒以上
            span.pa-2.mr-2(:class="conversionLogSessionClass") 問合訪問
          TheList(:isActiveTab="this.tab == 1" :storeModule="this.storeModule")
          v-layout(row wrap)
            v-flex(text-xs-center)
              v-btn(v-for="per in rowsPerPages" @click="submitPerPage(per)" :class="[rowsPerPage==per.value ? 'primary' : '']" :loading='loading' :key="per.text") {{ per.text }}
    v-tab-item
      v-card
        v-card-text
          div.mb-2.mt-2
            span.pa-2.mr-2(:class="second30OverColorClass") 30秒以上
            span.pa-2.mr-2(:class="second60OverColorClass") 60秒以上
            span.pa-2.mr-2(:class="second120OverColorClass") 120秒以上
            span.pa-2.mr-2(:class="conversionLogSessionClass") 問合訪問
          TheSimple(:isActiveTab="this.tab == 2" :storeModule="this.storeModule")
          v-layout(row wrap)
            v-flex(text-xs-center)
              v-btn(v-for="per in rowsPerPages" @click="submitPerPage(per)" :class="[rowsPerPage==per.value ? 'primary' : '']" :loading='loading' :key="per.text") {{ per.text }}
</template>

<script>
import TheInquiry from './TheInquiry'
import TheUserSession from './TheUserSession'
import TheDetail from './reports/TheDetail'
import TheList from './reports/TheList'
import TheSimple from './reports/TheSimple'
import ColorMixin from '../../libs/ColorMixin'

export default {
  name: 'TheAccessLog',
  components: {
    TheInquiry,
    TheUserSession,
    TheDetail,
    TheList,
    TheSimple,
  },
  mixins: [ColorMixin],
  props: {
    storeModule: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      tab: 0,
      rowsPerPages: [
        { text: '20件表示', value: 20 },
        { text: '50件表示', value: 50 },
        { text: '100件表示', value: 100 },
      ],
      browseTimeFroms: [
        { text: '全て表示', value: -1 },
        { text: '30秒以上のみ表示', value: 30 },
        { text: '1分以上のみ以上', value: 60 },
        { text: '3分以上のみ以上', value: 180 },
        { text: '5分以上のみ以上', value: 300 },
      ],
      sorts: [
        { text: '閲覧日時が古い順', value: 0, },
        { text: '閲覧日時が新しい順', value: 1, },
        { text: '閲覧時間が長い順', value: 2, },
        { text: '閲覧時間が短い順', value: 3, },
      ],
      tabs: [
        { number: 0, text: '詳細', value: 0 },
        { number: 1, text: '一覧', value: 1 },
        { number: 2, text: '要約', value: 2 },
      ]
    }
  },
  computed: {
    rowsPerPage: {
      get() {
        return this.$store.state[this.storeModule].pagination.rowsPerPage
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setRowsPerPage', value)
      }
    },
    searchConditions: {
      get() {
        return this.$store.state[this.storeModule].searchConditions
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setSearchConditions', value)
      }
    },
    browseTimeFrom: {
      get() {
        return this.searchConditions.browseTimeFrom
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setBrowseTimeFrom', value)
      }
    },
    sort: {
      get() {
        return this.searchConditions.sort
      },
      set(value) {
        this.$store.commit(this.storeModule + '/setSort', value)
      }
    },
    loading() {
      return this.$store.state[this.storeModule].loading
    },
    defaultTab: function() {
      if (this.$store.state.apojaws.isAnalysisPage) {
        return this.tabs.find(e => e.text == '要約').value
      } else {
        return this.$store.state.auth.user.report_page_default_view
      }
    },
  },
  created() {
    this.tab = this.tabs.find(e => e.value == this.defaultTab).number
  },
  methods: {
    async submitSearch() {
      await this.changeSearchQuery(this.searchConditions)
      await this.changeRowsPerPage(this.rowsPerPage)
      this.$emit('callFetch')
    },
    async submitPerPage(per) {
      this.rowsPerPage = per.value
      await this.changeRowsPerPage(this.rowsPerPage)
      this.$emit('callFetch')
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
