<script>
export default {
  name: 'ColorMixin',
  data() {
    return {
      second30OverColorClass: 'amber lighten-5',
      second60OverColorClass: 'amber lighten-3',
      second120OverColorClass: 'amber lighten-1',
      secondExcludeOverColorClass: 'grey lighten-2',
      conversionLogSessionClass: 'red lighten-4',

      hotSign05OverClass: 'orange lighten-5',
      hotSign08OverClass: 'orange lighten-4',
    }
  },

  methods: {
    // 閲覧時間によって色を変える。
    // includeBrowseTimeは、アクセス解析集計時間ないに収まっているか
    viewTimeColor(browseTime, includeBrowseTime = true) {
      if (browseTime >= 120) {
        return this.second120OverColorClass
      } else if (browseTime >= 60) {
        return this.second60OverColorClass
      } else if (browseTime >= 30) {
        return this.second30OverColorClass
      } else if (!includeBrowseTime) {
        return this.secondExcludeOverColorClass
      }
    },

    hotSignRatioColor(conversionLog) {
      if (conversionLog.attributes.hot_sign_ratio >= 0.8) {
        return this.hotSign08OverClass
      } else if (conversionLog.attributes.hot_sign_ratio >= 0.5) {
        return this.hotSign05OverClass
      }
    }
  },
}
</script>

