<template lang='pug'>
tr
  td
    v-layout(row wrap)
      v-flex(xs4)
        v-icon(class='mt-4 mr-3' @click='delUrl({urlId: urlProps.item.attributes.frontId})') remove_circle_outline
      v-flex(xs8)
        v-text-field(v-model="urlProps.item.attributes.search_url" :rules='urlRules')
  td
    v-text-field(v-model="urlProps.item.attributes.name")
  template(v-for="rankingName in getNames")
    td
      v-checkbox(:input-value='getAvable(urlProps.item.attributes.frontId, rankingName.attributes.id)' @change='changeAvable({urlId: urlProps.item.attributes.frontId,nameId: rankingName.attributes.id})' hide-details)
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'TheRankingPreferenceTd',
  props: {
    urlProps: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      urlRules: [
        v => !!v || '入力は必須です',
        v => (!v || v.length <= 250) || '250文字以内で入力してください。'
      ],
    }
  },

  computed: {
    ...mapGetters('ranking_preference', [
      'getAvable',
      'getNames',
    ]),
  },

  async created() {
  },

  methods: {
    ...mapActions('ranking_preference', [
      'delUrl',
    ]),
    ...mapMutations('ranking_preference', [
      'changeAvable',
    ]),
  },
}
</script>

<style lang='scss' scoped>
</style>
