<template lang='pug'>
tr
  td
    v-layout(row wrap)
      v-flex(xs4)
        v-icon(class='mt-4 mr-3' @click='submitDelUrl({urlId: sumUrlFieldProps.item.attributes.id})') remove_circle_outline
      v-flex(xs8)
        v-text-field(v-model="sumUrlFieldProps.item.attributes.url" :rules='urlRules')
  td
    v-text-field(v-model="sumUrlFieldProps.item.attributes.page_name" :rules='pageRules')
  td(v-for="category in getSumCategoris")
    v-text-field(v-model="categoryVar({category_id: category.attributes.id, url_id: sumUrlFieldProps.item.attributes.id}).attributes.title")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheAnalysisSettingTd',
  props: {
    sumUrlFieldProps: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      urlRules: [
        v => !!v || '入力は必須です',
        v => (!v || v.length <= 250) || '250文字以内で入力してください。'
      ],
      pageRules: [
        v => !!v || '入力は必須です',
        v => (!v || v.length <= 100) || '100文字以内で入力してください。'
      ],
    }
  },

  computed: {
    ...mapGetters('analysis_setting', [
      'getSumCategoris',
    ]),
    categoryVar: function() {
      return function ({category_id: category_id, url_id: url_id}) {
        return this.sumCategoryVars.find((e) => {
          return e.attributes.category_id == category_id &&
            e.attributes.url_id == url_id
        })
      }
    },
    sumCategoryVars: function () {
      return this.$store.state.analysis_setting.sumCategoryVars
    },
  },

  async created() {
  },

  methods: {
    ...mapActions('analysis_setting', [
      'deleteUrl',
    ]),
    submitDelUrl({urlId: urlId}) {
      this.deleteUrl({urlId: urlId})
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
