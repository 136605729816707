import api from 'api'
import store from '../../store'

export default {
  namespaced: true,

  state: {
    conversionLogs: [],
    hotSigns: [],
    departmentUsersById: {},
    shopUsersById: {},
    individualUsersById: {},
    companyRoles: [],
    companyRoleNamesByRoleCode: {},
    loading: false,
    searchConditions: [],
    totalPages: 0,
  },

  getters: {
    getConversionLogs(state) {
      return state.conversionLogs
    },
    getConversionLogsSize(state) {
      return state.conversionLogs.length
    },
    getHotSigns(state) {
      return state.hotSigns
    },
    getTotalPages(state) {
      return state.totalPages
    },
    getDepartmentName: (state) => (id) => {
      const departmentUser = state.departmentUsersById[id]
      return departmentUser === void 0 ? '' : departmentUser.attributes.name
    },

    getShopName: (state) => (id) => {
      const shopUsers = state.shopUsersById[id]
      return shopUsers === void 0 ? '' : shopUsers.attributes.name
    },

    getIndividualName: (state) => (id) => {
      const individualUsersById = state.individualUsersById[id]
      return individualUsersById === void 0 ? '' : individualUsersById.attributes.name
    },

    getRoleNameByRoleCode: (state) => (role_code) => {
      return state.companyRoleNamesByRoleCode[role_code]
    },

    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          sortBy: 'created',
          descending: true,
        },
        searchConditions: []
      }
    }
  },

  mutations: {
    setCvHotSigns(state, cvHotSigns) {
      cvHotSigns.forEach(cvHotSign => {
        let rankApiResult = {}
        cvHotSign.attributes.value.forEach(rankApiCheck => {
          rankApiResult[rankApiCheck.attributes.name] = rankApiCheck.attributes.checked
        })
        cvHotSign.attributes.rankApiResult = rankApiResult
      })
      state.cvHotSigns = cvHotSigns
    },
    setConversionLogs(state, conversionLogs) {
      conversionLogs.forEach(conversionLog => {
        let rankApiResult = {}
        if (conversionLog.attributes.hot_sign_value) {
          conversionLog.attributes.hot_sign_value.forEach(rankApiCheck => {
            rankApiResult[rankApiCheck.attributes.name] = rankApiCheck.attributes.checked
          })
        }
        conversionLog.attributes.vue_rank_api_result = rankApiResult
      })
      state.conversionLogs = conversionLogs
    },

    clearConversionLogs(state) {
      state.conversionLogs = []
    },
    setEveryRoleUsers(state, data) {
      data.department_users.data.forEach(department_user => {
        state.departmentUsersById[department_user.id] = department_user
      })
      data.shop_users.data.forEach(shopUser => {
        state.shopUsersById[shopUser.id] = shopUser
      })
      data.individual_users.data.forEach(individualUser => {
        state.individualUsersById[individualUser.id] = individualUser
      })
    },

    setHotSigns(state, hotSigns) {
      state.hotSigns = hotSigns
      if (state.searchConditions.length > 0) {
        return
      }
      hotSigns.forEach(hotSign => {
        state.searchConditions.push(
          {
            id: hotSign.attributes.id,
            name: hotSign.attributes.name,
            value: null,
          }
        )
      })
    },

    setCompanyRoles(state, companyRoles) {
      state.companyRoles = companyRoles
    },

    setCompanyRoleNamesByRoleCode(state, companyRoleNamesByRoleCode) {
      state.companyRoleNamesByRoleCode = companyRoleNamesByRoleCode
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    changeLoading(state, loading) {
      state.loading = loading
    },

    clearAffiliationUsers(state) {
      state.departmentUsersById = {}
      state.shopUsersById = {}
      state.individualUsersById = {}
    },
    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },
  },

  actions: {
    async init({ commit }) {
      const searchConditionMap = JSON.parse(JSON.stringify(this.state.route.searchConditions))
      commit('setSearchConditions', Object.keys(searchConditionMap).map((key) => searchConditionMap[key]))
    },
    async fetch({ state, commit }) {
      const params = {
        company_id: store.state.auth.user.company_id,
        searchConditions: state.searchConditions,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      state.loading = true
      const response =  await api.get('conversion_logs/hot_signs', { params: params }).then(response => {
        const data = response.data
        commit('setConversionLogs', data.conversion_logs.data)
        commit('setTotalPages', data.conversion_logs.links.total_pages)
        commit('setHotSigns', data.hot_signs.data)
        commit('setCompanyRoles', data.company_roles.data)
        commit('setCompanyRoleNamesByRoleCode', data.company_roles.links.role_names_by_role_code)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },

    async fetchEveryRoleUsers({commit }) {
      await api.get('users/every_role').then(response => {
        commit('setEveryRoleUsers', response.data)
      }).catch(err => {
        return err.response
      })
    },

    async csv_download({ state }) {
      const params = {
        company_id: store.state.auth.user.company_id,
        searchConditions: state.searchConditions,
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      state.loading = true
      const response =  await api.get('conversion_logs/hot_signs/csvs', { params: params, responseType: 'blob' }).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    }
  },
}
