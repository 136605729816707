<template lang='pug'>
v-container
  v-form(ref='form' v-model='valid' lazy-validastion='' @submit.prevent="submit")
    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.required.label
          | 公開開始日時
      v-flex(xs9)
        v-layout(row) 
          v-flex(xs3 pr-3)
            v-menu(v-model='menuFromDateYMD' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(v-model="notice.attributes.from_datetime_ymd" label='年月日' readonly='' v-on="on" :rules="fromDatetimeymdRules")
              v-date-picker(v-model='notice.attributes.from_datetime_ymd' @input='pickerMenuTo = false' locale='ja')
          v-flex(xs3 pr-3)
            v-menu(v-model='menuFromDateHM' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(v-model="notice.attributes.from_datetime_hm" label='時分' readonly='' v-on="on" required :rules="fromDatetimeymdRules")
              v-time-picker(v-model='notice.attributes.from_datetime_hm' @input='pickerMenuTo = false' locale='ja')

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.required.label
          | 公開終了日時
      v-flex(xs9)
        v-layout(row) 
          v-flex(xs3 pr-3)
            v-menu(v-model='menuToDateYMD' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(v-model="notice.attributes.to_datetime_ymd" label='年月日' readonly='' v-on="on" :rules="toDatetimeymdRules")
              v-date-picker(v-model='notice.attributes.to_datetime_ymd' @input='menuToDateYMD = false' locale='ja')
          v-flex(xs3 pr-3)
            v-menu(v-model='menuToDateHM' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
              template(v-slot:activator='{ on }')
                v-text-field(v-model="notice.attributes.to_datetime_hm" label='時分' readonly='' v-on="on" :rules="toDatetimeymdRules")
              v-time-picker(v-model='notice.attributes.to_datetime_hm' @input='menuFromDateHM = false' locale='ja')

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label
          | 曜日設定
      v-flex(xs9)
        v-layout(row wrap xs12)
          v-checkbox(v-model="notice.attributes.with_monday" label='月')
          v-checkbox(v-model="notice.attributes.with_tuesday" label='火')
          v-checkbox(v-model="notice.attributes.with_wednesday" label='水')
          v-checkbox(v-model="notice.attributes.with_thursday" label='木')
          v-checkbox(v-model="notice.attributes.with_friday" label='金')
          v-checkbox(v-model="notice.attributes.with_saturday" label='土')
          v-checkbox(v-model="notice.attributes.with_sunday" label='日')

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label
          | 毎月第何週目の指定
      v-flex(xs9)
        v-layout(row wrap xs12)
          v-select(v-model="notice.attributes.week_of_month" :items='itemsWeekOfMonth')

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label
          | 毎月何日の指定
      v-flex(xs9)
        v-layout(row wrap xs12)
          v-select(v-model="notice.attributes.day_of_month" :items="itemsDayOfMonth")
          
    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label.required
          | 表示画像
      v-flex(xs9)
        v-layout(row wrap xs12)
          v-btn(@click="$refs.inputUpload.click()" :disabled='loading' :loading='loading') 画像を選択
          input(v-show="false" ref="inputUpload" type="file" @change="submitUpload")
        v-layout(row wrap xs12)
          v-img.uploadedImage(:src="uploadDisplayImage()")

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label.required
          | 表示順
      v-flex(xs9)
        v-layout(row wrap xs12)
          v-text-field(v-model="notice.attributes.order" type="number" :rules="orderRules")

    v-layout.mb-3(row xs12)
      v-flex(xs3)
        v-subheader.label
          | 本文
      v-flex(xs9)
        v-layout(row wrap xs12)
          tinymce(id='tinymce-message' v-model="notice.attributes.message")

    v-layout(row wrap class='mb-5')
      v-flex(offset-xs3)
        v-btn(v-if="action == 'new'" color='info' @click='submitCreate' :loading="loading") 登録
        v-btn(v-else-if="action == 'edit'" color='info' @click='submitUpdate' :loading="loading") 更新
        router-link(:to="{ name: 'notices' }" class='btn-link')
          v-btn コメント一覧に戻る
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import tinymce from 'vue-tinymce-editor'
import ResponseMixin from '../libs/ResponseMixin'

export default {
  name: 'NoticeForm',
  components: {
    tinymce
  },
  mixins: [ResponseMixin],
  props: {
    action: {
      type: String,
      default: ''
    },
    notice: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      menuFromDateYMD: false,
      menuFromDateHM: false,
      menuToDateYMD: false,
      menuToDateHM: false,
      uploadImage: '',
      options: {
        language_url: ''
      },
      fromDatetimeymdRules: [
        v => !!v || '必須入力です',
      ],
      toDatetimeymdRules: [
        v => !!v || '必須入力です',
      ],
      orderRules: [
        v => !!v || '必須入力です',
      ]
    }
  },

  computed: {
    ...mapGetters('admin_notice', [
    ]),
    ...mapState({
    }),
    from_datetimes: function() {
      return [this.notice.attributes.from_datetime_ymd, this.notice.attributes.from_datetime_hm]
    },
    to_datetimes: function() {
      return [this.notice.attributes.to_datetime_ymd, this.notice.attributes.to_datetime_hm]
    },
    itemsWeekOfMonth: function() {
      let items = [...Array(5).keys()].map( e => e + 1 )
      items.unshift('')
      return items
    },
    itemsDayOfMonth: function() {
      let items = [...Array(31).keys()].map( e => e + 1 )
      items.unshift('')
      return items
    },
  },
  watch: {
    from_datetimes() {
      if (this.notice.attributes.from_datetime_ymd && this.notice.attributes.from_datetime_hm) {
        const fromDatetime = this.notice.attributes.from_datetime_ymd + ' ' + this.notice.attributes.from_datetime_hm
        this.setFromDateTime(fromDatetime)
      }
    },
    to_datetimes() {
      if (this.notice.attributes.to_datetime_ymd && this.notice.attributes.to_datetime_hm) {
        const toDatetime = this.notice.attributes.to_datetime_ymd + ' ' + this.notice.attributes.to_datetime_hm
        this.setToDateTime(toDatetime)
      }
    }
  },

  mounted: function () {
  },

  created: function() {
  },

  methods: {
    ...mapActions('admin_notice', [
      'createImage',
      'update',
      'create',
    ]),
    ...mapMutations('admin_notice', [
      'setFromDateTime',
      'setToDateTime',
    ]),
    async submitCreate() {
      if (!this.chkFrontValid()) {
        return
      }
      const response = await this.create()
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を登録しました。', color: 'info' })
      this.$router.push({ name: 'notices' })
    },
    async submitUpdate() {
      if (!this.chkFrontValid()) {
        return
      }
      const response = await this.update(this.$route.params)
      if (!this.chkResponse(response)) {
        return
      }
      this.$store.dispatch('flash/showMessage', { message: '設定を更新しました。', color: 'info' })
      this.$router.push({ name: 'notices' })
    },
    chkFrontValid() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('flash/showMessage', {  message: '入力誤りがあります。', color: 'error lighten-2' })
        return false
      }
      return true
    },
    uploadDisplayImage() {
      return this.notice.attributes.vue_upload_image ? this.notice.attributes.vue_upload_image : this.notice.attributes.vue_file_full_path
    },
    submitUpload(e) {
      const files = e.target.files
      if (files[0] == undefined) {
        return
      }
      this.createImage(files[0])
    },
  },
}
</script>

<style lang='scss' scoped>
.uploadedImage{
  max-width: 250px;
}
</style>
