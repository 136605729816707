var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userSessions)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"mb-2 mt-2"},[_c('span',{staticClass:"pa-2 mr-2",class:_vm.conversionLogSessionClass},[_vm._v("問合訪問")])])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.userSessions,"hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',{staticClass:"lighten-5",class:_vm.baseColor},[_c('th',{staticClass:"body-2 font-weight-bold",attrs:{"width":"10%"}},[_vm._v("訪問回数"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("HPを訪問した回数です。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold",attrs:{"width":"10%"}},[_vm._v("訪問日時"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("HPを訪問した日時です。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold",attrs:{"width":"50%"}},[_vm._v("HP訪問直前のページ"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("どこからHPに訪問したのかURLを表記しています。"),_c('br'),_vm._v("メール内のリンク、お気に入りなどから訪問する場合は「不明」となります。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold",attrs:{"width":"10%"}},[_vm._v("検索キーワード"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("お客様がGoogleやYahoo!で検索してHPを訪れる場合、その検索したキーワードを取得しています。")])])],1),_c('th',{staticClass:"body-2 font-weight-bold",attrs:{"width":"10%"}},[_vm._v("滞在時間"),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-2 tooltip-icon"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',{staticClass:"body-2"},[_vm._v("ホームページに滞在した合計時間です。直帰した場合は「-」が表示されます。")])])],1)])]}},{key:"items",fn:function(props){return [_c('tr',{class:_vm.rowColor(props.item)},[_c('td',[_vm._v(_vm._s((props.index + 1) + '回目'))]),_c('td',[_vm._v(_vm._s(props.item.attributes.created))]),_c('td',[_vm._v(_vm._s(_vm.userSessionRefereUrl(props.index)))]),_c('td',[_vm._v(_vm._s(_vm.userSessionSearchWord(props.index)))]),_c('td',[_vm._v(_vm._s(props.item.attributes.total_view_time))])])]}},(_vm.needFilter())?{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"lighten-4 toggle-btn grey",attrs:{"colspan":"6"},on:{"click":_vm.submitChangeFilter}},[_c('v-layout',{staticClass:"justify-center"},[(_vm.filter)?[_c('v-icon',{staticClass:"pr-3"},[_vm._v("arrow_downward")]),_vm._v("展開する")]:[_c('v-icon',{staticClass:"pr-3"},[_vm._v("arrow_upward")]),_vm._v("折り畳む")]],2)],1)])]},proxy:true}:null],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }