<template lang='pug'>
v-layout.column
  v-layout(row wrap xs12 class="mb-1")
    v-flex(text-xs-left)
      h2.headline 新タグ発行
    v-flex(text-xs-right)
      v-btn(color="primary" @click='submitDownloadJavascrip' :loading='loading || initLoading') JSダウンロード
      v-btn(v-if='!isHomeUrlRedirect' color="primary" @click='submitDownloadHtml' :loading='loading || initLoading') リダイレクトページ用Htmlダウンロード
  v-layout(row wrap class='mb-3' v-if="!initLoading")
    v-flex(xs12)
      v-card(class="elevation-4")
        v-card-text
          v-container.fluid.pr-5.pl-5
            template(v-if='!isHomeUrlRedirect && !company.attributes.storage_redirect_url')
              span.red--text.body-2
                | 会社編集画面からリダイレクトURLを設定してください。
            template(v-else)
              v-layout(row wrap xs12 class='mb-3')
                v-flex(xs12)
                  v-flex(xs12)
                    span(class='title mr-2')
                      | Access Tag
                    li.red--text.body-2
                      | LoadCode: 各ページに一つのみ設置してして下さい(複数の会社が乗合で使用するページでも一つのみ)
                      | HPドメインにJSファイルを配置読みこませる場合は、右上のダウンロードから取得したファイルをドメイン毎に配置し、srcをそのURLパスに書き換えてください。
                    li.red--text.body-2
                      | StorageCode、AccessCode: 複数会社乗合の場合は、会社分設置してください。
                    v-flex(xs12)
                    v-textarea(v-model="accessTagText" solo outline onclick="this.select()" auto-grow)
                v-layout(row wrap xs12 class='mb-3')
              v-layout(row wrap xs12 class='mb-3')
                v-flex(xs12)
                  v-flex(xs12)
                    span(class='title mr-2')
                      | Conversion Tag
                    li.red--text.body-2
                      | コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい(※AccessTag処理完了後に実行できるようにしてください。)
                  v-flex(xs12)
                    v-textarea(v-model="conversionCode" solo outline onclick="this.select()" auto-grow)
              v-layout(v-if='company.attributes.has_tel_conversion' row wrap xs12 class='mb-3')
                v-flex(xs12)
                  v-flex(xs12)
                    span(class='title mr-2')
                      | Tel Conversion Tag
                      span(class="body-2") (class追加用クリック電話コンバージョン用)
                    li.red--text.body-2
                      | buttonタグ等の場合、クリックで電話コンバージョンしたいタグに「class='apojows_tel_conversion'」を設置して下さい
                    li.red--text.body-2
                      | 電話コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい(※AccessTag処理完了後に実行できるようにしてください。)
                  v-flex(xs12)
                    v-textarea(v-model="telConversionBtnCode" solo outline onclick="this.select()" auto-grow)
              v-layout(v-if='company.attributes.has_tel_conversion' row wrap xs12 class='')
                v-flex(xs12)
                  v-flex(xs12)
                    span(class='title mr-2')
                      | Tel Conversion Tag
                      span(class='body-2') (ページアクセス電話コンバージョン用)
                    li.red--text.body-2
                        | 電話コンバージョンタグより上部にアクセスタグを一つだけ設置して下さい(※AccessTag処理完了後に実行できるようにしてください。)
                  v-flex(xs12)
                    v-textarea(v-model="telConversionAccessCode" solo outline onclick="this.select()" auto-grow)
</template>


<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import FileMixin from '../../libs/FileMixin'

export default {
  name: 'AdminCrossDomainTag',
  mixins: [FileMixin],
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('admin_cross_domain_tags', [
      'redirectUrl'
    ]),
    ...mapState(['admin_cross_domain_tags']),
    company: function() {
      return this.admin_cross_domain_tags.company
    },
    loading: function() {
      return this.admin_cross_domain_tags.loading
    },
    initLoading: function() {
      return this.admin_cross_domain_tags.initLoading
    },
    urlHomeRedirect: function() {
      return this.admin_cross_domain_tags.urlHomeRedirect
    },
    isHomeUrlRedirect: function() {
      return this.admin_cross_domain_tags.isHomeUrlRedirect
    },
    urlTrackJs: function() {
      return this.admin_cross_domain_tags.urlTrackJs
    },
    loadCode: function() {
      return `<!-- Load Code -->
<script type="text/javascript" src="${this.urlTrackJs}.js?v=${new Date().getTime()}"></${'script'}>
<!-- End Load Code -->`
    },
    storageCode: function() {
      return `<!-- Storage Code -->
<script type="text/javascript">ApojawsTracks.trackStorage('${this.company.attributes.company_code}', '${this.redirectUrl}')</${'script'}>
<!-- End Storage Code -->`
    },
    accessCode: function() {
      return `<!-- Access Code -->
<script type="text/javascript">ApojawsTracks.trackAccess('${this.company.attributes.company_code}', 'div=部署&user=会員ID&item=物件ID')</${'script'}>
<!-- End Access Code -->`
    },
    accessTagText: function() {
      return this.loadCode + '\r' + this.storageCode + '\r' + this.accessCode
    },
    conversionCode: function() {
      return `<!-- Conversion Code -->
<script type="text/javascript">ApojawsTracks.trackConversion('${this.company.attributes.company_code}', 'div=部署&user=会員ID&item=物件ID&cv=反響種別&rel=反響ID')</${'script'}>
<!-- End Conversion Code -->`
    },
    telConversionBtnCode: function() {
      return `<!-- Tel Conversion Code(click tel conversion) -->
<script type="text/javascript">ApojawsTracks.trackClassTagTelConversion('.apojows_tel_conversion','${this.company.attributes.company_code}', 'div=部署&user=会員ID&item=物件ID&cv=反響種別&rel=反響ID')</${'script'}>
<!-- End Tel Conversion Code(click tel conversion) -->`
    },

    telConversionAccessCode: function() {
      return `<!-- Tel Conversion Code(page access tel conversion) -->
<script type="text/javascript">ApojawsTracks.trackTelConversion('${this.company.attributes.company_code}', 'div=部署&user=会員ID&item=物件ID&cv=反響種別&rel=反響ID')</${'script'}>
<!-- End Tel Conversion Code(page access tel conversion) -->`
    }
  },

  async created() {
    await this.show(this.$route.params)
  },

  mounted: function() {
  },

  methods: {
    ...mapActions('admin_cross_domain_tags', [
      'show',
      'downloadJavascript',
      'downloadHtml'
    ]),
    submitDownloadJavascrip() {
      this.downloadJavascript(this.$route.params).then (responseJavascript => {
        this.fileDownload({filePath: 'apojaws_track.js', type: 'text/javascript', content: responseJavascript.data })
      })
    },
    submitDownloadHtml() {
      this.downloadHtml(this.$route.params).then (responseHtml => {
        this.fileDownload({filePath: 'apojaws.html', type: 'text/html', content: responseHtml.data })
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.title {
  color: grey;
}
</style>
