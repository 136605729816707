<template lang='pug'>
v-card
  v-card-title(class="title cyan lighten-2" primary-title)
    | アポ・成約者分析
  v-divider
  v-card-text
    v-form(v-model="searchValid" lazy-validation=true)
      v-layout(row xs12 class="mb-3")
        v-flex.mr-3(xs6)
          v-menu(v-model='pickerMenuFrom' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model='searchCondition.from_created' label='Fromを選択' prepend-icon='event' readonly='' v-on='on' :rules='fromCreatedRules')
            v-date-picker(v-model='searchCondition.from_created' @input='pickerMenuFrom = false' locale='ja')
        v-flex(xs6)
          v-menu(v-model='pickerMenuTo' :close-on-content-click='false' :nudge-right='40' lazy='' transition='scale-transition' offset-y='' full-width='' min-width='290px')
            template(v-slot:activator='{ on }')
              v-text-field(v-model='searchCondition.to_created' label='Toを選択' readonly='' v-on='on' :rules='toCreatedRules')
            v-date-picker(v-model='searchCondition.to_created' @input='pickerMenuFrom = false' locale='ja')
      v-layout(row xs12 class="mb-3")
        v-flex.mr-3(xs6)
          v-text-field(v-model='searchCondition.filter_start' type='number' label='秒以上' prepend-icon='access_alarm' :rules='filterStartRules')
        v-flex(xs6)
          v-text-field(v-model='searchCondition.filter_end' type='number' label='秒以下' :rules='filterEndRules')
  v-card-actions
    v-spacer
    v-btn(color="" @click="submitClose")
     | 閉じる
    v-btn(color="primary" @click="submitSearch" :disabled="!searchValid" :loading='loading')
     | 検索
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'TheAnalysisSearchField',
  data: function () {
    return {
      searchValid: true,
      pickerMenuFrom: false,
      pickerMenuTo: false,
      fromCreatedRules: [
        v => (!v || !this.searchCondition.to_created || v < this.searchCondition.to_created) || '入力範囲が異常です。'
      ],
      toCreatedRules: [
        v => (!v || !this.searchCondition.from_created || v > this.searchCondition.from_created) || '入力範囲が異常です。'
      ],
      filterStartRules: [
        v => (!v || !this.searchCondition.filter_end || v < this.searchCondition.filter_end) || '入力範囲が異常です。'
      ],
      filterEndRules: [
        v => (!v || !this.searchCondition.filter_start || v > this.searchCondition.filter_start) || '入力範囲が異常です。'
      ],
    }
  },

  computed: {
    searchCondition: {
      get() {
        return this.$store.state.analysis.searchCondition
      },
      set(value) {
        this.$store.commit('analysis/setSearchCondition', value)
      }
    },
    loading: {
      get() {
        return this.$store.state.analysis.loading
      },
      set (value) {
        this.$store.commit('analysis/setLoading', value)
      }
    },
  },

  beforeDestroy: function(){
    this.clearSearchCondition()
  },

  methods: {
    ...mapMutations('analysis', [
      'clearSearchCondition'
    ]),
    submitClose() {
      this.$emit('close')
    },
    async submitSearch() {
      this.$emit('close')
      await this.$emit('fetch')
    },
  },

}
</script>

<style lang='scss' scoped>
.title {
  color: black;
}
</style>
