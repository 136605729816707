import api from 'api'

// DB関連付けの対象リスト
const IncludeLists = [
  {
    key: 'ip_black_lists',
    type: 'ip_black_list',
  },
  {
    key: 'ip_white_lists',
    type: 'ip_white_list',
  },
  {
    key: 'use_iframe_urls',
    type: 'use_iframe_url',
  },
  {
    key: 'domain_groups',
    type: 'domain_group'
  }
]

// 更新or作成フォームアトリビュート
const FormAttribute = {
  company_name: '',
  group_name: '',
  url: '',
  email: '',
  conv_email: '',
  revisit_flag: false,
  is_only_individuals: false,
  beacon_flag: false,
  bcc_email: '',
  auth_user: '',
  report_rsort: false,
  edit_relation_code: false,
  stop_zerotime_conversion: false,
  cross_domain_flag: false,
  is_ana_user: false,
  is_auto_mail: false,
  is_chart: false,
  has_tel_conversion: false,
  has_ranking: false,
  has_auto_ranking: false,
  report_page_default: '',
  is_redirect_to_newly_site: false,
  storage_redirect_url: '',
}

export default {
  namespaced: true,

  state: {
    companies: [],
    company: {},
    homeRedirectUrl: '',
    isHomeUrlRedirect: '',
    totalPages: 0,
    loading: false,
  },

  getters: {
    getCompanies(state) {
      return state.companies
    },
    getCompany(state) {
      return state.company
    },

    getCompaniesSize(state) {
      return state.companies.length
    },

    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          descending: false,
          sortBy: 'id',
        },
        searchConditions: {
          name: '',
          email: '',
          company_role_id: '',
          affiliation_dept: '',
          affiliation_shop: '',
        }
      }
    },
  },

  mutations: {
    setCompanies(state, companies) {
      state.companies = companies
    },

    setCompany(state, company) {
      state.company = company

      // DB関連付けの格納先
      company.relation = {}
      for (let key in company.included)  {
        let include = company.included[key]
        if (company.relation[include.type] == undefined) {
          company.relation[include.type] = [include]
        } else {
          company.relation[include.type].push(include)
        }
      }
    },

    setFormCompany(state, data) {
      // 初期化フォームより不足アトリビュートを初期化
      for (let atrKey in FormAttribute) {
        if (data.data.attributes[atrKey] == undefined) {
          data.data.attributes[atrKey] = FormAttribute[atrKey]
        }
      }
      // 初期化フォーム以外のデータを削ぎ落とす
      for (let dataKey in data.data.attributes) {
        if (FormAttribute[dataKey] == undefined) {
          delete data.data.attributes[dataKey]
        }
      }

      // DB関連付けの格納先
      data.relation = {}

      // parseテキストデータ格納先
      // Hanplus::Companyモデルでparseしたデータ
      data.parse = {}

      this.commit('company/initRelationList', {data: data})
      this.commit('company/initParseText', { data: data })
      delete data.included
      delete data.links
      state.company = data
    },

    initRelationList(state, {data: data}) {
      if (data.included == undefined) {
        return
      }
      IncludeLists.forEach(function (included) {
        let key = data.relation[included.key] = []

        data.included.filter(include =>
          include.type == included.type
        ).forEach(function(e) {
          e.vue = {}
          e.vue.needSave = true
          e.vue.existDB = true
          key.push(e)
        })

        // 画面追加用のインスタンス
        this.commit('company/pushList', {targetList: key})
      },this)
    },

    initParseText(state, {data: data}) {
      for(let key in data.links) {
        let parseKey = data.parse[key] = []
        data.links[key].forEach(function(parseText) {
          parseKey.push(
            {
              vue: {
                needSave: true,
                existDB: true
              },
              attributes: parseText
            }
          )
        })
        // 画面追加用のインスタンス
        this.commit('company/pushList', {targetList: parseKey})
      }
    },

    pushList(state, {targetList: targetList, idx: idx}) {
      // 追加ボタン押下時
      if (idx != undefined) {
        let saveTarget = targetList[idx]
        saveTarget.vue.needSave = true
      }
      let addEl = Object.assign({}, targetList[0])
      for (let key in addEl) {
        if (key == 'vue') {
          continue
        }
        addEl[key] = ''
      }
      // 画面追加用のインスタンス
      addEl.attributes = {}
      addEl.vue = {}
      addEl.vue.needSave = false
      addEl.vue.existDB = false
      targetList.push(addEl)
    },

    popList(state, {targetList: targetList, idx: idx}) {
      let deleteEl = targetList[idx]
      if (!deleteEl.vue.existDB) {
        targetList.splice(idx, 1)
      } else {
        deleteEl.vue.needSave = false
      }
    },

    setTotalPages(state, totalPages) {
      state.totalPages = totalPages
    },

    setLoading(state, loading) {
      this.loading = loading
    },

    clearCompany(state) {
      state.company = {}
    },

    spliceCopmany(state, idx) {
      state.companies.splice(idx, 1)
    },

    clearCompanies(state) {
      state.companies = []
    },

    setHomeRedirectUrl(state, homeRedirectUrl) {
      state.homeRedirectUrl = homeRedirectUrl
    },
    setIsHomeUrlRedirect(state, isHomeUrlRedirect) {
      state.isHomeUrlRedirect = isHomeUrlRedirect
    },
  },

  methods: {
  },

  actions: {
    fetch({commit}) {
      let options = {
        params: this.getters['route/getPaginationApiParam']
      }
      return new Promise((resolve) => {
        api.get('admin/companies', options)
          .then(response => {
            let data = response.data
            commit('setCompanies', data.data)
            commit('setTotalPages', data.links.total_pages)
            resolve()
          })
      })
    },

    edit({commit}, id) {
      return new Promise((resolve) => {
        api.get('admin/companies/' + id + '/edit')
          .then(response => {
            let data = response.data
            commit('setIsHomeUrlRedirect', response.data.is_home_url_redirect)
            commit('setFormCompany', data.company)
            commit('setHomeRedirectUrl', data.home_redirect_url)
            resolve()
          })
      })
    },

    async new({commit}) {
      let data = {
        data: {
          attributes: {
            bcc_email: 'apojaws@gmail.com'
          },
        },
        included: [],
        links: {
          parse_div_emails: [],
          parse_cv_titles: [],
        }
      }
      commit('setFormCompany', data)
      const response = await api.get('admin/companies/new').then(response => {
        commit('setIsHomeUrlRedirect', response.data.is_home_url_redirect)
        commit('setHomeRedirectUrl', response.data.home_redirect_url)
        return response
      }).catch(err => {
        return err.response
      })
      return response
    },

    update({commit}, params) { // eslint-disable-line
      let options = {
        params: params
      }
      return new Promise((resolve) => {
        api.patch('admin/companies/' + params.data.id, options)
          .then(response => {
            resolve(response)
          }).catch(error => {
            resolve(error.response)
          })
      })
    },

    create({commit}, params) { // eslint-disable-line
      let options = {
        params: params
      }
      return new Promise((resolve) => {
        api.post('admin/companies', options)
          .then(function(response) {
            resolve(response)
          }).catch(error => {
            resolve(error.response)
          })
      })
    },

    delete({commit}, {idx: idx, company_id: company_id}) {
      return new Promise((resolve) => {
        api.delete('admin/companies/' + company_id)
          .then(response => {
            commit('spliceCopmany', idx)
            resolve(response)
          }).catch(error => {
            resolve(error.response)
          })
      })
    },

    show({commit}, {company_id: company_id}) {
      return new Promise((resolve) => {
        api.get('admin/companies/' + company_id)
          .then(response => {
            commit('setCompany', response.data)
            resolve()
          })
      })
    },

    pushList({commit}, {targetList: targetList, idx: idx}) {
      commit('pushList', {targetList: targetList, idx: idx})
    },

    popList({commit}, {targetList: targetList, idx: idx}) {
      commit('popList', {targetList: targetList, idx: idx})
    },

    clearCompany({commit}) {
      commit('clearCompany')
    }
  },
}
