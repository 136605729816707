import { render, staticRenderFns } from "./Revisit.vue?vue&type=template&id=05a58498&scoped=true&lang=pug&"
import script from "./Revisit.vue?vue&type=script&lang=js&"
export * from "./Revisit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a58498",
  null
  
)

export default component.exports