import api from 'api'
import qs from 'qs'

const DEFAULT_CONVERSION_LOG = {
  attributes: {}
}
const DEFAULT_USER_COOKIE = {
  attributes: {}
}
const DEFAULT_REVISIT = {
  attributes: {}
}

export default {
  namespaced: true,

  state: {
    conversionLog: {},
    accessLogs: [],
    pagination: {},
    searchConditions: {},
    accessLogTotalPage: 1,
    userSessions: [],
    docodocoIpParams: {},
    userCookie: {},
    revisits: [],
    revisit: {},
    loading: false,
  },

  getters: {
    conversionLogsRelationCode(state) {
      return state.conversionLog.attributes.relation_code ?
        state.conversionLog.attributes.relation_code.replace(/,/g, ', ') : ''
    },
    conversionLogsItemCode(state) {
      return state.conversionLog.attributes.item_code ?
        state.conversionLog.attributes.item_code.replace(/,/g, ', ') : ''
    },
    conversionLogsCreated(state) {
      return state.revisit.attributes.conversion_created_jaymd ?
        state.revisit.attributes.conversion_created_jaymd : state.conversionLog.attributes.created
    },
    userSessionRefereUrl: (state) => (idx) => {
      return state.userSessions[idx].attributes.referer_url || '不明'
    },
    userSessionSearchWord: (state) => (idx) => {
      return state.userSessions[idx].attributes.search_word || '不明'
    },
    userSessionIpSource: (state) => (idx) => {
      return state.docodocoIpParams[state.userSessions[idx].attributes.ip]
    },
    accessLogScreenShotStatus: (state) => (idx) => {
      return state.accessLogs[idx].attributes.screen_shot.attributes.status
    },
    revisitUserCookie: (state) => (idx) => {
      return state.revisits[idx].attributes.user_cookie.data
    },
    getInitUrlQuery() {
      return {
        pagination: {
          currentPage: 1,
          descending: true,
          rowsPerPage: 20,
        },
        searchConditions: {
          sort: '',
          browseTimeFrom: -1,
        }
      }
    }
  },

  mutations: {
    setConversionLog(state, conversionLog) {
      state.conversionLog = conversionLog
    },
    setAccessLogs(state, accessLogs) {
      accessLogs.forEach(accessLog => {
        accessLog.attributes.vue_is_image_loading = !accessLog.attributes.is_iframe_screen
        accessLog.attributes.vue_is_show_img_dialog = false
      })
      state.accessLogs = accessLogs
    },
    setAccessLogVueIsimageLoading(state, {accessLog: accessLog, vue_is_image_loading: vue_is_image_loading} ) {
      accessLog.attributes.vue_is_image_loading = vue_is_image_loading
    },
    setSearchConditions(state, searchConditions) {
      state.searchConditions = searchConditions
    },
    setPagination(state, pagination) {
      state.pagination = pagination
    },
    setRowsPerPage(state, rowsPerPage) {
      state.pagination.rowsPerPage = rowsPerPage
    },
    setAccessLogTotalPage(state, accessLogTotalPage) {
      state.accessLogTotalPage = accessLogTotalPage
    },
    setdocodocoIpParams(state, docodocoIps) {
      docodocoIps.forEach((docodocoIp) => {
        state.docodocoIpParams[docodocoIp.attributes.ip] = docodocoIp.attributes.access_source
      })
    },
    setUserCookie(state, userCookie) {
      state.userCookie = userCookie
    },
    setUserSessions(state, userSessions) {
      state.userSessions = userSessions
    },
    setUserSessionTotalPage(state, userSessionTotalPage) {
      state.userSessionTotalPage = userSessionTotalPage
    },
    setBrowseTimeFrom(state, browseTimeFrom) {
      state.searchConditions.browseTimeFrom = browseTimeFrom
    },
    setSort(state, sort) {
      state.searchConditions.sort = sort
    },
    setPagePer(state, pagePer) {
      state.searchConditions.pagePer = pagePer
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setRevisits(state, revisits) {
      state.revisits = revisits
    },
    setRevisit(state, revisit) {
      state.revisit = revisit
    }
  },

  actions: {
    async fetch({ state, commit }, urlParams) {
      state.loading = true
      const params = {
        access_log_search: {
          browse_time_from: state.searchConditions.browseTimeFrom,
          sort: state.searchConditions.sort,
        }
      }
      Object.assign(params, this.getters['route/getPaginationApiParam'])
      const options = {
        params: params,
        paramsSerializer: (params) => qs.stringify(params)
      }
      const response =  await api.get('conversion_logs/' + urlParams.conversion_log_id + '/revisits/' + urlParams.revisit_id, options).then(response => {
        const data = response.data
        commit('setConversionLog', data.conversion_log.data)
        commit('setAccessLogs', data.access_logs.data)
        commit('setAccessLogTotalPage', data.access_logs.links.total_pages)
        commit('setUserSessions', data.user_sessions.data)
        commit('setdocodocoIpParams', data.docodoco_ips.data)
        commit('setBrowseTimeFrom', data.access_log_search_params.browse_time_from)
        commit('setSort', data.access_log_search_params.sort)
        commit('setUserCookie', data.user_cookie.data)
        commit('setRevisits', data.revisits.data)
        commit('setRevisit', data.revisit.data)
        return response
      }).catch(err => {
        return err.response
      })
      state.loading = false
      return response
    },
    async init({ commit }) {
      commit('setConversionLog', JSON.parse(JSON.stringify(DEFAULT_CONVERSION_LOG)))
      commit('setUserCookie', JSON.parse(JSON.stringify(DEFAULT_USER_COOKIE)))
      commit('setRevisit', JSON.parse(JSON.stringify(DEFAULT_REVISIT)))
    },

    async initState({ commit } ) {
      commit('setSearchConditions', JSON.parse(JSON.stringify(this.state.route.searchConditions)))
      commit('setPagination', JSON.parse(JSON.stringify(this.state.route.pagination)))
    },

    reloadImg({state, dispatch}) {
      state.accessLogs.forEach(accessLog => {
        dispatch('reloadImgAccessLog', accessLog)
      })
    },

    reloadImgAccessLog({commit, dispatch}, accessLog) {
      if (!accessLog.attributes.vue_is_image_loading) {
        return
      }
      const img = new Image()
      img.src = accessLog.attributes.screen_shot.attributes.img_full_path
      img.onload = function() {
        commit('setAccessLogVueIsimageLoading', {accessLog: accessLog, vue_is_image_loading: false})
      }
      img.onerror = function() {
        setTimeout(() => {
          dispatch('reloadImgAccessLog', accessLog)
        }, 10000)
      }
    },
    async screenReload({state, dispatch}, {accessLogIdx: accessLogIdx, urlParams: urlParams}) {
      state.loading = true
      const accessLog = state.accessLogs[accessLogIdx]
      const response =  await api.get('access_logs/' + accessLog.id + '/screen_shot_reload', {}).then(response => {
        return response
      }).catch(err => {
        return err.response
      })
      await dispatch('fetch', urlParams)
      state.loading = false
      return response
    }
  }
}
