<template lang='pug'>
v-layout.column
  h3.headline
    | 自動メール作成
  v-card.mb-5.pr-3.pl-3
    v-card-text
      v-layout(raw warp)
        v-menu.inline-menu(v-for="(mailFormats, sumCategoryId) in mailFormatsBySumCategoryId" offset-y :key="sumCategoryId")
          template(v-slot:activator='{on}')
            v-btn(color='primary' dark v-on='on') {{ sumCategoryById(sumCategoryId).attributes.name }}
          v-list
            v-list-tile(v-for="mailFormat in mailFormats" @click='submitMailFormat(mailFormat)' :key="mailFormat.id")
              v-list-tile-title {{ mailFormat.attributes.category_small_name }}
      v-layout.mb-3(raw warp)
        v-btn(@click='submitCopy') 下記メール文章をコピー
      v-layout(raw warp)
        v-textarea#replace-text(v-model='mailDefault.attributes.replace_mail_body' outline full-width auto-grow)
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheConversionLogsAutoMail',

  props: {
  },
  computed: {
    ...mapGetters('conversion_logs_auto_mail', [
      'sumCategoryById'
    ]),
    mailFormatsBySumCategoryId: function() {
      return this.$store.state.conversion_logs_auto_mail.mailFormatsBySumCategoryId
    },
    mailFormats: function() {
      return this.$store.state.conversion_logs_auto_mail.mailFormats
    },
    mailDefault: function() {
      return this.$store.state.conversion_logs_auto_mail.mailDefault
    },
  },
  methods: {
    ...mapActions('conversion_logs_auto_mail', [
      'chgReplaceMailBody',
    ]),
    async submitMailFormat(mailFormat) {
      const obj = document.getElementById('replace-text')
      obj.focus()
      const str = mailFormat.attributes.mail_body
      if (navigator.userAgent.match(/MSIE/)) {
        var r = document.selection.createRange()
        r.text = str
        r.select()
      } else {
        const s = this.mailDefault.attributes.replace_mail_body
        const p = obj.selectionStart
        const np = p + str.length
        await this.chgReplaceMailBody(s.substr(0, p) + str + s.substr(p))
        obj.setSelectionRange(np, np)
      }
    },
    submitCopy() {
      const copyFrom = document.createElement('textarea')
      copyFrom.textContent = this.mailDefault.attributes.replace_mail_body
      document.body.appendChild(copyFrom).select()
      document.execCommand('copy')
      copyFrom.remove()
    },
  }
}
</script>

<style lang='scss' scoped>
div.inline-menu {
  display: inline;
}
</style>
