<template lang='pug'>
v-layout.align-center.justify-center
  v-flex.xs12.sm8.md4
    v-card
      v-card-text
        v-layout.align-center.justify-center
          img(src='~apojaws.svg')
      v-card-text
        v-subheader パスワードを再発行したいアカウントのメールアドレスを入力してください。
        v-form(ref='form' v-model='valid' lazy-validation @submit.prevent='submit')
          v-text-field(v-model='email' prepend-icon='person' name='email' label='ID' type='email' :rules='emailRules' required)
          v-card-actions
            v-layout.align-center.justify-center
              v-btn(color='accent' :disabled='!valid || loading' :loading='loading' type='submit')
                v-icon mail
                | 送信する
          v-layout.align-center.justify-center.column
            v-card-text ※入力したメールアドレス宛に再発行用のメールが送信されます。
</template>

<script>
import 'logo.jpg'
import api from 'api'

export default {
  name: 'SendMail',

  data () {
    return {
      valid: true,
      loading: false,
      email: '',
      emailRules: [
        v => !!v || '必須入力です。',
        v => (v && v.length <= 255) || '255文字以内で入力してください。',
        v => /.+@.+/.test(v) || 'メールアドレスの形式で入力してください。',
      ],
    }
  },

  methods: {
    async submit () {
      if (!this.validate()) {
        return
      }

      this.loading = true

      const options = {
        notAuth: true,
        params: { email: this.email },
      }

      const res = await api.post('/users/password_reissue', options).then(function (response) {
        return response
      }).catch(err => {
        return err.response
      })

      if (res.status == 200) {
        this.$router.push({ name: 'password_reissue_complete'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error' })
      }

      this.loading = false
    },

    validate () {
      return this.$refs.form.validate()
    },
  }
}
</script>

<style lang='scss' scoped>
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
