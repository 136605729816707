export default {
  namespaced: true,

  state: {
    message: '',
    color: '',
    needDisplay: false,
    timeout: 6000,
  },

  mutations: {
    setMessage(state, {message: message, color: color, timeout: timeout}) {
      state.message = message
      state.color = color
      state.needDisplay = true
      state.timeout = timeout
    },

    clearMessage(state) {
      state.message = ''
      state.color = ''
      state.needDisplay = false
      state.timeout = 6000
    }
  },

  actions: {
    showMessage({commit}, {message: message, color: color='info', timeout: timeout}) {
      // エラーつタイムアウト未設定の場合はタイムアウトを0にする
      if (!timeout && color.match(/error/)) {
        timeout = 0
      }

      commit('clearMessage')
      // 既に開いているsnackbarが存在する場合、一度閉じるためにsetTimeoutでパラメター反映させる。
      setTimeout(() => {
        commit('setMessage', {message: message, color: color, timeout: timeout})
      }, 100)
    }
  }
}
