export default {
  namespaced: true,

  state: {
    name: '',
    path: '',
    hash: '',
    query: '',
    params: '',
    fullPath: '',
    meta: '',
    pagination: {},
    searchConditions: {},
    rankingSearchConditions: {}
  },

  getters: {
    getOrder(state) {
      return state.pagination.descending ? 'desc' : 'asc'
    },
    getPaginationApiParam: (state, getters) => {
      return {
        page: state.pagination.currentPage,
        sort_by: state.pagination.sortBy,
        page_per: state.pagination.rowsPerPage,
        order: getters.getOrder,
      }
    }
  },

  mutations: {
    setRoute(state, to) {
      state.name = to.name
      state.path = to.path
      state.hash = to.hash
      state.query = to.query
      state.params = to.params
      state.fullPath = to.fullPath
      state.meta = to.meta
      new Array('pagination', 'searchConditions', 'rankingSearchConditions').forEach((queryKey) => {
        state[queryKey] = state.query[queryKey] ? state.query[queryKey] : {}
      })
    },
  },
  actions: {
    async initRoute({commit}, to) {
      commit('setRoute', to)
      return true
    }
  },
}
