<script>
export default {
  name: 'TheFileMixin',

  methods: {
    fileDownload({filePath: filePath, type: type, content: content}) {
      const blob = new Blob([content], { 'type' : type })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filePath)
        window.navigator.msSaveOrOpenBlob(blob, filePath)
      } else {
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.href = window.URL.createObjectURL(blob)
        link.download = filePath
        link.click()
        link.remove()
      }
    },

    // { status: String, texts: [String] } をコールバックで返却する
    async readFile({file: file, separator: separator},anyCallbackFunc) {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        anyCallbackFunc({status: 'no_support', texts: []})
      }
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onerror = function() {
        anyCallbackFunc({status: 'error', texts: []})
      } 
      reader.onload = function() {
        const texts = reader.result.split(separator)
        anyCallbackFunc({status: 'ok', texts: texts})
      }
    }
  },
}
</script>

