<template lang='pug'>
v-layout.align-center.justify-center
  v-flex.xs12.sm8.md4
    v-card
      v-card-text
        v-layout.align-center.justify-center
          img(src='~apojaws.svg')
      v-card-text(v-if='Object.keys(preUser).length !== 0')
        div.title {{ preUser.email }} 様
        v-subheader 登録するパスワードを入力してください。
        v-form(ref='form' v-model='valid' lazy-validation @submit.prevent='submit')
          v-text-field#password(v-model='formAttributes.password' prepend-icon='lock' name='password' label='パスワード' type='password' :rules='passwordRules' required)
          v-text-field#password(v-model='formAttributes.password_confirmation' prepend-icon='lock' name='password_confirmation' label='パスワード再入力' type='password' :rules='passwordConfirmationRules' required)
          v-card-actions
            v-layout.align-center.justify-center
              v-btn(color='accent' :disabled='!valid || loading' :loading='loading' type='submit')
                v-icon save
                | 登録する
      v-card-actions.mt-3(v-else)
        v-layout.align-center.justify-center
          router-link.btn-link(:to="{ name: 'login' }")
            v-btn(color='accent')
              v-icon keyboard_backspace
              | ログイン画面へ
</template>

<script>
import 'logo.jpg'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Activate',

  data () {
    return {
      valid: true,
      passwordRules: [
        v => !!v || '必須入力です。',
        v => (v && v.length <= 72) || '72文字以内で入力してください。',
        v => /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{8,}$/i.test(v) || '8文字以上かつ、数字・英字・記号をそれぞれ最低1文字含むパスワードにしてください',
      ],
      passwordConfirmationRules: [
        v => !!v || '必須入力です。',
        v => v === this.formAttributes.password || 'パスワード入力が一致していません',
      ],
    }
  },

  computed: {
    preUser: function () {
      return this.$store.state.pre_user.preUser
    },

    formAttributes: {
      get () {
        return this.$store.state.pre_user.formAttributes
      },
      set (value) {
        this.$store.commit('pre_user/setFormAttributes', value)
      }
    },

    loading: {
      get () {
        return this.$store.state.pre_user.loading
      },
      set (value) {
        this.$store.commit('pre_user/changeLoading', value)
      }
    },
  },

  async created() {
    this.loading = true
    const res = await this.fetch({ token: this.$route.params.token })
    if (res.status != 200 && res.status != 500) {
      this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error' })
    }

    this.loading = false
  },

  beforeDestroy: function() {
    this.clearPreUser()
    this.clearFormAttributes()
  },

  methods: {
    ...mapActions('pre_user', [
      'fetch',
      'activate',
    ]),

    ...mapMutations('pre_user', [
      'changeLoading',
      'setFormAttributes',
      'clearPreUser',
      'clearFormAttributes',
    ]),

    async submit () {
      if (!this.validate()) {
        return
      }

      this.loading = true

      const res = await this.activate()
      if (res.status == 200) {
        this.$router.push({ name: 'pre_user_complete'})
      } else if (res.status == 500) {
        this.loading = false
        return
      } else {
        this.$store.dispatch('flash/showMessage', { message: res.data.errors.join('\r\n'), color: 'error' })
      }

      this.loading = false
    },

    validate () {
      return this.$refs.form.validate()
    },
  }
}
</script>

<style lang='scss' scoped>
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
